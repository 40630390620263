import { API_EMAIL } from "../../constants";
import { checkAuthorised } from "../../helpers/checkAuthorisedAPI";
import { Email } from "../../types/Email";

export const sendEmail = (email: Email): Promise<any> => {
  return fetch(`${API_EMAIL}/Send`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: JSON.stringify(email),
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.payload);
}
