import './styles.scss';

import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import { Label, LabelProps, TextInput } from '@flotilla/component-library';
import Fuse from 'fuse.js';

import { Action, Actions } from '../../../../../../types/Action';
import useActions from '../../../../../../hooks/Action/useActions';
import ActionCard from '../../../../../../components/ActionCard';

interface ActionSelectorProps {
  label?: LabelProps;
  className?: string;
  currentAction?: Action;
  selectedActions?: Actions;
  removeActions?: Actions;
  targetProducts?: string[];
  onChange?: (actions?: Actions) => void;
};

const ActionSelector: FC<ActionSelectorProps> = ({
  currentAction,
  label,
  className = "",
  selectedActions: initialSelectedActions,
  removeActions,
  targetProducts = [],
  onChange = () => ({}),
}) => {
  const { actions } = useActions(true);
  const [searchActions, setSearchActions] = useState<Actions>();
  const [selectedActions, setSelectedActions] = useState<Actions>();
  const [searchTerm, setSearchTerm] = useState("");
  const fuse = new Fuse(actions || [], { keys: ['title'] });

  useEffect(() => {
    initialSelectedActions && setSelectedActions(initialSelectedActions);
  }, [initialSelectedActions]);

  useEffect(() => {
    setSearchActions(getSelectableActions(searchActions));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActions, removeActions]);

  const getSelectableActions = (actions: Actions = []): Actions => {
    const selectedIds = [...(selectedActions?.map((item) => item.id) || []), ...(removeActions?.map((item) => item.id) || []), currentAction?.id || -1];
    return [...actions].filter((item) => !selectedIds?.includes(item.id) && (targetProducts.length === 0 || item.products?.find(p => targetProducts.includes(p.label))));
  }

  const handleAddAction = (action?: Action) => {
    if (action) {
      const updatedList = [...(selectedActions || []).filter((value) => value.id !== action.id), action];
      setSelectedActions(updatedList);
      onChange(updatedList);
    }
  }

  const handleAddActionClick = (action?: Action) => {
    return () => {
      handleAddAction(action);
    }
  }

  const handleRemoveActionClick = (action: Action) => {
    return () => {
      const updatedList = (selectedActions || []).filter((value) => value.id !== action.id);
      setSelectedActions(updatedList);
      onChange(updatedList);
    }
  }

  const handleOnChangeSearchTerm: ChangeEventHandler<HTMLInputElement> = (event) => {
    const searchWord = event.currentTarget.value;
    const items = fuse.search(searchWord || '');
    setSearchActions(getSelectableActions(items.map((item) => item.item)).slice(0, 15));
    setSearchTerm(searchWord);
  }

  return (
    <section id="action-selector" className={className}>
      { label && <Label {...label}/>}
      { (selectedActions?.length || 0) > 0 && (
        <article id="selected-actions-container" className="actions-container">
          { selectedActions?.map((item) => 
            <ActionCard 
              key={item.id}
              action={item}
              onClose={handleRemoveActionClick(item)}
              targetProducts={targetProducts}
              reducedDetail />)}
        </article>
      )}
      <TextInput
        id="search-actions"
        placeholder='Search actions...'
        value={searchTerm}
        onChange={handleOnChangeSearchTerm}
      />
      { (searchActions?.length || 0) > 0 && (
        <article id="option-actions-container" className="actions-container">
          { searchActions?.map((item) => 
            <ActionCard
              key={item.id}
              action={item}
              onClick={handleAddActionClick(item)}
              targetProducts={targetProducts}
              reducedDetail />)}
        </article>
      )}
    </section>
  );
}

export default ActionSelector;
