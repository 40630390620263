import './styles.scss';

import React, { ChangeEventHandler, useState } from 'react';
import { TextInput } from '@flotilla/component-library';

import { SectionProps } from '../../../../../../types/CreateWizard';

const LocationNameSection: React.FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const [name, setName] = useState<string>(values?.["name"]);

  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.currentTarget.value;
    setName(value);
    onChange({ name: value });
  }

  return (
    <section id="location-name-section" className={className}>
      <TextInput
        id="location-name"
        label="Location Name"
        value={name}
        maxLength={50}
        onChange={handleNameChange}
      />
    </section>
  );
}

export default LocationNameSection;
