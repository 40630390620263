import './styles.scss';

import React from 'react';

import { SectionProps } from '../../../../../../types/CreateWizard';

const FinalSection: React.FC<SectionProps> = ({
  className = "",
  values
}) => {
  return (
    <section id="survey-final-section" className={className}>
      <article className="stat">
        <p>Number of Employees</p>
        <h3>{values?.['sentTo']}</h3>
      </article>
      <article className="stat">
        <p>Survey Dates</p>
        <h3>{new Date(values?.['fromDate']).toLocaleDateString()} -  {new Date(values?.['toDate']).toLocaleDateString()}</h3>
      </article>
    </section>
  );
}

export default FinalSection;
