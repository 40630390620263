import './styles.scss';

import React from 'react';

interface TrainingHomeProps {
  className?: string;
};

const TrainingHome: React.FC<TrainingHomeProps> = ({
  className = "",
}) => {
  return (
    <section id="training-home-page" className={className}>
      <header>
        <h1>Training</h1>
      </header>
      <iframe
        title="Flotilla World Climate Academy"
        src='https://flotillastorage.blob.core.windows.net/flotillaworld/Flotilla World Climate Academy.pdf'
      />
    </section>
  );
}

export default TrainingHome;
