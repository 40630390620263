import './styles.scss';

import ReportUl from '../../ReportUl';
import ReportLi from '../../ReportLi';
import ReportDiv from '../../ReportDiv';
import ReportH3 from '../../ReportH3';
import { ReportEditorProps } from '..';

export const UnorderedList: React.FC<ReportEditorProps> = ({
  editorContent,
  downloadVersion = false,
  style
}) => {
  const { header = '', content = [], boxAccentColor = '' } = editorContent;

  return (
    <ReportDiv
      className={`report-editor-unordered-list-container`}
      downloadVersion={downloadVersion}
      style={style}
    >
      {header && (
        <ReportH3 downloadVersion={downloadVersion}>{header}</ReportH3>
      )}
      <ReportUl
        className="report-editor-unordered-list"
        downloadVersion={downloadVersion}
      >
        {content?.map((contentItem, index) => (
          <ReportLi
            key={index}
            style={{
              borderColor: boxAccentColor === 'dark' ? '#011e26' : '#e35924',
            }}
            downloadVersion={downloadVersion}
          >
            {contentItem}
          </ReportLi>
        ))}
      </ReportUl>
    </ReportDiv>
  );
};
