
export type CompanyTask = {
  id: number;
  title: string;
  description: string;
  navLink: string;
  readMoreLink?: string;
  completed: boolean;
};

export type CompanyTasks = CompanyTask[];

export const mapCompanyTasks = (data: Partial<CompanyTask>[]): CompanyTasks => {
  return data.map((item) => ({
    completed: false,
    ...item,
  })) as CompanyTasks;
}