import './styles.scss';

import { StyleSheet } from '@react-pdf/renderer';

import { Data } from '../helpers';
import { BarChart, BartCO2eDifference } from '../../../BarChart';
import {
  ReportDiv,
  ReportH4,
  ReportP,
  ReportChart,
} from '../../../../../../../../components/PDFReport';

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  circleStat: {
    padding: 7,
    border: '6px solid #0b8577',
    backgroundColor: '#fbfaf8',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    width: 85,
    height: 85,
    textAlign: 'center',
    lineHeight: 0.9,
  },
});

type HowFarDoYouDriveToWorkProps = {
  className?: string;
  data: Data;
  averageDrivingCommute?: number;
  downloadVersion?: boolean;
};

const dataOrder: { [key: string]: number } = {
  '0-2 miles': 0,
  '2-5 miles': 1,
  '5-10 miles': 2,
  '10-20 miles': 3,
  '20+ miles': 4,
};

const getColor = () => {
  return '#0B8577';
};

export const HowFarDoYouDriveToWork: React.FC<HowFarDoYouDriveToWorkProps> = ({
  className = '',
  data,
  averageDrivingCommute,
  downloadVersion = false,
}) => {
  const chartData =
    data.data.map((obj) => ({
      key: obj.xLabel,
      [obj.xLabel]: obj.value,
    })) ?? [];
  chartData.sort((a, b) => dataOrder[b.key] - dataOrder[a.key]);

  return (
    <ReportDiv
      id="how-far-do-you-drive-to-work"
      className={className}
      style={styles.container}
      downloadVersion={downloadVersion}
    >
      <ReportH4 className="section-header" downloadVersion={downloadVersion}>
        How far is your drive to work (one way)?
      </ReportH4>
      <ReportChart downloadVersion={downloadVersion}>
        <BarChart
          width={downloadVersion ? 350 : 450}
          height={downloadVersion ? 120 : 200}
          fontSize={downloadVersion ? 8 : 16}
          data={chartData}
          getColor={getColor}
          axisLeft={{
            tickSize: 0,
            tickPadding: 10,
          }}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
            tickValues: 5,
            format: (value) => `${value}%`,
          }}
          enableLabel={false}
          enableGridX
          margin={{
            top: downloadVersion ? 10 : 20,
            right: 10,
            bottom: 40,
            left: downloadVersion ? 60 : 105,
          }}
          layout="horizontal"
          barComponent={(props) => (
            <BartCO2eDifference
              {...props}
              hideValues
              downloadVersion={downloadVersion}
            />
          )}
        />
      </ReportChart>
      {averageDrivingCommute && (
        <ReportDiv
          id="average-commute"
          className="circle-stat"
          style={styles.circleStat}
          downloadVersion={downloadVersion}
        >
          <ReportP downloadVersion={downloadVersion}>
            Average driving commute {averageDrivingCommute} miles
          </ReportP>
        </ReportDiv>
      )}
    </ReportDiv>
  );
};
