import "./styles.scss";
import { StyleSheet } from "@react-pdf/renderer";
import {
  ReportPage,
  ReportDiv,
  ReportP,
  ReportImage,
  ReportB,
} from "../../../../../../../components/PDFReport";
import { ReportPageProps } from "../../../../../../../types/Report";
import bcorp from '../../../images/BCorpLogo.png';
import caa from '../../../images/CAALogo.jpeg';
import iso from '../../../images/27001Logo.png';

const styles = StyleSheet.create({
  pageStyle: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center"
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '15px',
    gap: '20px',
  },
  contentText: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '75%',
    justifyContent: 'space-evenly',
    paddingBottom: '30px'
  },
  contentImage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexBasis: '25%',
    gap: '20px',
    marginTop: '15px',
    marginBottom: '15px'
  },
  logo: {
    width: '60%',
    objectFit: 'contain',
  },
  testimonial: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px'
  },
  testimonialName: {
    color: '#F2A505',
    width: '100%'
  },
  testimonialRole: {
    marginTop: '5px',
    fontSize: '10px'
  }
});

export const Accreditations: React.FC<ReportPageProps> = ({
  page,
  className,
  downloadVersion = false,
  onContentUpdate = () => {},
}) => {
  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`accreditations image-full-page ${className}`}
      style={styles.pageStyle}
      downloadVersion={downloadVersion}
      footer={page.footer}
      onChange={() => onContentUpdate()}
      pageId={page.pageId}
      showFooter
      editable={false}
      page={page}
      showHeader
    >
      <ReportDiv
        className="content"
        style={styles.content}
        downloadVersion={downloadVersion}
      >
        <ReportDiv
          className="content-image"
          style={styles.contentImage}
          downloadVersion={downloadVersion}
        >
          <ReportImage
            className="accreditation-logo"
            style={styles.logo}
            src={caa}
            alt="Carbon Accounting Alliance Member"
            downloadVersion={downloadVersion}
          />
          <ReportImage
            className="accreditation-logo"
            style={styles.logo}
            src={iso}
            alt="ISO27001 Accredited"
            downloadVersion={downloadVersion}
          />
          <ReportImage
            className="accreditation-logo"
            style={styles.logo}
            src={bcorp}
            alt="BCorp Certified"
            downloadVersion={downloadVersion}
          />
        </ReportDiv>
        <ReportDiv
          className="content-text"
          style={styles.contentText}
          downloadVersion={downloadVersion}
        >
          <ReportDiv className="testimonial" style={styles.testimonial} downloadVersion={downloadVersion}>
            <ReportP downloadVersion={downloadVersion}>
              “The Flotilla carbon accounting platform enables real, evidence-driven change. With science-based measurement
              and reporting, Flotilla allows businesses to take an honest look at their emissions, set achievable goals, make wise
              data-led decisions and become genuinely sustainable.”
            </ReportP>
            <ReportDiv downloadVersion={downloadVersion}>
              <ReportP downloadVersion={downloadVersion}>
                <ReportB downloadVersion={downloadVersion} className="testimonial-name" style={styles.testimonialName}>
                  Professor Piers Forster
                </ReportB>
              </ReportP>
              <ReportP downloadVersion={downloadVersion} className="testimonial-role" style={styles.testimonialRole}>
                Founder Director of the Priestley International Centre for Climate, University of Leeds, IPCC Sixth Assessment
                Report, Lead Author, Nobel prize winner for climate action
              </ReportP>
            </ReportDiv>
          </ReportDiv>
          <ReportDiv className="testimonial" style={styles.testimonial} downloadVersion={downloadVersion}>
            <ReportP downloadVersion={downloadVersion}>
              “The United Nations Climate Change Initiative is pleased to recommend Flotilla's science-based carbon reduction
              service, supporting businesses on their crucial journey to net zero. The Flotilla approach creates positive action
              throughout the entire ecosystem of a company, involving, motivating and empowering every individual to drive
              meaningful change.”
            </ReportP>
            <ReportDiv downloadVersion={downloadVersion}>
              <ReportP downloadVersion={downloadVersion}>
                <ReportB downloadVersion={downloadVersion} className="testimonial-name" style={styles.testimonialName}>
                  Miguel Naranjo
                </ReportB>
              </ReportP>
              <ReportP downloadVersion={downloadVersion} className="testimonial-role" style={styles.testimonialRole}>
                Programme Officer - UN Climate Change
              </ReportP>
            </ReportDiv>
          </ReportDiv>
        </ReportDiv>
      </ReportDiv>
    </ReportPage>
  );
};
