export const mapActionGroupStats = (data: {[key: string]: string | number | any}[]): ActionGroups => {
  return data.map((item) => ({
    id: item?.id,
    name: item?.name,
    todo: item?.statusCounts?.todoActions || 0,
    active: item?.statusCounts?.activeActions || 0,
    completed: item?.statusCounts?.completedActions || 0,
    overdue: item?.statusCounts?.overdueActions || 0,
  } as unknown as ActionGroup));
}

export interface ActionGroup {
  id: number | string,
  name: string,
  todo: number,
  active: number,
  completed: number,
  overdue: number,
};

export type ActionGroups = ActionGroup[];
