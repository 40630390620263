import './styles.scss';

import { FC, useState } from 'react';
import { AddIcon, GenericData, IconButton, SearchFilter } from '@flotilla/component-library';

import Header from '../../../components/HeaderV2';
import { useAppDispatch } from '../../../helpers/hooks';
import { addModal, removeModal } from '../../../reducers/modal';
import { ReactComponent as BoardIcon } from '../../../assets/icons/Board.svg';
import { ReactComponent as ListIcon } from '../../../assets/icons/List.svg';
import CardView from './Card';
import TableView from './Table';
import { useCompany, useUsers } from '../../../hooks';
import { Users } from '../../../types/User';
import { InviteUserModal } from '../../../components/Modal';
import { useIdentityCompanyId } from '../../../context';
import { useParams } from 'react-router-dom';

interface UsersListProps {
  className?: string;
};

const UsersList: FC<UsersListProps> = ({
  className = "",
}) => {
  const dispatch = useAppDispatch();
  const [selectedView, setSelectedView] = useState('card');
  const identityCompanyId = useIdentityCompanyId();
  const { companyId = "0" } = useParams();
  const [company] = useCompany(companyId);

  const {
    users,
    updateUsers,
    loading
  } = useUsers();

  const handleAddUser = () => {
    dispatch(addModal({
      id: 'create-user',
      children: (
        <InviteUserModal
          inviteCompanyId={identityCompanyId}
          onClose={() => {
            dispatch(removeModal());
            updateUsers();
          }}
          companyName={company?.name}
        />
      )
    }));
  }

  return (
    <section id="users-page" className={className}>
      <Header
        key={users?.length ? `users-header--${users?.length}` : ""}
        rightChildren={() =>
          <IconButton
            id="add-user"
            onClick={handleAddUser}
            icon={<AddIcon />}
            small
          />
        }
        subtitle={users ? `${users.length} Total Users` : undefined}
      >
        Manage users
      </Header>
      <main>
        <SearchFilter
          key={users?.length ? `users-view--${users?.length}` : ""}
          id="users-view"
          instanceName="UsersView"
          data={users as unknown as GenericData}
          selectedView={selectedView}
          onViewChange={setSelectedView}
          quickFilters={[
            {
              key: "userRoleValues",
              label: "Roles",
              isArray: true
            },
            {
              key: "departmentValue",
              label: "Department",
            },
            {
              key: "status",
              label: "Status",
            }
          ]}
          views={[
            {
              key: "list",
              label: "List",
              icon: <ListIcon />,
              children: (data) => (
                <TableView
                  data={data as unknown as Users}
                  loading={loading}
                  onChange={updateUsers}
                />
              )
            },
            {
              key: "card",
              label: "Card",
              icon: <BoardIcon />,
              children: (data) => (
                <CardView
                  data={data as unknown as Users}
                  loading={loading}
                  onChange={updateUsers}
                />
              )
            }
          ]}
        />
      </main>
    </section>
  );
}

export default UsersList;
