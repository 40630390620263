import './styles.scss';

import { useEffect, FC } from 'react';

import CustomerList from './CustomerList';
import NotificationList from './NotificationList';
import { useAppSelector } from '../../helpers/hooks';
import { getName } from '../../reducers/user';
import Header from '../../components/HeaderV2';
import { useNavigate } from 'react-router-dom';
import { useUserAccess } from '../../context';
import { getCompanySettings } from '../../api/CompanySettings';

interface HomeProps {
  className?: string;
};

const Home: FC<HomeProps> = ({
  className = "",
}) => {
  const navigate = useNavigate();
  const { userAccess } = useUserAccess();
  const { ADMIN, EDIT, STANDARD } = userAccess;
  const userName = useAppSelector(getName);

  useEffect(() => {
    if (!ADMIN && !STANDARD && EDIT) {
      getCompanySettings(0)
        .then((res) => {
          if (!res.industryId) {
            navigate('/0/onboarding', { replace: true });
          }
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccess]);

  return (
    <section id="home-page" className={className}>
      <Header>
        Welcome back, {userName}
      </Header>
      <article className="main-content">
        <CustomerList id="dashboard-customer-list"/>
        <NotificationList id="dashboard-notification-list"/>
      </article>
    </section>
  );
}

export default Home;
