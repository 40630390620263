import { Tag, TagProps } from '@flotilla/component-library';
import { Text, StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FONT_FAMILY } from '../../../styles/font';

interface ReportTagProps extends Omit<TagProps, 'style'> {
  id?: string;
  className?: string;
  style?: Style;
  downloadVersion?: boolean;
  children: string;
}

const styles = StyleSheet.create({
  tag: {
    fontFamily: FONT_FAMILY,
    fontSize: 11,
    color: '#011e26',
    borderRadius: 99999,
    backgroundColor: 'rgba(1, 30, 38, 0.05)',
    padding: '4px 6px',
    textAlign: 'center',
  },
  medium: {},
  large: {},
  small: {
    fontSize: 8.25,
    padding: '3px 6px',
  },
});

const ReportTag: React.FC<ReportTagProps> = ({
  className = '',
  id,
  children,
  style = {},
  downloadVersion = false,
  ...props
}) => {
  const {
    size = "medium"
  } = props;

  if (downloadVersion) {
    return (
      <Text id={id} style={[styles.tag, styles[size], style]}>
        {children}
      </Text>
    );
  }

  return (
    <Tag id={id} className={className} {...props}>
      {children}
    </Tag>
  );
};

export default ReportTag;
