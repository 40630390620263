import './styles.scss';

import React, { ChangeEventHandler } from 'react';
import { TextInput } from '@flotilla/component-library';

import { SectionProps } from '../../../../../../types/CreateWizard';

const ReportTitleSection: React.FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const handleTitleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.currentTarget.value;
    onChange({ title: value });
  }

  return (
    <section id="report-title-section" className={className}>
      <TextInput
        id="report-title"
        label="Report title"
        value={values?.["title"]}
        onChange={handleTitleChange}
      />
    </section>
  );
}

export default ReportTitleSection;
