import React from "react";
import ActionGanttTaskListRow from "./ActionGanttTaskListRow";
import { ActionGanttTaskListProps } from "./ActionGantt.types";

const ActionGanttTaskList: React.FC<ActionGanttTaskListProps> = ({ fontFamily, fontSize, tasks, rowWidth, rowHeight, onExpanderClick }) => {
    return <section style={{ fontFamily, fontSize, width: rowWidth, marginBottom: 21 }}>
        {tasks.map(t => {
            return <ActionGanttTaskListRow key={t.id} task={t} onExpanderClick={onExpanderClick} height={rowHeight} />
        })}
    </section>
};

export default ActionGanttTaskList;