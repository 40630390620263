import './styles.scss';

import React from 'react';
import { Skeleton } from '@flotilla/component-library';

import { Action, Actions } from '../../../../../types/Action';
import ActionCard from '../../../../../components/ActionCard';

interface CardViewProps {
  className?: string;
  data: Actions;
  loading?: boolean;
  onClick?: (action: Action) => void;
};


const CardView: React.FC<CardViewProps> = ({
  className = "",
  data,
  loading = false,
  onClick = () => ({})
}) => {
  const handleActionClick = (action: Action): React.MouseEventHandler<HTMLElement> => {
    return (event) => {
      event.stopPropagation();
      onClick(action);
    }
  }

  return (
    <section id="card-view" className={className}>
      { data?.length > 0 ? (
        data?.map((action, index) => (
          <ActionCard
            key={`${action.id}-${index}`}
            className="action-card"
            onClick={handleActionClick(action)}
            action={action}
          />
        ))
      ) : (
        loading && (
          <>
            <Skeleton className="action-card--loading"/>
            <Skeleton className="action-card--loading"/>
            <Skeleton className="action-card--loading"/>
          </>
        )
      )}
    </section>
  );
}

export default CardView;
