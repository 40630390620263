import './styles.scss';

import React, { FunctionComponent, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MouseEventHandler, PieCustomLayer, PieTooltipProps, ResponsivePie } from '@nivo/pie';
import { Card, IconButton, PadlockIcon, Tooltip, useAlert } from '@flotilla/component-library';

import { getActionStatusColor } from '../../../helpers/colors';
import { useAppSelector } from '../../../helpers/hooks';
import { getNetZeroPlanId } from '../../../reducers/user';
import { StatusCount, getStatusLabel } from '../../../types/StatusCount';
import { getActionsSummary } from '../../../api/Statistics';
import { useCompanyId, useUserAccess } from '../../../context';
import { ReactComponent as ChevronLeftIcon } from '../../../assets/icons/ChevronLeft.svg';
import { LOCKED_DASHBOARD_TOOLTIP } from '../../../assets/content/Tooltips';
import { FONT_FAMILY } from '../../../styles/font';
import { numberLocaleFormatter } from '../../../helpers/numberFormatter';

type PieDataType = {
  id: string;
  label: string;
  value: number;
}[];

interface ActionsSummaryProps {
  className?: string;
  variant?: "default" | "card"
};

const ActionsSummary: React.FC<ActionsSummaryProps> = ({
  className = "",
  variant = "default"
}) => {
  const { addAlert } = useAlert();
  const { userAccess } = useUserAccess();
  const { ADMIN, STANDARD } = userAccess;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyId = useCompanyId();
  const netZeroPlanId = useAppSelector(getNetZeroPlanId);
  const [isLoading, setIsLoading] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [data, setData] = useState<PieDataType>();
  const [completedActions, setCompletedActions] = useState(0);

  useEffect(() => {
    if (netZeroPlanId && companyId) {
      setIsLocked(false);
      setIsLoading(true);
      getActionsSummary(netZeroPlanId, companyId)
        .then((res) => {
          setCompletedActions(res.completedActions);
          setData(mapData(res));
        })
        .catch((error) => {
          addAlert({
            id: `Get Actions ${netZeroPlanId}`,
            type: 'error',
            title: 'Error Getting Actions Summary Data',
            content: 'Something went wrong, please try again by refreshing the page.',
          });
          console.log('Error while getting Actions Summary Data: ', error);
        })
        .finally(() => setIsLoading(false));
    } else if (!netZeroPlanId) {
      setIsLocked(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [netZeroPlanId, companyId, dispatch]);

  const mapData = (initialData: StatusCount): PieDataType => {
    const mappedData: PieDataType = [];

    Object.entries(initialData).forEach(([key, value]) => {
      mappedData.push({
        id: `${key.replace("Actions", "")}`,
        label: getStatusLabel(key),
        value: value || 0,
      })
    });
  
    return mappedData;
  }

  const handleClick: MouseEventHandler<{
    id: string;
    label: string;
    value: number;
  }, SVGPathElement> = ({ label }) => {
    if (!STANDARD || ADMIN) {
      navigate(`/${companyId || 0}/netzeroplan/projectplan?status=${(label as string)}`);
    } else {
      navigate(`/netzeroplan/projectplan?status=${(label as string)}`);
    }
  }

  const handleTitleClick = () => {
    if (!isLocked) {
      if (!STANDARD || ADMIN) {
        navigate(`/${companyId || 0}/netzeroplan/projectplan`);
      } else {
        navigate(`/netzeroplan/projectplan`);
      }
    }
  }

  const getTotal = () => {
    const total = data?.reduce((prevValue, current) => prevValue + (current?.value || 0), 0) || 0;
    return numberLocaleFormatter(total < 10 ? Math.round(total * 10) / 10 : Math.round(total));
  }

  return (
    <Card
      id="actions-summary-widget"
      className={`${isLocked ? 'actions-summary-widget--locked' : ''} variant--${variant} ${className}`}
    >
      { variant === "card" ? (
        <main>
          <header onClick={handleTitleClick}>
            <h3>Actions</h3>
            { isLocked ? (
              <Tooltip icon={PadlockIcon} tooltipText={LOCKED_DASHBOARD_TOOLTIP} />
            ) : (
              <IconButton
                icon={<ChevronLeftIcon style={{transform: 'rotate(180deg)'}} />}
                variant='ghost'
                small
              />
            )}
          </header>
          <article id="completed-stat">
            <section id="value">
              <h4 id="completed">{numberLocaleFormatter(completedActions)}</h4>
              <p id="total">/{getTotal()}</p>
            </section>
            <p>Completed</p>
          </article>
        </main>
      ) : (
        <h3>Actions Summary</h3>
      )}
      { !isLoading && data ? (
        <ResponsivePie
          id="donut-chart"
          data={data}
          margin={{
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
          innerRadius={0.7}
          colors={(data) =>
            getActionStatusColor(
              data.data.label as string
            )
          }
          layers={
            variant === "default" ?
            [ CenteredMetric, 'arcs' ] :
            [ 'arcs' ]
          }
          theme={{
            background: 'transparent'
          }}
          sortByValue
          animate={false}
          onClick={handleClick}
          tooltip={CustomTooltip}
        />
      ) : (
        <div className="pie-chart--loading" />
      )}
    </Card>
  );
}

const CenteredMetric: PieCustomLayer<{
  id: string;
  label: string;
  value: number;
}> = ({ centerX, centerY, ...props }) => {
  const centerValue = props.dataWithArc.find((item) => item.label === "Completed")?.value || 0;

  return (
    <>
      <text
        x={centerX}
        y={centerY - 8}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: 40,
          fontFamily: FONT_FAMILY
        }}
      >
        {centerValue}
      </text>
      <text
        x={centerX}
        y={centerY + 32}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: 16,
          fontFamily: FONT_FAMILY
        }}
      >
        Completed
      </text>
    </>
  );
};

const CustomTooltip: FunctionComponent<PieTooltipProps<{
  id: string;
  label: string;
  value: number;
}>> = ({ 
  datum: {
    value,
    label
  }
}) => {
  return (
    <Card id={(label as string).toLowerCase().replaceAll(' ', '-')} className="chart-tooltip">
      <h4>{label}</h4>
      <p>{value < 10 ? Math.round(value * 10) / 10 : Math.round(value)} tCO2e</p>
    </Card>
  );
};

export default ActionsSummary;
