import './styles.scss';

import React, { useEffect, useState } from 'react';
import { AddIcon, CloseIcon, IconButton, Tag, TextInput } from '@flotilla/component-library';
import { getActionTags } from '../../../../../../api/Action';

interface TagsInputProps {
  className?: string;
  tags?: string[];
  onChange?: (tags?: string[]) => void,
};

const TagsInput: React.FC<TagsInputProps> = ({
  className = "",
  tags: initialTags,
  onChange = () => ({}),
}) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [initialOptionTags, setInitialOptionTags] = useState<string[]>();
  const [optionTags, setOptionTags] = useState<string[]>();
  const [value, setValue] = useState("");

  useEffect(() => {
    initialTags && setSelectedTags(initialTags);
    getActionTags()
      .then((res) => setInitialOptionTags(res))
  }, [initialTags]);

  useEffect(() => {
    updateOptionTags();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialOptionTags, selectedTags]);

  const updateOptionTags = () => {
    const updatedOptionTags = [...initialOptionTags || []].filter((tag) => {
      return !selectedTags.includes(tag);
    });
    setOptionTags(updatedOptionTags);
  }

  const handleAddTag = (tag: string) => {
    if (tag !== "") {
      const updatedTagList = [...selectedTags, tag];
      setSelectedTags(updatedTagList);
      setValue("");
      onChange(updatedTagList);
    }
  }

  const handleAddTagClick = (tag?: string) => {
    return () => {
      handleAddTag(tag || value);
    }
  }

  const handleOnKeyUp: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === "Enter") {
      handleAddTag(value);
    }
  }

  const handleRemoveTag = (tag: string) => {
    return () => {
      const updatedTagList = selectedTags.filter((value) => value !== tag);
      setSelectedTags(updatedTagList);
      setValue("");
      onChange(updatedTagList);
    }
  }

  return (
    <section id="tags-input" className={className}>
      <h4>Tags</h4>
      <article id="selected-tags-container" className="tags-container">
        {selectedTags?.map((tag, index) => <Tag key={index} className="tag" icon={<CloseIcon variant='dark' onClick={handleRemoveTag(tag)}/>}>{tag}</Tag>)}
      </article>
      <article id="add-tag">
        <TextInput
          id="new-tag-input"
          placeholder='Add tag...'
          value={value}
          onChange={(event) => setValue(event.currentTarget.value)}
          onKeyUp={handleOnKeyUp}
        />
        <IconButton
          id="add-new-tag"
          onClick={handleAddTagClick()}
          label="Add"
          icon={<AddIcon stroke="#011e26"/>}
          small
          variant='ghost'
          disabled={value === ""}
        />
      </article>
      <article id="option-tags-container" className="tags-container">
        {optionTags?.filter((tag) => tag.toLowerCase().includes(value)).map((tag, index) => <Tag key={index} className="tag" icon={<AddIcon stroke="#011e26" onClick={handleAddTagClick(tag)}/>}>{tag}</Tag>)}
      </article>
    </section>
  );
}

export default TagsInput;
