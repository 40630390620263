import './styles.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AddIcon, CloseIcon, IconButton, Menu, MenuItem, TextInput } from '@flotilla/component-library';

import { ReactComponent as TickIcon } from '../../assets/icons/Tick.svg';
import { getTags } from '../../api/tags';
import { useAppSelector } from '../../helpers/hooks';
import { getNetZeroPlanId } from '../../reducers/user';
import { useCompanyId } from '../../context';

interface AddTagProps {
  addTag: (value: string) => void;
  className?: string;
};

const AddTag: React.FC<AddTagProps> = ({
  className = "",
  addTag = () => {}
}) => {
  const addTagRef = useRef<HTMLElement>(null);
  const companyId = useCompanyId();
  const netZeroPlanId = useAppSelector(getNetZeroPlanId);
  const [showInput, setShowInput] = useState(false);
  const [value, setValue] = useState("");
  const [defaultValues, setDefaultValues] = useState<string[]>();
  const [suggestedValues, setSuggestedValues] = useState<string[]>();

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (addTagRef.current && !addTagRef.current.contains(event.target as Node)) {
        setShowInput(false);
        setValue("");
        setSuggestedValues(defaultValues);
      }
  }, [defaultValues]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, addTagRef]);

  useEffect(() => {
    netZeroPlanId && getTags(netZeroPlanId, companyId)
      .then((res) => setDefaultValues(res))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [netZeroPlanId]);

  useEffect(() => {
    let values: string[] = defaultValues || [];
    if (value.length > 0 && defaultValues) {
      values = defaultValues.filter((item) => item.toLowerCase().includes(value.toLowerCase()));
    }
    setSuggestedValues(values);
  }, [defaultValues, value]);

  const handleButtonClick = () => {
    if (showInput && value.length > 0) {
      addTag(value);
      setShowInput(false);
      setValue("");
      setSuggestedValues(defaultValues);
    } else {
      setShowInput(!showInput);
    }
  }

  const handleOnChangeInput: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const enteredValue = event?.currentTarget.value
    setValue(enteredValue);
    defaultValues && setSuggestedValues(defaultValues.filter((item) => item.toLowerCase().includes(enteredValue)))
  }

  const getIcon = () => {
    if (showInput && value.length > 0) {
      return <TickIcon stroke="#011E26" width="16px" height="16px"/>;
    } else if (showInput) {
      return <CloseIcon stroke="#011E26" strokeWidth={1.2} width="16px" height="16px"/>;
    }

    return <AddIcon stroke="#011E26" width="16px" height="16px"/>;
  }

  return (
    <section className={`add-tag-container ${className}`} ref={addTagRef}>
      {
        showInput && (
          <>
            <TextInput
              id="edit-tag"
              value={value}
              onChange={handleOnChangeInput}
            />
            { suggestedValues && (
              <Menu id="edit-tag-suggestions">
                { suggestedValues.map((suggestedValue, index) => (
                  <MenuItem key={index} className="suggested-item" onClick={() => setValue(suggestedValue)}>{suggestedValue}</MenuItem>
                ))}
              </Menu>
            )}
          </>
        )
      }
      <IconButton
        id="add-tag"
        icon={getIcon()}
        variant='ghost'
        onClick={handleButtonClick}
      />
    </section>
  )
}

export default AddTag;
