import './styles.scss';

import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wizard } from '@flotilla/component-library';

import { getReportWizardPages } from '../../../../assets/content/CreateWizard';
import useCreateReport from '../../../../hooks/Report/useCreateReport';
import FinalSection from '../../../../assets/content/CreateWizard/Report/Sections/Final';
import { useCompanyId, useUserAccess } from '../../../../context';
import { useCompany } from '../../../../hooks';

interface ReportCreateProps {
  className?: string;
};

const ReportCreate: FC<ReportCreateProps> = ({
  className = ""
}) => {
  const { userAccess } = useUserAccess();
  const companyId = useCompanyId();
  const [
    company,
    ,
    ,
    companyLoading,
    editCompany,
    saveCompany
  ] = useCompany(companyId);
  const { CARBON_REDUCTION_PLAN_PRODUCT_ACCESS, ADMIN } = userAccess;
  const navigate = useNavigate();
  const {
    createReport,
    saveCreateReport,
    updateCreateReport,
    loading
  } = useCreateReport();

  const handleOnComplete = async () => {
    await saveCompany(true);
    await saveCreateReport()
      .then((res) => {
        if (createReport?.reportType === 2) {
          navigate('..');
        } else {
          navigate(res ? `../${res}` : '..', { replace: true });
        }
      });
  }
  
  const handleOnChange = (values: { [key: string]: any }) => {
    if (Object.hasOwn(values, 'description')) {
      editCompany(values);
    } else {
      updateCreateReport(values);
    }
  }

  useEffect(() => {
    CARBON_REDUCTION_PLAN_PRODUCT_ACCESS && updateCreateReport({ reportType: 2 });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CARBON_REDUCTION_PLAN_PRODUCT_ACCESS]);

  return (
    <Wizard
      className={className}
      pages={getReportWizardPages(CARBON_REDUCTION_PLAN_PRODUCT_ACCESS, createReport?.reportType, ADMIN)}
      values={{
        ...createReport,
        description: company?.description || ''
      }}
      onChange={handleOnChange}
      startPage={{
        name: 'Initial',
        title: 'Create Report',
        subtitle: 'Welcome to your Report Creator Wizard, this Wizard will help create a new Report!'
      }}
      endPage={{
        name: 'Final',
        title: 'Create Report',
        subtitle: 'Well done you are ready to create your Report.',
        children: (
          <FinalSection
            values={{
              ...createReport,
              description: company?.description || ''
            }}
          />
        ),
      }}
      submitLoading={loading || companyLoading}
      onComplete={handleOnComplete}
      onBack={() => navigate(-1)}
    />
  );
}

export default ReportCreate;
