import { ReactNode } from 'react';
import { View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

interface ReportDivProps {
  id?: string;
  className?: string;
  style?: Style;
  downloadVersion?: boolean;
  children?: ReactNode;
  debug?: boolean;
  divStyle?: React.CSSProperties | undefined;
}

const ReportDiv: React.FC<ReportDivProps> = ({
  className = '',
  id,
  children,
  style = {},
  divStyle = {},
  downloadVersion = false,
  debug = false,
}) => {
  if (downloadVersion) {
    return (
      <View id={id} style={style} debug={debug}>
        {children}
      </View>
    );
  }

  return (
    <div id={id} className={className} style={divStyle}>
      {children}
    </div>
  );
};

export default ReportDiv;
