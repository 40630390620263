import './styles.scss';

import { StyleSheet } from '@react-pdf/renderer';

import {
  ReportPage,
  ReportDiv,
  ReportH1,
  ReportH2,
  ReportH3,
  ReportH4,
  ReportP,
  ReportImage,
} from '../../../../../../components/PDFReport';
import { ReportPageProps } from '../../../../../../types/Report';

const styles = StyleSheet.create({
  offsetPackagePage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 25,
  },
  offsetPackageHeader: {
    width: 630,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  topText: {
    width: 630,
  },
  priceBubble: {
    position: 'absolute',
    width: 170,
    padding: 30,
    aspectRatio: 1,
    top: -30,
    right: -25,
    backgroundColor: '#e35924',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 24,
  },
  priceBubbleTitle: {
    fontSize: 24,
    width: '100%',
    textAlign: 'center',
    lineHeight: 1,
  },
  projectSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
    gap: 24,
  },
  project: {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    borderLeft: '4px solid #011e26',
    backgroundColor: '#EEEEED',
    borderRadius: 4,
    padding: '8px 12.5px',
  },
  projectHeader: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
  },
  projectContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    gap: 24,
  },
  projectContentText: {
    flex: 1,
  },
  percentageBubble: {
    width: 48,
    aspectRatio: 1,
    backgroundColor: '#e35924',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  projectImage: {
    borderRadius: '100%',
    aspectRatio: 1,
    width: 120,
    objectFit: 'cover',
  },
  iconImage: {
    height: 80,
    objectFit: 'contain',
  },
});

interface OffsetPackageProps extends ReportPageProps {
  packageType?: string;
  price?: string;
  topText?: string[];
  projects?: Project[];
};

export type Project = {
  title: string;
  subtitle: string;
  percentage: string;
  image?: string;
  content: string[];
  icons?: string[];
};

export const OffsetPackage: React.FC<OffsetPackageProps> = ({
  report,
  page,
  className,
  packageType,
  price,
  topText,
  projects,
  downloadVersion = false,
  onContentUpdate = () => {},
  editable
}) => {
  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`offset-package ${className}`}
      style={styles.offsetPackagePage}
      downloadVersion={downloadVersion}
      footer={page.footer}
      onChange={() => onContentUpdate()}
      pageId={page.pageId}
      showFooter
      editable={editable}
      page={page}
      showHeader
    >
      <ReportDiv
        className="content"
        style={styles.content}
        downloadVersion={downloadVersion}
      >
        {topText && (
          <ReportDiv
            className="top-text"
            style={styles.topText}
            downloadVersion={downloadVersion}
          >
            {topText.map((text, index) => (
              <ReportP key={index} downloadVersion={downloadVersion}>
                {text}
              </ReportP>
            ))}
          </ReportDiv>
        )}
        <ReportDiv
          className="project-section"
          style={styles.projectSection}
          downloadVersion={downloadVersion}
        >
          {projects &&
            projects.slice(0, 2).map((project: Project, index) => {
              const { title, subtitle, percentage, image, content, icons } =
                project;
              return (
                <ReportDiv
                  key={index}
                  className="project"
                  style={styles.project}
                  downloadVersion={downloadVersion}
                >
                  <ReportDiv
                    className="header"
                    style={styles.projectHeader}
                    downloadVersion={downloadVersion}
                  >
                    <ReportDiv
                      className="percentage-bubble"
                      style={styles.percentageBubble}
                      downloadVersion={downloadVersion}
                    >
                      <ReportH3 downloadVersion={downloadVersion}>
                        {percentage}
                      </ReportH3>
                    </ReportDiv>
                    <ReportDiv
                      className="title"
                      downloadVersion={downloadVersion}
                    >
                      <ReportH4 downloadVersion={downloadVersion}>
                        {title}
                      </ReportH4>
                      <ReportP downloadVersion={downloadVersion}>
                        {subtitle}
                      </ReportP>
                    </ReportDiv>
                  </ReportDiv>
                  <ReportDiv
                    className="content"
                    style={styles.projectContent}
                    downloadVersion={downloadVersion}
                  >
                    <ReportDiv
                      className="text"
                      style={styles.projectContentText}
                      downloadVersion={downloadVersion}
                    >
                      {content.map((text, index) => (
                        <ReportP
                          key={index}
                          downloadVersion={downloadVersion}
                        >
                          {text}
                        </ReportP>
                      ))}
                    </ReportDiv>
                    {image && (
                      <ReportImage
                        alt="Project Description"
                        style={styles.projectImage}
                        src={image}
                        downloadVersion={downloadVersion}
                      />
                    )}
                  </ReportDiv>
                  <ReportDiv className="footer">
                    {icons &&
                      icons.map((icon, index) => (
                        <ReportImage
                          key={index}
                          alt={`Icon Number ${index}`}
                          className="icon"
                          style={styles.iconImage}
                          src={icon}
                          downloadVersion={downloadVersion}
                        />
                      ))}
                  </ReportDiv>
                </ReportDiv>
              );
            })}
        </ReportDiv>
        <ReportP downloadVersion={downloadVersion}>
          Note: prices correct at time of issuing and include a commission fee
          of 20% to cover our due diligence
        </ReportP>
      </ReportDiv>
      <ReportDiv
        className="price-bubble"
        style={styles.priceBubble}
        downloadVersion={downloadVersion}
      >
        <ReportH2
          style={styles.priceBubbleTitle}
          downloadVersion={downloadVersion}
        >
          {packageType || ''}
        </ReportH2>
        <ReportH1 downloadVersion={downloadVersion}>{price || ''}</ReportH1>
        <ReportP downloadVersion={downloadVersion}>per tCO2e</ReportP>
      </ReportDiv>
    </ReportPage>
  );
};
