import { useCallback, useEffect, useState } from "react";
import { useAlert } from "@flotilla/component-library";

import { getDataPeriodDates } from "../../api/DataPeriod";
import { useCompanyId } from "../../context";
import { DataPeriodDates } from "../../types/DataPeriod";

const useDataPeriodDates = (initialYears: number = 2, showAll: boolean = false) => {
  const { addAlert } = useAlert();
  const companyId = useCompanyId();
  const [loading, setLoading] = useState(false);
  const [dataPeriodDates, setDataPeriodDates] = useState<DataPeriodDates>();
  const [years, setYears] = useState(initialYears);

  const fetchApi = useCallback(async () => {
    if (companyId) {
      setLoading(true);
      await getDataPeriodDates(companyId, years, showAll)
        .then((res) => {
          if (res.length === 0 && years <= 21) {
            setYears(years + 2);
          } else {
            setDataPeriodDates(res.sort((a, b) => a.startDate < b.startDate ? 1 : -1));
          }
        })
        .catch((error) => {
          addAlert({ id: `Data Period Dates Load Failed ${companyId}`, type: "error", title: "Data Period Dates Unsuccessful", content: "Data Period Dates failed to load please try again." });
          console.log("Data Period Dates Failed to load, please try again:", error);
        })
        .finally(() => setLoading(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, years]);

  const refreshDataPeriodDates = () => {
    fetchApi();
  }

  const addMoreYears = () => {
    setYears(years + 2);
  }

  useEffect(() => {
    if (companyId) {
      fetchApi();
    }
  }, [companyId, fetchApi, years]);

  return {
    dataPeriodDates,
    years,
    refreshDataPeriodDates,
    addMoreYears,
    setYears,
    loading
  };
}

export default useDataPeriodDates;
