type FontWeight =
  | '500'
  | '700'
  | '900'
  | 'normal'
  | 'bold'
  | '100'
  | '200'
  | '300'
  | '400'
  | '600'
  | '800'
  | undefined;

type FontSizesType = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
};

type FontWeightType = {
  light: FontWeight;
  regular: FontWeight;
  bold: FontWeight;
};

type SizingGroupType = {
  size: FontSizesType;
  weight: FontWeightType;
};

const FONT_SIZE = 14;
const FONT_FAMILY = 'Verdana';

const Font: SizingGroupType = {
  size: {
    xs: FONT_SIZE * 0.5,
    sm: FONT_SIZE * 0.75,
    md: FONT_SIZE,
    lg: FONT_SIZE * 1.25,
    xl: FONT_SIZE * 1.5,
    xxl: FONT_SIZE * 2
  },
  weight: {
    light: '300',
    regular: '400',
    bold: '700'
  }
};

export { FONT_SIZE, FONT_FAMILY };
export default Font;
