import { Tooltip } from "@flotilla/component-library";
import CopyButton from "../components/CopyButton";
import ScopeTag from "../components/ScopeTag";
import StatusTag from "../components/StatusTag";
import { formatDate } from "./dateFormatter";
import getRatingIcon from "./getRatingsIcon";
import formatMoney from "./moneyFormatter";
import numberFormatter from "./numberFormatter";

const getNZPTableCell = (key: string, item: { [key: string]: any }) => {
  const value = item[key];

  if (value === null) return <td key={key}></td>;

  switch (key) {
    case "costSavingsPotential":
    case "carbonSavingsPotential":
    case "coBenefitsPotential":
      return <td key={key} className="rating">
        {getRatingIcon(value, key === "costSavingsPotential" ? "money" : key === "carbonSavingsPotential" ? "cloud" : "hand")}
      </td>;
    case "startDate":
    case "targetCompletionDate":
      return <td key={key}>{formatDate(value)}</td>;
    case "status":
      return <td key={key} id="status">
          { item?.netZeroPlanAction?.status && <StatusTag size="small">{item?.netZeroPlanAction?.status}</StatusTag>}
        </td>;
    case "estimatedCost":
    case "estimatedROI":
      return <td key={key}>{formatMoney(value)}</td>;
    case "estReduction":
      return <td key={key}>{numberFormatter(value)}%</td>;
    case "estimatedEffort":
      return <td key={key}>{value > 0 ? `${value} Days` : '-'}</td>;
    case "quickWin":
      return <td key={key}>{value ? "Yes" : "No"}</td>;
    case "scopeId":
      return <td key={key} className={`scope scope-${value}`}>
          { value && <ScopeTag size="small">{`Scope ${value}`}</ScopeTag>}
        </td>;
    case "scopeCategoryName":
      return <td key={key} className="scope-category">{value}</td>;
    case "companiesUsingAction":
      if(Array.isArray(value) && value.length) {
        return <td key={key} className="customers">
          <Tooltip 
            hideCloseButton 
            icon={() => <>{value?.length ?? 0}</>}>
              <CopyButton text={value?.join("\r\n")}/>
              {value.map(c => <p key={c}>{c}</p>)}
          </Tooltip>
        </td>;
      } else {
        return <td key={key} className="customers">
          <p>0</p>
        </td>;
      }
    default:
      return <td key={key}>{value?.toString()}</td>;
  }
};

export default getNZPTableCell;
