import "./styles.scss";

import { ReportPageProps } from '../../../../../types/Report';
import { getExamplePackageContent } from '../../../../../assets/content/OffsetPackages';

import { PrefacePage } from './Preface';
import { ImageComments } from './ImageComments';
import { ImageFull } from './ImageFull';
import { CoverPage } from './Cover';
import { SectionCoverPage } from './SectionCover';
import { CommentsComments } from './CommentsComments';
import { Contents } from './Contents';
import { EmployeeCommutingPage } from './EmployeeCommuting';
import { FinalPage } from './FinalPage';
import { CarbonFootprintScope } from './CarbonFootprintScope';
import { CarbonFootprintBaselineYear } from './CarbonFootprintBaselineYear';
import { AccountingForGrowth } from './AccountingForGrowth';
import { DualReportingCurrentFootprint } from './DualReportingCurrentFootprint';
import { EmployeeImpactAttitudes } from './EmployeeImpactAttitudes';
import { CarbonFootprintingKeyTermsExplained } from './CarbonFootprintingKeyTermsExplained';
import { OffsetPackage } from './OffsetPackage';
import { CarbonFootprintTrendsExplained } from './CarbonFootprintTrendsExplained';
import { Glossary } from "./Appendices/Glossary";
import { Accreditations } from "./Appendices/Accreditations";
import { OperationalBoundary } from "./Appendices/OperationalBoundary";
import { MultiOfficeCommutingPage } from "./MultiOfficeCommutingPage";
import { ComparativeInformation } from "./Appendices/ComparativeInformation";
import { CarbonFootprintImpactArea } from "./CarbonFootprintImpactArea";
import { ImpactAreaBreakdown } from "./ImpactAreaBreakdown";
import { GraphComments } from "./GraphComments";

export const ReportPageSkeleton: React.FC<{className?: string}> = ({ className = "" }) => (
  <article className={`report-page report-page--skeleton ${className}`} />
);

const ReportPage: React.FC<ReportPageProps> = (props) => {
  const {
    page,
  } = props;

  const pageType = page.type.toLowerCase();
  const pageTitle = page.pageTitle.toLowerCase();

  if (pageType === "impactareabreakdown") {
    const impactArea = pageTitle.split("-")[1].trim();
    return (
      <ImpactAreaBreakdown
        {...props}
        impactArea={impactArea}
      />
    );

  }

  if (pageType.includes("chart") || pageType.includes("customgenerated")) {
    switch (pageTitle) {
      case 'employee commuting by office':
      case 'employee commuting analysis':
        return <MultiOfficeCommutingPage
          {...props}
        />;
      case 'employee commuting':
        return (
          <EmployeeCommutingPage
            {...props}
          />
        );
      case 'your carbon footprint by scope':
      case 'carbon footprint by scope':
        return (
          <CarbonFootprintScope
            {...props}
          />
        );
      case 'your carbon footprint by impact area':
      case 'your carbon footprint by key impact area':
      case 'carbon footprint by impact area':
        return (
          // eslint-disable-next-line react/jsx-no-undef
          <CarbonFootprintImpactArea
            {...props}
          />
        );
      case 'your carbon footprint in your baseline year':
      case 'carbon footprint in the baseline year':
      case 'carbon footprint in the latest year':
        return (
          <CarbonFootprintBaselineYear
            {...props}
          />
        );
      case 'benchmarking and accounting for growth':
        return (
          <AccountingForGrowth {...props} />
        );
      case 'dual reporting of your current footprint':
        return (
          <DualReportingCurrentFootprint
            {...props}
          />
        );
      case 'employee impacts and attitudes':
        return (
          <EmployeeImpactAttitudes
            {...props}
          />
        );
      case 'carbon offsetting explained':
        return <ImageComments {...props} isReverse />;
      case 'carbon footprint trends explained':
        return (
          <CarbonFootprintTrendsExplained
            {...props}
          />
        );
      case 'appendix 2 - impact area breakdown':
      case 'appendix 2 - key impact area breakdown':
        return (
          <ImpactAreaBreakdown
            {...props}
            impactArea={page.pageSubTitle.toLowerCase() as 'travel & logistics' | 'energy' | 'facilities' | 'supply chain'}
          />
        );
      case 'appendix 4 - operational boundary':
        return (
          <OperationalBoundary 
            {...props}
          />
        );
    }
  } else {
    switch (pageTitle) {
      case 'carbon footprinting key terms explained':
      case 'carbon footprinting key terms':
        return (
          <CarbonFootprintingKeyTermsExplained
            {...props}
          />
        );
      case 'example offset package':
        const packageContent = getExamplePackageContent();
        return (
          <OffsetPackage
            {...props}
            price="£17"
            packageType="Indicative Price"
            {...packageContent}
          />
        );
      case 'appendix 1 - boundary of reporting':
        return (
          <ComparativeInformation 
            {...props}
          />
        );
      case 'appendix 1 - glossary':
      case 'appendix 2 - glossary':
        return (
          <Glossary 
            {...props}
          />
        );
      case 'appendix 3 - operational boundary':
      case 'appendix 4 - operational boundary':
        return (
          <OperationalBoundary 
            {...props}
          />
        );
      case 'appendix 5 - flotilla accreditations':
      case 'appendix 6 - flotilla accreditations':
        return (
          <Accreditations 
            {...props}
          />
        );
    }
  }

  if (pageType === 'cover') {
    return <CoverPage {...props} />;
  } else if (pageType === 'sectioncover') {
    return <SectionCoverPage {...props} />;
  } else if (pageType === 'preface') {
    return <PrefacePage {...props} />;
  } else if (
    pageType === 'imageleftcommentsright' ||
    pageType === 'imagerightcommentsleft'
  ) {
    return (
      <ImageComments
        {...props}
        isReverse={pageType !== 'imageleftcommentsright'}
      />
    );
  } else if (
    pageType === 'graphleftcommentsright' ||
    pageType === 'graphrightcommentsleft'
  ) {
    return (
      <GraphComments
        {...props}
        isReverse={pageType !== 'graphleftcommentsright'}
      />
    );
  } else if (pageType === 'imagefull') {
    return <ImageFull {...props} />;
  } else if (pageType === 'commentsleftcommentsright') {
    return <CommentsComments {...props} />;
  } else if (pageType === 'contents') {
    return <Contents {...props} />;
  } else if (pageType === 'finalpage') {
    return <FinalPage {...props} />;
  }

  return null;
};

export default ReportPage;
