import './styles.scss';

import { useState } from 'react';
import { CloseIcon, IconButton } from '@flotilla/component-library';

import { Page } from '../../../../../../../types/Report';

export type ContentsOptionProps = {
  page: Page;
  className?: string;
  active?: boolean;
  onDelete?: () => void;
};

export const ContentsOption: React.FC<ContentsOptionProps> = ({
  page,
  className,
  active = false,
  onDelete = () => ({}),
}) => {
  const { pageTitle, pageNumber } = page;
  const generatedPage = page.type === "CustomGenerated";
  const [deleteTimer, setDeleteTimer] = useState<NodeJS.Timeout | null>();

  const handleDelete: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setDeleteTimer(
      setTimeout(() => {
        onDelete();
        setDeleteTimer(null);
      }, 5000)
    );
  };

  const handleCancelDelete = () => {
    deleteTimer && clearTimeout(deleteTimer);
    setDeleteTimer(null);
  };

  return (
    <section
      className={`contents-option ${active && !deleteTimer ? "contents-option--active" : ""} ${className}`}
      onClick={() => deleteTimer && handleCancelDelete()}
    >
      <span className="title">{deleteTimer ? 'Click to undo' : pageTitle}</span>
      {!deleteTimer && (
        <>
          <span className="page-number">{pageNumber}</span>
          <IconButton
            id="delete"
            variant='ghost'
            aria-label="Delete Icon"
            onMouseUp={generatedPage ? undefined : handleDelete}
            icon={<CloseIcon stroke={active ? "#FBFAF8" : "#011e26"}/>}
            className={generatedPage ? "hidden-btn" : ""}
          />
        </>
      )}
    </section>
  );
};
