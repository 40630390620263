import { createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";

export interface UserState {
  id: number;
  name: string;
  company: string;
  logoutUrl: string;
  netZeroPlanId?: number;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: UserState = {
  id: 0,
  name: 'User Name',
  company: '',
  logoutUrl: '',
  netZeroPlanId: undefined,
  status: 'idle'
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setId: (state, action) => {
      state.id = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setCompany: (state, action) => {
      state.company = action.payload;
    },
    setLogoutUrl: (state, action) => {
      state.logoutUrl = action.payload;
    },
    setNetZeroPlanId: (state, action) => {
      state.netZeroPlanId = action.payload;
    },
  },
});

export const { setId, setName, setCompany, setNetZeroPlanId } = userSlice.actions;

export const getId = (state: RootState): number => state.user.id;
export const getName = (state: RootState): string => state.user.name;
export const getCompany = (state: RootState): string => state.user.company;
export const getLogoutUrl = (state: RootState): string => state.user.logoutUrl;
export const getNetZeroPlanId = (state: RootState): number | undefined => state.user.netZeroPlanId;

export default userSlice.reducer;
