import React from 'react';

import { Route, Routes } from 'react-router-dom';

import SurveyDetail from './Detail';
import SurveysList from './List';
import SurveyCreate from './Create';
import { useCompanyId, useUserAccess } from '../../../context';

const Surveys: React.FC = () => {
  const companyId = useCompanyId();
  const { userAccess } = useUserAccess();
  const { CREATE, PE } = userAccess;
  return (
    <Routes>
      <Route index element={<SurveysList />} />
      { !(PE && companyId !== "0") && (
        <Route path=":id" element={<SurveyDetail />} />
      )}
      { CREATE && !(PE && companyId !== "0") && (
        <Route path="create" element={<SurveyCreate />} />
      )}
    </Routes>
  );
}

export default Surveys;
