import './styles.scss';

import { FC } from 'react';

import { outputTypes, reportTypes } from '../../../../../../types/Report';
import { SectionProps } from '../../../../../../types/CreateWizard';

const FinalSection: FC<SectionProps> = ({
  className = "",
  values
}) => {
  return (
    <section id="report-final-section" className={className}>
      { values?.['reportType'] === 2 ? (
        <>
          <article className="stat">
            <p>Report Type</p>
            <h3>{reportTypes[values?.['reportType']]}</h3>
          </article>
          <article className="stat">
            <p>Output Type</p>
            <h3>{outputTypes[values?.['outputType']]}</h3>
          </article>
          <article className="stat">
            <p>Company description</p>
            <h3>{values?.['description']}</h3>
          </article>
        </>
      ) : (
        <>
          <article className="stat">
            <p>Report Title</p>
            <h3>{values?.['title']}</h3>
          </article>
          <article className="stat">
            <p>Report Type</p>
            <h3>{reportTypes[values?.['reportType']]}</h3>
          </article>
          <article className="stat">
            <p>Report Baseline Year</p>
            <h3>{values?.['baselineYear']}</h3>
          </article>
        </>
      )}
    </section>
  );
}

export default FinalSection;
