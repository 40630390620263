import './styles.scss';

import React, { useState } from 'react';
import { Button, Modal, NumberInput, TextInput, useAlert } from '@flotilla/component-library';

import { addAction } from '../../../api/netZeroPlan';
import { ESTIMATED_UPTAKE_TOOLTIP } from '../../../assets/content/Tooltips';
import { Action } from '../../../types/Action';
import { useCompanyId } from '../../../context';

interface AddActionModalProps {
  className?: string;
  onClose: () => void;
  onSubmit?: () => void;
  action: Action;
  netZeroPlanId: number,
};

const AddActionModal: React.FC<AddActionModalProps> = ({
  className = "",
  onClose = () => {},
  onSubmit = () => {},
  action,
  netZeroPlanId,
}) => {
  const { addAlert } = useAlert(); 
  const {
    id,
    title,
    defaultPriority: priority,
    estimatedUptakeRequired: requiredUptake = false
  } = action;
  const companyId = useCompanyId();
  const [isLoading, setIsLoading] = useState(false);
  // Adding 7 days to todays date as a rough guide for how long the survey should take
  const [completionDate, setCompletionDate] = useState<string>(new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString().substring(0, 10));
  const [startDate, setStartDate] = useState<string>(new Date(new Date().getTime()).toISOString().substring(0, 10));
  const [uptake, setUptake] = useState<number>();

  const handleSubmit = async () => {
    setIsLoading(true);
    completionDate && (requiredUptake ? uptake : true) &&
      await addAction({
          id,
          netZeroPlanId,
          uptake,
          completionDate,
          startDate,
          priority,
          companyId
        })
        .then(() => {
          onSubmit();
          addAlert({
            id: `Added Action ${id}`,
            type: 'success',
            title: 'Success, Action added to your plan!',
            content: 'Well done you have added an Action to your plan, one step closer to Net Zero.',
          });
        })
        .catch(() => {
          addAlert({
            id: `Added Action ${id}`,
            type: 'error',
            title: 'Error Adding Action to your Plan',
            content: 'There seems to be an error when adding an action to your plan, please try again. If the problem continues to happen, please contact us.',
          });
        })
        .finally(() => {
          setIsLoading(false);
          onClose();
        });
  };

  return (
    <Modal
      id="add-action-modal"
      className={className}
      onClose={onClose}
    >
      <h2>Add Action to your Net Zero Plan</h2>
      <h4>{title}</h4>

      <main>
        <TextInput
          id="start-date"
          label="Start Date"
          aria-label="Start Date"
          value={startDate}
          onChange={(event) => setStartDate(event?.currentTarget.value)}
          type='date'
        />
  
        <TextInput
          id="completion-date"
          label="Target Completion Date"
          aria-label="Target Completion Date"
          value={completionDate}
          onChange={(event) => setCompletionDate(event?.currentTarget.value)}
          type='date'
        />

        { requiredUptake &&
          <NumberInput
            id="uptake"
            label="Estimated Uptake (%)"
            aria-label="Estimated Uptake (%)"
            value={uptake}
            onChange={(value) => setUptake(value)}
            min={0}
            max={100}
            decimal
            tooltipText={ESTIMATED_UPTAKE_TOOLTIP}
          />
        }

      </main>

      <section className="button-container">
        <Button
          variant="ghost"
          onClick={() => onClose()}
          isLoading={isLoading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={!completionDate || (requiredUptake ? !uptake : false)}
        >
          Add
        </Button>
      </section>
    </Modal>
  );
}

export default AddActionModal;
