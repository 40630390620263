import './styles.scss';

import React, { useState } from 'react';
import { Button, Checkbox, Divider, Label, TextInput } from '@flotilla/component-library';

import { updateUser } from '../../../api/User';
import { useCompanyId } from '../../../context';
import { User, UserRole } from '../../../types/User';
import { useUserRoles } from '../../../hooks';

interface EditUserModalProps {
  className?: string;
  data: User;
  onClose: () => void;
};

const EditUserModal: React.FC<EditUserModalProps> = ({
  className = "",
  data,
  onClose = () => {},
}) => {
  const companyId = useCompanyId();
  const {
    userRoles
  } = useUserRoles();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(data);

  const handleOnChangeRole = (userRole: UserRole) => {
    return () => {
      if ((user.roles || []).findIndex((item) => item.id === userRole.id) > -1) {
        setUser({
          ...user,
          roles: [
            ...(user.roles || []).filter((item) => item.id !== userRole.id)
          ]
        });
      } else {
        setUser({
          ...user,
          roles: [
            ...user.roles || [],
            userRole
          ]
        });
      }
    }
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    await updateUser(companyId, user)
      .finally(() => {
        onClose();
      });
  };

  return (
    <section
      id="edit-user-modal"
      className={`edit-user-modal ${className}`}
      onClick={((event) => event.stopPropagation())}
    >
      <h4>Edit user</h4>
      <main>
        <TextInput
          id="name"
          label="Name"
          aria-label="User name"
          value={user.fullName}
          disabled
        />
        <TextInput
          id="email"
          label="Email"
          aria-label="Email"
          value={user.email}
          disabled
        />
        { userRoles && (
          <>
            <Divider />
            <section id="roles">
              <Label>Role</Label>
              { user.roles ? (
                user.roles.map((userRole) => (
                  <Checkbox
                    key={userRole.id}
                    label={userRole.friendlyName || userRole.name}
                    onClick={handleOnChangeRole(userRole)}
                    checked={(user.roles || []).findIndex((item) => item.id === userRole.id) > -1}
                    disabled
                  />
                ))
              ) : (
                userRoles?.filter((role) => role.friendlyName === "Admin").map((userRole) => (
                  <Checkbox
                    key={userRole.id}
                    label={userRole.friendlyName || userRole.name}
                    onClick={handleOnChangeRole(userRole)}
                    checked={(user.roles || []).findIndex((item) => item.id === userRole.id) > -1}
                    disabled
                  />
                ))
              )}
            </section>
          </>
        )}
      </main>

      <section className="button-container">
        <Button variant="ghost" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} isLoading={isLoading}>Save</Button>
      </section>
    </section>
  );
}

export default EditUserModal;
