import formatMoney from '../../../../helpers/moneyFormatter';
import { numberLocaleFormatter } from '../../../../helpers/numberFormatter';
import { ReductionChart, ReductionChartViewMode } from '../../../../types/ReductionChart';
import { BarCustomLayerProps, BarDatum } from '@nivo/bar';

const getFTELabels = (data: ReductionChart, hidden: boolean, viewMode: ReductionChartViewMode = 'absolute') => ({ bars, xScale, yScale }: BarCustomLayerProps<BarDatum>) => {
  if (hidden || !(viewMode === 'fte' || viewMode === 'turnover') || bars.length === 0) return null;

  return (
    <>
      {Object.keys(data.fte).map((year) => {
        const x = xScale(parseInt(year)) + (bars[0]?.width ?? 0) / 2;
        const y = yScale(0) - 15;
        const text = viewMode === 'fte' ?
            numberLocaleFormatter(data.fte?.[parseInt(year)] || 0).toString() :
            formatMoney(data.turnover?.[parseInt(year)] || 0);

        if (!text?.length) return null;

        const rectWidth = 12 + (text.length * 8);

        return (
          <g key={`fte.${year}`} transform={`translate(${x},${y})`}>
            <rect
              x={-rectWidth / 2} y={-12}
              width={rectWidth} height={20}
              rx={10} ry={10}
              fill="#E2E4E3"
            />
            <text
              textAnchor="middle"
              alignmentBaseline="middle"
              style={{ fill: '#011E26', fontSize: '12px' }}
            >
              {text}
            </text>
          </g>
        );
      })}
    </>
  );
};

export default getFTELabels;
