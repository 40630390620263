import { useState } from "react";

import { pdf } from "@react-pdf/renderer";
import { useAlert } from "@flotilla/component-library";
import saveAs from "file-saver";

import { CompanyDocument, CompanyReport } from "../../types/Report";
import { deleteDocument as deleteDocumentAPI, deleteReport, getReport, updateReportStatus } from "../../api/Report";
import { useCompanyId } from "../../context";
import PDFDocument from "../../components/PDFReport/PDFDocument";
import ReportPage from "../../pages/Company/CarbonReports/Detail/Page";

const useReportActions = (_data: CompanyReport | CompanyDocument) => {
  const { addAlert } = useAlert();
  const companyId = useCompanyId();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<CompanyReport | CompanyDocument>(_data);


  const download = async () => {
    if ((data as CompanyReport)?.reportId) {
      setLoading(true);
      const report = await getReport((data as CompanyReport)?.reportId, companyId);
      await pdf(
        <PDFDocument>
          {
            report.pages.map((page) => (
              <ReportPage
                key={page.pageId}
                report={report}
                page={page}
                onContentUpdate={() => {}}
                downloadVersion
              />
            ))
          }
        </PDFDocument>
      )
        .toBlob()
        .then(pdfBlob => {
          saveAs(pdfBlob, `${report.title.replaceAll(' ', '-')}.pdf`);
        })
        .catch((error) => {
          addAlert({ id: `Report Download Failed ${companyId}`, type: "error", title: "Report Download Unsuccessful", content: "Report failed to download please try again." });
          console.log('Error while downloading report: ', error);
        })
        .finally(() => setLoading(false));
    } else if ((data as CompanyDocument)?.carbonReportUrl) {
      setLoading(true);
      window.open((data as CompanyDocument).carbonReportUrl);
      setLoading(false);
    }
  }

  const publishReport = async (publish: boolean = true): Promise<boolean | void> => {
    const reportId = (data as CompanyReport).reportId;
    if (reportId) {
      setLoading(true);
      return await updateReportStatus(Number(reportId), publish ? 3 : 1, companyId)
        .then(() => {
          update({status: publish ? 'Published' : 'New'});
          addAlert({ id: `Report ${publish ? 'Publish' : 'Unpublish'} Success ${reportId}`, type: "success", title: `Report ${publish ? 'Publish' : 'Unpublish'} Success` });
        })
        .catch((error) => {
          addAlert({ id: `Report Update Status Failed ${reportId}`, type: "error", title: "Report Update Status Unsuccessful", content: "Report failed to update status please try again." });
          console.log('Error while updating report status: ', error);
        })
        .finally(()=> setLoading(false));
    }
  }

  const deleteDocument = async (): Promise<boolean | void> => {
    const documentId = (data as CompanyDocument).id;
    const reportId = (data as CompanyReport).reportId;
  
    if (documentId) {
      setLoading(true);
      return deleteDocumentAPI(documentId, companyId)
        .then(() => {
          addAlert({ id: `Document Delete Success ${documentId}`, type: "success", title: `Document Delete Success` });
        })
        .catch((error) => {
          addAlert({ id: `Document Delete Failed ${documentId}`, type: "error", title: "Document Delete Unsuccessful", content: "Document failed to delete please try again." });
          console.log('Error while deleting document: ', error);
        })
        .finally(() => setLoading(false));
    } else if (reportId) {
      setLoading(true);
      return deleteReport(reportId, companyId)
        .then(() => {
          addAlert({ id: `Report Delete Success ${reportId}`, type: "success", title: `Report Delete Success` });
        })
        .catch((error) => {
          addAlert({ id: `Report Delete Failed ${reportId}`, type: "error", title: "Report Delete Unsuccessful", content: "Report failed to delete please try again." });
          console.log('Error while deleting report: ', error);
        })
        .finally(() => setLoading(false));
    }
  }

  const update = (items: Partial<CompanyReport | CompanyDocument>) => {
    setData({
      ...data,
      ...items
    });
  }

  return {
    data,
    download,
    publishReport,
    deleteDocument,
    update,
    loading
  };
}

export default useReportActions;

