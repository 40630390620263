export type StatusCount = {
  activeActions: number,
  completedActions: number,
  overdueActions: number,
  todoActions: number,
  [key: string]: number,
}

export const getStatusLabel = (key: string): string => {
  switch (key.toLowerCase()) {
    case 'active':
    case 'activeactions':
      return 'Active';
    case 'completed':
    case 'completedactions':
      return 'Completed';
    case 'overdue':
    case 'overdueactions':
      return 'Overdue';
    case 'todo':
    case 'todoactions':
    default:
      return 'To do';
  }
}