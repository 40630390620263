import './styles.scss';
import { useEffect, useState } from "react";
import * as powerbi from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { Report } from "powerbi-client";
import { saveAs } from 'file-saver';
import { DownloadIcon, IconButton, Menu, MenuItem, useAlert } from '@flotilla/component-library';

import { PowerBiReport } from '../../types/PowerBiReport';
import { useCompanyId } from '../../context';
import Header from '../HeaderV2';

export const getReportEmbed = async (companyId: string): Promise<PowerBiReport> => {
    return fetch(`/api/powerbi/EmbedReport?companyId=${companyId}`, {
        headers: { "X-CSRF": "1" },
        method: 'GET',
    }).then((res) => res.json()).then((res) => res.payload as PowerBiReport);
};

export const exportReportToPdf = async (companyId: string, state: string, fileFormat: number): Promise<Blob> => {
    return fetch('/api/powerbi/ExportPaginatedReport', {
        headers: {
            "X-CSRF": "1",
            "Content-Type": "application/json"
        },
        method: 'POST',
        body: JSON.stringify({
            companyId,
            state,
            fileFormat
        })
    }).then((res) => res.blob());
};

const EmbedReport = () => {
    const { addAlert } = useAlert();
    const companyId = useCompanyId();
    const [reportId, setReportId] = useState<string>("");
    const [report, setReport] = useState<Report>();
    const [embedUrl, setEmbedUrl] = useState<string>("");
    const [accessToken, setAccessToken] = useState<string>("");
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    const [showDownloadOptions, setShowDownloadOptions] = useState(false);
    const slicerSummary = new powerbi.models.VisualSelector("eb2d44aa956256a46d03");
    const slicerImpactArea = new powerbi.models.VisualSelector("cb3f4a9925100904a632");
    const slicerEmployeeCommuting = new powerbi.models.VisualSelector("060a86a7bd290040894e");

    const embedConfig = {
        type: 'report',
        id: reportId,
        embedUrl: embedUrl,
        accessToken: accessToken,
        tokenType: powerbi.models.TokenType.Embed,
        permissions: powerbi.models.Permissions.Read,
        viewMode: powerbi.models.ViewMode.View,
        slicers: [
            {
                selector: slicerSummary,
                state: {
                    filters: [
                        {
                            target: {
                                table: "PublishedReportData",
                                column: "[rd.DateTo]"
                            },
                            operator: "In",
                            values: [ '' ]
                        }
                    ]
                }
            },
            {
                selector: slicerImpactArea,
                state: {
                    filters: [
                        {
                            target: {
                                table: "PublishedReportData",
                                column: "[rd.DateTo]"
                            },
                            operator: "In",
                            values: [ '' ]
                        }
                    ]
                }
            },
            {
                selector: slicerEmployeeCommuting,
                state: {
                    filters: [
                        {
                            target: {
                                table: "CompanySurveys",
                                column: "ExpiryDate"
                            },
                            operator: "In",
                            values: [ '' ]
                        }
                    ]
                }
            }
        ],
        settings: {
            panes: {
                filters: {
                    expanded: false,
                    visible: false
                },
                pageNavigation: {
                    visible: true,
                    position: powerbi.models.PageNavigationPosition.Left
                },
            },
            bars: {
                statusBar: {
                    visible: true
                }
            },
            localeSettings: {
                language: "en"
            }
        },
        layoutType: powerbi.models.LayoutType.Master,
    };

    const handleDownloadClick = () => {
        setShowDownloadOptions(!showDownloadOptions);
    };

    const handleExport = async (fileFormat: number) => {
        if (report) {
            setIsDownloadLoading(true);
            setShowDownloadOptions(false);

            const capturedBookmark = await report.bookmarksManager.capture({
                allPages: true,
                personalizeVisuals: true
            });

            await exportReportToPdf(companyId, capturedBookmark.state ?? "", fileFormat)
                .then((blob) => {
                    let reportName = 'CarbonReport.' + (fileFormat === 0 ? 'pptx' : 'pdf');
                    saveAs(blob, reportName);
                })
                .catch((error) => {
                    addAlert({
                        id: `Downloading Carbon Report ${companyId}`,
                        type: 'error',
                        title: 'Error Downloading Carbon Report',
                        content:
                            'Something went wrong when downloading your Carbon Report, please try again',
                    });
                    console.log('Download PowerBI Carbon Report Error: ', error);
                })
                .finally(() => {
                    setIsDownloadLoading(false);
                });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const res = await getReportEmbed(companyId);
            setReportId(res.id);
            setEmbedUrl(res.embedUrl);
            setAccessToken(res.token);
        };

        fetchData();
    }, [companyId]);

    return (
        <>
            <Header title='Analytics'
                subtitle="Please note that data in the analytics below is refreshed on a nightly basis. Use the download button to export this to PDF or PowerPoint (this may take up to a minute or so to download)." // TODO: Move to consts
                rightChildren={() =>
                    <>
                        <IconButton
                            id="download"
                            isLoading={isDownloadLoading}
                            onClick={(event) => {
                                event.stopPropagation();
                                handleDownloadClick();
                            }}
                            icon={<DownloadIcon stroke="#FBFAF8" />}
                        />
                        {
                            showDownloadOptions &&
                            <Menu id="download-options-menu" onClose={() => setShowDownloadOptions(false)}>
                                <MenuItem onClick={() => handleExport(1)}>PDF</MenuItem>
                                <MenuItem onClick={() => handleExport(0)}>PowerPoint</MenuItem>
                            </Menu>
                        }
                    </>
                }>
                Analytics
            </Header >
            {reportId && embedUrl && accessToken && (
                <PowerBIEmbed
                    embedConfig={embedConfig}
                    cssClassName={"reportClass"}
                    getEmbeddedComponent={async (embeddedReport) => {
                        setReport(embeddedReport as Report)
                    }}
                />
            )}
        </>
    );
};

export default EmbedReport;
