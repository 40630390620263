import './styles.scss';

import React from 'react';
import { IconButton } from '@flotilla/component-library';

import { ReactComponent as ExternalLinkIcon } from '../../../../assets/icons/ExternalLink-Light.svg'

interface ClimateSchoolProps {
  className?: string;
};

const ClimateSchool: React.FC<ClimateSchoolProps> = ({
  className = "",
}) => {
  return (
    <section id="climate-school-page" className={className}>
      <header>
        <h1>Climate School</h1>
        <IconButton
          icon={<ExternalLinkIcon />}
          label="Climate School"
          onClick={() => window.open("https://web.edapp.com/home", "_blank")}
        />
      </header>
      <iframe
        title="AXA Climate School"
        src="https://flotillastorage.blob.core.windows.net/flotillaworld/AXA Climate School.pdf"
      />
    </section>
  );
}

export default ClimateSchool;
