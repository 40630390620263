import { useCallback, useEffect, useState } from "react";
import { useAlert } from "@flotilla/component-library";

import { useAppDispatch } from "../../helpers/hooks";
import { useCompanyId } from "../../context";
import { getAvailableBaselineYears } from "../../api/DataPeriod";

const useAvailableBaselineYears = () => {
  const { addAlert } = useAlert();
  const dispatch = useAppDispatch();
  const companyId = useCompanyId();
  const [loading, setLoading] = useState(false);
  const [baselineYears, setBaselineYears] = useState<string[]>();

  const fetchApi = useCallback(async () => {
    if (companyId) {
      setLoading(true);
      await getAvailableBaselineYears(companyId)
        .then((res) => {
          setBaselineYears(res);
        })
        .catch(() => {
          addAlert({ id: `Available Baseline Years Get Failed ${companyId}`, type: "error", title: "Get Available Baseline Years Unsuccessful" });
        })
        .finally(() => setLoading(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const refresh = () => {
    fetchApi();
  }

  useEffect(() => {
    if (companyId) {
      fetchApi();
    }
  }, [companyId, dispatch, fetchApi]);

  return {
    baselineYears,
    refresh,
    loading
  };
}

export default useAvailableBaselineYears;
