import './styles.scss';

import { FC, useState } from 'react';
import { CheckboxButtonV2 } from '@flotilla/component-library';

import { SectionProps } from '../../../../../../types/CreateWizard';
import { Industries, Industry } from '../../../../../../types/CompanySettings';

const IndustrySection: FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const [industryId, setIndustryId] = useState<number>(values?.["industryId"]);

  const handleOnToggle = (selectedOption: Industry) => {
    return (checked: boolean) => {
      const value = checked ? selectedOption.id : 0;
      setIndustryId(value);
      onChange({
        industryId: value,
        industry: checked ? selectedOption : undefined
      });
    }
  }

  return (
    <section id="industry-section" className={className}>
      <article id="options">
        { (values?.["sector"]?.['industries'] as Industries)?.map((option, index) => 
          <CheckboxButtonV2
            className='checkbox'
            key={index}
            label={option.name}
            checked={option.id === industryId}
            onToggle={handleOnToggle(option)}
          />
        )}
      </article>
    </section>
  );
}

export default IndustrySection;
