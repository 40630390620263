import { Fragment } from "react";

const convertLinkText = (text: string) => {
  const textSplit = text.split("](");
  const textArray = [textSplit[0], ...textSplit[1].split(")")];
  if (textArray.length > 2) {
    return (
      <Fragment >
        <a href={textArray[1]} target="_blank" rel="noreferrer">{textArray[0]}</a>
        { textArray[2] }
      </Fragment>
    )
  }
  return text;
}

const convertBoldText = (text: string) => {
  const textArrayHtml = [];
  const textArray = text.split("**");
  for (let i = 0; i < textArray.length; i = i + 2) {
    textArrayHtml.push(
      <Fragment key={i}>
        {textArray[i]}
        { textArray[i + 1] && <b>{textArray[i + 1]}</b> }
      </Fragment>
    )
  }
  return textArrayHtml;
}

export const convertMarkdown = (text: string) => {
  const textArrayHtml = [];
  const textArray = text.split("[");
  for (let i = 0; i < textArray.length; i = i + 2) {
    textArrayHtml.push(
      <Fragment key={i}>
        { convertBoldText(textArray[i]) }
        { textArray[i + 1] && convertLinkText(textArray[i + 1]) }
      </Fragment>
    )
  }
  return textArrayHtml;
}