import './styles.scss';

import React, { MouseEventHandler } from 'react';
import { Skeleton } from '@flotilla/component-library';

import { Company, Companies } from '../../../../types/Company';
import CompanyCard from './Company';

interface CardViewProps {
  className?: string;
  data: Companies;
  loading?: boolean;
  onClick?: (company: Company) => void;
  onChange?: () => void;
};

const CardView: React.FC<CardViewProps> = ({
  className = "",
  data,
  loading = false,
  onClick = () => ({}),
  onChange = () => ({}),
}) => {
  const handleActionClick = (company: Company): MouseEventHandler<HTMLElement> => {
    return (event) => {
      event.stopPropagation();
      onClick(company);
    }
  }

  return (
    <section id="card-view" className={className}>
      { data?.length > 0 ? (
        data?.map((company) => (
          <CompanyCard
            key={company.id}
            data={company}
            onClick={handleActionClick(company)}
            onChange={onChange}
          />
        ))
      ) : (
        loading && (
          <>
            <Skeleton className="company-card--loading"/>
            <Skeleton className="company-card--loading"/>
            <Skeleton className="company-card--loading"/>
          </>
        )
      )}
    </section>
  );
}

export default CardView;
