import { FONT_FAMILY } from '../../../../../../../styles/font';
import { TriangleGraphic } from './TriangleGraphic';

interface GraphicSectionInfo {
  value: string | number;
  text?: string[];
}

interface HexagonGraphicProps {
  className?: string;
  topLeftTriangle: GraphicSectionInfo;
  topRightTriangle: GraphicSectionInfo;
  bottomLeftTriangle: GraphicSectionInfo;
  bottomRightTriangle: GraphicSectionInfo;
  circle: GraphicSectionInfo;
  downloadVersion?: boolean;
}

export const HexagonGraphic: React.FC<HexagonGraphicProps> = ({
  className = '',
  topLeftTriangle,
  topRightTriangle,
  bottomLeftTriangle,
  bottomRightTriangle,
  circle,
  downloadVersion = false,
}) => {
  return (
    <svg className={`hexagon-chart ${className}`} viewBox="0 0 300 300">
      <g>
        <circle cx="75" cy="150" r="48" fill="#8E979B" />
        <text
          x={75}
          y={150}
          fill="#FBFAF8"
          textAnchor="middle"
          style={{
            fontFamily: FONT_FAMILY,
            fontSize: downloadVersion ? '6px' : '7.5px',
          }}
        >
          <tspan style={{ fontSize: downloadVersion ? '30px' : '30px' }}>
            {circle.value}
          </tspan>
          {circle.text?.map((textValue, index) => (
            <tspan key={index} x={75} y={165 + index * 8}>
              {textValue}
            </tspan>
          ))}
        </text>
      </g>
      <TriangleGraphic
        value={topLeftTriangle.value}
        text={topLeftTriangle.text}
        x={72}
        y={10.5}
        downward
        fillColor="#051821"
        fontColor="#FBFAF8"
      />
      <TriangleGraphic
        value={topRightTriangle.value}
        text={topRightTriangle.text}
        x={149}
        y={11}
        fillColor="#EE5809"
        fontColor="#FBFAF8"
      />
      <TriangleGraphic
        value={bottomRightTriangle.value}
        text={bottomRightTriangle.text}
        x={149}
        y={142}
        downward
        fillColor="#47B19D"
        fontColor="#FBFAF8"
      />
      <TriangleGraphic
        value={bottomLeftTriangle.value}
        text={bottomLeftTriangle.text}
        x={72}
        y={142.5}
        fillColor="#EEF4B4"
        fontColor="#011E26"
      />
    </svg>
  );
};
