import { ImpactAreaGroupType } from "./ImpactArea";

export const mapOfficeMeasures = (data: {[key: string]: unknown}[], impactArea?: string, focusGroup?: string, activity?: string, scope?: string): OfficeMeasures => {
  return data.map((item:  {[key: string]: unknown | unknown[] | any}) => ({
    ...item,
    tco2e: item.tcO2e,
    name: item.office,
    impactArea,
    focusGroup,
    activity,
    scope,
    type: "office"
  })) as unknown[] as OfficeMeasures;
}

export interface OfficeMeasure extends ImpactAreaGroupType {}

export type OfficeMeasures = OfficeMeasure[];