import './styles.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddIcon, GenericData, IconButton, SearchFilter } from '@flotilla/component-library';

import { getAllCompanies } from '../../../api/companies';
import { Company, Companies } from '../../../types/Company';
import Header from '../../../components/HeaderV2';
import { useAppDispatch } from '../../../helpers/hooks';
import { addModal, removeModal } from '../../../reducers/modal';
import { ReactComponent as BoardIcon } from '../../../assets/icons/Board.svg';
import { ReactComponent as ListIcon } from '../../../assets/icons/List.svg';
import CardView from './Card';
import TableView from './Table';
import { CreateCustomerModal } from '../../../components/Modal';
import { useUserAccess } from '../../../context';
import { setNetZeroPlanId } from '../../../reducers/user';

interface CustomersListProps {
  className?: string;
};

const CustomersList: React.FC<CustomersListProps> = ({
  className = ""
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userAccess } = useUserAccess();
  const { ADMIN, PE } = userAccess;
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<Companies>();

  const getCompanies = () => {
    setLoading(true);
    getAllCompanies()
      .then((res) => {
        res && setCompanies(res.sort((a, b) => a.name.trim().toLowerCase() > b.name.trim().toLowerCase() ? 1 : -1));
      })
      .finally(() => setLoading(false));
  };

  const handleOnCloseCreateCompanyModal = (change: boolean = false) => {
    dispatch(removeModal());
    change && getCompanies();
  }

  const handleAddCompany = () => {
    dispatch(addModal({
      id: 'create-company-modal',
      children: <CreateCustomerModal onClose={handleOnCloseCreateCompanyModal} />
    }));
  }

  const handleOnClickCompany = (company: Company) => {
    dispatch(setNetZeroPlanId(undefined));
    navigate(`/${company.id}`);
  }

  const handleOnChange = () => {
    getCompanies();
  }

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <section id="customers-page" className={className}>
      <Header
        rightChildren={() =>
          ADMIN ? <IconButton
            id="add-data-period"
            onClick={handleAddCompany}
            icon={<AddIcon />}
            small
          /> : <></>
        }
      >
        {PE ? "Portfolio" : "Customers" }
      </Header>
      <main>
        <SearchFilter
          key={loading ? '' : 'customers-view'}
          id="customers-view"
          instanceName="CustomersView"
          data={companies as unknown as GenericData}
          selectedView="card"
          quickFilters={[
            {
              key: "companyTypeName",
              label: "Type",
            },
            {
              key: "productName",
              label: "Product",
            }
          ]}
          filters={[
            {
              key: "epcRating",
              label: "EPC Rating",
              addLabel: true,
            }
          ]}
          views={[
            {
              key: "card",
              label: "Card",
              icon: <BoardIcon />,
              children: (data) => (
                <CardView
                  data={data as unknown as Companies}
                  onClick={handleOnClickCompany}
                  loading={loading}
                  onChange={handleOnChange}
                />
              )
            },
            {
              key: "list",
              label: "List",
              icon: <ListIcon />,
              children: (data) => (
                <TableView
                  data={data as unknown as Companies}
                  onClick={handleOnClickCompany}
                  loading={loading}
                  onChange={handleOnChange}
                />
              )
            },
          ]}
        />
      </main>
    </section>
  );
}

export default CustomersList;
