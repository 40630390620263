import { useEffect, useState } from 'react';
import './styles.scss';
import { Company, FullCompanyQuestion, FullCompanyQuestions } from '../../../../types/Company';
import { getCompanyQuestions } from '../../../../api/surveys';
import QuestionRow from './QuestionRow';
import { AddIcon, IconButton, Skeleton, useAlert } from '@flotilla/component-library';
import CreateQuestionModal from './CreateQuestionModal';

type QuestionsListProps = {
  company: Company,
  onChange: (questions: FullCompanyQuestions) => void,
  selectedIds?: number[],
  isLoading?: boolean
};

const QuestionsList: React.FC<QuestionsListProps> = ({ company, onChange, selectedIds = null, isLoading = null }) => {
    const { addAlert } = useAlert()
    const [companyQuestions, setCompanyQuestions] = useState<FullCompanyQuestions>();
    const [selectedQuestions, setSelectedQuestions] = useState<FullCompanyQuestions>([]);
    const [isCreatingQuestion, setIsCreatingQuestion] = useState<boolean>(false);

    const availableQuestions = companyQuestions?.filter(q => !selectedQuestions?.includes(q)) ?? null;

    useEffect(() => {
        company && getCompanyQuestions(company.identityCompanyId)
        .then((res) => {
            setCompanyQuestions(res);
        })
        .catch(() => {
            addAlert({
                id: `loading company questions ${company.identityCompanyId}`,
                type: 'error',
                title: 'Error loading company questions',
                content: 'There seems to be an error loading the company questions list, please try again.'
            });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company]);

    useEffect(() => {
        if(companyQuestions?.length && selectedIds?.length) {
            var newSelected = companyQuestions.filter(q => selectedIds.includes(q.id));
            setSelectedQuestions(newSelected);
            onChange(newSelected);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyQuestions, selectedIds]);

    const handleQuestionAdded = (question: FullCompanyQuestion) => {
        var newQuestions = [...selectedQuestions, question];
        setSelectedQuestions(newQuestions);
        onChange && onChange(newQuestions);
    };

    const handleQuestionRemoved = (question: FullCompanyQuestion) => {
        var newQuestions = selectedQuestions.filter(q => q !== question);
        setSelectedQuestions(newQuestions);
        onChange && onChange(newQuestions);
    };

    const handleQuestionCreated = (question: FullCompanyQuestion) => {
        setCompanyQuestions([...(companyQuestions || []), question]);
        setIsCreatingQuestion(false);
    };

    return (
        <section className='company-questions-list'>
            <section>
                {isCreatingQuestion && <CreateQuestionModal 
                    onClose={() => { setIsCreatingQuestion(false) }}
                    onSuccess={handleQuestionCreated}
                    company={company}
                    />
                }
                <h4>
                    Available Questions{isLoading || !availableQuestions ? '' : ` (${availableQuestions?.length || 0})`}
                    <IconButton
                        className="add-button"
                        icon={<AddIcon />}
                        isLoading={isLoading || !availableQuestions}
                        onClick={() => { setIsCreatingQuestion(true) }}
                        small
                    />
                </h4>
                <section className='selection-list'>
                    {(isLoading || !availableQuestions) ? 
                        <Skeleton className="question--loading"/> :
                        availableQuestions?.map(q => {
                            return <QuestionRow key={q.id} 
                                question={q} 
                                onClick={() => handleQuestionAdded(q)}
                                selected={false}/>
                        })}
                </section>
            </section>
            <section>
                <h4>Active Questions{isLoading || !availableQuestions ? '' : ` (${selectedQuestions?.length || 0})`}</h4>
                <section className='selection-list'>
                    {(isLoading || !availableQuestions) ? 
                        <Skeleton className="question--loading"/> :
                        selectedQuestions.map(q => {
                            return <QuestionRow key={q.id}
                                question={q}
                                onClick={() => handleQuestionRemoved(q)}
                                selected={true}/>
                        })}
                </section>
            </section>
        </section>
    );
};

export default QuestionsList;
