import './styles.scss';

import React from 'react';
import { Button } from '@flotilla/component-library';

import { useCompanyName } from '../../../../context';

interface AskFlotillaProps {
  className?: string;
};

const AskFlotilla: React.FC<AskFlotillaProps> = ({
  className = "",
}) => {
  const companyName = useCompanyName();

  const handleHelpButtonClick = () => {
    const email = "getintouch@flotillaworld.com";
    const subject = `Support request from ${companyName}`;
    const params = `subject=${encodeURIComponent(subject)}`;
    window.location.href = `mailto:${email}?${params}`;
  }

  return (
    <section id="ask-flotilla-page" className={className}>
      <header>
        <h1>Ask Flotilla</h1>
      </header>
      <main>
        <div id="virtual-consultant">
          <h3>Virtual Consultant</h3>
          <p>Chat with our virtual consultant, they have all the answers you need.</p>
          <iframe
            id="ribbo-widget-iframe"
            title="Ribbo Embed Widget"
            src="https://www.ribbo.ai/share/application/Va6EhnAoWtGuPKD3SA3M?padding=2"
          />
        </div>
        <section id="contact-info">
          <h3>Human Consultant</h3>
          <p>Did the virtual consultant really not provide you with the answer? No problem, our specialists are more than happy to help. Click the "I need help" button below to send an email to the team.</p>
          <Button
            variant='outline'
            onClick={handleHelpButtonClick}
          >
            I need help
          </Button>
        </section>
      </main>
    </section>
  );
}

export default AskFlotilla;
