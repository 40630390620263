const formatMoney = (
  value: number,
  currencySymbol: string = '£',
  allowDecimals: boolean = false,
  absolute: boolean = true
): string => {
  const moneyPostFix = ['k', 'm', 'b', 't'];
  let formatValue = Math.abs(value);
  let zeros = 0;
  let decimalFactor = 1;
  while (formatValue > 1000 && zeros < moneyPostFix.length) {
    formatValue = formatValue / 1000;
    zeros++;
  }
  if(allowDecimals) {
    if(formatValue < 10) {
      decimalFactor = 100;
    } else if (formatValue < 100) {
      decimalFactor = 10;
    }
  }
  formatValue = formatValue * decimalFactor;
  return `${!absolute && value < 0 ? "-" : ""}${currencySymbol}${Number(Math.round(formatValue) / decimalFactor).toLocaleString()}${moneyPostFix[zeros - 1] || ''}`;
};

export default formatMoney;
