import './styles.scss';

import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Card, IconButton, PadlockIcon, Tooltip, useAlert } from '@flotilla/component-library';

import { useCompanyId, useUserAccess } from '../../../context';
import { ReactComponent as ChevronLeftIcon } from '../../../assets/icons/ChevronLeft.svg';
import { getProgressValues } from '../../../api/Statistics';
import { getNetZeroPlanId } from '../../../reducers/user';
import { useAppSelector } from '../../../helpers/hooks';
import { ProgressAPIPayload } from '../../../types/ProgressStat';
import { LOCKED_DASHBOARD_TOOLTIP } from '../../../assets/content/Tooltips';
import { numberLocaleFormatter } from '../../../helpers/numberFormatter';

interface IntensitySummaryProps {
  className?: string;
};

const IntensitySummary: React.FC<IntensitySummaryProps> = ({
  className = "",
}) => {
  const { addAlert } = useAlert();
  const { userAccess } = useUserAccess();
  const { ADMIN, STANDARD, NET_ZERO_PLAN_EDIT } = userAccess;
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const netZeroPlanId = useAppSelector(getNetZeroPlanId);
  const [data, setData] = useState<ProgressAPIPayload>();
  const [isLocked, setIsLocked] = useState(false);

  useEffect(() => {
    if(netZeroPlanId && companyId) {
      setIsLocked(false);
      getProgressValues(netZeroPlanId, companyId)
        .then((res) => {
          setData(res);
        })
        .catch((error) => {
          if (error.cause.noReportData) {
            setIsLocked(true);
          } else {
            addAlert({
              id: `Getting intensity data ${netZeroPlanId}`,
              type: 'error',
              title: 'Error Getting Intensity Summary Data',
              content: 'Something went wrong, please try again by refreshing the page.',
            });
            console.log('Error while getting Intensity Summary Data: ', error);
          }
        });
    } else if (!netZeroPlanId) {
      setIsLocked(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, netZeroPlanId]);

  const handleTitleClick = () => {
    if (!isLocked && NET_ZERO_PLAN_EDIT) {
      if (!STANDARD || ADMIN) {
        navigate(`/${companyId || 0}/netzeroplan`);
      } else {
        navigate(`/netzeroplan`);
      }
    }
  }

  const mapValue = (value: number = 0) => {
    if (value < 1) {
      return Math.round(value * 100) / 100;
    } else if (value < 10) {
      return Math.round(value * 10) / 10;
    }
    return Math.round(value);
  }

  return (
    <Card
      id="intensity-summary-widget"
      className={`${isLocked ? 'intensity-summary-widget--locked' : ''}${className}`}
    >
      <main>
        <header onClick={handleTitleClick}>
          <section id="title">
            <h3>Intensity Metric</h3>
            <p>tCO2e per FTE</p>
          </section>
          { isLocked ? (
            <Tooltip icon={PadlockIcon} tooltipText={LOCKED_DASHBOARD_TOOLTIP} />
          ) : (
            NET_ZERO_PLAN_EDIT ? <IconButton
              icon={<ChevronLeftIcon style={{transform: 'rotate(180deg)'}} />}
              variant='ghost'
              small
            /> : <></>
          )}
        </header>
        <article className="stat">
          <h4>{numberLocaleFormatter(mapValue(data?.currentTCO2ePerFte))}</h4>
          <p>Current</p>
        </article>
        <article className="stat">
          <h4>{numberLocaleFormatter(Math.abs(mapValue(data?.netZeroTCO2ePerFte)))}</h4>
          <p>Net Zero Target</p>
        </article>
      </main>
    </Card>
  );
}

export default IntensitySummary;
