import './styles.scss';

import { useEffect, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wizard } from '@flotilla/component-library';

import { getSurveyWizardPages } from '../../../../assets/content/CreateWizard';
import useSurvey from '../../../../hooks/Survey/useSurvey';
import { environment } from '../../../../helpers/environment';
import { useCompany } from '../../../../hooks';
import { useCompanyId } from '../../../../context';
import FinalSection from '../../../../assets/content/CreateWizard/Survey/Sections/Final';

interface SurveyCreateProps {
  className?: string;
};

const SurveyCreate: FC<SurveyCreateProps> = ({
  className = ""
}) => {
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const [company] = useCompany(companyId);
  const env = environment();
  const {
    survey,
    saveSurvey,
    updateSurvey,
    loading
  } = useSurvey();

  const handleOnComplete = () => {
    saveSurvey()
      .then((res) => res && navigate(res?.id ? `../${res.id}` : '..', { replace: true }));
  }
  
  const handleOnChange = (values: { [key: string]: any }) => {
    updateSurvey(values);
  }

  useEffect(() => {
    updateSurvey({ pUrl: `${env.employee.baseUrl}/?companyToken=${company?.companyGuid || ''}`});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    updateSurvey({
      fromDate: new Date().toISOString().substring(0, 10),
      toDate: new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000).toISOString().substring(0, 10)
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wizard
      className={className}
      pages={getSurveyWizardPages()}
      values={survey}
      onChange={handleOnChange}
      startPage={{
        name: 'Initial',
        title: 'Create Survey',
        subtitle: 'Welcome to your survey creator wizard, this wizard will help create a new survey to send to your employees!'
      }}
      endPage={{
        name: 'Final',
        title: 'Create Survey',
        subtitle: 'Well done you are ready to create your Survey.',
        children: <FinalSection values={survey}/>,
      }}
      submitLoading={loading}
      onComplete={handleOnComplete}
      onBack={() => navigate(-1)}
    />
  );
}

export default SurveyCreate;
