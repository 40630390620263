import { StyleSheet } from "@react-pdf/renderer";

import {
  ReportPage,
  ReportDiv,
  ReportChart,
  ReportEditor,
} from "../../../../../../../components/PDFReport";
import { ReportPageProps } from "../../../../../../../types/Report";
import { BarChart, BartCO2eDifference } from "../../BarChart";
import { getChartLabels } from "../../MultiOfficeCommutingPage/Labels";
import groupBy from "../../../../../../../helpers/groupBy";
import { getEmissionsLabels } from "./Layers/EmissionsLabels";
import { getBarTotals } from "./Layers/BarTotalsLayer";
import { getTurnoverLine } from "./Layers/TurnoverLine";

const styles = StyleSheet.create({
  pageStyle: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  content: {
    width: "100%",
    paddingTop: 12.5,
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
});

export const ComparativeInformation: React.FC<ReportPageProps> = ({
  page,
  className,
  downloadVersion = false,
  onContentUpdate = () => {},
}) => {
  var rawData = page.charts[0].data.filter(d => d.legend !== '£m Revenue' && d.legend !== 'tCO2e');
  var keys: string[] = [];

  const colors: { [key: string]: string; } = {};
  const data = groupBy(
    rawData.sort((a, b) => (b.xLabel < a.xLabel ? 1 : -1)),
    'xLabel'
  ).map((data) => {
    const dataObject: { key: string, [key: string]: any} = {
      key: data[0].xLabel
    };

    data.forEach(d => {
      if(d.colourHex && !colors[d.legend as string]) {
        colors[d.legend as string] = d.colourHex;
      }
      
      dataObject[d.legend as string] = d.value;
      if(!keys.includes(d.legend)) keys.push(d.legend);
    });

    return dataObject;
  });

  keys.sort((a, b) => b.localeCompare(a));

  const revenueData = page.charts[0].data.filter(d => d.legend === '£m Revenue');
  const emissionsData = page.charts[0].data.filter(d => d.legend === 'tCO2e');

  let chartWidth = downloadVersion ? 670 : 1000;
  let chartHeight = downloadVersion ? 450 : 600;

  const theme = { 
    background: 'transparent', 
    axis: {
      ticks: { 
        text: {
          fontSize: downloadVersion ? 10 : 12, fontWeight: 700, fill: '#011E26'
        }
      },
      legend: { text: { fontSize: downloadVersion ? 10 : 12 } }
    },
    legends: {
      text: {
        fontSize: downloadVersion ? 6 : 8
      }
    }
  };

  const legendData = data
    .flatMap(d => { 
      return Object.keys(d)
        .filter(k => k !== 'key')
        .map(u => { return { id: d.key, label: u, color: colors[u] ?? '#4C6065' } });
    })
    .filter((item, index, self) =>
      index === self.findIndex((t) => t.label === item.label)
    );

  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`image-full-page ${className}`}
      style={styles.pageStyle}
      downloadVersion={downloadVersion}
      footer={page.footer}
      onChange={() => onContentUpdate()}
      pageId={page.pageId}
      showFooter
      editable={false}
      page={page}
      showHeader
    >
      <ReportDiv
        className="content"
        style={styles.content}
        downloadVersion={downloadVersion}
      >
        <ReportChart downloadVersion={downloadVersion}>
          <BarChart
            width={chartWidth + (downloadVersion ? 74 : 30)}
            height={chartHeight}
            fontSize={downloadVersion ? 9 : 12}
            data={data}
            layout='vertical'
            keys={keys}
            theme={theme}
            getColor={() => '#4C6065'}
            axisLeft={{
              tickSize: 0,
              tickPadding: 8,
              legend: 'Average FTE',
              legendOffset: downloadVersion ? -35 : -45,
              legendPosition: 'middle'
            }}
            axisBottom={{
              tickSize: 0,
              tickPadding: 5
            }}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'right',
                direction: 'column',
                translateX: downloadVersion ? 175 : 195,
                itemWidth: downloadVersion ? 120 : 125,
                itemHeight: downloadVersion ? 20 : 25,
                itemDirection: 'left-to-right',
                symbolSize: downloadVersion ? 8 : 12,
                data: legendData
              },
              {
                dataFrom: 'keys',
                anchor: 'right',
                direction: 'column',
                translateX: downloadVersion ? 175 : 195,
                translateY: (downloadVersion ? 25 : 30) + ((keys.length + 1) * (downloadVersion ? 25 : 30)) / 2,
                itemWidth: downloadVersion ? 120 : 120,
                itemHeight: downloadVersion ? 25 : 30,
                itemDirection: 'left-to-right',
                symbolSize: downloadVersion ? 12 : 20,
                symbolShape: 'circle',
                data: [
                  {
                    id: '£m Revenue',
                    label: '£m Revenue',
                    color: '#254A5C',
                  },
                  {
                    id: 'Total FTE',
                    label: 'Total FTE',
                    color: '#E2E4E3',
                  }
                ]
              },
            ]}
            enableLabel={false}
            enableGridY={true}
            gridYValues={null}
            margin={{ top: 25, right: downloadVersion ? 160 : 200, bottom: 60, left: downloadVersion ? 40 : 75}}
            layers={['grid', 'axes', 'bars', getTurnoverLine(revenueData, chartWidth - (downloadVersion ? 125 : 240), chartHeight - 85, downloadVersion), 'markers', 'legends', 'annotations', getChartLabels(downloadVersion, false, ' FTE'), getEmissionsLabels(downloadVersion, emissionsData), getBarTotals(downloadVersion)]}
            barComponent={(props) => (
              <BartCO2eDifference
                {...props}
                hideValues
                downloadVersion={downloadVersion}
                color={colors[props.bar.data.id]}
              />
            )}
          />
        </ReportChart>
        {page.details[0] ? (
            <ReportEditor
              className="comment-editor"
              contentId={page.details[0].id}
              editorContent={page.details[0].comments}
              downloadVersion={downloadVersion}
              editable={true}
              onChange={() => onContentUpdate()}
              onDelete={() => onContentUpdate()}
            />
          ) : (
            !downloadVersion && (
              <ReportEditor
                pageId={page.pageId}
                editorContent={{
                  boxAccent: true,
                  boxAccentColor: 'dark',
                  header: 'Comments',
                  content: [],
                  type: 'text',
                }}
                newEditor
                onChange={() => onContentUpdate()}
              />
            )
          )}
      </ReportDiv>
    </ReportPage>
  );
};