import './styles.scss';

import { Text, StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FONT_FAMILY } from '../../../styles/font';

interface ReportH4Props {
  id?: string;
  className?: string;
  style?: Style;
  downloadVersion?: boolean;
  children: string | string[];
}

const styles = StyleSheet.create({
  text: {
    fontFamily: FONT_FAMILY,
    fontSize: 13,
    color: '#011e26',
  },
});

const ReportH4: React.FC<ReportH4Props> = ({
  className = '',
  id,
  children,
  style = {},
  downloadVersion = false,
}) => {
  if (downloadVersion) {
    return (
      <Text id={id} style={[styles.text, style]}>
        {children}
      </Text>
    );
  }

  return (
    <h4 id={id} className={`report-h4 ${className}`}>
      {children}
    </h4>
  );
};

export default ReportH4;
