import './styles.scss';

import React, { useEffect, useState } from 'react';
import { Button, CloseIcon, Datalist, Tag, useAlert } from '@flotilla/component-library';

import { Action } from '../../../types/Action';
import useChildCompanies from '../../../hooks/Company/useChildCompanies';
import { recommendAction as recommendActionAPI } from '../../../api/Action';
import { Companies, Company } from '../../../types/Company';

interface RecommendActionModalProps {
  companyId: string | number;
  className?: string;
  data: Action;
  onClose: (change?: boolean) => void;
};

interface Option {
  value: string;
  [key: string]: unknown;
}

const RecommendActionModal: React.FC<RecommendActionModalProps> = ({
  companyId,
  className = "",
  data,
  onClose = () => {},
}) => {
  const { addAlert } = useAlert();
  const {
    childCompanies = []
  } = useChildCompanies(companyId);
  const [loading, setLoading] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState<Companies>([]);

  const handleSubmit = async () => {
    setLoading(true);
    await recommendActionAPI({
      actionId: data.id,
      recommendingCompanyId: companyId,
      companyIds: selectedCompanies.map((item) => Number(item.id)),
    })
      .then(() => {
        addAlert({ id: `Recommend Action Success`, type: "success", title: "Successfully recommended action" });
      })
      .catch(() => {
        addAlert({ id: `Recommend Action Failed`, type: "error", title: "Failed to recommend action" });
      })
      .finally(() => {
        onClose(true);
      });
  };

  const handleOnChangeCompany = (option: Option | unknown | undefined) => {
    const companyOption = option as Company;
    if (companyOption && (selectedCompanies || []).findIndex((item) => item.id === companyOption.id) === -1) {
      setSelectedCompanies([...(selectedCompanies || []), companyOption]);
    }
  }

  const handleOnClickAddAll = () => {
    setSelectedCompanies(childCompanies.map((item => ({ value: item.name, ...item }))));
  }

  const handleOnClickClearAll = () => {
    setSelectedCompanies([]);
  }

  const handleOnClickCloseCompany = (company: Company) => {
    return () => {
      setSelectedCompanies([...selectedCompanies.filter(item => item.id !== company.id)]);
    }
  }

  useEffect(() => {
    if (childCompanies) {
      setSelectedCompanies([...selectedCompanies, ...childCompanies.filter((item) => (data.recommendedCompanyIds || []).includes(item.id))])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, childCompanies]);

  return (
    <section
      id="recommend-action-modal"
      className={`recommend-action-modal ${className}`}
      onClick={((event) => event.stopPropagation())}
    >
      <header>
        <h4>Recommend action</h4>
        <p>{data.title}</p>
      </header>
      <main>
        <Datalist
          key="search-companies"
          id="search-companies"
          className="search-companies"
          options={childCompanies.filter((item) => !selectedCompanies.map((sc) => sc.id).includes(item.id)).map((item => ({ value: item.name, ...item })))}
          onChange={handleOnChangeCompany}
          searchKeys={['value']}
          placeholder='Search for company to recommend action...'
        />
        <section id="option-actions" className="button-container">
          <Button onClick={handleOnClickClearAll} variant='ghost'>Clear</Button>
          <Button onClick={handleOnClickAddAll}>Add all</Button>
        </section>

        <section id="selected-companies">
          {selectedCompanies?.map((company) => (
            <Tag className="company" icon={<CloseIcon variant='dark' onClick={handleOnClickCloseCompany(company)}/>}>{company.name}</Tag>
          ))}
        </section>
      </main>

      <section className="button-container">
        <Button variant="ghost" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} isLoading={loading}>Save</Button>
      </section>
    </section>
  );
}

export default RecommendActionModal;
