import { Actions } from "./Action";
import { Tasks } from "./Task";
import { User } from "./User";

export const statusTypeDisplay = ["To do", "Active", "Overdue", "Completed"];
export const statusType = ["To do", "Active", "Completed", "Overdue"];
export type StatusType = typeof statusType[number];

export interface NetZeroPlan {
  id: number;
  companyId: number;
  targetDate: string;
  initialTotalCo2E: number;
  interimTargetYear: number;
  scope1ReductionTargetInterim: number;
  scope2ReductionTargetInterim: number;
  scope3ReductionTargetInterim: number;
  scope1ReductionTargetNetZero: number;
  scope2ReductionTargetNetZero: number;
  scope3ReductionTargetNetZero: number;
  alignedToSBT: boolean;
  createdAt: string;
  updatedAt: string;
  actions?: Actions,
}

export interface NetZeroPlanAction {
  id: number;
  netZeroPlanId: number;
  actionId: number;
  status: StatusType;
  estimatedUptakePercent: number;
  actualUptakePercent: number;
  targetCompletionDate: string;
  targetCompletionDateFormatString: string;
  actualCompletionDate: string;
  priority: number;
  createdAt: string;
  updatedAt: string;
  tags?: string[];
  tagString?: string;
  startDate?: string;
  startDateFormatString: string;
  notes?: string;
  estimatedCost?: number,
  estimatedROI?: number,
  estimatedEffort?: number,
  estimatedReductionPercent?: number,
  user?: User;
  userId?: number;
  tasks?: Tasks;
}

export interface CreateNetZeroPlan {
  id: number,
  targetDate: string,
  interimTargetYear: number,
  scope1InterimReductionTarget?: number,
  scope2InterimReductionTarget?: number,
  scope3InterimReductionTarget?: number
}