import groupBy from "../helpers/groupBy";

export const mapReductionChart = (data: ReductionChart): ReductionChart => {
  const result: ReductionChart = {
    actual: mapReductionChartValues(data.actual),
    actualFTE: mapReductionChartValues(data.actualFTE),
    target: mapReductionChartValues(data.target),
    latestTarget: mapReductionChartValues(data.latestTarget),
    targetFTE: mapReductionChartValues(data.targetFTE),
    latestTargetFTE: mapReductionChartValues(data.latestTargetFTE),
    fte: data.fte,
    actualTurnover: mapReductionChartValues(data.actualTurnover),
    targetTurnover: mapReductionChartValues(data.targetTurnover),
    latestTargetTurnover: mapReductionChartValues(data.latestTargetTurnover),
    turnover: data.turnover,
    ...(data.planned ? { planned: mapReductionChartValues(data.planned) } : {}),
    ...(data.plannedFTE ? { plannedFTE: mapReductionChartValues(data.plannedFTE) } : {}),
    ...(data.plannedTurnover ? { plannedTurnover: mapReductionChartValues(data.plannedTurnover) } : {}),
  };
  return result;
}

export const mapReductionChartValues = (data: ReductionChartValues): ReductionChartValues => {
  const result: ReductionChartValues = [];
  groupBy(data, "year").forEach((item) => result.push(mapReductionObject(item)));
  return result.sort((a, b) => Number(a.year) - Number(b.year));
}

export const mapReductionObject = (data: ReductionChartValues) : ReductionChartValue => {
  const result: ReductionChartValue = data.reduce((prev, curr) => {
    const newTotal = prev.total + (Number(curr["tCO2e"]) || 0);
    return {
      ...prev,
      year: String(curr.year),
      total: newTotal,
      [curr.scopeId || "0"]: curr["tCO2e"] || 0,
    }
  }, { year: "0", total: 0 });
  return result;
}

export interface ReductionChart {
  actual: ReductionChartValues;
  planned?: ReductionChartValues;
  target: ReductionChartValues;
  latestTarget: ReductionChartValues;
  actualFTE: ReductionChartValues;
  targetFTE: ReductionChartValues;
  plannedFTE?: ReductionChartValues;
  latestTargetFTE: ReductionChartValues;
  fte: { [key: number]: number }
  actualTurnover: ReductionChartValues;
  targetTurnover: ReductionChartValues;
  plannedTurnover?: ReductionChartValues;
  latestTargetTurnover: ReductionChartValues;
  turnover: { [key: number]: number }
}

export interface ReductionChartValue {
  year: string,
  total: number,
  scopeId?: number,
  [key: string]: number | string | unknown,
}

export type ReductionChartValues = ReductionChartValue[];

export type ReductionChartViewMode = 'absolute' | 'fte' | 'turnover';