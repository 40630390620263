  import './styles.scss';

import React from 'react';
import { Card, Divider, Tag } from '@flotilla/component-library';

import { Action } from '../../../../../types/Action';
import getRatingIcon from '../../../../../helpers/getRatingsIcon';
import ImpactAreaTag from '../../../../../components/ImpactAreaTag';

interface CardItemProps extends Action {
  className?: string;
  onClick?: () => void;
};

const CardItem: React.FC<CardItemProps> = ({
  className = "",
  title,
  impactArea,
  departments,
  carbonSavingsPotential = "none",
  coBenefitsPotential = "none",
  costSavingsPotential = "none",
  activityName,
  onClick = () => ({})
}) => {
  const sentenceCase = (value: string) => {
    const initialChar = value.slice(0, 1);
    const slicedValue = value.slice(1);
    return `${initialChar.toUpperCase()}${slicedValue}`;
  }

  return (
    <Card
      id="card-item"
      className={className}
      onClick={onClick}
    >
      <header>
        <p>{title}</p>
      </header>
      <section id="tags">
        { impactArea &&
          <ImpactAreaTag
            size="small"
            extraText={activityName}
          >
            {impactArea.name}
          </ImpactAreaTag>
        }
        { departments.map((department, index) => <Tag key={index} className="department-tag" size="small">{department.name}</Tag>) }
      </section>
      <section id="stats">
        <section className="stat">
          { getRatingIcon(carbonSavingsPotential, "cloud") }
          <p>{sentenceCase(carbonSavingsPotential)}</p>
        </section>
        <Divider />
        <section className="stat">
          { getRatingIcon(coBenefitsPotential, "hand") }
          <p>{sentenceCase(coBenefitsPotential)}</p>
        </section>
        <Divider />
        <section className="stat">
          { getRatingIcon(costSavingsPotential, "money") }
          <p>{sentenceCase(costSavingsPotential)}</p>
        </section>
      </section>
    </Card>
  );
}

export default CardItem;
