import './styles.scss';

import React from 'react';
import EmbedReport from '../../../components/PowerBiReport';

interface DashboardProps {
  className?: string;
};


const Analytics: React.FC<DashboardProps> = ({
  className = "",
}) => {
  return (
    <section id="analytics-page" className={className}>
      <article className="main-content">
        <EmbedReport />
      </article>
    </section>
  );
}

export default Analytics;
