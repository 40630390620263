import { EntityMatch } from "@m31coding/fuzzy-search";
import { Activity } from "./DataPeriod";

export interface DataIntegration {
    id: number;
    companyId: number;
    type: number;
    thirdPartyId: number;
    authorizationUrl: string;
    requiresAuthorization: boolean;
    syncing: boolean;
    name: string;
}

export type DataIntegrations = DataIntegration[];

export interface AccountsNominal {
    id?: number;
    dataIntegrationId: number;
    thirdPartyId: string;
    accountName: string;
    activityId?: number;
    predicted?: number;
    matches?: EntityMatch<Activity>[];
}

export type AccountsNominals = AccountsNominal[];

export enum DataIntegrationType {
    Accounts = 2
}