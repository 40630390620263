import './styles.scss';

import React, { useState } from 'react';
import { Button, Divider, TextInput, Checkbox, Label, useAlert } from '@flotilla/component-library';

import { inviteUser } from '../../../api/User';
import { useIdentityCompanyId } from '../../../context';
import { useUserRoles } from '../../../hooks';

interface InviteUserModalProps {
  className?: string;
  inviteCompanyId?: number | string;
  onClose: () => void;
  companyName?: string;
};

const InviteUserModal: React.FC<InviteUserModalProps> = ({
  className = "",
  onClose = () => {},
  inviteCompanyId,
  companyName
}) => {
  const identityCompanyId = useIdentityCompanyId();
  const { addAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const {
    userRoles
  } = useUserRoles();

  const handleSubmit = async () => {
    setIsLoading(true);
    await inviteUser(inviteCompanyId || identityCompanyId, email, userRoles?.filter((role) => role.friendlyName === "Admin"))
      .then(() => {
        onClose();
        addAlert({ id: `Invite User Success`, type: "success", title: "Successfully invited user", content: "An invite has been sent via email." });
      })
      .catch((err) => {
        setIsLoading(false);
        if(err?.message.includes('already')) {
          addAlert({ id: `Invite User Error`, type: "error", title: "Failed to send invite", content: err.message });
        }
        else {
          addAlert({ id: `Invite User Error`, type: "error", title: "Failed to send invite", content: "Something went wrong, please try again." });
        }
      });
  };

  return (
    <section
      id="invite-user-modal"
      className={`invite-user-modal ${className}`}
      onClick={((event) => event.stopPropagation())}
    >
      <header>
        <h4>Invite user</h4>
        { companyName && (
          <p id="company-name">{companyName}</p>
        )}
      </header>
      <main>
        <TextInput
          id="email"
          label="Enter an email below to send a link for sign up"
          aria-label="User email"
          value={email}
          onChange={(event) => setEmail(event?.currentTarget.value)}
        />
        { userRoles && (
          <>
            <Divider />
            <section id="roles">
              <Label>Role</Label>
              { userRoles?.filter((role) => role.friendlyName === "Admin").map((userRole) => (
                <Checkbox
                  key={userRole.id}
                  label={userRole.friendlyName || userRole.name}
                  onClick={() => {}}
                  checked
                  disabled
                />
              ))}
            </section>
          </>
        )}
      </main>

      <section className="button-container">
        <Button variant="ghost" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} isLoading={isLoading} disabled={!email}>Invite</Button>
      </section>
    </section>
  );
}

export default InviteUserModal;
