import './styles.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { Carousel, IconButton, Spinner, Tab, Tabs } from '@flotilla/component-library';
import { ReactComponent as TrophyIcon } from '../../../../../assets/icons/Trophy.svg';
import { ReactComponent as ChevronLeftIcon } from '../../../../../assets/icons/ChevronLeft.svg';
import { getRecommendedActions } from '../../../../../api/actions';
import { Action, Actions, RecommendedActionType } from '../../../../../types/Action';
import { useAppSelector } from '../../../../../helpers/hooks';
import { getNetZeroPlanId } from '../../../../../reducers/user';
import ReductionStats from '../../../../../components/ReductionStats';
import NetZeroPlanActionUpdater from '../../../../../components/NetZeroPlanActionUpdater';
import ActionCard from '../../../../../components/ActionCard';
import DetailsTab from '../../ActionDetail/TabContent/Details';

interface SuggestedActionsProps {
  className?: string;
  title?: string;
  onClose: () => void;
  id: number,
  type?: RecommendedActionType,
  companyId: string
};

const SuggestedActions: React.FC<SuggestedActionsProps> = ({
  className = "",
  title,
  onClose = () => {},
  id,
  type = "impactArea",
  companyId
}) => {
  const netZeroPlanId = useAppSelector(getNetZeroPlanId);
  const [isLoading, setIsLoading] = useState(false);
  const [actions, setActions] = useState<Actions>();
  const [selectedAction, setSelectedAction] = useState<Action>();
  const [refreshReductionStats, setRefreshReductionStats] = useState(false);

  const handleGetActions = useCallback(async () => {
    setIsLoading(true);
    netZeroPlanId && await getRecommendedActions(id, netZeroPlanId, type, companyId)
      .then((res) => setActions(res))
      .finally(() => {
        setIsLoading(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, netZeroPlanId, type]);

  const handleClickAction = (action: Action) => {
    setSelectedAction(action);
  }

  const handleActionSubmit = () => {
    var currentIdx = actions?.findIndex((action) => selectedAction?.id === action.id) || 0;
    var newActions = actions?.filter((action) => selectedAction?.id !== action.id);

    setActions(newActions);

    if(newActions && newActions.length > 0) {
      // Set selected action to be either next action if it exists or the last action
      setSelectedAction(newActions[Math.min(currentIdx, newActions.length - 1)]);
    } else {
      setSelectedAction(undefined);
    }

    setRefreshReductionStats(true);
  }

  useEffect(() => {
    handleGetActions();
  }, [handleGetActions]);

  useEffect(() => {
    actions && !actions.some((action) => selectedAction?.id === action.id) && setSelectedAction(actions[0]);
    setRefreshReductionStats(false);
  }, [actions, selectedAction]);

  return (
    <article
      id="suggested-actions-view"
      className={className}
    >
      <header>
        <IconButton
          id="back-button"
          variant='ghost'
          icon={<ChevronLeftIcon />}
          onClick={() => onClose()}
        />
        <section id="title">
          <h3>{ title ? title : "Add Action to your Net Zero Plan"}</h3>
          <p>Select the Action you would like to add to your plan</p>
        </section>
        <ReductionStats
          variant="inline"
          refresh={refreshReductionStats}
          companyId={companyId}
        />
      </header>
      <main>
        {
          isLoading ? (
            <Spinner />
          ) : (
            <>
              <Carousel selected={actions ? actions.findIndex(a => a.id === selectedAction?.id) : -1}
                onSelected={(idx) => { actions && handleClickAction(actions[idx]) }}>
                {
                  actions?.map((action, index) => {
                    return <ActionCard
                      key={index}
                      action={action}
                      onClick={() => handleClickAction(action)}
                      className={`suggested-action suggested-action--${selectedAction?.id === action.id ? 'selected' : ''}`}
                    />
                  }) || []
                }
              </Carousel>
              { selectedAction && (
                <section id="selected-action">
                  <section id="selected-action-detail">
                    <h3>{ selectedAction?.quickWin && <TrophyIcon id="quick-win" title="Quick Win"/> }{selectedAction?.title}</h3>
                    <Tabs id="detail-tabs">
                      <Tab title="Detail" id="detail-tab" className="tab-container">
                        <DetailsTab data={selectedAction} />
                      </Tab>
                      <Tab title="Support" id="support-tab" className="tab-container">
                        {selectedAction?.flotillaWorldUrl ? (
                          <iframe id="document" src={selectedAction?.flotillaWorldUrl} title="document" />
                        ) : (
                          <p>At the moment we have no documentation on this item. Please feel free to use our Assistant in the bottom right corner for more information.</p>
                        )}
                      </Tab>
                    </Tabs>
                  </section>
                  <NetZeroPlanActionUpdater
                    className="action-updater"
                    action={selectedAction}
                    onSubmit={handleActionSubmit}
                  />
                </section>
              )}
            </>
          )
        }
      </main>
    </article>
  );
};

export default SuggestedActions;
