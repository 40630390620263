import { Placement } from "react-joyride";

const buttonBase = {
  fontSize: '1rem'
};

export const TOUR_STYLES = {
  tooltip: {
    fontSize: '1rem'
  },
  buttonNext: {
    ...buttonBase
  },
  buttonBack: {
    ...buttonBase
  },
  buttonSkip: {
    ...buttonBase
  },
  options: {
    primaryColor: '#E35924',
    textColor: '#011E26',
    zIndex: 999999,
  }
}

export const SKIPPED_STEP = [
  {
    target: '#tour-item',
    content: <div>
      <p>You can view this platform tour again at any time by clicking here.</p>
    </div>,
    placement: 'top' as Placement,
    disableBeacon: true
  }
];

export const DATA_COLLECTION_STEPS = [
  {
    target: '#add-data-period',
    content: 'Click here to create a new data period for your next carbon footprint.',
    placement: 'left' as Placement,
    disableBeacon: true,
    data: {
      skip: 3
    }
  },
  {
    target: '#table-container > .filter-section',
    content: 'Use the filters and the search to find the data period you wish to review.',
    placement: 'bottom' as Placement,
    disableBeacon: true,
    data: {
      skip: 2
    }
  },
  {
    target: '#data-period-table > tbody',
    content: 'Review the status of your current data collection project. Click in to a specific period to enter data or review a historical submission.',
    placement: 'auto' as Placement,
    disableBeacon: true,
    data: {
      skip: 1
    }
  }
];

export const DATA_COLLECTION_TABS_STEPS = [
  {
    target: '.tab-selector',
    content: 'Click between the tabs to enter more data in each of the categories.',
    placement: 'bottom' as Placement,
    disableBeacon: true
  },
  {
    target: '#save-data-period',
    content: 'Use this button to save progress as you go along.',
    placement: 'bottom' as Placement,
    disableBeacon: true
  },
  {
    target: '#submit-data-period',
    content: 'Once you have entered all the data you wish to be used by Flotilla, click submit.',
    placement: 'bottom' as Placement,
    disableBeacon: true
  }
];

export const DATA_COLLECTION_LOCATION_STEPS = [
  {
    target: '#add-location',
    content: 'Click here to create a new location for your next carbon footprint.',
    placement: 'left' as Placement,
    disableBeacon: true,
    data: {
      skip: 3
    }
  },
  {
    target: '#table-container > .filter-section .flotilla-search-filter',
    content: 'Use the filters and the search to find the location you wish to review.',
    placement: 'bottom' as Placement,
    disableBeacon: true,
    data: {
      skip: 2
    }
  },
  {
    target: '#table-container table',
    content: 'Review the list of your locations. Click in to a specific location to view, add and update information relating to the location.',
    placement: 'top-start' as Placement,
    disableBeacon: true,
    data: {
      skip: 1
    }
  }
];

export const WELCOME_STEPS = [
  {
    target: 'body',
    content: 'Welcome to the Flotilla platform! This quick tour will guide you through the platform, highlighting the key areas to help you get started.',
    placement: 'center' as Placement,
    disableBeacon: true
  },
  {
    target: '#nav-home',
    content: <div>
      <p>This is your homepage, where you can come to get an update on your Net Zero project.</p>
      <br/>
      <p>You can check employee survey response rates, see an overview of your carbon footprint, track your Net Zero progress and view key emission stats at a glance.</p>
    </div>,
    placement: 'right' as Placement,
    disableBeacon: true
  },
  {
    target: '#nav-company-settings',
    content: 'On this page you can view and update your company details, as they change.',
    placement: 'right' as Placement,
    disableBeacon: true
  },
  {
    target: '#nav-locations',
    content: <p>
      The <strong>Locations</strong> page allows you to view, add and update information about each location you operate in. This drives what you see throughout the platform and the data asked for within <strong>Data</strong>.
    </p>,
    placement: 'right' as Placement,
    disableBeacon: true,
    data: {
      next: 'locations',
      skip: DATA_COLLECTION_LOCATION_STEPS.length + 1
    },
  },
  ...DATA_COLLECTION_LOCATION_STEPS,
  {
    target: '#nav-data',
    content: <p>
      On the <strong>Data</strong> page you can review historical data and enter data for your next carbon footprint.
    </p>,
    placement: 'right' as Placement,
    disableBeacon: true,
    data: {
      previous: 'locations',
      next: 'data',
      skip: DATA_COLLECTION_STEPS.length + 1
    },
  },
  ...DATA_COLLECTION_STEPS,
  {
    target: '#nav-surveys',
    content: <p>The <strong>Employee Surveys</strong> page contains everything you need to issue and manage your employee survey.  Find best practice guidance, an email template, active surveys links, and the ability to view response rates.</p>,
    placement: 'right' as Placement,
    disableBeacon: true,
    data: {
      previous: 'data'
    }
  },
  {
    target: '#nav-reports',
    content: <p>Within <strong>Reports & Certificates</strong> you can view and download your Carbon Reports, Progress Reports, and Certificates.  You can also upload and store project related documents.</p>,
    placement: 'right' as Placement,
    disableBeacon: true
  },
  {
    target: '#nav-analytics',
    content: <p>Within <strong>Analytics</strong> you can fully explore all your platform data.  Drill down into emissions data, assess your Net Zero Plan progress, and analyse the results of your Employee Survey.</p>,
    placement: 'right' as Placement,
    disableBeacon: true
  },
  {
    target: '#nav-netzeroplan',
    content: <p>The <strong>Net Zero Plan</strong> section contains all of the information and tools you will require to effectively manage your Net Zero project.</p>,
    placement: 'right' as Placement,
    disableBeacon: true
  },
  {
    target: '#nav-netzeroplan-dashboard',
    content: <div>
      <p>This is the <strong>Net Zero Dashboard</strong>.</p>
      <br/>
      <p>View data from your more recent Carbon Report, and your science-based trajectory to Net Zero. See your historical, current, and forecasted emissions in absolute tCO2e, and by your chosen intensity metric - FTE or £m turnover.</p>
      <br/>
      <p>The impact of actions in your Project Plan will be reflected in the forecasted emissions shown on this page.</p>
      </div>,
    placement: 'right' as Placement,
    disableBeacon: true
  },
  {
    target: '#nav-netzeroplan-project-plan',
    content: <p>Your <strong>Project Plan</strong> contains your current Net Zero actions. Click into each Action tile to view in-depth details, assign to team members, and read Support documents.  Choose the view that works best for you - Kanban board, simple list, or Gantt chart.</p>,
    placement: 'right' as Placement,
    disableBeacon: true
  },
  {
    target: '#nav-netzeroplan-emissions',
    content: <div>
      <p>On the <strong>Our Emissions</strong> page, you can see a breakdown of your emissions organised by either Scope or Impact Area.</p>
      <br/>
      <p>To find ways to reduce your impact, look for the orange plus button next to each emission activity. Clicking this button will show you actions your business can take.</p>
    </div>,
    placement: 'right' as Placement,
    disableBeacon: true
  },
  {
    target: '#nav-netzeroplan-action-library',
    content: <div>The <strong>Action Library</strong> is our database of actions which you could add into your Project Plan. Use the filter and sort functions to discover the actions which are the best fit for you and your business.</div>,
    placement: 'right' as Placement,
    disableBeacon: true
  },
  {
    target: '.fab-container',
    content: <div>
      <p>Say hello to our Virtual Consultant!  Access downloadable content curated by our in-house experts, along with insights from trusted external sources.</p>
      <br/>
      <p>Feel free to ask our Net Zero guide any questions that arise on your path to Net Zero.</p>
    </div>,
    placement: 'top' as Placement,
    disableBeacon: true
  },
  {
    target: '#tour-item',
    content: <div>
      <p>You can view this platform tour again at any time by clicking here.</p>
    </div>,
    placement: 'top' as Placement,
    disableBeacon: true
  },
  {
    target: 'body',
    content: <div>
      <p>Need any more help?</p>
      <br/>
      <p>Email us at:</p>
      <a href="mailto:getintouch@flotillaworld.com"><strong>getintouch@flotillaworld.com</strong></a>
    </div>,
    placement: 'center' as Placement,
    disableBeacon: true
  },
];

export const POST_ONBOARDING_STEPS = [
  {
    target: 'body',
    content: (
      <section>
        <h4>Welcome to Flotilla</h4>
        <h1>👋</h1>
        <p>We just wanted to say Welcome to Flotilla from all of us here, and point out a couple of handy tips and tricks.</p>
      </section>
    ),
    placement: 'center' as Placement,
    disableBeacon: true,
  },
  {
    target: '.flotilla-fab',
    content: (
      <section>
        <h4>Heres our Magic Wand</h4>
        <h1>🪄</h1>
        <p>Heres our magic wand, use this to help understand what you need to do next by providing a constant place to quickly see your task list. If theres nothing here then just explore but for now theres plenty to be getting on with.</p>
      </section>
    ),
    placement: 'top-start' as Placement,
    disableBeacon: true
  },
  {
    target: '#tour-item',
    content: (
      <section>
        <h4>Heres our platform guide</h4>
        <h1>📖</h1>
        <p>Our platform guide here can be clicked at any point to help you when you aren't sure of what things are for. There are also specific guides on pages with this same icon, don't be afraid to use these over and over again until you are a Flotilla Wizard!</p>
      </section>
    ),
    placement: 'bottom' as Placement,
    disableBeacon: true,
  },
  {
    target: '#company-home-page #tasks',
    content: (
      <section>
        <h4>Heres are your tasks</h4>
        <h1>✅</h1>
        <p>Our task list is here on your dashboard page and within the magic wand, it lists the tasks that you need to complete to get your GHG Report. We recommend doing them in this order.</p>
      </section>
    ),
    placement: 'bottom' as Placement,
    disableBeacon: true,
  },
];
