import { Activity } from "./Activity";
import { Measure } from "./Measure";

export interface ConversionFactor {
  id: number;
  activityId: number;
  measureId: number;
  minValue?: number
  maxValue?: number;
  isDaily: boolean;
  dateFrom: string;
  dateTo: string;
  value: number;
  measure?: Measure;
  activity?: Activity;
  year?: number;
  activityName?: string;
  measureName?: string;
  [key: string]: any;
}

export type ConversionFactors = ConversionFactor[];

export const mapConversionFactors = (data: {[key: string]: unknown}[]): ConversionFactors => {
  return data.map((item:  {[key: string]: unknown | unknown[] | any}) => {
    const dateFrom = new Date(item.dateFrom);
    const dateTo = new Date(item.dateTo);

    return {
      ...item,
      activityName: item.activity.name,
      measureName: item.measure.name,
      year: `${dateFrom.getFullYear() || dateTo.getFullYear()}`
    }
  }) as unknown[] as ConversionFactors;
}