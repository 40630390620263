import { useCallback, useState } from "react";
import { useAlert } from "@flotilla/component-library";

import { useAppDispatch } from "../../helpers/hooks";
import { User } from "../../types/User";
import { getUser, removeInvite as removeInviteAPI, resendInvite as resendInviteAPI, updateUser } from "../../api/User";
import { useCompanyId } from "../../context";

const useUser = (data: User) => {
  const { addAlert } = useAlert();
  const dispatch = useAppDispatch();
  const companyId = useCompanyId();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User>(data);
  const {
    id,
    inviteId
  } = user;

  const fetchApi = useCallback(() => {
    setLoading(true);
    getUser(id, companyId)
      .then(setUser)
      .catch((error) => {
        addAlert({
          id: "Get User Error",
          type: 'error',
          title: 'Error getting User',
          content: 'We are sorry there seems to be an issue getting the User. Please try again.',
        });
        console.log("Error while getting User: ", error);
      })
      .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const refreshUser = () => {
    fetchApi();
  }

  const removeInvite = async () => {
    if (inviteId) {
      setLoading(true);
      await removeInviteAPI(inviteId, companyId)
        .then(() => {
          addAlert({
            id: "Revoke invite success",
            type: 'success',
            title: 'Successfully revoked invite',
          });
        })
        .catch((error) => {
          addAlert({
            id: "Revoke invite error",
            type: 'error',
            title: 'Error revoking invite',
            content: 'We are sorry there seems to be an issue revoking the invite. Please try again.',
          });
          console.log("Error while revoking invite: ", error);
        })
        .finally(() => setLoading(false));
    }
  }

  const resendInvite = async () => {
    if (inviteId) {
      await resendInviteAPI(inviteId, companyId)
        .then(() => {
          addAlert({
            id: "Resend invite success",
            type: 'success',
            title: 'Successfully resent invite',
          });
        })
        .catch((error) => {
          addAlert({
            id: "Resend invite error",
            type: 'error',
            title: 'Error resending invite',
            content: 'We are sorry there seems to be an issue resending the invite. Please try again.',
          });
          console.log("Error while resending invite: ", error);
        });
    }
  }

  const disableUser = async (): Promise<void> => {
    if (id) {
      return await updateUser(
        user.companyId,
        {
          ...user,
          roles: undefined,
          status: 'Disabled',
        }
      )
        .then(() => {
          addAlert({
            id: "Disabled user success",
            type: 'success',
            title: 'Successfully disabled user',
          });
          setUser({
            ...user,
            roles: undefined,
            status: 'Disabled',
          });
        })
        .catch((error) => {
          addAlert({
            id: "Disabled user error",
            type: 'error',
            title: 'Error disabling user',
            content: 'We are sorry there seems to be an issue disabling the user. Please try again.',
          });
          console.log("Error while disabling user: ", error);
        });
    }
  }

  const enableUser = async (): Promise<void> => {
    if (id) {
      return await updateUser(
        user.companyId,
        {
          ...user,
          status: 'Active',
        }
      )
        .then(() => {
          setUser({
            ...user,
            roles: undefined,
            status: 'Active',
          });
          addAlert({
            id: "Enable user success",
            type: 'success',
            title: 'Successfully enabled user',
          });
        })
        .catch((error) => {
          addAlert({
            id: "Enable user error",
            type: 'error',
            title: 'Error enabling user',
            content: 'We are sorry there seems to be an issue enabling the user. Please try again.',
          });
          console.log("Error while enabling user: ", error);
        });
    }
  }

  return {
    user,
    refreshUser,
    disableUser,
    enableUser,
    removeInvite,
    resendInvite,
    loading
  };
}

export default useUser;
