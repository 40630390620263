import './styles.scss';

import { StyleSheet } from '@react-pdf/renderer';

import { Data } from '../helpers';
import { BarChart, BartCO2eDifference } from '../../../BarChart';
import {
  ReportDiv,
  ReportH4,
  ReportP,
  ReportChart
} from '../../../../../../../../components/PDFReport';

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  circleStat: {
    padding: 7,
    border: '6px solid #0b8577',
    backgroundColor: '#fbfaf8',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    width: 95,
    height: 95,
    textAlign: 'center',
    lineHeight: 0.9,
  },
});

type HowDidYouTravelToWorkProps = {
  className?: string;
  data: Data;
  carCommutingEmissions?: number;
  downloadVersion?: boolean;
};

const travelTypeOrder: { [key: string]: number } = {
  'By car': 1,
  'By public transport': 2,
  'By bike': 3,
  'On foot': 4,
};

const getColor = () => {
  return '#008037';
};

export const HowDidYouTravelToWork: React.FC<HowDidYouTravelToWorkProps> = ({
  className = '',
  data,
  carCommutingEmissions,
  downloadVersion = false,
}) => {
  const chartData =
    data.data.map((obj) => ({
      key: obj.xLabel,
      [obj.xLabel]: obj.value,
      tCO2eTotal: obj.value,
    })) ?? [];

  chartData.sort((a, b) => travelTypeOrder[a.key] - travelTypeOrder[b.key]);

  return (
    <ReportDiv
      id="how-did-you-travel-to-work"
      className={className}
      style={styles.container}
      downloadVersion={downloadVersion}
    >
      <ReportH4 className="section-header" downloadVersion={downloadVersion}>
        How do you travel to the office?
      </ReportH4>
      <ReportChart downloadVersion={downloadVersion}>
        <BarChart
          width={downloadVersion ? 350 : 520}
          height={downloadVersion ? 300 : 450}
          fontSize={downloadVersion ? 8 : 16}
          data={chartData}
          getColor={getColor}
          axisLeft={{
            tickSize: 0,
            tickPadding: 10,
            tickValues: 5,
            format: (value) => `${value}%`,
          }}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
          }}
          enableLabel={false}
          enableGridY
          margin={{ top: 50, right: 0, bottom: 50, left: 40 }}
          barComponent={(props) => (
            <BartCO2eDifference
              {...props}
              hideValues
              downloadVersion={downloadVersion}
            />
          )}
        />
      </ReportChart>
      {carCommutingEmissions && (
        <ReportDiv
          id="car-emissions"
          className="circle-stat"
          style={styles.circleStat}
          downloadVersion={downloadVersion}
        >
          <ReportP downloadVersion={downloadVersion}>
            {carCommutingEmissions}% of commuting emissions were from car travel
          </ReportP>
        </ReportDiv>
      )}
    </ReportDiv>
  );
};
