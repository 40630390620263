import './styles.scss';

import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Card, CircularProgress, IconButton, PadlockIcon, Skeleton, Tooltip, useAlert } from '@flotilla/component-library';

import { useCompanyId, useUserAccess } from '../../../context';
import { ReactComponent as ChevronLeftIcon } from '../../../assets/icons/ChevronLeft.svg';
import { getFullSurveys } from '../../../api/surveys';
import { Survey } from '../../../types/Survey';
import { LOCKED_SURVEY_TOOLTIP } from '../../../assets/content/Tooltips';
import { useCompany } from '../../../hooks';
import { numberLocaleFormatter } from '../../../helpers/numberFormatter';

interface SurveySummaryProps {
  className?: string;
  variant?: "default" | "card"
};

const SurveySummary: React.FC<SurveySummaryProps> = ({
  className = "",
  variant = "default"
}) => {
  const { addAlert } = useAlert();
  const { userAccess } = useUserAccess();
  const { ADMIN, STANDARD, EDIT } = userAccess;
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const [company] = useCompany(companyId);
  const [isLoading, setIsLoading] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [survey, setSurvey] = useState<Survey>();

  useEffect(() => {
    setIsLoading(true);
    company && getFullSurveys(company.id, company.identityCompanyId)
      .then((res) => {
        res.sort((a, b) => a.toDate.localeCompare(b.toDate));
        setSurvey(res.find(s => s.active) || res[res.length - 1]);
        setIsLocked(res.length === 0);
      })
      .catch((error) => {
        addAlert({
          id: `Getting Survey Summary Data ${company.id}`,
          type: 'error',
          title: 'Error Getting Survey Summary Data',
          content: 'Something went wrong, please try again by refreshing the page.',
        });
        console.log('Error while getting Survey Summary Data: ', error);
      })
      .finally(() => setIsLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const handleTitleClick = () => {
    if (!isLocked && EDIT) {
      if (!STANDARD || ADMIN) {
        navigate(`/${companyId || 0}/surveys`);
      } else {
        navigate(`/surveys`);
      }
    }
  }

  return (
    <Card
      id="survey-summary-widget"
      className={`variant--${variant} ${isLocked ? 'survey-summary-widget--locked' : ''} ${className}`}
    >
      { variant === "card" ? (
        <main>
          <header onClick={handleTitleClick}>
            <h3>Employee Survey</h3>
            { isLocked ? (
              <Tooltip icon={PadlockIcon} tooltipText={LOCKED_SURVEY_TOOLTIP} />
            ) : (
              EDIT ? <IconButton
                icon={<ChevronLeftIcon style={{transform: 'rotate(180deg)'}} />}
                variant='ghost'
                small
              /> : <></>
            )}
            {survey?.active && <p className="type success">Active</p>}
          </header>
          <article id="employees-stat" className="stat">
            <h4>{numberLocaleFormatter(survey?.sentTo || 0)}</h4>
            <p>Employees</p>
          </article>
          <article id="completed-stat" className="stat">
            <h4>{numberLocaleFormatter(survey?.responses || 0)}</h4>
            <p>Completed</p>
          </article>
        </main>
      ) : (
        <h3>Employee Survey</h3>
      )}
      { !isLoading ? (
        <section id="response-rate">
          <CircularProgress
            score={survey?.responseRate || 0}
            centerValue={`${numberLocaleFormatter(Math.round(survey?.responseRate || 0))}%`}
          />
        </section>
      ) : (
        <Skeleton circle className="progress--loading" />
      )}
    </Card>
  );
}

export default SurveySummary;
