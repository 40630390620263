import './styles.scss';

import React, { useCallback, useEffect } from 'react';
import { Button, Modal } from '@flotilla/component-library';
import { Blocker, useBlocker } from 'react-router-dom';

interface SaveChangesModalProps {
  className?: string;
  condition: boolean;
  onSave: (navigateBlocker: Blocker) => void;
};

const SaveChangesModal: React.FC<SaveChangesModalProps> = ({
  className = "",
  condition,
  onSave = () => {},
}) => {
  let navigateBlocker = useBlocker(({ currentLocation, nextLocation }) => (
    (condition) &&
    currentLocation.pathname !== nextLocation.pathname
  ));

  const handleOnClickSave = () => {
    onSave(navigateBlocker);
  }

  const handleBeforeUnload = useCallback((event: BeforeUnloadEvent) => {
    if (condition) {
      event.preventDefault();
      event.returnValue = 'You have unsaved changes, are you sure you want to leave the page?';
      return event.returnValue;
    }
  }, [condition]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  if (navigateBlocker.state !== "blocked") {
    return <></>;
  }

  return (
    <Modal
      id="save-changes-modal"
      className={className}
      onClose={navigateBlocker.reset}
    >
      <h4>You have unsaved changes, are you sure you want to leave the page?</h4>

      <section className="button-container">
        <Button variant='ghost' onClick={navigateBlocker.reset}>Cancel</Button>
        <Button variant='ghost' onClick={navigateBlocker.proceed}>Leave, discard changes</Button>
        <Button onClick={handleOnClickSave}>Save, and leave</Button>
      </section>
    </Modal>
  );
}

export default SaveChangesModal;
