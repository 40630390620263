import { Filters } from "@flotilla/component-library";

import { useAppDispatch, useAppSelector } from "../../helpers/hooks";
import { addPageCache, getPageCache } from "../../reducers/pageCache";

const useCachedFilters = (storeKey: string) => {
    const dispatch = useAppDispatch();
    const filterData = useAppSelector(getPageCache(storeKey));

    const onFiltersChange = (filters?: Filters, quickFilters?: Filters) => {
        dispatch(addPageCache({
            storeKey: storeKey,
            data: { filters: filters, quickFilters: quickFilters }
        }))
    }

    return {
        onFiltersChange,
        filterData
    }
}

export default useCachedFilters;