export const numberLocaleFormatter = (value: number = 0, fractionalDigits: number = 0) => {
  return value.toLocaleString(undefined, { 
    minimumFractionDigits: fractionalDigits,
    maximumFractionDigits: fractionalDigits
  });
}

const numberFormatter = (value: number = 0) => {
  if (value < 1) {
    return Math.round(value * 100) / 100;
  } else if (value < 10) {
    return Math.round(value * 10) / 10;
  }
  return Math.round(value);
}

export default numberFormatter;