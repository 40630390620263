import './styles.scss';

import { useState } from 'react';
import {
  Button,
  Modal,
  TextInput,
  useAlert,
} from '@flotilla/component-library';

import { updateReportPageImage } from '../../../../api/Report';
import { useCompanyId } from '../../../../context';

interface EditImagePopUpProps {
  onClose?: () => void;
  onChange?: () => void;
  detailId: number;
}

export const EditImagePopUp: React.FC<EditImagePopUpProps> = ({
  onClose = () => ({}),
  onChange = () => ({}),
  detailId
}) => {
  const { addAlert } = useAlert();
  const companyId = useCompanyId();
  const [file, setFile] = useState<File>();
  const [inProgress, setInProgress] = useState(false);

  const handlePageImageChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.currentTarget.files) {
      setFile(event.currentTarget.files[0]);
    }
  };

  const handleAddImageClick = () => {
    document.getElementById("edit-image-input")?.click();
  }

  const handleSaveClick = async () => {
    if(!inProgress) {
      setInProgress(true);
      await updateReportPageImage(companyId, detailId, file)
      .then(() => {
        onChange();
      })
      .catch((error) => {
        addAlert({
          id: `Updating Page Image ${companyId}`,
          type: 'error',
          title: 'Error Updating Page Image',
          content: 'Something went wrong when updating the image.'
        });
        console.log('Update Page Image Error: ', error);
      })
      .finally(() => setInProgress(false));
    }
  };

  return (
    <Modal
      id="edit-image-pop-up"
      onClose={onClose}
    >
      <h4>Replace Image</h4>
      <>
        <Button
          variant="outline"
          onClick={handleAddImageClick}
        >
          {
            file ? (
              `Select Image (${file.name})`
            ) : (
              "Select Image"
            )
          }
        </Button>
        <TextInput
          id="edit-image-input"
          onChange={handlePageImageChange}
          type="file"
        />
      </>
      <section id="actions">
        <Button variant='ghost' onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!file}
          onClick={handleSaveClick}
        >
          Save
        </Button>
      </section>
    </Modal>
  );
};
