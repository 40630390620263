import { useCallback, useEffect, useState } from "react";

import { getProducts } from "../../api/Lookup";
import { Lookups } from "../../types/Lookup";

const useProductTypes = () => {
  const [loading, setLoading] = useState(true);
  const [productTypes, setProductTypes] = useState<Lookups>();

  const fetchApi = useCallback(async () => {
    setLoading(true);
    getProducts()
      .then(setProductTypes)
      .finally(() => setLoading(false));;
  }, []);

  const refresh = () => {
    fetchApi();
  }

  useEffect(() => {
    fetchApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    productTypes,
    refresh,
    loading
  };
}

export default useProductTypes;
