import { DataIntegration } from '../../../../types/DataIntegrations';
import './styles.scss';
import { Button, Modal } from "@flotilla/component-library";

interface IntegrationSuccessModalProps {
    onClose: () => void;
    integration: DataIntegration;
  };

const IntegrationSuccessModal: React.FC<IntegrationSuccessModalProps> = ({
    onClose = () => {},
    integration
  }) => {
    return (
        <Modal
          id="confirm-navigation-modal"
          onClose={onClose}
        >
            <h2>Connection successful!</h2>
            <main>
                <p>You have successfully connected {integration.name ?? 'your accounts package'} to the platform.</p>
                <section>
                  <p>Your data is currently syncing. Please check back shortly to link your account nominals to Flotilla categories.</p>
                  <p>This can be done by clicking the three dots next to your new integration and selecting the 'Mappings' option after syncing is complete.</p>
                </section>
            </main>
            <section className="button-container">
            <Button onClick={onClose}>OK</Button>
            </section>
        </Modal>);
}

export default IntegrationSuccessModal;