import './styles.scss';

import { FC } from 'react';
import { Card, CardProps } from '@flotilla/component-library';

import { CompanyTasksView } from '../../View';

export interface CompanyTasksWidgetProps extends CardProps {};

const CompanyTasksWidget: FC<CompanyTasksWidgetProps> = ({ className = '', ...props }) => {
  return (
    <Card className={`company-tasks-widget ${className}`} {...props}>
      <CompanyTasksView />
    </Card>
  );
};

export default CompanyTasksWidget;
