import './styles.scss';

import React from 'react';
import { Tag, TagProps } from '@flotilla/component-library';

interface ScopeTagProps extends Omit<TagProps, 'children'> {
  children?: string
};

const ActiveTag: React.FC<ScopeTagProps> = ({className, children = "", ...props}) => (
  <Tag {...props} className={`active-status-tag active-status-tag--${children.toLowerCase()}`}>
    {children}
  </Tag>
);

export default ActiveTag;
