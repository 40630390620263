import './styles.scss';

import { FC, useState } from 'react';

import { SectionProps } from '../../../../../../types/CreateWizard';
import DayMonthInput from '../../../../../../components/DayMonthInput';
import ActivityTooltip from '../../../../../../pages/Company/Data/Detail/Tabs/DataPeriod/Activity/ActivityTooltip';
import { SETTINGS_ENVIRONMENTAL_YEAR_END_TOOLTIP } from '../../../../Tooltips';

const EnvironmentalYearEndSection: FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const [environmentalReportingPeriod, setEnvironmentalReportingPeriod] = useState<string>(values?.["environmentalReportingPeriod"]);

  const handleOnChange = (value: string | undefined) => {
    const date = value || '';
    setEnvironmentalReportingPeriod(date);
    onChange({ environmentalReportingPeriod: date });
  }

  return (
    <section id="environmental-year-end-section" className={className}>
      <DayMonthInput
        id="year-end"
        label={{
          children: "What is your environmental reporting period end?*",
          tooltip: <ActivityTooltip tooltip={SETTINGS_ENVIRONMENTAL_YEAR_END_TOOLTIP} position='bottom' alignment='left'/>
        }}
        aria-label="What is your environmental reporting period end?"
        value={new Date(environmentalReportingPeriod)}
        onChange={handleOnChange}
      />
    </section>
  );
}

export default EnvironmentalYearEndSection;
