import './styles.scss';
import { Divider, Tag, Tooltip } from "@flotilla/component-library";
import { Activity } from "../../../../../../../../types/DataPeriod";
import { convertMarkdown } from '../../../../../../../../helpers/convertMarkdown';

interface ActivityTooltipProps {
    activity?: Activity;
    helpText?: string;
    tooltip?: string;
    position?: 'top' | 'bottom';
    alignment?: 'left' | 'center' | 'right';
};
  
const ActivityTooltip: React.FC<ActivityTooltipProps> = ({
    activity,
    helpText,
    tooltip,
    position = 'top',
    alignment = 'right'
}) => {
    let tooltipValue = tooltip ?? activity?.tooltip ?? "";
    let helpTextValue = helpText ?? activity?.helpText ?? "";
    let hasCompliances = (activity?.compliances.length ?? 0) > 0;

    if(!tooltipValue && !helpTextValue && !activity?.compliances?.length)
        return <></>;

    return <Tooltip id="activity-tooltip" position={position} alignment={alignment} title="Information tooltip" hideCloseButton>
        {tooltipValue && <>
            <label>Explanation:</label>
            <p>{convertMarkdown(tooltipValue)}</p>
        </>}
        {tooltipValue && (helpTextValue ?? hasCompliances) && <Divider />}
        {helpTextValue && <>
            <label>Where to find this information:</label>
            {helpTextValue && <p>{convertMarkdown(helpTextValue)}</p>}
        </>}
        {(tooltipValue || helpTextValue) && hasCompliances && <Divider />}
        {hasCompliances && <>
            <label>This is required to meet the following regulations:</label>
            <article id="tags-container">
                { activity?.compliances.map((item) => (
                <Tag key={item.id} className='compliance-tag'>
                    {item.name}
                </Tag>
                ))}
            </article>
        </>}
    </Tooltip>;
}

export default ActivityTooltip;