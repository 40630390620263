import { mapActions } from "./Action";
import { ImpactAreaGroupType } from "./ImpactArea";
import { Activities, mapActivities } from "./Activity";

export const mapFocusGroups = (data: {[key: string]: unknown}[], impactArea?: string): FocusGroups => {
  return data.map((item:  {[key: string]: unknown | unknown[] | any}, index) => ({
    id: index + 1000,
    ...item,
    statusCount: item.statusCounts,
    actionsTotal: item.actions?.length,
    tco2e: item.tcO2e,
    impactArea: impactArea,
    focusGroup: item.name,
    ...(item?.actions ? {actions: mapActions(item.actions as {[key: string]: unknown}[])} : {}),
    ...(item?.activities ? {children: mapActivities(item.activities as {[key: string]: unknown}[], impactArea, item.name)} : {}),
  })) as unknown[] as FocusGroups;
}

export interface FocusGroup extends Omit<ImpactAreaGroupType, 'children'> {
  impactAreaId?: number,
  impactArea?: any,
  children?: Activities
}

export type FocusGroups = FocusGroup[];