export const EMPLOYEE_SURVEY_EMAIL_BODY = `As part of our ambition to reduce our environmental impact, we have recently engaged with Flotilla, a carbon accounting and net zero partner, who will be measuring our carbon footprint and working with us to develop a carbon reduction plan. For an introduction to Flotilla you can read their welcome document here: https://flotillastorage.blob.core.windows.net/general/Welcome%20to%20Flotilla.pdf
 
A large part of our business carbon footprint comes from us, the employees, in how we travel to work and the business activities we undertake as we perform our roles. 'Workforce impact' is something that few organisations currently measure, and we believe capturing this is something that will set us apart from our competition as the environmental impact of businesses comes under increasing scrutiny.
 
I'm asking that you all participate by completing a short online survey about your commute to work, your home energy tariff, and your thoughts about climate change and our project to become net zero.This survey should take less than 5 minutes to complete, and all responses are completely anonymous.
 
Please click on the following link to register and complete the survey, which will close at the end of %%TODATE%%:
 
%%LINK%%
 
Thank you in advance for playing your part in our journey to net zero.`;