import './styles.scss';

import { ReactNode } from 'react';
import { Text, StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FONT_FAMILY } from '../../../styles/font';

interface ReportPProps {
  id?: string;
  className?: string;
  style?: Style;
  downloadVersion?: boolean;
  children?: string | string[] | number | ReactNode | ReactNode[];
}

const styles = StyleSheet.create({
  text: {
    fontFamily: FONT_FAMILY,
    fontSize: 10,
    color: '#011e26',
    lineHeight: 1.1
  },
});

const ReportP: React.FC<ReportPProps> = ({
  className = '',
  id,
  children,
  style = {},
  downloadVersion = false,
}) => {
  if (downloadVersion) {
    return (
      <Text id={id} style={[styles.text, style]}>
        {children}
      </Text>
    );
  }

  return (
    <p id={id} className={`report-p ${className}`}>
      {children}
    </p>
  );
};

export default ReportP;
