import { API_SURVEY } from "../constants";
import { checkAuthorised, checkErrors } from "../helpers/checkAuthorisedAPI";
import { CompanyQuestions, FullCompanyQuestion, FullCompanyQuestions } from "../types/Company";
import { EngagementCompanySurvey, Survey, SurveyTypes, Surveys, mapSurveys } from "../types/Survey";

export const getSurveys = (id: string | number): Promise<Surveys> => {
  return fetch(`${API_SURVEY}/GetCompanySurveys?companyId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(({ payload }) => payload as Surveys);
}

export const getSurveyDataCSV = async (id: number, identityCompanyId: string | number): Promise<Blob> => {
  return await fetch(`${API_SURVEY}/GetSurveyResultsAsCsv?engagementCompanySurveyId=${id}&identityCompanyId=${identityCompanyId}`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'GET',
  })
  .then((res) => res.blob());
};

export const getFullSurveys = (companyId: number | string, identityCompanyId: number | string): Promise<Surveys> => {
  return fetch(`${API_SURVEY}/GetFullCompanySurveys?companyId=${companyId}&identityCompanyId=${identityCompanyId}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(({ payload }) => mapSurveys(payload));
}

export const getEngagementCompanySurvey = (companySurveyId: number, identityCompanyId: string | number): Promise<EngagementCompanySurvey> => {
  return fetch(`${API_SURVEY}/GetEngagementCompanySurvey?companySurveyId=${companySurveyId}&identityCompanyId=${identityCompanyId}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(({ payload }) => payload as EngagementCompanySurvey);
}

export const getSurveyTypes = (): Promise<SurveyTypes> => {
  return fetch(`${API_SURVEY}/GetSurveyTypes`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(({ payload }) => payload as SurveyTypes);
}

export const createSurvey = (
  companyId: string,
  identityCompanyId: string,
  survey: Survey,
): Promise<string> => {
  const {
    fromDate,
    toDate,
    sentTo = 0,
    pUrl,
  } = survey;
  return fetch(`${API_SURVEY}/CreateCompanySurvey`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json",
    },
    method: 'POST',
    body: JSON.stringify({
      companyId,
      identityCompanyId,
      fromDate,
      toDate,
      pUrl,
      sentTo,
      surveyTypeId: 1,
      surveyTypeName: "Employee Survey",
    }),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then(({ payload }) => payload as string);
}

export const updateSurvey = (
  companyId: string,
  identityCompanyId: string,
  survey: Survey,
): Promise<string> => {  
  const {
    id: companySurveyId,
    engagementCompanySurveyId,
    pUrl,
    fromDate,
    toDate,
    sentTo = 0,
    includedQuestions
  } = survey;
  return fetch(`${API_SURVEY}/UpdateCompanySurvey`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json",
    },
    method: 'PUT',
    body: JSON.stringify({
      companySurveyId,
      engagementCompanySurveyId,
      companyId,
      identityCompanyId,
      pUrl,
      surveyTypeId: 1,
      surveyTypeName: "Employee Survey",
      fromDate,
      toDate,
      sentTo,
      companyQuestionIds: includedQuestions
    })
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => res as unknown as string);
}

export const getCompanyQuestions = (identityCompanyId: number): Promise<FullCompanyQuestions> => {
  return fetch(`${API_SURVEY}/GetCompanyQuestions?identityCompanyId=${identityCompanyId}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then(({ payload }) => payload as FullCompanyQuestions);
}

export const createCompanyQuestions = (
  identityCompanyId: number,
  companyQuestions: CompanyQuestions
): Promise<FullCompanyQuestion> => {
  const companyQuestionsFormatted = companyQuestions.map((companyQuestion) => {
    const questionSlug = companyQuestion.question.replaceAll(' ', '-');
    const answers = companyQuestion.answers.split(',');
    const answersFormatted = answers.map((answer) => {
      const answerFormatted = answer.trim();
      return {
        slug: answerFormatted.replaceAll(' ', '-'),
        answerText: answerFormatted,
      };
    });
    return {
      questionText: companyQuestion.question,
      type: 'MULTIPLE_CHOICE_SELECT',
      slug: questionSlug,
      companyQuestionAnswers: answersFormatted,
    };
  });
  return fetch(`${API_SURVEY}/CreateCompanyQuestions`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: JSON.stringify({
      identityCompanyId,
      companyQuestions: companyQuestionsFormatted,
    }),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then(({ payload }) => payload?.[0] as FullCompanyQuestion || null);
};
