import { API_NOTIFICATION } from "../constants";
import { checkAuthorised } from "../helpers/checkAuthorisedAPI";
import { Notifications } from "../types/Notification";

export const getAllNotifications = (): Promise<Notifications> => {
  return fetch(`${API_NOTIFICATION}/GetUserNotifications`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => {
      return res.payload as Notifications;
    });
}