import './styles.scss';

import { ReactNode } from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FONT_FAMILY } from '../../../styles/font';

interface ReportOlProps {
  id?: string;
  className?: string;
  style?: Style;
  downloadVersion?: boolean;
  children: string | string[] | number | ReactNode | ReactNode[];
}

const styles = StyleSheet.create({
  ol: {
    fontFamily: FONT_FAMILY,
    fontSize: 11,
    display: 'flex',
    flexDirection: 'column',
    gap: 12.5,
  },
});

const ReportOl: React.FC<ReportOlProps> = ({
  className = '',
  id,
  children,
  style = {},
  downloadVersion = false,
}) => {
  if (downloadVersion) {
    return (
      <View id={id} style={[styles.ol, style]}>
        {children}
      </View>
    );
  }

  return (
    <ol id={id} className={`report-ol ${className}`}>
      {children}
    </ol>
  );
};

export default ReportOl;
