import { useCallback, useEffect, useState } from "react";
import { useAlert } from "@flotilla/component-library";

import { useAppDispatch } from "../../helpers/hooks";
import { UserRoles } from "../../types/User";
import { getUserRoles } from "../../api/User";

const useUserRoles = () => {
  const { addAlert } = useAlert();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [userRoles, setUserRoles] = useState<UserRoles>();

  const fetchApi = useCallback(() => {
    setLoading(true);
    getUserRoles()
      .then(setUserRoles)
      .catch((error) => {
        addAlert({
          id: "Get User Roles Error",
          type: 'error',
          title: 'Error getting User Roles',
          content: 'We are sorry there seems to be an issue getting the User Roles. Please try again.',
        });
        console.log("Error while getting Users: ", error);
      })
      .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const updateUserRoles = () => {
    fetchApi();
  }

  useEffect(() => {
    fetchApi();
  }, [dispatch, fetchApi]);

  return {
    userRoles,
    updateUserRoles,
    loading
  };
}

export default useUserRoles;
