import './styles.scss';

import { ReactNode } from 'react';
import { Text, StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FONT_FAMILY } from '../../../styles/font';

interface ReportBProps {
  id?: string;
  className?: string;
  style?: Style;
  downloadVersion?: boolean;
  children: string | string[] | number | ReactNode | ReactNode[];
}

const styles = StyleSheet.create({
  text: {
    fontFamily: FONT_FAMILY,
    fontSize: 11,
  },
});

const ReportB: React.FC<ReportBProps> = ({
  className = '',
  id,
  children,
  style = {},
  downloadVersion = false,
}) => {
  if (downloadVersion) {
    return (
      <Text id={id} style={[styles.text, style]}>
        {children}
      </Text>
    );
  }

  return (
    <b id={id} className={`report-b ${className}`}>
      {children}
    </b>
  );
};

export default ReportB;
