import { Image, StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

interface ReportImageProps {
  id?: string;
  className?: string;
  style?: Style;
  downloadVersion?: boolean;
  src: string;
  alt: string;
}

const styles = StyleSheet.create({
  image: {},
});

const ReportImage: React.FC<ReportImageProps> = ({
  src,
  className = '',
  style = {},
  downloadVersion = false,
  alt,
}) => {
  if (downloadVersion) {
    return <Image style={[styles.image, style]} src={src} />;
  }

  return <img className={className} src={src} alt={alt} />;
};

export default ReportImage;
