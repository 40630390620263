import { API_RANKING } from "../../constants";
import { checkAuthorised, checkErrors } from "../../helpers/checkAuthorisedAPI";
import { ActionRanking, ActionRankings } from "../../types/ActionRanking";

export const getRankings = (): Promise<ActionRankings> => {
  return fetch(`${API_RANKING}/GetAll`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return res.payload as ActionRankings;
    });
}

export const deleteRanking = (id: number): Promise<boolean> => {
  return fetch(`${API_RANKING}/Delete?rankingId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'DELETE',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return res.success;
    });
}

export const createRanking = (ranking: ActionRanking): Promise<number> => {
  return submitRanking(ranking, false);
}

export const updateRanking = (ranking: ActionRanking): Promise<number> => {
  return submitRanking(ranking, true);
}

const submitRanking = (ranking: ActionRanking, update: boolean): Promise<number> => {
  return fetch(`${API_RANKING}/${update ? 'Update' : 'Create'}`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: update ? 'PUT' : 'POST',
    body: JSON.stringify(update ? {
      rankingId: ranking.id,
      property: ranking.property,
      min: ranking.min,
      max: ranking.max,
      baseScore: ranking.baseScore
    } : ranking),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return res.payload as number;
    });
};