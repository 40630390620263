import {
  cloneElement,
  createElement,
  JSXElementConstructor,
  ReactElement,
} from 'react';
import ReactDom from 'react-dom/server';
import reactHtmlParser from 'react-html-parser';

interface ReportChartProps {
  width?: number;
  height?: number;
  downloadVersion?: boolean;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
}

const htmlSvgToPdfSvg = (
  children: ReactElement<any, string | JSXElementConstructor<any>>
) => {
  const svgString = ReactDom.renderToStaticMarkup(children)
    .replaceAll('px', 'pt')
    .replaceAll('transparent', '');

  const [component] = reactHtmlParser(svgString, {
    transform: convertToPdfSvg,
  });
  return component;
};

const convertToPdfSvg = (
  node: {
    type: string;
    data: any;
    props: { [x: string]: unknown; key?: any };
    attribs: { [s: string]: unknown } | ArrayLike<unknown>;
    name: string;
    parent: { name: string };
    children: any[];
  },
  index: any
) => {
  if (node.type === 'text') {
    return node.data;
  }

  node.props = { key: index };

  Object.entries(node.attribs).forEach(([key, value]) => {
    const [first, ...rest] = key.split('-');

    const newKey = [
      first,
      ...rest.map((word) => `${word[0].toUpperCase()}${word.slice(1)}`),
    ].join('');
    node.props[newKey] = value;
  });

  node.name = node.name?.toUpperCase();
  if (node.name === 'CLIPPATH') node.name = 'CLIP_PATH';

  if (node.name === 'DEFS' && node.parent.name !== 'SVG') return null;

  if (node.children) node.children = node.children.map(convertToPdfSvg);
  if (node.props.style) {
    const style = node.props.style as string;
    const styleObj: { [key: string]: string | number } = {};
    const styleSplit = style.split(';');
    styleSplit.forEach((styleItem) => {
      const styleItemSplit = styleItem.split(':');
      const key = styleItemSplit[0]
        .split('-')
        .map((word, index) => {
          if (index > 0) {
            return `${word[0].toUpperCase()}${word.slice(1)}`;
          }
          return word;
        })
        .join('');
      const value = styleItemSplit[1];
      styleObj[key] = value;
    });

    return createElement(
      node.name,
      {
        ...node.props,
        style: styleObj,
      },
      node.children
    );
  }

  return createElement(node.name, node.props, node.children);
};

const ReportChart: React.FC<ReportChartProps> = ({
  width = 100,
  height = 100,
  children,
  downloadVersion = false,
}) => {
  if (downloadVersion) {
    const component = htmlSvgToPdfSvg(children);
    const result = cloneElement(component);
    return result;
  }

  return children;
};

export default ReportChart;