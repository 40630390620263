export interface Environment {
  api: {
    customer: {
      baseUrl: string;
    };
    data: {
      baseUrl: string;
    };
    reporting: {
      baseUrl: string;
    };
    employee: {
      baseUrl: string;
    };
  },
  identity: {
    baseUrl: string;
  },
  employee: {
    baseUrl: string;
  }
}

const content = {
  DEVELOPMENT: {
    CUSTOMER_BASE_API: 'https://flotillaportalapi-dev.azurewebsites.net/api',
    DATA_BASE_API:
      'https://flotilla-datacollectionapi-dev.azurewebsites.net/api',
    REPORTING_BASE_API:
      'https://flotillareportingapi-dev.azurewebsites.net/api',
    EMPLOYEE_BASE_API: 'https://flotillaappapinew-dev.azurewebsites.net/api',
    IDENTITY_BASE_URL: 'https://flotillaidentity-dev.azurewebsites.net',
    EMPLOYEE_BASE_URL: 'https://app-dev.flotillaworld.com',
  },
  TEST: {
    CUSTOMER_BASE_API:
      'https://flotillaportalapi-test.azurewebsites.net/api',
    DATA_BASE_API:
      'https://flotilla-datacollectionapi-test.azurewebsites.net/api',
    REPORTING_BASE_API:
      'https://flotillareportingapi-test.azurewebsites.net/api',
    EMPLOYEE_BASE_API:
      'https://flotillaappapinew-test.azurewebsites.net/api',
    IDENTITY_BASE_URL: 'https://flotillaidentity-test.azurewebsites.net',
    EMPLOYEE_BASE_URL: 'https://app-test.flotillaworld.com',
  },
  STAGING: {
    CUSTOMER_BASE_API:
      'https://flotillaportalapi-staging.azurewebsites.net/api',
    DATA_BASE_API:
      'https://flotilla-datacollectionapi-staging.azurewebsites.net/api',
    REPORTING_BASE_API:
      'https://flotillareportingapi-staging.azurewebsites.net/api',
    EMPLOYEE_BASE_API:
      'https://flotillaappapinew-staging.azurewebsites.net/api',
    IDENTITY_BASE_URL: 'https://flotillaidentity-stg.azurewebsites.net',
    EMPLOYEE_BASE_URL: 'https://app-stg.flotillaworld.com',
  },
  PRODUCTION: {
    CUSTOMER_BASE_API: 'https://flotillaportalapi.azurewebsites.net/api',
    DATA_BASE_API: 'https://flotilla-datacollectionapi.azurewebsites.net/api',
    REPORTING_BASE_API: 'https://flotillareportingapi.azurewebsites.net/api',
    EMPLOYEE_BASE_API: 'https://flotillaappapinew.azurewebsites.net/api',
    IDENTITY_BASE_URL: 'https://flotillaidentity.azurewebsites.net',
    EMPLOYEE_BASE_URL: 'https://app.flotillaworld.com',
  },
};

export const environment = (): Environment => {
  const hostname = window.location.hostname;
  let envVariables = content.DEVELOPMENT;

  switch (hostname) {
    case 'localhost':
    case 'portal-dev.flotillaworld.com':
      envVariables = content.DEVELOPMENT;
      break;
    case 'portal-test.flotillaworld.com':
      envVariables = content.TEST;
      break;
    case 'portal-stg.flotillaworld.com':
      envVariables = content.STAGING;
      break;
    case 'portal.flotillaworld.com':
      envVariables = content.PRODUCTION;
      break;
    default:
      envVariables = content.DEVELOPMENT;
  }

  return {
    api: {
      customer: {
        baseUrl: envVariables.CUSTOMER_BASE_API,
      },
      data: {
        baseUrl: envVariables.DATA_BASE_API,
      },
      reporting: {
        baseUrl: envVariables.REPORTING_BASE_API,
      },
      employee: {
        baseUrl: envVariables.EMPLOYEE_BASE_API,
      },
    },
    identity: {
      baseUrl: envVariables.IDENTITY_BASE_URL,
    },
    employee: {
      baseUrl: envVariables.EMPLOYEE_BASE_URL,
    }
  };
};
