import './styles.scss';

import React, { useState } from 'react';
import { ChatIcon, IconButton, ListIcon } from '@flotilla/component-library';

import { useUserAccess } from '../../context';
import { CompanyTasksView } from '../View';

export type FABContentProps = {
  className?: string,
  isOpen?: boolean
};

const FABContent: React.FC<FABContentProps> = ({ className = '', isOpen = false }) => {
  const {userAccess: USER_ACCESS} = useUserAccess();
  const { ADMIN } = USER_ACCESS;
  const [view, setView] = useState<"chat" | "task">(ADMIN ? "chat" : "task");

  const getView = (_view: string) => {
    switch (_view) {
      case 'task':
        return <CompanyTasksView isOpen={isOpen} />;
      case 'chat':
      case 'default':
        return (
          <iframe
            id="ribbo-iframe"
            title="Ribbo Embed Widget"
            src="https://www.ribbo.ai/share/application/Va6EhnAoWtGuPKD3SA3M?padding=2"
          />
        );
      
    }
  }

  return (
    <article className={`fab-content ${className}`}>
      { getView(view) }
      <section id="view-selector">
        { !ADMIN && (
          <IconButton
            icon={<ListIcon variant={view === 'task' ? 'light' : 'dark'} />}
            variant={view === 'task' ? 'solid' : 'ghost'}
            onClick={() => setView('task')}
          />
        )}
        <IconButton
          icon={<ChatIcon variant={view === 'chat' ? 'light' : 'dark'} />}
          variant={view === 'chat' ? 'solid' : 'ghost'}
          onClick={() => setView('chat')}
        />
      </section>
    </article>
  );
};

export default FABContent;
