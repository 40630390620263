import './styles.scss';

import { Data } from '../helpers';
import {
  ReportDiv,
  ReportH4,
  ReportP,
} from '../../../../../../../../components/PDFReport';

interface HowOftenDoYouWorkFromHomeProps {
  className?: string;
  data: Data;
  downloadVersion?: boolean;
}

export const HowOftenDoYouWorkFromHome: React.FC<
  HowOftenDoYouWorkFromHomeProps
> = ({ className = '', data, downloadVersion = false }) => {
  const workingDaysHomePercentage = Math.round(
    data.data.find((dataItem) => dataItem.legend === 'TotalDaysHome')?.value ||
      0
  );
  const workingDaysOfficePercentage = Math.round(
    data.data.find((dataItem) => dataItem.legend === 'TotalDaysCommuted')
      ?.value || 0
  );

  return (
    <ReportDiv
      id="how-often-do-you-work-from-home"
      className={className}
      downloadVersion={downloadVersion}
    >
      <ReportH4 className="section-header" downloadVersion={downloadVersion}>
        How often do you work from home?
      </ReportH4>
      <ReportP className="text" downloadVersion={downloadVersion}>
        {workingDaysHomePercentage}% of working days are WFH, according to the
        employee survey.
      </ReportP>
      <ReportP className="text" downloadVersion={downloadVersion}>
        {workingDaysOfficePercentage}% are spent in the office.
      </ReportP>
    </ReportDiv>
  );
};
