import { useEffect, useState } from "react";

import { setCompanyLogo as setCompanyLogoApi } from "../../api/companies";
import useCompany from "./useCompany";

const useCompanyLogo = (id: string) => {
  const [loading, setLoading] = useState(true);
  const [companyId, setCompanyId] = useState<string>(id);
  const [company, setCompany] = useCompany(companyId);
  const [companyLogo, setCompanyLogoState] = useState<string | undefined>();

  const setCompanyLogo = async (logo: File): Promise<string> => {
    setLoading(true);
    return await setCompanyLogoApi(companyId, logo)
      .then((res) => {
        setCompanyLogoState(res);
        return res;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setCompany(companyId);
  }, [companyId, setCompany]);

  useEffect(() => {
    company?.logoUrl && setCompanyLogoState(company.logoUrl);
  }, [company]);

  return [companyLogo, setCompanyLogo, setCompanyId, loading] as [
    string,
    (logo: File) => Promise<string>,
    React.Dispatch<React.SetStateAction<string>>,
    boolean
  ];
}

export default useCompanyLogo;
