import './styles.scss';

import React from 'react';
import { Tag, TagProps } from '@flotilla/component-library';
import { statusType } from '../../types/NetZeroPlan';

interface StatusTagProps extends Omit<TagProps, "children"> {
  children?: string
};

const StatusTag: React.FC<StatusTagProps> = ({className, children = statusType[0], ...props}) => (
  <Tag {...props} className={`status-tag status-tag--${children.toLowerCase().replaceAll(' ', '-')} ${className}`}>
    {children}
  </Tag>
);

export default StatusTag;
