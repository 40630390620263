import { FC } from 'react';

import { Route, Routes } from 'react-router-dom';

import LocationList from './List';
import LocationDetail from './Detail';
import LocationCreate from './Create';
import { useCompanyId, useUserAccess } from '../../../context';

const Locations: FC = () => {
  const { userAccess } = useUserAccess();
  const { CREATE, PE } = userAccess;
  const companyId = useCompanyId();
  return (
    <Routes>
      <Route index element={<LocationList />} />
      <Route path=":id" element={<LocationDetail />} />
      { CREATE && !(PE && companyId !== "0") && (
        <Route path="create" element={<LocationCreate />} />
      )}
    </Routes>
  );
}

export default Locations;
