import { useState } from "react";
import { useAlert } from "@flotilla/component-library";

import { ConversionFactor } from "../../types/ConversionFactor";
import {
  updateConversionFactor as updateConversionFactorAPI,
  deleteConversionFactor as deleteConversionFactorAPI,
  createConversionFactor as createConversionFactorAPI
} from '../../api/ConversionFactors';

const useConversionFactor = (_data: ConversionFactor) => {
  const { addAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const [conversionFactor, setConversionFactor] = useState<ConversionFactor>(_data);

  const saveConversionFactor = async () => {
    setLoading(true);
    if (conversionFactor.id === -1) {
      return await createConversionFactorAPI(conversionFactor)
        .catch((error) => {
          addAlert({
            id: "Create Conversion Factors Error",
            type: 'error',
            title: 'Error creating Conversion Factors',
            content: 'We are sorry there seems to be an issue creating the Conversion Factor. Please try again.',
          });
          console.log("Error while creating Conversion Factors: ", error);
        })
        .finally(() => setLoading(false));
    }
    return await updateConversionFactorAPI(conversionFactor)
      .catch((error) => {
        addAlert({
          id: "Save Conversion Factors Error",
          type: 'error',
          title: 'Error saving Conversion Factors',
          content: 'We are sorry there seems to be an issue saving the Conversion Factor. Please try again.',
        });
        console.log("Error while saving Conversion Factors: ", error);
      })
      .finally(() => setLoading(false));
  };

  const deleteConversionFactor = async () => {
    setLoading(true);
    return await deleteConversionFactorAPI(conversionFactor.id)
      .catch((error) => {
        addAlert({
          id: "Delete Conversion Factor Error",
          type: 'error',
          title: 'Error deleting Conversion Factor',
          content: 'We are sorry there seems to be an issue deleting the Conversion Factor. Please try again.',
        });
        console.log("Error while getting Conversion Factors: ", error);
      })
      .finally(() => setLoading(false));
  };

  const updateConversionFactor = (_conversionFactor: Partial<ConversionFactor>) => {
    setConversionFactor({
      ...conversionFactor,
      ..._conversionFactor,
    });
  }

  return {
    conversionFactor,
    updateConversionFactor,
    saveConversionFactor,
    deleteConversionFactor,
    loading
  };
}

export default useConversionFactor;
