import { useEffect, useState } from "react";
import { Task, Tasks } from "../../types/Task";
import { createTask, deleteTask, updateTask as updateTaskAPI } from "../../api/Task";
import { useCompanyId } from "../../context";

const useTasks = (data: Tasks) => {
  const companyId = useCompanyId() as unknown as number;
  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] = useState<Tasks>(data);

  const addTask = async (task: Partial<Task>) => {
    setLoading(true);
    await createTask(task, companyId)
      .then((res) => {
        setTasks([
          ...tasks,
          res
        ])
      })
      .finally(() => setLoading(false));
  }

  const updateTask = async (task: Task) => {
    setLoading(true);
    await updateTaskAPI(task, companyId)
      .then((res) => {
        setTasks(tasks.map((item) => item.id === res.id ? res : item));
      })
      .finally(() => setLoading(false));
  }

  const removeTask = async (task: Task) => {
    setLoading(true);
    await deleteTask(task.id, companyId)
      .then(() => {
        setTasks(tasks.filter((item) => item.id !== task.id));
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    setTasks(data);
  }, [data]);

  return {
    tasks,
    addTask,
    updateTask,
    removeTask,
    loading
  };
}

export default useTasks;
