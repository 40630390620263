import './styles.scss';

import React from 'react';
import { CheckboxButtonV2 } from '@flotilla/component-library';

import { reportTypes } from '../../../../../../types/Report';
import { SectionProps } from '../../../../../../types/CreateWizard';

const ReportTypeSection: React.FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const handleTypeChange = (updatedType: number) => {
    return (checked: boolean) => {
      const value = checked ? updatedType : -1;
      onChange({ reportType: value });
    }
  }

  return (
    <section id="report-type-section" className={className}>
      <article id="options">
        {reportTypes.map((typeOption, index) => (
          <CheckboxButtonV2
            className='checkbox'
            key={typeOption}
            label={typeOption}
            checked={index === values?.["reportType"]}
            onToggle={handleTypeChange(index)}
          />
        ))}
      </article>
    </section>
  );
}

export default ReportTypeSection;
