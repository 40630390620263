import './styles.scss';

import { FC, Fragment, useEffect } from 'react';
import { Divider, TickIcon } from '@flotilla/component-library';

import useCompanyTasks from '../../../hooks/CompanyTask';
import { CompanyTask } from '../../../types/CompanyTask';
import { useNavigate } from 'react-router-dom';
import { useCompanyId, useUserAccess } from '../../../context';

export type CompanyTasksViewProps = {
  className?: string,
  isOpen?: boolean
};

const CompanyTasksView: FC<CompanyTasksViewProps> = ({ className = '', isOpen = false }) => {
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const { userAccess } = useUserAccess();
  const { ADMIN, STANDARD, EDIT } = userAccess;
  const { companyTasks, refreshCompanyTasks } = useCompanyTasks();
  let firstNotCompletedFound = false;

  useEffect(() => {
    isOpen && refreshCompanyTasks();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleOnClickItem = (task: CompanyTask) => {
    return () => {
      if(!EDIT) return;
      if (!STANDARD || ADMIN) {
        navigate(`/${companyId || 0}/${task.navLink}`);
      } else {
        navigate(`/${task.navLink}`);
      }
    }
  }

  return (
    <article className={`company-tasks-view ${className}`}>
      <header>
        <h4>Tasks</h4>
        <p>{companyTasks?.filter((item) => item.completed).length || 0} of {companyTasks?.length || 0} completed</p>
      </header>
      <main>
        { companyTasks?.map((item, index) => {
          const isFirstNotCompleted = !firstNotCompletedFound && !item.completed;

          if (isFirstNotCompleted) {
            firstNotCompletedFound = true;
          }
          return (
            <Fragment key={item.id}>
              <section
                className={`company-task-item ${item.completed ? 'company-task-item--completed' : isFirstNotCompleted ? 'company-task-item--first-not-completed' : ''}`}
                onClick={handleOnClickItem(item)}
              >
                <section className="main-info">
                  <p id="title">{item.title}</p>
                  <p id="description" title={item.description}>{item.description}</p>
                  {item.readMoreLink && (
                    <a href={item.readMoreLink} target="_blank" rel="noreferrer">See more</a>
                  )}
                </section>
                { item.completed && (
                  <span id="completed">
                    <TickIcon variant='light' />
                  </span>
                )}
              </section>
              { index < (companyTasks.length - 1) && (
                <Divider />
              )}
            </Fragment>
          )
        })}
      </main>
    </article>
  );
};

export default CompanyTasksView;
