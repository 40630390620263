import './styles.scss';

import React from 'react';

import { SectionProps } from '../../../../../../types/CreateWizard';

const FinalSection: React.FC<SectionProps> = ({
  className = "",
  values,
}) => {
  return (
    <section id="data-collection-final-section" className={className}>
      <article className="stat">
        <p>Data Period</p>
        <h3>{values?.['name']}</h3>
      </article>
      <article className="stat">
        <p>Data Period Start Date</p>
        <h3>{new Date(values?.['startDate']).toLocaleDateString()}</h3>
      </article>
      <article className="stat">
        <p>Data Period End Date</p>
        <h3>{new Date(values?.['endDate']).toLocaleDateString()}</h3>
      </article>
    </section>
  );
}

export default FinalSection;
