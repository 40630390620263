import './styles.scss';

import { useState } from 'react';
import {
  Button,
  Modal,
  TextArea,
  TextInput,
  useAlert,
} from '@flotilla/component-library';

import { updateReportPageTitles } from '../../../../api/Report';
import { useCompanyId } from '../../../../context';

interface EditHeaderPopUpProps {
  onClose?: () => void;
  onChange?: () => void;
  pageId?: number;
  title?: string;
  subtitle?: string;
}

export const EditHeaderPopUp: React.FC<EditHeaderPopUpProps> = ({
  onClose = () => ({}),
  onChange = () => ({}),
  pageId,
  title: initialTitle,
  subtitle: initialSubtitle,
}) => {
  const { addAlert } = useAlert();
  const companyId = useCompanyId();
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState(initialTitle);
  const [subtitle, setSubtitle] = useState(initialSubtitle);

  const handleSaveClick = async () => {
    if (pageId) {
      setIsLoading(true);
      await updateReportPageTitles(companyId, pageId, title, subtitle)
        .then(() => {
          setIsLoading(false);
          onChange();
          onClose();
        })
        .catch((error) => {
          addAlert({
            id: `Updating Report Page Title Comment ${companyId}`,
            type: 'error',
            title: 'Error Updating Report Page Title Comment',
            content: 'There seems to be an error when setting your report page title, please try again.',
          });
          console.log('Update Report Title Error: ', error);
        });
    }
  };

  return (
    <Modal
      id="edit-header-pop-up"
      onClose={onClose}
    >
      <h4>Edit the Title</h4>
      <TextInput
        id='title'
        label='Title'
        defaultValue={title}
        onChange={(event) => setTitle(event.currentTarget.value)}
      />
      <TextArea
        id="subtitle"
        label='Subtitle'
        placeholder='Enter a subtitle for this page...'
        defaultValue={subtitle}
        onChange={(event) => setSubtitle(event.currentTarget.value)}
      />
      <section id="actions">
        <Button variant='ghost' isLoading={isLoading} onClick={onClose}>
          Cancel
        </Button>
        <Button
          isLoading={isLoading}
          disabled={title === initialTitle && subtitle === initialSubtitle}
          onClick={handleSaveClick}
        >
          Save
        </Button>
      </section>
    </Modal>
  );
};
