import './styles.scss';

import { Data } from '../helpers';
import { BarChart, BartCO2eDifference } from '../../../BarChart';
import {
  ReportDiv,
  ReportH4,
  ReportChart,
} from '../../../../../../../../components/PDFReport';

type HowBigIsYourCarProps = {
  className?: string;
  data: Data;
  downloadVersion?: boolean;
};

const getColor = () => {
  return '#14AF7D';
};

export const HowBigIsYourCar: React.FC<HowBigIsYourCarProps> = ({
  className = '',
  data,
  downloadVersion = false,
}) => {
  const chartData =
    data.data.map((obj) => ({
      key: obj.xLabel,
      [obj.xLabel]: obj.value,
    })) ?? [];

  return (
    <ReportDiv
      id="how-big-is-your-car"
      className={className}
      downloadVersion={downloadVersion}
    >
      <ReportH4 className="section-header" downloadVersion={downloadVersion}>
        How big is your car?
      </ReportH4>
      <ReportChart downloadVersion={downloadVersion}>
        <BarChart
          width={downloadVersion ? 350 : 450}
          height={downloadVersion ? 120 : 180}
          fontSize={downloadVersion ? 8 : 16}
          data={chartData}
          getColor={getColor}
          axisLeft={{
            tickSize: 0,
            tickPadding: 10,
            tickValues: 5,
            format: (value) => `${value}%`,
          }}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
          }}
          enableLabel={false}
          enableGridY
          margin={{ top: 20, right: 0, bottom: 30, left: 40 }}
          barComponent={(props) => (
            <BartCO2eDifference
              {...props}
              hideValues
              downloadVersion={downloadVersion}
            />
          )}
        />
      </ReportChart>
    </ReportDiv>
  );
};
