import './styles.scss';

import React from 'react';

import { SectionProps } from '../../../../../../types/CreateWizard';

const FinalSection: React.FC<SectionProps> = ({
  className = "",
  values,
}) => {
  return (
    <section id="location-final-section" className={className}>
      <article className="stat">
        <p>Location Name</p>
        <h3>{values?.['name']}</h3>
      </article>
      <article className="stat">
        <p>Location Type</p>
        <h3>{values?.['type']}</h3>
      </article>
      {values?.['type'] !== 'Non-physical' && <article className="stat">
        <p>Location Address</p>
        <h3>{values?.['address']?.firstLine}</h3>
        <h3>{values?.['address']?.secondLine}</h3>
        <h3>{values?.['address']?.thirdLine}</h3>
        <h3>{values?.['address']?.postcode}</h3>
        <h3>{values?.['address']?.country}</h3>
      </article>}
      <article className="stat">
        <p>Location Start Date</p>
        <h3>{new Date(values?.['startDate']).toLocaleDateString()}</h3>
      </article>
    </section>
  );
}

export default FinalSection;
