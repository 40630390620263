import './styles.scss';

import React, { ChangeEventHandler } from 'react';
import { TextInput } from '@flotilla/component-library';

import { SectionProps } from '../../../../../../types/CreateWizard';

const SurveyDatesSection: React.FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const handleOnChangeDate = (key: string): ChangeEventHandler<HTMLInputElement> => {
    return (event) => {
      const value = event.currentTarget.value;
      onChange({ [key]: value });
    }
  }

  return (
    <section id="survey-dates-section" className={className}>
    <TextInput
      id="survey-start-date"
      label="Survey Start Date"
      aria-label='Survey start date'
      value={values?.["fromDate"] || new Date().toISOString().substring(0, 10)}
      type='date'
      onChange={handleOnChangeDate('fromDate')}
    />
    <TextInput
      id="survey-end-date"
      label="Survey End Date"
      aria-label='Survey end date'
      value={values?.["toDate"] || new Date().toISOString().substring(0, 10)}
      type='date'
      onChange={handleOnChangeDate('toDate')}
      min={values?.["fromDate"]}
    />
    </section>
  );
}

export default SurveyDatesSection;
