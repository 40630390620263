export const mapLookups = (data: {[key: string]: unknown}[]): Lookups => {
  return data.map((item:  {[key: string]: unknown | unknown[]}) => ({
    id: item.id,
    label: item.value || item.name,
    ...(item?.sublabels ? {sublabels: mapLookups(item.sublabels as {[key: string]: unknown}[])} : {}),
  })) as unknown[] as Lookups;
}

export const mapSDGLookups = (data: {[key: string]: unknown}[]): Lookups => {
  return data.map((item:  {[key: string]: unknown | unknown[]}) => ({
    id: item.id,
    label: item.title,
    imgURL: item.logoUrl,
    ...(item?.sustainableDevelopmentTargets ? {sublabels: mapSDTLookups(item.sustainableDevelopmentTargets as {[key: string]: unknown}[])} : {}),
    ...item,
  })) as unknown[] as Lookups;
}

export const mapSDTLookups = (data: {[key: string]: unknown}[]): Lookups => {
  return data.map((item:  {[key: string]: unknown | unknown[]}) => ({
    id: item.id,
    label: item.description,
    ...item,
  })) as unknown[] as Lookups;
}

export type Lookup = {
  id: number | string,
  label: string,
  sublabels?: Lookups,
  [key: string]: string | number | Lookups | unknown | undefined,
}

export type Lookups = Lookup[];
