import { combineReducers, configureStore } from "@reduxjs/toolkit";

import userReducer from './user';
import alertsReducer from './alerts';
import modalReducer from './modal';
import pageCacheReducer from './pageCache';

const reducers = combineReducers({
  user: userReducer,
  alerts: alertsReducer,
  modal: modalReducer,
  pageCache: pageCacheReducer
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['modal/addModal', 'pageCache/addPageCache'],
        ignoredPaths: ['modal.modal', 'pageCache'],
      },
    }),
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;