import { useCallback, useEffect, useState } from "react";
import { useAlert } from "@flotilla/component-library";

import { useAppDispatch } from "../../helpers/hooks";
import { getLocationMandatoryQuestions } from "../../api/Location";
import { Question, Questions, Submissions } from "../../types/Question";
import { useCompanyId } from "../../context";

const useLocationMandatoryQuestions = (_submissions?: Submissions) => {
  const { addAlert } = useAlert();
  const dispatch = useAppDispatch();
  const companyId = useCompanyId();
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<Questions>();
  const [submissions, setSubmissions] = useState<Submissions>(_submissions || []);
  const [updatedSubmissions, setUpdatedSubmissions] = useState(false);
  const [completed, setCompleted] = useState(false);

  const fetchApi = useCallback(async () => {
    setLoading(true);
    await getLocationMandatoryQuestions()
      .then((res) => {
        const _questions = res?.sort((a, b) => a.orderNumber - b.orderNumber)?.map(mapSubmissionsAndQuestions);
        setQuestions(_questions || []);
      })
      .catch(() => {
        addAlert({ id: `Get Location Mandatory Questions Failed ${companyId}`, type: "error", title: "Get Location Mandatory Questions Unsuccessful", content: "Location Questions failed to be retrieved please try again." });
      })
      .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshQuestions = () => {
    fetchApi();
  }

  const updateSubmissions = (questionId: number, updatedSubmissions: Submissions = []) => {
    setUpdatedSubmissions(true);
    setSubmissions((prev) => ([
      ...(prev?.filter((submission) => submission.questionId !== questionId) || []),
      ...updatedSubmissions.map((item) => ({ ...item, questionId}))
    ]));
  };

  const isQuestionComplete = (_question: Question): boolean => {
    let _completed = false;
    const _submissions = (submissions || []).filter((item) => item.questionId === _question.id);
    if (_submissions) {
      _submissions.forEach((_submission) => {
        const _answer = _question.locationQuestionAnswers.find((item) => item.id === _submission.locationQuestionAnswerId);
        if (_answer?.linkedLocationQuestion) {
          _completed = isQuestionComplete(_answer?.linkedLocationQuestion);
        } else {
          _completed = true;
        }
      })
    } else {
      _completed = false;
    }
    return _completed;
  }

  const isComplete = () => {
    let _completedCount = 0;
    questions?.forEach((question) => {
     if (isQuestionComplete(question)) {
      _completedCount = _completedCount + 1;
     }
    });
    setCompleted(_completedCount === (questions?.length || 1))
  }

  const mapSubmissionsAndQuestions = (_question: Question): Question => {
    const _submissions = (submissions || []).filter((item) => item.questionId === _question.id);
    if (_submissions) {
      const {
        locationQuestionAnswers
      } = _question;
      const _locationQuestionAnswers = locationQuestionAnswers.map((locationQuestionAnswer) => {
        const { 
          linkedLocationQuestion
        } = locationQuestionAnswer
        return {
          ...locationQuestionAnswer,
          locationQuestionAnswerSubmissions: _submissions.filter((item) => item.locationQuestionAnswerId === locationQuestionAnswer.id),
          linkedLocationQuestion: linkedLocationQuestion ? mapSubmissionsAndQuestions(linkedLocationQuestion) : undefined
        };
      })
      return {
        ..._question,
        locationQuestionAnswers: _locationQuestionAnswers
      }
    }
    return _question;
  }

  useEffect(() => {
    fetchApi();
  }, [dispatch, fetchApi]);

  useEffect(() => {
    isComplete();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, submissions]);

  useEffect(() => {
    const _questions = questions?.map(mapSubmissionsAndQuestions);
    setQuestions(_questions || []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissions]);

  return {
    questions,
    refreshQuestions,
    updateSubmissions,
    updatedSubmissions,
    submissions,
    loading,
    completed
  };
}

export default useLocationMandatoryQuestions;
