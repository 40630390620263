import './styles.scss';

import { ReactNode } from 'react';
import {
  Text,
  StyleSheet,
  View,
  Svg,
  Polygon,
} from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FONT_FAMILY } from '../../../styles/font';

interface ReportOlLiProps {
  id?: string;
  className?: string;
  style?: Style;
  downloadVersion?: boolean;
  number?: number;
  children: string | string[] | number | ReactNode | ReactNode[];
}

const styles = StyleSheet.create({
  olLi: {
    fontFamily: FONT_FAMILY,
    fontSize: 11,
    color: '#011e26',
    display: 'flex',
    flexDirection: 'row',
    gap: 6,
    paddingTop: 15,
  },
  marker: {
    position: 'relative',
    width: 20,
  },
  triangle: {
    position: 'absolute',
    top: -10,
    right: 0,
  },
  markerText: {
    position: 'absolute',
    top: 0,
    right: 2,
  },
});

const ReportOlLi: React.FC<ReportOlLiProps> = ({
  className = '',
  id,
  children,
  style = {},
  downloadVersion = false,
  number = 0,
}) => {
  if (downloadVersion) {
    return (
      <View id={id} style={[styles.olLi, style]}>
        <View style={styles.marker}>
          <Svg style={styles.triangle} viewBox="0 0 22 22" width={28}>
            <Polygon points="0 22, 22 0, 22 22" fill="#e35924" />
          </Svg>
          <Text style={styles.markerText}>{number}</Text>
        </View>
        <Text>{children}</Text>
      </View>
    );
  }

  return (
    <li id={id} className={`report-ol-li ${className}`}>
      {children}
    </li>
  );
};

export default ReportOlLi;
