import './styles.scss';

import { ReactNode } from 'react';
import { Text, StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import ReportB from '../ReportB';
import { FONT_FAMILY } from '../../../styles/font';

interface ReportLiProps {
  id?: string;
  className?: string;
  style?: Style;
  downloadVersion?: boolean;
  children: string | string[] | number | ReactNode | ReactNode[];
}

const styles = StyleSheet.create({
  text: {
    fontFamily: FONT_FAMILY,
    fontSize: 11,
    paddingLeft: 12.5,
    borderLeft: '4px solid #011e26',
    borderRadius: 4,
    color: '#011e26',
  },
});

const ReportLi: React.FC<ReportLiProps> = ({
  className = '',
  id,
  children,
  style = {},
  downloadVersion = false,
}) => {
  var textContent = typeof(children) === 'string' && children.startsWith("<strong>") ? children.split("</strong>").map((t: string, index) => {
      return t.startsWith("<strong>") ? <ReportB key={index} downloadVersion={downloadVersion}>{t.substring(8)}</ReportB> : t;
  }) : children;

  if (downloadVersion) {
    return (
      <Text id={id} style={[styles.text, style]}>
        {textContent}
      </Text>
    );
  }

  return (
    <li id={id} className={`report-li ${className}`}>
        {textContent}
    </li>
  );
};

export default ReportLi;