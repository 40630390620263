import { Font, Document, DocumentProps } from '@react-pdf/renderer';

Font.register({
  family: "Baskerville",
  src: "https://fonts.cdnfonts.com/s/14899/baskvl.woff",
});

Font.register({
  family: "Verdana",
  src: "https://fonts.cdnfonts.com/s/14412/VerdanaPro-Regular.woff",
});

const PDFDocument: React.FC<React.PropsWithChildren<DocumentProps>> = ({
  children,
  ...props
}) => {
  return (
    <Document {...props}>
      {children}
    </Document>
  );
};

export default PDFDocument;
