import formatMoney from "./moneyFormatter";
import { numberLocaleFormatter } from "./numberFormatter";

const formatMeasure = (value: number, measure: string = "", shortFormat: boolean = true): string => {
  let measureValue = (measure || "").toLowerCase();

  if (measureValue.includes("mile")) {
    measureValue = "miles";
  }

  switch (measureValue) {
    case '£':
      return shortFormat ? formatMoney(value, measure, true) : `£${numberLocaleFormatter(value, 2)}`;
    case 'kwh':
    case 'm2':
    case 'ltr':
    case 'tonnes':
      return `${numberLocaleFormatter(value)} ${measure}`;
    case 'tonnes/km':
      return `${numberLocaleFormatter(value)} tkm`;
    case 'miles':
    case 'mile':
      return `${numberLocaleFormatter(value)} ${value > 1 ? 'miles' : 'mile'}`;
    case 'capacity':
      return `${numberLocaleFormatter(value)} ${value > 1 ? 'desks' : 'desk'}`;
    default:
      return `${numberLocaleFormatter(value)}`;
  }
};

export default formatMeasure;
