import { COMPANY_TYPE_PARENT_IDS } from "../constants";
import { Lookup } from "../types/Lookup";

const isParentCompany = (companyType?: Lookup): boolean => {
  if (companyType) {
    return COMPANY_TYPE_PARENT_IDS.includes(Number(companyType.id));
  }

  return false;
}

export default isParentCompany;