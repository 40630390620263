import { useCallback, useEffect, useState } from "react";
import { useAlert } from "@flotilla/component-library";

import { useAppDispatch } from "../../helpers/hooks";
import { setCompany as setCompanyName } from "../../reducers/user";
import { getCompanySettings, updateCompanySettings as updateCompanySettingsAPI } from "../../api/CompanySettings";
import { CompanySettings } from "../../types/CompanySettings";
import { useCompanyId } from "../../context";
import useCompany from "./useCompany";

const useCompanySettings = () => {
  const { addAlert } = useAlert();
  const dispatch = useAppDispatch();
  const companyId = useCompanyId();
  const [company] = useCompany(companyId);
  const [loading, setLoading] = useState(true);
  const [updated, setUpdated] = useState(true);
  const [companySettings, setCompanySettings] = useState<CompanySettings>();

  const fetchApi = useCallback(async () => {
    if (company?.id) {
      setLoading(true);
      await getCompanySettings(company?.id)
        .then((res) => {
          setCompanySettings(res);
        })
        .catch(() => {
          addAlert({ id: `Company Settings Failed ${company?.id}`, type: "error", title: "Get Company Settings Failed", content: "Failed to get Company Settings please try again." });
        })
        .finally(() => setLoading(false));
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const refreshCompanySettings = () => {
    fetchApi();
  }

  const saveCompanySettings = async (): Promise<CompanySettings | void> => {
    if (companySettings) {
      setLoading(true);
      return updateCompanySettingsAPI(companySettings)
        .then(() => {
          setUpdated(false);
          addAlert({ id: `Update Company Settings Success`, type: "success", title: "Successfully updated company settings" });
          return companySettings;
        })
        .catch((error) => {
          addAlert({ id: `Update Company Settings Failed`, type: "error", title: "Failed to update company settings", content: error.message ?? "Failed to update the company settings, please try again." });
        })
        .finally(() => setLoading(false));
    }
  }

  const updateCompanySettings = (_companySettings: Partial<CompanySettings>) => {
    setCompanySettings({
      ...companySettings,
      ..._companySettings
    } as CompanySettings);
    setUpdated(true);
  }

  useEffect(() => {
    if (company?.id) {
      fetchApi();
    } else {
      dispatch(setCompanyName(""));
    }
  }, [company, dispatch, fetchApi]);

  return {
    companySettings,
    updateCompanySettings,
    saveCompanySettings,
    refreshCompanySettings,
    loading,
    updated
  };
}

export default useCompanySettings;
