import './styles.scss';

import { FC } from 'react';

import { Action } from '../../../../../../types/Action';
import ActionCard from '../../../../../../components/ActionCard';
import { useNavigate } from 'react-router-dom';

interface RelatedActionsTabProps {
  data: Action;
};

const RelatedActionsTab: FC<RelatedActionsTabProps> = ({ data }) => {
  const navigate = useNavigate();
  const {
    parentActions = [],
    childActions = []
  } = data;

  const handleOnClickAction = (action: Action) => {
    return () => {
      navigate(`../${action.id}`);
    }
  }

  return (
    <article id="related-actions-tab-content">
      { parentActions.length > 0 && (
        <section id="parent-actions" className="actions-container">
          <h4>Parent Actions</h4>
          <section id="actions">
            {parentActions.map((action) => (
              <ActionCard key={action.id} className="related-action" action={action} onClick={handleOnClickAction(action)}/>
            ))}
          </section>
        </section>
      )}
      { childActions.length > 0 && (
        <section id="child-actions" className="actions-container">
          <h4>Child Actions</h4>
          <section id="actions">
            {childActions.map((action) => (
              <ActionCard key={action.id} className="related-action" action={action} onClick={handleOnClickAction(action)} />
            ))}
          </section>
        </section>
      )}
    </article>
  )
}
export default RelatedActionsTab;
