import './styles.scss';

import React, { useEffect, useState } from 'react';

import { getDepartments } from '../../../../../../api/Lookup';
import { Lookup, Lookups } from '../../../../../../types/Lookup';
import CheckboxButton from '../../../../../../components/CheckboxButton';
import { Card } from '@flotilla/component-library';
import { Departments } from '../../../../../../types/Action';

interface DepartmentSelectorProps {
  className?: string;
  departments?: Departments;
  onChange?: (departments?: Departments) => void,
};

const DepartmentSelector: React.FC<DepartmentSelectorProps> = ({
  className = "",
  departments: initialDepartments,
  onChange = () => ({}),
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [departments, setDepartments] = useState<Departments>();
  const [departmentsLookup, setDepartmentsLookup] = useState<Lookups>();

  useEffect(() => {
    handleGetLookup();
  }, []);

  useEffect(() => {
    initialDepartments && setDepartments(initialDepartments);
  }, [initialDepartments]);

  useEffect(() => {
    onChange(departments);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departments]);

  const handleGetLookup = () => {
    setIsLoading(true);
    getDepartments()
      .then((res) => setDepartmentsLookup(res))
      .finally(() => setIsLoading(false));
  };

  const handleCheckboxChange = (item: Lookup): ((checked: boolean) => void) => {
    return (checked: boolean) => {
      if (checked) {
        setDepartments([
          ...(departments || []),
          {
            id: Number(item.id),
            name: item.label
          }
        ]);
      } else {
        const filteredDepartments = departments?.filter((department) => department.id !== Number(item.id));
        setDepartments(filteredDepartments);
      }
    }
  }

  return (
    <section id="department-selector" className={className}>
      <h4>Departments</h4>
      <article className="checkbox-container">
        { !isLoading ? ( departmentsLookup?.map((departmentLookup) => (
          <CheckboxButton
            key={departmentLookup.id}
            label={departmentLookup.label}
            onToggle={handleCheckboxChange(departmentLookup)}
            checked={(departments?.findIndex((item) => item.id === Number(departmentLookup.id)) || 0) > -1}
            className="department-checkbox"
          />
        ))) : (
          <article id="skeleton-container">
            <Card className="skeleton-checkbox"/>
            <Card className="skeleton-checkbox"/>
            <Card className="skeleton-checkbox"/>
          </article>
        )}
      </article>
    </section>
  );
}

export default DepartmentSelector;
