import './styles.scss';

import React, { MouseEventHandler } from 'react';
import { Table, type Header as HeaderType } from '@flotilla/component-library';

import TableRow from './TableRow';
import { User, Users } from '../../../../types/User';

const headers = [
  {
    key: "username",
    label: "Username",
    fixed: true
  },
  {
    key: "email",
    label: "Email",
    optional: true
  },
  {
    key: "department",
    label: "Department",
    optional: true
  },
  {
    key: "role",
    label: "Role",
    optional: true
  },
  {
    key: "status",
    label: "Status",
    optional: true
  },
  {
    key: "lastLoggedIn",
    label: "Last active",
    optional: true
  },
] as HeaderType[];

interface TableViewProps {
  className?: string;
  data?: Users;
  loading?: boolean;
  onClick?: (user: User) => void;
  onChange?: () => void;
};

const TableView: React.FC<TableViewProps> = ({
  className = "",
  data,
  onClick = () => ({}),
  onChange = () => ({}),
  loading = false,
}) => {

  const handleClickAction = (user: User): MouseEventHandler<HTMLTableRowElement> => {
    return (event) => {
      event.stopPropagation();
      onClick(user);
    }
  }

  return (
    <section className={className}>
      <Table
        id="customer-list-table"
        isLoading={loading}
        stickyHeader
        dataTable
        headers={headers}
        data={data as unknown as { [key: string]: string }[]}
        rowComponent={(item, headers) => (
          <TableRow
            key={(item as unknown as User).id}
            data={item as unknown as User}
            headers={headers || []}
            onClick={handleClickAction(item as unknown as User)}
            onChange={onChange}
          />
        )}
      />
    </section>
  );
}

export default TableView;
