import { ReactComponent as CloudHighIcon } from '../assets/icons/CloudHigh.svg';
import { ReactComponent as CloudMediumIcon } from '../assets/icons/CloudMedium.svg';
import { ReactComponent as CloudLowIcon } from '../assets/icons/CloudLow.svg';
import { ReactComponent as CloudNoneIcon } from '../assets/icons/CloudNone.svg';
import { ReactComponent as MoneyHighIcon } from '../assets/icons/MoneyHigh.svg';
import { ReactComponent as MoneyMediumIcon } from '../assets/icons/MoneyMedium.svg';
import { ReactComponent as MoneyLowIcon } from '../assets/icons/MoneyLow.svg';
import { ReactComponent as MoneyNoneIcon } from '../assets/icons/MoneyNone.svg';
import { ReactComponent as HandHighIcon } from '../assets/icons/HandHigh.svg';
import { ReactComponent as HandMediumIcon } from '../assets/icons/HandMedium.svg';
import { ReactComponent as HandLowIcon } from '../assets/icons/HandLow.svg';
import { ReactComponent as HandNoneIcon } from '../assets/icons/HandNone.svg';
import { PotentialType } from '../types/Action';

const getRatingIcon = (rating: PotentialType = "Low", type: "cloud" | "money" | "hand" = "cloud") => {
  switch (`${type.toLowerCase()}-${rating.toLowerCase()}`) {
    case "cloud-high":
      return <CloudHighIcon />;
    case "cloud-medium":
      return <CloudMediumIcon />;
    case "cloud-low":
      return <CloudLowIcon />;
    case "cloud-none":
      return <CloudNoneIcon />;
    case "money-high":
      return <MoneyHighIcon />;
    case "money-medium":
      return <MoneyMediumIcon />;
    case "money-low":
      return <MoneyLowIcon />;
    case "money-none":
      return <MoneyNoneIcon />;
    case "hand-high":
      return <HandHighIcon />;
    case "hand-medium":
      return <HandMediumIcon />;
    case "hand-low":
      return <HandLowIcon />;
    case "hand-none":
      return <HandNoneIcon />;
    default:
      return <CloudLowIcon />;
  }
}

export default getRatingIcon;
