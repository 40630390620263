import React from 'react';

import { Route, Routes } from 'react-router-dom';

import DataList from './List';
import DataDetail from './Detail';
import DataCreate from './Create';
import { useCompanyId, useUserAccess } from '../../../context';

const Data: React.FC = () => {
  const { userAccess } = useUserAccess();
  const {
    CREATE,
    PE
  } = userAccess;
  const companyId = useCompanyId();

  return (
    <Routes>
      <Route index element={<DataList />} />
      { CREATE && !(PE && companyId !== "0") && (
        <Route path="create" element={<DataCreate />} />
      )}
      <Route path=":id" element={<DataDetail />} />
    </Routes>
  );
}

export default Data;
