import './styles.scss';

import { FC, useState } from 'react';

import { SectionProps } from '../../../../../../types/CreateWizard';
import DayMonthInput from '../../../../../../components/DayMonthInput';

const FinancialYearEndSection: FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const [financialYearEnd, setFinancialYearEnd] = useState<string>(values?.["financialYearEnd"]);

  const handleOnChange = (value: string | undefined) => {
    const date = value || '';
    setFinancialYearEnd(date);
    onChange({ financialYearEnd: date });
  }

  return (
    <section id="financial-year-end-section" className={className}>
      <DayMonthInput
        id="year-end"
        label={{ children: "What is your financial year end?" }}
        aria-label="What is your financial year end?"
        value={new Date(financialYearEnd)}
        onChange={handleOnChange}
      />
    </section>
  );
}

export default FinancialYearEndSection;
