import './styles.scss';

import React, { useState } from 'react';
import { Button, Modal, Select, TextInput, useAlert } from '@flotilla/component-library';

import { uploadDocument as uploadDocumentAPI, updateDocument as updateDocumentAPI } from '../../../../../api/Report';
import { CompanyDocument, documentTypes } from '../../../../../types/Report';
import { useCompanyId } from '../../../../../context';

const InitialUploadDocument: CompanyDocument = {
  id: null,
  title: '',
  reportType: -1,
  reportTypeString: '',
  carbonReportUrl: ''
}

interface DocumentModalProps {
  className?: string;
  onClose?: () => void;
  data?: CompanyDocument;
  documentId?: string;
};

const DocumentModal: React.FC<DocumentModalProps> = ({
  className = "",
  onClose = () => ({}),
  data = InitialUploadDocument,
}) => {
  const { addAlert } = useAlert();
  const companyId = useCompanyId();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadDocument, setUploadDocument] = useState(data);

  const handleOnClose = () => {
    onClose();
  }

  const handleAddDocument = async () => {
    const {
      id,
      title,
      file
    } = uploadDocument;
    if (id) {
      setIsLoading(true);
      await updateDocumentAPI(companyId, uploadDocument)
        .then(() => {
          handleOnClose();
        })
        .catch(() => {
          addAlert({
            id: `Updating Document ${companyId}`,
            type: 'error',
            title: 'Error Updating Document',
            content: 'There seems to be an error when updating document, please try again. If the problem continues to happen, please contact us.',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (title && file) {
      setIsLoading(true);
      await uploadDocumentAPI(companyId, uploadDocument)
        .then(() => {
          handleOnClose();
        })
        .catch(() => {
          addAlert({
            id: `Uploading Document ${companyId}`,
            type: 'error',
            title: 'Error Uploading Document',
            content: 'There seems to be an error when uploading document, please try again. If the problem continues to happen, please contact us.',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  const handleUploadDocumentClick = () => {
    document.getElementById("upload-document-input")?.click();
  }

  const handleTitleChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setUploadDocument({
      ...uploadDocument,
      title: event?.currentTarget.value
    });
  };

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.currentTarget.files) {
      setUploadDocument({
        ...uploadDocument,
        file: event.currentTarget.files[0]
      });
    }
  };

  const handleTypeChange: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    setUploadDocument({
      ...uploadDocument,
      reportType: event?.currentTarget.value as unknown as number
    });
  };

  return (
    <Modal
      id="upload-document-modal"
      className={className}
      onClose={handleOnClose}
    >
      <h3>{ uploadDocument.id ? "Update" : "Add" } Document</h3>

      <main>
        <TextInput
          id="document-title"
          label="Title"
          aria-label="Title"
          value={uploadDocument.title}
          onChange={handleTitleChange}
        />
        <Select id="document-type" label="Type" onChange={handleTypeChange} value={uploadDocument.reportType}>
          <option key={-1} value={-1}></option>
          {documentTypes
            .map((item, index) => { return { item, index }})
            .sort((a, b) => (a.item === "Other" ? 1 : 0) - (b.item === "Other" ? 1 : 0) || a.item.localeCompare(b.item))
            .map(({ item, index }) => <option key={index} value={index}>{item}</option>)}
        </Select>
        <Button
          id="upload-document"
          variant="outline"
          onClick={handleUploadDocumentClick}
        >
          {uploadDocument.file ? uploadDocument.file.name : uploadDocument.carbonReportUrl ? "Update Document" : "Upload Document"}
        </Button>
        <TextInput
          id="upload-document-input"
          onChange={handleFileChange}
          type="file"
        />
      </main>

      <section className="button-container">
        <Button variant="ghost" onClick={() => onClose()} isLoading={isLoading}>
          Cancel
        </Button>
        <Button onClick={handleAddDocument} isLoading={isLoading} disabled={(uploadDocument.file === undefined && uploadDocument.id === null) || uploadDocument.title === undefined || uploadDocument.reportType < 0}>
          { uploadDocument.id ? "Update" : "Add" }
        </Button>
      </section>
    </Modal>
  );
}

export default DocumentModal;
