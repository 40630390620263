import './styles.scss';

import { Image, StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { IconButton } from '@flotilla/component-library';

import {
  ReportDiv,
  ReportH2,
  ReportP,
} from '../../../../../../components/PDFReport';
import Logo from '../../../../../../assets/images/Logo.png';
import { ReactComponent as EditIcon } from '../../../../../../assets/icons/Edit.svg';
import { useState } from 'react';
import { EditHeaderPopUp } from '../../../../../../components/PDFReport/ReportEditor/EditHeaderPopUp';
import Colors, { GreyColors } from '../../../../../../styles/colors';

interface ReportHeaderProps {
  pageId?: number;
  title?: string;
  section?: string;
  className?: string;
  subtitle?: string;
  downloadVersion?: boolean;
  editable?: boolean;
  style?: Style;
  onChange?: () => void;
  hideLogo?: boolean;
}

const styles = StyleSheet.create({
  reportHeaderContainer: {
    width: '842px',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: GreyColors[60],
    padding: '20 40',
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 10
  },
  title: {
    flex: 1,
  },
  section: {
    color: Colors.text.brand
  },
  subtitle: {
    fontSize: 12
  },
  logo: {
    position: 'absolute',
    top: 10,
    right: 0,
    width: 50,
  },
});

export const ReportHeader: React.FC<ReportHeaderProps> = ({
  pageId,
  title = '',
  section = '',
  className = '',
  subtitle = '',
  downloadVersion = false,
  editable = false,
  hideLogo = false,
  style,
  onChange = () => ({})
}) => {
  const [openEditor, setOpenEditor] = useState(false);

  return (
    <ReportDiv
      className={`report-header-container ${editable ? 'report-header-container--editable' : ''} ${className}`}
      style={{ ...styles.reportHeaderContainer, ...style }}
      downloadVersion={downloadVersion}
    >
      <ReportDiv
        className="title-container"
        style={styles.titleContainer}
        downloadVersion={downloadVersion}
      >
        <ReportH2
          id="section"
          style={styles.section}
          downloadVersion={downloadVersion}
        >
          {section}.
        </ReportH2>
        <ReportH2
          id="title"
          style={styles.title}
          downloadVersion={downloadVersion}
        >
          {title}
        </ReportH2>

        {!hideLogo && downloadVersion ? (
          <Image
            style={styles.logo}
            src={Logo}
          />
        ) : (
          <img
            className="logo"
            src={Logo}
            alt="Flotilla Logo"
            aria-label="Flotilla Logo"
          />
        )}
      </ReportDiv>
      {subtitle !== '' && (
        <ReportP id="subtitle" downloadVersion={downloadVersion} style={styles.subtitle}>
          {subtitle}
        </ReportP>
      )}
      {!downloadVersion && editable && (
        <IconButton
          id="edit-header"
          variant="solid"
          aria-label="Edit Icon"
          icon={<EditIcon stroke="#FBFAF8" />}
          onClick={() => setOpenEditor(true)}
        />
      )}
      {!downloadVersion && editable && openEditor && (
        <EditHeaderPopUp
          pageId={pageId}
          title={title}
          subtitle={subtitle}
          onClose={() => setOpenEditor(false)}
          onChange={onChange}
        />
      )}
    </ReportDiv>
  );
};
