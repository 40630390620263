const groupBy = <type>(
  list: Array<type>,
  key: keyof type
): Array<Array<type>> => {
  const groupedArray: Array<Array<type>> = [];
  list.forEach((item) => {
    const collectionIndex = groupedArray.findIndex((group) => {
      return group[0][key] === item[key];
    });

    if (collectionIndex !== -1) {
      groupedArray[collectionIndex].push(item);
    } else {
      groupedArray.push([item]);
    }
  });
  return groupedArray;
}

export default groupBy;