import './styles.scss';

import React, { Fragment, useEffect, useState } from 'react';

import { Button, Divider } from '@flotilla/component-library';

import CustomerListItem from './CustomerListItem';
import { getAllCompanies } from '../../../api/companies';
import { Companies } from '../../../types/Company';
import { addModal, removeModal } from '../../../reducers/modal';
import { useAppDispatch } from '../../../helpers/hooks';
import { CreateCustomerModal } from '../../../components/Modal';
import { useUserAccess } from '../../../context';

interface CustomerListProps {
  id?: string;
  className?: string;
};

const CustomerList: React.FC<CustomerListProps> = ({
  id = "",
  className = ""
}) => {
  const { userAccess } = useUserAccess();
  const { ADMIN, PE } = userAccess;
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState<Companies>();

  const getCompanies = () => {
    setIsLoading(true);
    getAllCompanies()
      .then((res) => {
        res && setCompanies(res.slice(0, 5));
      })
      .finally(() => setIsLoading(false));
  };

  const handleOpenModal = () => {
    dispatch(addModal({
      id: 'create-company',
      children: <CreateCustomerModal onClose={() => dispatch(removeModal())} />
    }));
  }

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <section id={id} className={`customer-list-container ${className}`}>
      <header>
        <h3>{PE ? "Portfolio" : "Customers" }</h3>
        {ADMIN && <Button onClick={handleOpenModal}>Add new</Button>}
      </header>
      <article className="customer-list">
        {
          isLoading ? (
            Array.from(Array(3))?.map((_, index) => (
              <Fragment key={index}>
                <CustomerListItem
                  isLoading
                />
                {
                  index < 2 &&
                    <Divider />
                }
              </Fragment>
            ))
          ) : (
            companies?.map((company, index) => (
              <Fragment key={index}>
                <CustomerListItem
                  id={company.id}
                  title={company.name}
                  subtitle={company.mainContactName}
                  logo={company.logoUrl}
                />
                {
                  index < (companies.length - 1) ?
                    <Divider />
                  :
                    <a href="/customers">See all</a>
                }
              </Fragment>
            ))
          )
        }
      </article>
    </section>
  );
}

export default CustomerList;
