import './styles.scss';

import React from 'react';

interface IEMAProps {
  className?: string;
};

const IEMA: React.FC<IEMAProps> = ({
  className = "",
}) => {
  return (
    <section id="iema-page" className={className}>
      <header>
        <h1>IEMA</h1>
      </header>
      <iframe
        title="Astutis IEMA Qualifications"
        src="https://flotillastorage.blob.core.windows.net/flotillaworld/Astutis IEMA Qualifications.pdf"
      />
    </section>
  );
}

export default IEMA;
