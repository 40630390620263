import { API_FACTOR_VALUE } from "../../constants";
import { checkAuthorised, checkErrors } from "../../helpers/checkAuthorisedAPI";
import { ConversionFactor, ConversionFactors, mapConversionFactors } from "../../types/ConversionFactor";

export const getConversionFactors = (): Promise<ConversionFactors> => {
  return fetch(`${API_FACTOR_VALUE}/GetAll`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapConversionFactors(res.payload);
    });
}
export const getConversionFactor = (id: number | string): Promise<ConversionFactor> => {
  return fetch(`${API_FACTOR_VALUE}/Get?factorValueId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapConversionFactors([res.payload])[0];
    });
}

export const deleteConversionFactor = (id: number): Promise<boolean> => {
  return fetch(`${API_FACTOR_VALUE}/Delete?factorValueId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'DELETE',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => res.success);
}

export const createConversionFactor = (_conversionFactor: ConversionFactor): Promise<number> => {
  return fetch(`${API_FACTOR_VALUE}/Create`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: getConversionFactorBody(_conversionFactor),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return res.payload as number;
    });
};

export const updateConversionFactor = (_conversionFactor: ConversionFactor): Promise<number> => {
  return fetch(`${API_FACTOR_VALUE}/Update`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'PUT',
    body: getConversionFactorBody(_conversionFactor),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return res.payload as number;
    });
};

const getConversionFactorBody = (_conversionFactor: ConversionFactor): string => {
  const {
    id,
    year,
    activityId,
    measureId,
    minValue = 0,
    maxValue,
    isDaily = false,
    value
  } = _conversionFactor
  return JSON.stringify({
    factorValueId: id,
    activityId,
    measureId,
    minValue,
    maxValue,
    isDaily,
    value,
    dateFrom: `${year}-01-01`,
    dateTo: `${year}-12-31`,
  })
}