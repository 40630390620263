import './styles.scss';

import { StyleSheet } from '@react-pdf/renderer';

import {
  ReportDiv,
  ReportEditor,
  ReportPage,
} from '../../../../../../components/PDFReport';
import { ReportPageProps } from '../../../../../../types/Report';

const styles = StyleSheet.create({
  commentsCommentsPage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 25,
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 25,
  },
  side: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

export const CommentsComments: React.FC<ReportPageProps> = ({ page, downloadVersion = false, className, onContentUpdate, editable }) => {
  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`comments-comments ${className}`}
      style={styles.commentsCommentsPage}
      downloadVersion={downloadVersion}
      page={page}
      showHeader
    >
      <ReportDiv
        className="content"
        style={styles.content}
        downloadVersion={downloadVersion}
      >
        {page.details.map((detail, index) => (
          <ReportDiv
            key={index}
            className="comments-container"
            style={styles.side}
            downloadVersion={downloadVersion}
          >
            <ReportEditor
              contentId={detail.id}
              editorContent={detail.comments}
              downloadVersion={downloadVersion}
              onChange={() => onContentUpdate()}
              editable={editable}
            />
          </ReportDiv>
        ))}
      </ReportDiv>
    </ReportPage>
  );
};
