import './styles.scss';
import { Button, Modal, useAlert } from "@flotilla/component-library";
import { useState } from "react";
import { useAppDispatch } from '../../../helpers/hooks';
import { removeModal } from '../../../reducers/modal';

interface ReopenDataPeriodModalProps {
    onConfirm: () => Promise<void>;
  };

const ReopenDataPeriodModal: React.FC<ReopenDataPeriodModalProps> = ({
    onConfirm,
  }) => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const handleReopen = () => {
      setIsLoading(true);
      onConfirm()
      .then(handleClose)
      .finally(() => { setIsLoading(false) });
    }

    const handleClose = () => {
      dispatch(removeModal());
    }

    return (
        <Modal
          id="reopen-data-period-modal"
          onClose={handleClose}
        >
            <h2>Reopen Data Period</h2>
            <main>
                <p>Are you sure you wish to reopen this data period?</p>
                <p>Once you reopen this data period it will use your current company/location settings and the available fields may change. Any data in no longer applicable fields will be lost.</p>
                <p>This is permanent and cannot be undone.</p>
            </main>
            <section className="button-container">
            <Button onClick={handleReopen} isLoading={isLoading}>Reopen</Button>
            <Button variant="ghost" onClick={handleClose}>
                Cancel
            </Button>
            </section>
        </Modal>);
}

export default ReopenDataPeriodModal;