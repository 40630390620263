import './styles.scss';

import React from 'react';
import { Tag, TagProps } from '@flotilla/component-library';

interface ImpactAreaTagProps extends TagProps {
  extraText?: string
};

const ImpactAreaTag: React.FC<ImpactAreaTagProps> = ({className, children, extraText, ...props}) => (
  <Tag {...props} className={`impact-area-tag impact-area-tag--${String(children).toLowerCase().replaceAll(' ', '-')} ${className}`} title={`${children}${extraText ? ` | ${extraText}` : ''}`}>
    {extraText || children}
  </Tag>
);

export default ImpactAreaTag;
