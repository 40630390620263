import { TreeTableItem } from "@flotilla/component-library";

export const filterTreeByValue = (tree: TreeTableItem[], key: string, value: string, comparison?: (item: TreeTableItem) => boolean): TreeTableItem[] => {
  const filteredTree: TreeTableItem[] = [];
  
  tree.forEach((item) => {
    if (comparison ? comparison(item) : String(item[key]).toLowerCase() === value) {
      filteredTree.push(item);
    } else if (item.children) {
      const childrenItems = filterTreeByValue(item.children, key, value, comparison);
      if (childrenItems.length > 0) {
        filteredTree.push({
          ...item,
          children: childrenItems
        });
      }
    }
  })

  return filteredTree;
}
