import './styles.scss';

import React from 'react';

import { SectionProps } from '../../../../../../types/CreateWizard';

const FinalSection: React.FC<SectionProps> = ({
  className = "",
  values,
}) => {
  const financialYear = new Date(values?.['financialYearEnd']).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'long'
  });
  const environmentalPeriod = new Date(values?.['environmentalReportingPeriod']).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'long'
  });

  return (
    <section id="onboarding-final-section" className={className}>
      <article className="stat">
        <p>Financial Year End</p>
        <h3>{financialYear}</h3>
      </article>
      <article className="stat">
        <p>Environmental Reporting Period</p>
        <h3>{environmentalPeriod}</h3>
      </article>
      <article className="stat">
        <p>Sector</p>
        <h3>{values?.['sector']?.['name']}</h3>
      </article>
      <article className="stat">
        <p>Industry</p>
        <h3>{values?.['industry']?.['name']}</h3>
      </article>
      <article className="stat">
        <p>Website URL</p>
        <h3><a href={`${values?.['url'].startsWith('http') ? "" : "https://"}${values?.['url']}`} target='_blank' rel="noreferrer">{values?.['url']}</a></h3>
      </article>
    </section>
  );
}

export default FinalSection;
