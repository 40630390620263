import './styles.scss';

import { FC } from 'react';
import { AddIcon, GenericData, IconButton, SearchFilter } from '@flotilla/component-library';

import TableView from './Table';
import Header from '../../../../components/HeaderV2';
import useCachedFilters from '../../../../hooks/PageCaching/useCachedFilters';
import { useConversionFactors } from '../../../../hooks';
import { ConversionFactors } from '../../../../types/ConversionFactor';
import { useAppDispatch } from '../../../../helpers/hooks';
import { addModal, removeModal } from '../../../../reducers/modal';
import ConversionFactorModal from '../../../../components/Modal/ConversionFactorModal';

interface ConversionFactorListProps {
  className?: string;
};

const ConversionFactorList: FC<ConversionFactorListProps> = ({
  className = ""
}) => {
  const dispatch = useAppDispatch();
  const { filterData, onFiltersChange } = useCachedFilters("ConversionFactorFilters");
  const { conversionFactors, loading, deleteConversionFactor, updateConversionFactors } = useConversionFactors();


  const handleOnClickAdd = () => {
    dispatch(addModal({
      id: 'create-conversion-factor-modal',
      children: (
        <ConversionFactorModal
          onClose={() => dispatch(removeModal())}
          onSubmit={() => {
            dispatch(removeModal());
            updateConversionFactors();
          }}
        />
      )
    }));
  }

  return (
    <section id="conversion-factor-list-page" className={className}>
      <Header
        rightChildren={() =>
          <>
            <IconButton
              id="add-new-conversion-factor"
              onClick={handleOnClickAdd}
              label="Add New"
              icon={<AddIcon />}
              smartLayout />
          </>
        }
      >
        Conversion Factors
      </Header>
    <main>
        <SearchFilter
          id="data-view"
          key={!loading && conversionFactors ? `conversion-factors--${conversionFactors.length}` : ''}
          data={conversionFactors as unknown as GenericData}
          onFiltersChange={onFiltersChange}
          quickFilters={[
            {
              key: 'measureName',
              label: 'Measure',
            },
            {
              key: 'year',
              label: 'Year',
              type: 'number',
              sort: (a, b) => a > b ? 1 : -1,
            },
          ]}
          filters={filterData?.filters || [
            {
              key: "isDaily",
              label: "Is Daily",
              value: "Is Daily",
              type: "boolean"
            },
            {
              key: "activityName",
              label: "Activity",
            },
          ]}
          children={(data) => (
            <TableView
              data={data as unknown as ConversionFactors}
              loading={loading}
              deleteConversionFactor={deleteConversionFactor}
              updateConversionFactor={updateConversionFactors}
            />
          )}
        />
      </main>
    </section>
  );
}

export default ConversionFactorList;
