import "./styles.scss";

import { Path, StyleSheet, Svg } from "@react-pdf/renderer";

import { TopAction } from "../";
import ImpactAreaTag from "../../../../../../../components/ImpactAreaTag";
import { ReportB, ReportDiv, ReportP } from "../../../../../../../components/PDFReport";
import QuickWinTag from "../../../../../../../components/QuickWinTag";
import { getImpactAreaColor } from "../../../../../../../helpers/colors";
import { numberLocaleFormatter } from "../../../../../../../helpers/numberFormatter";

type LeaderboardRowProps = {
    position: number;
    action: TopAction;
    downloadVersion: boolean;
};

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'stretch',
        marginTop: 3,
        width: '100%',
        backgroundColor: '#EEEEED',
        borderRadius: 4,
        padding: '4px 8px',
    },
    rowContent: {
        flexDirection: 'column',
        flex: 1,
        width: '100%'
    },
    description: {
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        alignItems: 'flex-start',
        marginBottom: 2
    },
    name: {
        flex: 1,
        width: '100%'
    },
    stats: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 'auto'
    },
    scores: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 10
    },
    votes: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 2
    },
    position: {
        width: 30,
        height: 30,
        borderRadius: 999,
        backgroundColor: '#213F37',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#FBFAF8'
    },
    positionText: {
        fontSize: 14
    },
    tagContainer: {
        flexDirection: 'row',
        gap: 2
    },
    kiaTag: {
        borderRadius: 999,
        padding: 1,
        paddingLeft: 6,
        paddingRight: 6,
        height: 16
    },
    quickWin: {
        flexDirection: 'row',
        marginRight: 2,
        backgroundColor: 'gold',
        justifyContent: 'center',
        gap: 2
    },
    tagText: {
        color: '#FBFAF8',
    },
    tagTextTravel: {
        color: '#213F37',
    },
    bronze: {
        border: '4px solid #cd7f32',
        backgroundColor: 'transparent',
        color: '#213F37'
    },
    silver: {
        border: '4px solid #c0c0c0',
        backgroundColor: 'transparent',
        color: '#213F37'
    },
    gold: {
        border: '4px solid #d4af37',
        backgroundColor: 'transparent',
        color: '#213F37'
    },
    reductionText: {
        fontSize: 10
    },
    trophyIcon: {
        marginTop: 3
    }
});

export const LeaderboardRow: React.FC<LeaderboardRowProps> = ({ position, action, downloadVersion = false }) => {
    return <ReportDiv 
        className={`leaderboard-row flotilla-card ${position <= 3 ? "large" : "small"}`} 
        style={styles.row}
        downloadVersion={downloadVersion}>
        <ReportDiv className="row-content" 
            style={styles.rowContent}
            downloadVersion={downloadVersion}>
            <ReportDiv className="description" style={styles.description} downloadVersion={downloadVersion}>
                <ReportP className="name" 
                    style={styles.name}
                    downloadVersion={downloadVersion}>
                    {action.Name}
                </ReportP>
            </ReportDiv>
            <ReportDiv className="stats" 
                style={styles.stats} 
                downloadVersion={downloadVersion}>
                <ReportDiv className="tag-container" style={styles.tagContainer} downloadVersion={downloadVersion}>
                    {!downloadVersion ? <ImpactAreaTag size="small">
                        {action.KeyImpactArea}
                    </ImpactAreaTag> : 
                    <ReportDiv
                        downloadVersion={downloadVersion}
                        style={{...styles.kiaTag, ...{ backgroundColor: getImpactAreaColor(action.KeyImpactArea.toLowerCase()) }}}>
                        <ReportP 
                            style={action.KeyImpactArea === "Travel & Logistics" ? styles.tagTextTravel : styles.tagText}
                            downloadVersion={downloadVersion}>
                            {action.KeyImpactArea}
                        </ReportP>
                    </ReportDiv>}
                    {action.QuickWin && 
                        (!downloadVersion ? <QuickWinTag showLabel /> :
                            <ReportDiv className="quick-win" style={{...styles.kiaTag, ...styles.quickWin}} downloadVersion={downloadVersion}>
                                <Svg height="9px" width="9px" style={styles.trophyIcon} viewBox="0 0 490 490">
                                    <Path fill="#011E26" d="M50,0v15c0,34.836,9.303,69.031,26.903,98.887c15.605,26.474,37.351,48.954,63.195,65.484
                                        c2.093,50.835,40.495,92.454,89.902,99.553V310c-35.841,0-65,29.159-65,65v25h-45v90h250v-90h-45v-25c0-35.841-29.159-65-65-65
                                        v-31.076c49.407-7.099,87.809-48.718,89.902-99.553c25.844-16.529,47.589-39.01,63.195-65.484C430.697,84.031,440,49.836,440,15V0
                                        H50z M140,142.238C106.287,114.32,84.723,73.826,80.689,30H140V142.238z M340,430v30H150v-30H340z M295,375v25H195v-25
                                        c0-19.299,15.701-35,35-35h30C279.299,340,295,355.701,295,375z M320,175c0,41.355-33.645,75-75,75c-41.355,0-75-33.645-75-75V30
                                        h150V175z M350,142.238V30h59.311C405.277,73.826,383.713,114.32,350,142.238z"/>
                                </Svg>
                                <ReportP downloadVersion={downloadVersion}>Quick Win</ReportP>
                            </ReportDiv>)}
                </ReportDiv>
                <ReportB style={styles.reductionText} downloadVersion={downloadVersion}>
                    {action.EstimatedTCO2eSaving >= 0.5 ? `Estimated ${numberLocaleFormatter(action.EstimatedTCO2eSaving)}% reduction of ${action.KeyImpactArea} emissions` : ""}
                </ReportB>
            </ReportDiv>
        </ReportDiv>
        <ReportDiv className="scores" 
            style={styles.scores}
            downloadVersion={downloadVersion}>
            <ReportDiv className={`position ${action.KeyImpactArea.toLowerCase().replaceAll(' ', '-')}`}
                style={{...styles.position, ...(position === 3 ? styles.bronze : position === 2 ? styles.silver : position === 1 ? styles.gold : {})}}
                downloadVersion={downloadVersion}>
                <ReportB style={styles.positionText} downloadVersion={downloadVersion}>
                    {position}
                </ReportB>
            </ReportDiv>
            <ReportDiv className="votes"
                style={styles.votes}
                downloadVersion={downloadVersion}>
                <ReportP downloadVersion={downloadVersion}>
                    {action.SuggestionCount} Votes
                </ReportP>
            </ReportDiv>
        </ReportDiv>
    </ReportDiv>;
};