const inFlight: { [key: string]: Promise<Response> } = {};

export const dedupeFetch = (url: string, options: RequestInit) => {
    if(options.method !== "GET") return fetch(url, options);

    if (inFlight.hasOwnProperty(url)) {
      return inFlight[url].then((res) => {
        return res.clone();
      });
    }

    inFlight[url] = fetch(url, options);

    return inFlight[url].then((res) => {
        delete inFlight[url];
        return res.clone();
    });
};
