import {
  ReportChart,
  ReportDiv,
  ReportH4
} from '../../../../../../../components/PDFReport';
import { BarChart, BartCO2eDifference } from '../../BarChart';
import { ChartByOfficeProps } from '..';
import { getChartLabels } from '../Labels';

export const HowOftenDoYouWFHByOffice: React.FC<
    ChartByOfficeProps
> = ({ className = '', data, downloadVersion = false, chartWidth, chartHeight, theme, keys }) => {
  const chartData =
    (data.data.map((obj) => ({
      key: obj.xLabel,
      percent: obj.value,
      colourHex: obj.colourHex
    })) ?? [])
    .filter(d => keys?.includes(d.key));

  chartData.sort((a, b) => b.key.localeCompare(a.key));

  const renderTick = (props: any) => {
    const value = props.value;
    const value1 = value.slice(0, 25);
    const value2 = value.slice(25);
  
    return (
      <g textAnchor='end' transform={`translate(${props.x - 10},${props.y})`}>
        <text style={{fontSize: 6}} transform={`translate(0, ${-(value2 ? 3 : 0)})`}>{value1}</text>
        <text style={{fontSize: 6}} transform={`translate(0, 3)`}>{value2}</text>
      </g>
    )
  }

  return (
    <ReportDiv
      id="how-often-do-you-wfh-by-office"
      className={className}
      downloadVersion={downloadVersion}
    >
      <ReportH4 className="section-header" downloadVersion={downloadVersion} style={{ display: "flex", alignSelf: "center", left: 25, fontSize: 10 }}>
        How often do you WFH?
      </ReportH4>
      <ReportChart downloadVersion={downloadVersion}>
        <BarChart
          width={chartWidth + (downloadVersion ? 74 : 30)}
          height={chartHeight}
          fontSize={downloadVersion ? 6 : 8}
          data={chartData}
          layout='horizontal'
          getColor={() => '#317F70'}
          keys={['percent']}
          theme={theme}
          axisLeft={{
            tickSize: 0,
            tickPadding: 8,
            renderTick: renderTick
          }}
          barComponent={(props) => (
            <BartCO2eDifference
              {...props}
              hideValues
              downloadVersion={downloadVersion}
              color={props.bar.data.data.colourHex}
            />
          )}
          enableLabel={false}
          enableGridX
          margin={{ top: 10, right: downloadVersion ? 30 : 50, bottom: 60, left: downloadVersion ? 100 : 150}}
          layers={['grid', 'axes', 'bars', 'markers', 'legends', 'annotations', getChartLabels(downloadVersion, true)]}
        />
      </ReportChart>
    </ReportDiv>
  );
};