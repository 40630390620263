import FinancialYearEndSection from "./Sections/FinancialYearEnd";
import EnvironmentalYearEndSection from "./Sections/EnvironmentalYearEnd";
import IndustrySection from "./Sections/Industry";
import SectorSection from "./Sections/Sector";
import { CreateWizardPage, CreateWizardPages } from "../../../../types/CreateWizard";
import ExtrasSection from "./Sections/Extras";
import RegulationAndFrameworkSection from "./Sections/RegulationAndFramework";

const getPages = (): CreateWizardPages => ([
  {
    name: 'Sector',
    title: 'Sector',
    subtitle: "Please select the sector that best describes the area your company operates within.",
    children: SectorSection,
    isNextDisabled: (values) => values?.["sectorId"] === undefined || values?.["sectorId"] === 0
  },
  {
    name: 'Industry',
    title: 'Industry',
    subtitle: "Please select the industry that best describes the area your company operates within.",
    children: IndustrySection,
    isNextDisabled: (values) => values?.["industryId"] === undefined || values?.["industryId"] === 0
  },
  {
    name: 'Year End',
    title: 'Financial Year End',
    subtitle: "Please enter your financial year end below.",
    children: FinancialYearEndSection,
    isNextDisabled: (values) => values?.["financialYearEnd"] === undefined || values?.["financialYearEnd"] === ''
  },
  {
    name: 'Environmental Period',
    title: 'Environmental Year End',
    subtitle: "Please enter your environmental reporting period end below.",
    children: EnvironmentalYearEndSection,
    isNextDisabled: (values) => values?.["environmentalReportingPeriod"] === undefined || values?.["environmentalReportingPeriod"] === ''
  },
  {
    name: 'Regulations',
    title: 'Regulations and Frameworks',
    subtitle: "Please select which of the following regulations and frameworks you would like to comply with.",
    children: RegulationAndFrameworkSection,
    isNextDisabled: (values) => values?.["complianceIds"] === undefined || values?.["complianceIds"].length === 0
  },
  {
    name: 'Extras',
    title: 'Extras',
    subtitle: "Here we would like to collect extra information about your company, primarily your company URL and website, and a logo that we can then use on reporting. These can be changed in your company settings page, but are needed to get started.",
    children: ExtrasSection
  }
] as (CreateWizardPage | boolean)[]).filter(Boolean) as CreateWizardPages;


export default getPages;