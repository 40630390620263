import './styles.scss';

import React from 'react';
import { Accordion, AccordionContent, AccordionHeader, AddIcon, IconButton, Tooltip } from '@flotilla/component-library';
import { useNavigate } from 'react-router-dom';

import { Activities, ActivityDataItem, ActivityNote, FocusGroups } from '../../../../../../types/DataPeriod';
import moneyFormatter from '../../../../../../helpers/moneyFormatter';
import { Location, Locations } from '../../../../../../types/Location';
import DataPeriodTabContent from '../DataPeriod';
import { LOCATION_TAB_TOOLTIP } from '../../../../../../assets/content/Tooltips';
import RequiredMarker from '../../../../../../components/RequiredMarker';
import { useUserAccess } from '../../../../../../context';

interface LocationsTabContentProps {
  className?: string;
  data?: Locations;
  onChange?: (data: ActivityDataItem[]) => void;
  onChangeNote?: (data: ActivityNote) => void;
  openAccordions?: number[];
  onOpenAccordionsChange?: (ids: number[]) => void;
  submitAttempt?: boolean;
  disabled?: boolean;
};

const LocationsTabContent: React.FC<LocationsTabContentProps> = ({
  className,
  data,
  onChange = () => {},
  openAccordions,
  onOpenAccordionsChange = () => {},
  onChangeNote = () => {},
  submitAttempt = false,
  disabled = false,
}) => {
  const navigate = useNavigate();
  const { userAccess } = useUserAccess();
  const { CREATE } = userAccess;

  const getSpendTotal = (): string => {
    const value = data?.reduce((prev, item) => prev += item.focusGroups?.reduce((prev, item) => prev += item.activities?.reduce((prev, value) => prev += value?.data?.find((item) => item.measure.name === '£')?.value || 0, 0), 0) || 0, 0) || 0;
    return moneyFormatter(value, '£', true);
  }

  const getLocationSpendTotal = (location: Location): string => {
    const value = location.focusGroups?.reduce((prev, item) => prev += item.activities?.reduce((prev, value) => prev += value?.data?.find((item) => item.measure.name === '£')?.value || 0, 0), 0) || 0;
    return moneyFormatter(value, '£', true);
  }

  const isRequiredFocusGroupsCompleted = (focusGroups: FocusGroups): boolean => {
    const completed = focusGroups.filter((item) => item.activities.filter((activity) => activity.mandatory ? !activity.data?.some((item) => (item.value ?? -1) > -1) : false).length > 0).length;
    return completed > 0;
  }


  const getCompletedByActivities = (activities: Activities): number => {
    return activities.filter((activity) => (activity.data?.find((item) => (item.value ?? -1) >= 0)?.value ?? -1) >= 0).length;
  }

  const getCompletedByFocusGroups = (focusGroups: FocusGroups): number => {
    let completed = 0;
    completed = focusGroups?.reduce((prev, item) => prev += getCompletedByActivities(item.activities), 0) || 0;
    return completed;
  }

  const getCompletedByLocations = (): number => {
    let completed = 0;
    completed = data?.reduce((prev, item) => prev += getCompletedByFocusGroups(item.focusGroups || []), 0) || 0;
    return completed;
  }

  const handleOnClickAccordionHeader = (id: number) => {
    return () => {
      let updatedOpenAccordions = [...openAccordions || []];
      if (updatedOpenAccordions.includes(id)) {
        updatedOpenAccordions = updatedOpenAccordions.filter((item) => item !== id);
      } else {
        updatedOpenAccordions.push(id);
      }
      onOpenAccordionsChange(updatedOpenAccordions);
    }
  }

  const handleOnClickAdd = () => {
    navigate('../../locations/create');
  }

  return (
    <section id="data-tab-content" className={className}>
      <header>
        <section id="total">
          <h4>
            Total
          </h4>
          <p>{getCompletedByLocations()} of {data?.reduce((prev, item) => prev += item.focusGroups?.reduce((prev, value) => prev += value.activities.length, 0) || 0, 0)} completed</p>
        </section>
        <section className="spend-total">
          <h4>
            Spend
          </h4>
          <p>{getSpendTotal()}</p>
        </section>
        <section id="actions">
          <Tooltip
            tooltipText={LOCATION_TAB_TOOLTIP}
            alignment='left'
          />
          {CREATE && <IconButton
            variant='outline'
            icon={<AddIcon variant='brand'/>}
            onClick={handleOnClickAdd}
            small
          />}
        </section>
      </header>
      { data?.map((item) => (
        <Accordion
          key={item.id}
          className={`location-accordion ${item.colorHex ? 'location-accordion--color' : ''}`}
          open={openAccordions?.includes(item.id)}
          style={item.colorHex ? { borderLeft: `solid 0.5rem ${item.colorHex}` } : {}}
        >
          <AccordionHeader id="accordion-header" onClick={handleOnClickAccordionHeader(item.id)}>
            <section id="title">
              <h4>
                {item.name}
                { submitAttempt && isRequiredFocusGroupsCompleted(item.focusGroups || []) &&
                  <RequiredMarker />
                }
              </h4>
              <p>{getCompletedByFocusGroups(item.focusGroups || [])} of {item.focusGroups?.reduce((prev, value) => prev += value.activities.length, 0)} completed</p>
            </section>
            <section className="spend-total">
              <p>{getLocationSpendTotal(item)}</p>
            </section>
          </AccordionHeader>
          <AccordionContent id="location-accordion-content">
            <DataPeriodTabContent
              data={item.focusGroups}
              secondLevel
              onChange={onChange}
              onChangeNote={onChangeNote}
              openAccordions={openAccordions}
              onOpenAccordionsChange={onOpenAccordionsChange}
              className="location-focus-groups"
              submitAttempt={submitAttempt}
              disabled={disabled}
            />
          </AccordionContent>
        </Accordion>
      ))}
    </section>
  );
}

export default LocationsTabContent;
