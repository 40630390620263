import { useCallback, useEffect, useState } from "react";
import { useAlert } from "@flotilla/component-library";

import { Company } from "../../types/Company";
import { getCompany, editCompanyDetails } from "../../api/companies";
import { useAppDispatch } from "../../helpers/hooks";
import { setCompany as setCompanyName } from "../../reducers/user";
import { useUserAccess } from "../../context";

const useCompany = (id: string) => {
  const { userAccess } = useUserAccess();
  const { ADMIN } = userAccess;
  const { addAlert } = useAlert();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [companyId, setCompanyId] = useState<string>(id);
  const [company, setCompany] = useState<Company>();

  const fetchApi = useCallback(async () => {
    if (!(ADMIN && companyId === "0")) {
      setLoading(true);
      await getCompany(companyId)
        .then((res) => {
          setCompany(res);
          dispatch(setCompanyName(res?.name || ""));
        })
        .finally(() => setLoading(false));
    } else {
      ADMIN && setCompany(undefined);
    }
  }, [ADMIN, companyId, dispatch]);

  const updateCompany = () => {
    fetchApi();
  }

  const editCompany = (_company: Partial<Company>) => {
    company && setCompany({
      ...company,
      ..._company,
    });
  }

  const saveCompany = async (silent: boolean = false): Promise<boolean | void> => {
    if (company) {
      setLoading(true);
      return await editCompanyDetails(company)
        .then((res) => {
          !silent && addAlert({ id: `Company Saved ${id}`, type: "success", title: "Company Saved Successfully" });
          return res;
        })
        .catch((err) => {
          !silent && addAlert({ id: `Company Save Failed ${id}`, type: "error", title: "Company Save Unsuccessful", content: err.message.length ? err.message : "Company failed to save please try again." });
        })
        .finally(() => setLoading(false));;
    }
  }

  useEffect(() => {
    if (companyId) {
      fetchApi();
    } else {
      dispatch(setCompanyName(""));
    }
  }, [companyId, dispatch, fetchApi]);

  return [
    company,
    setCompanyId,
    updateCompany,
    loading,
    editCompany,
    saveCompany
  ] as [
    Company,
    React.Dispatch<React.SetStateAction<string>>,
    () => void,
    boolean,
    (_company: Partial<Company>) => void,
    (_silent?: boolean) => Promise<boolean | void>,
  ];
}

export default useCompany;
