import { FONT_FAMILY } from "../../../../../../../../styles/font";

interface TriangleGraphicProps {
  className?: string;
  value: string | number;
  text?: string[];
  downward?: boolean;
  width?: number;
  height?: number;
  x?: number;
  y?: number;
  fillColor?: string;
  fontColor?: string;
}

export const TriangleGraphic: React.FC<TriangleGraphicProps> = ({
  className = '',
  value,
  text,
  downward = false,
  width = 49,
  height = 49,
  x = 0,
  y = 0,
  fillColor = '#14AF7D',
  fontColor = '#FBFAF8',
}) => {
  const points = downward ? '0,0 150,0 75,129' : '0,129 150,129 75,0';
  const textX = 75;
  const textY = downward ? 30 : 75;

  return (
    <g
      className={`triangle-graphic ${className}`}
      viewBox="0 0 150 129"
      width={width}
      height={height}
      transform={`translate(${x}, ${y})`}
    >
      <polygon points={points} fill={fillColor} />
      <text
        x={textX}
        y={textY}
        fill={fontColor}
        textAnchor="middle"
        style={{ fontFamily: FONT_FAMILY, fontSize: '6px' }}
      >
        <tspan style={{ fontSize: '30px' }}>{value}</tspan>
        {text?.map((textValue, index) => (
          <tspan key={index} x={textX} y={textY + 15 + index * 8}>
            {textValue}
          </tspan>
        ))}
      </text>
    </g>
  );
};
