import './styles.scss';

import { StyleSheet } from '@react-pdf/renderer';

import ReportDiv from '../../ReportDiv';
import ReportH3 from '../../ReportH3';
import { ReportEditorProps } from '..';
import ReportP from '../../ReportP';

const styles = StyleSheet.create({
  paddingLeft: {
    borderLeft: '4px solid #011e26',
    backgroundColor: '#EEEEED',
    borderRadius: 4,
    padding: '8px 12.5px',
  },
});

export const Text: React.FC<ReportEditorProps> = ({
  style = {},
  editorContent,
  downloadVersion = false,
  preventColorChange = false,
  borderColor
}) => {
  const {
    header = '',
    content = [],
    boxAccent = false,
    boxAccentColor = '',
  } = editorContent;

  return (
    <ReportDiv
      style={{
        ...(boxAccent && styles.paddingLeft),
        borderColor: (
          borderColor ? borderColor : boxAccentColor === 'dark' ? '#011e26' : '#e35924'
        ),
        ...style,
      }}
      className={`report-editor-text-container ${
        (boxAccent && !preventColorChange) || borderColor
          ? 'report-editor-text-container--show-border'
          : ''
      } border-color--${boxAccentColor}`}
      divStyle={{
        borderColor: borderColor
      }}
      downloadVersion={downloadVersion}
    >
      {header && (
        <ReportH3 downloadVersion={downloadVersion}>{header}</ReportH3>
      )}
      {content?.map((contentText, index) => (
        <ReportP
          key={index}
          className="report-editor-text"
          downloadVersion={downloadVersion}
        >
          {contentText}
        </ReportP>
      ))}
    </ReportDiv>
  );
};
