export const formatDate = (dateString: string): string => {
  const date = new Date(dateString.replace(' ', 'T'));
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  return date.toLocaleDateString(undefined, options);
}

export const getDateYYYYMMDDString = (_date: Date | string) => {
  let date = typeof _date === "string" ? new Date(_date) : _date;

  if (date.toString()=== 'Invalid Date') {
    return '';
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
};