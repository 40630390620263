import './styles.scss';

import React from 'react';
import { Tag, TagProps } from '@flotilla/component-library';

import { ReactComponent as SuggestedIcon } from "../../assets/icons/Suggested.svg";

interface EmployeeSuggestedTagProps extends Omit<TagProps, "children"> {
  showLabel?: boolean;
};

const EmployeeSuggestedTag: React.FC<EmployeeSuggestedTagProps> = ({className, showLabel, ...props}) => (
  <Tag
    {...props}
    className={`tag employee-suggested-tag ${showLabel ? "employee-suggested-tag--show-label" : ""} ${className}`}
    title="Employee Suggested"
    icon={<SuggestedIcon id="employee-suggested" />}
    >
    {showLabel ? "Employee Suggested" : ""}
  </Tag>
);

export default EmployeeSuggestedTag;
