import { User, mapUsers } from "./User";

export type Task = {
  id: number;
  netZeroPlanActionId: number;
  userId?: number;
  user?: User;
  title: string;
  completed: boolean;
}

export type Tasks = Task[];

export const mapTasks = (data: {[key: string]: unknown}[]): Tasks => {
  return data.map((item:  {[key: string]: unknown | unknown[] | any}) => ({
    ...item,
    user: item.user ? mapUsers([item.user])[0] : undefined
  })) as unknown[] as Tasks;
}