import React from 'react';

import { Route, Routes } from 'react-router-dom';

import ClimateSchool from './ClimateSchool';
import IEMA from './IEMA';
import TrainingHome from './TrainingHome';

const Training: React.FC = () => {  
  return (
    <Routes>
      <Route index element={<TrainingHome />} />
      <Route path="climateschool" element={<ClimateSchool />} />
      <Route path="iema" element={<IEMA />}/>
    </Routes>
  );
}

export default Training;
