import { useCallback, useEffect, useState } from "react";

import { getImpactAreas } from "../../api/Lookup";
import { Lookups } from "../../types/Lookup";

const useImpactAreas = (flat: boolean = false) => {
  const [loading, setLoading] = useState(true);
  const [impactAreas, setImpactAreas] = useState<Lookups>();

  const fetchApi = useCallback(async () => {
    setLoading(true);
    getImpactAreas()
      .then((res) => {
        if (flat) {
          const flatArray: Lookups = [];
          res.forEach((item) => {
            item.sublabels?.forEach((subItem) => {
              flatArray.push(...subItem.sublabels || []);
            })
          })
          setImpactAreas(flatArray);
        } else {
          setImpactAreas(res);
        }
      })
      .finally(() => setLoading(false));
  }, [flat]);

  const refresh = () => {
    fetchApi();
  }

  useEffect(() => {
    fetchApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    impactAreas,
    refresh,
    loading
  };
}

export default useImpactAreas;
