import { ImpactAreaColors, ImpactAreaColorsType } from "../styles/colors";

export const getColorScale = () => [
  '#30356f',
  '#0069B2',
  '#A6D737',
  '#0096FF',
  '#40b8d4',
  '#2c8aba',
  '#24C8B1',
  '#00C28E',
  '#F1F7FB',
];

export const scopeColorScale = ['#000', '#0076A1', '#83D1B9', '#008575'];
export const fallbackChartColors = ['#c7d043', '#CE3636', '#14AF7D', '#A499D7', '#648491', '#0d91e2', '#DB7093', '#6A7DC2', '#46B05D', '#E35924', '#00A096'];

export const getImpactAreaColorScale = () => [
  '#648491',
  '#0D6045',
  '#83D1B9',
  '#C7D043',
];

export const getDualReportingColorScale = () => ['#E25A24', '#14AF7D'];

export function defaultColor(label: string | null | undefined, index: number) {
  const nameColor: Record<string, string> = {
    travel: '#0096FF',
    energy: '#24C8B1',
    facilities: '#0076A1',
    supply: '#009FAE',
    'tco2e per fte': '#C7D043',
    'tco2e per £m turnover': '#83D1B9',
  };
  const color = getColorScale();
  return label
    ? nameColor[label] || color[index] || '#F1F7FB'
    : color[index] || '#F1F7FB';
}

export const defaultPieColorScale = [
  '#14AF7D',
  '#00A096',
  '#008EA9',
  '#007AB1',
  '#0063AA',
  '#6A7DC2',
  '#A499D7',
  '#d8a7ca',
  '#948eb2',
  '#D5B9EA',
  '#73a6ad',
  '#d9f0f6',
  '#00a096',
  '#125a60',
  '#78b450'
];

export const carbonFootprintBaselineYearColors: Record<string, string> = {
  'employee commuting': '#14AF7D',
  'commuting': '#14AF7D',
  'business travel': '#00A096',
  facilities: '#008EA9',
  'professional fees': '#007AB1',
  technology: '#0063AA',
  energy: '#6A7DC2',
  'subsistence & hotels': '#A499D7',
  consumables: '#D5B9EA',
  'financed emissions': '#8060A7',
};

export const getTravelShades = () => [
  '#c7d043',
  '#99A409',
  '#6C7A00',
  '#425300',
  '#282D00',
  '#181a06',
];

export const getEnergyShades = () => [
  '#091713',
  '#003020',
  '#005542',
  '#2D7C67',
  '#59A68F',
  '#83d1b9',
];

export const getFacilitiesShades = () => [
  '#abf4dc',
  '#73ecc5',
  '#3ae4ad',
  '#1bc58d',
  '#138c65',
  '#0D6045',
];

export const getSupplyChainShades = () => [
  '#d1dade',
  '#a2b5bd',
  '#839da7',
  '#648491',
  '#506a74',
  '#324249',
];

export const getPeopleShades = () => [
  '#CD6C8E',
  '#AD607D',
  '#8D536D',
  '#6D475D',
  '#4C3B4C',
  '#2C2E3C',
];

export const getBuildingsShades = () => [
  '#83d1b9',
  '#59A68F',
  '#2D7C67',
  '#005542',
  '#003020',
  '#091713',
];

export const getImpactAreaColor = (value: string) => {
  const color = ImpactAreaColors[value.toLowerCase().replaceAll(' ', '').replaceAll('&', '') as keyof ImpactAreaColorsType];

  if (color) {
    return color.brand;
  }

  switch (value.toLowerCase()) {
    case 'travel & logistics':
    case 'travel':
      return '#c7d043';
    case 'travel & logistics planned':
      return 'RGB(199, 208, 67, 0.3';
    case 'energy':
      return '#1BAF94';
    case 'energy planned':
      return 'RGB(27, 175, 148, 0.3';
    case 'facilities':
      return '#213F37';
    case 'facilities planned':
      return 'RGB(33, 63, 55, 0.3';
    case 'supply chain':
      return '#648491';
    case 'supply chain planned':
      return 'RGB(100, 132, 145, 0.3';
    case 'governance':
      return '#0d91e2';
  }
  return 'transparent';
};

export const getActionStatusColor = (value: string) => {
  switch (value) {
    case 'To do':
      return '#E2E4E3';
    case 'Active':
      return '#E35924';
    case 'Completed':
      return '#46B05D';
    case 'Overdue':
      return '#CE3636';
  }
  return 'transparent';
};