import { API_ACTIVITY } from "../../constants/APIEndpoints";
import { checkAuthorised, checkErrors } from "../../helpers/checkAuthorisedAPI";
import { Activity } from "../../types/DataPeriod";

export const getAllActivities = (): Promise<Activity[]> => {
    return fetch(`${API_ACTIVITY}/GetAll`, {
      headers: { "X-CSRF": "1" },
      method: 'GET',
    })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => res.payload);
}

export const getAllMappableActivities = (): Promise<Activity[]> => {
  return fetch(`${API_ACTIVITY}/GetAllMappable`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
  .then((res) => checkAuthorised(res))
  .then(checkErrors)
  .then((res) => res.payload);
}