import './styles.scss';

import { useState } from 'react';
import { Button, DeleteIcon, IconButton } from '@flotilla/component-library';
import { Style } from '@react-pdf/types';

import { ReactComponent as EditIcon } from '../../../assets/icons/Edit.svg';
import ReportDiv from '../ReportDiv';
import { OrderedList } from './OrderedList';
import { PopUp } from './PopUp';
import { UnorderedList } from './UnorderedList';
import { Text } from './Text';
import { EditorContent, EditorContentType } from '../../../types/Report';
import { ConfirmDeletePopUp } from './ConfirmDeletePopUp';

export interface ReportEditorProps {
  id?: string;
  className?: string;
  style?: Style;
  downloadVersion?: boolean;
  pageId?: number;
  contentId?: number;
  editorContent: EditorContent;
  editable?: boolean;
  onChange?: (editorContent: EditorContent) => void;
  onSave?: (editorContent: EditorContent) => void;
  onDelete?: (editorContent: EditorContent) => void;
  preventColorChange?: boolean;
  newEditor?: boolean;
  borderColor?: string;
}

const ReportEditor: React.FC<ReportEditorProps> = (props) => {
  const {
    id,
    downloadVersion = false,
    pageId,
    contentId,
    editorContent: initialEditorContent,
    editable = true,
    onDelete,
    onChange = () => ({}),
    onSave,
    preventColorChange = false,
    className = '',
    newEditor = false,
    style
  } = props;
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [editorContent, setEditorContent] = useState(initialEditorContent || {});
  const [showConfirmDeletePopUp, setShowConfirmDeletePopUp] = useState(false);

  const openEditor = () => {
    setIsEditorOpen(true);
  };

  const closeEditor = () => {
    setIsEditorOpen(false);
  };

  const handleChange = (content: EditorContent) => {
    setEditorContent(content);
    onChange(content);
  };

  const handleDeleteClick = () => {
    setShowConfirmDeletePopUp(true);
  };

  const handleDelete = () => {
    setShowConfirmDeletePopUp(false);
    onDelete && onDelete(editorContent);
  };

  const getContent = (type: EditorContentType | undefined) => {
    switch (type) {
      case 'ordered-list':
        return <OrderedList {...props} editorContent={editorContent} />;
      case 'unordered-list':
        return <UnorderedList {...props} editorContent={editorContent} />;
      default:
        return <Text {...props} editorContent={editorContent} />;
    }
  };

  return (
    <ReportDiv
      id={id}
      className={`report-editor ${className}`}
      style={style}
      downloadVersion={downloadVersion}
    >
      {newEditor && editable ? (
        <Button onClick={openEditor} variant="ghost">
          Add Comment
        </Button>
      ) : (
        getContent(editorContent.type)
      )}
      {!downloadVersion && !newEditor && editable && (
        <div className="edit-functions">
          <IconButton
            variant="solid"
            aria-label="Edit Icon"
            icon={<EditIcon stroke="#FBFAF8" />}
            onClick={openEditor}
          />
          {onDelete && contentId && (
            <IconButton
              variant="solid"
              aria-label="Delete Icon"
              icon={<DeleteIcon />}
              onClick={handleDeleteClick}
            />
          )}
        </div>
      )}
      {!downloadVersion && (
        <PopUp
          pageId={pageId}
          contentId={contentId}
          openEditor={isEditorOpen}
          onClose={closeEditor}
          editorContent={editorContent}
          onChange={handleChange}
          onSave={onSave}
          preventColorChange={preventColorChange}
          newEditor={newEditor}
        />
      )}
      {showConfirmDeletePopUp && contentId && (
        <ConfirmDeletePopUp
          contentId={contentId}
          onClose={() => setShowConfirmDeletePopUp(false)}
          onDelete={handleDelete}
        />
      )}
    </ReportDiv>
  );
};

export default ReportEditor;