import './styles.scss';
import { useState } from "react";
import { Button } from "@flotilla/component-library";
import { useAppDispatch } from '../../../helpers/hooks';
import { removeModal } from '../../../reducers/modal';

interface ConfirmDeleteModalProps {
  onClose?: () => void;
  deleteItem: () => Promise<void>;
  header?: string;
  text?: string;
};

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  onClose,
  deleteItem,
  header = 'Delete',
  text = 'Are you sure you wish to delete? This is permanent and cannot be undone.'
}) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClose = () => {
    onClose ? onClose() : dispatch(removeModal());
  }
  
  const handleOnClickDelete = () => {
    setIsLoading(true);
    deleteItem()
      .then(handleOnClose)
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <section
      id="confirm-delete-modal"
    >
      <h4>{header}</h4>
      <main>
        <p>{text}</p>
      </main>
      <section className="button-container">
        <Button variant="ghost" onClick={handleOnClose}>
          Cancel
        </Button>
        <Button onClick={handleOnClickDelete} isLoading={isLoading}>Yes, Delete</Button>
      </section>
    </section>
  );
}

export default ConfirmDeleteModal;