import React from 'react';

import { Route, Routes } from 'react-router-dom';

import UsersList from './List';

const Users: React.FC = () => {
  return (
    <Routes>
      <Route index element={<UsersList />} />
    </Routes>
  );
}

export default Users;
