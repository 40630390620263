import './styles.scss';

import { FC, SVGProps } from 'react';
import { Link } from 'react-router-dom';
import { LockIcon, ExternalLinkIcon } from '@flotilla/component-library';

import { classNames } from '../../../helpers/classNames';

export interface SideNavItemProps {
  id?: string;
  className?: string;
  children?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  to?: string;
  selected?: boolean;
  flotillaIcon?: FC<any>;
  icon?: FC<SVGProps<SVGSVGElement>>;
  selectedIcon?: FC<SVGProps<SVGSVGElement>>;
  externalLink?: boolean;
  locked?: boolean;
  title?: string;
}

export const SideNavItem: FC<SideNavItemProps> = ({
  id,
  className = "",
  children,
  onClick,
  to = '',
  selected = false,
  icon: Icon,
  flotillaIcon: FlotillaIcon,
  selectedIcon: SelectedIcon,
  externalLink = false,
  locked = false,
  title: _title
}) => {
  const title = _title || (locked ? 'This section is locked.' : undefined);

  return (
    <Link
      id={id}
      className={classNames([
        'side-nav-item',
        (FlotillaIcon || Icon) ? 'side-nav-item--has-icon' : '',
        selected ? 'side-nav-item--selected' : '',
        to || externalLink || onClick ? '' : 'side-nav-item--no-link',
        locked ? 'side-nav-item--locked' : '',
        className
      ])}
      to={!locked ? to : '#'}
      onClick={(e) => !locked && onClick?.(e)}
      title={title}
      target={externalLink ? '_blank' : undefined}
      aria-disabled={locked}
    >
      {(Icon || FlotillaIcon) && (
        <span id="icon-container">
          {
            FlotillaIcon ? (
              <FlotillaIcon variant={selected ? 'light' : 'dark'} className='icon'/>
            ) : (
              Icon ? (
                (selected && SelectedIcon) ?
                  <SelectedIcon className="icon" /> :
                  <Icon className="icon" />
              ) : undefined
            )
          }
        </span>
      )}
      <span className="label">{children}</span>
      {!locked && externalLink && (
        <ExternalLinkIcon variant='dark'/> 
      )}
      {locked && (
        <LockIcon variant='dark'/> 
      )}
    </Link>
  );
};
