import './styles.scss';

import React, { useEffect, useState } from 'react';
import { Button, Modal, Skeleton } from '@flotilla/component-library';
import { useAppSelector } from '../../helpers/hooks';
import { getNetZeroPlanId } from '../../reducers/user';
import { useCompanyId } from '../../context';
import ReductionTargetSection from '../../assets/content/CreateWizard/NetZeroPlanSetup/Sections/ReductionTarget';
import useNetZeroPlan from '../../hooks/NetZeroPlan/useNetZeroPlan';
import { NetZeroPlan } from '../../types/NetZeroPlan';
import { createReductionTargets } from '../../api/Reduction';
import { TARGETS_GUIDANCE_SUBTITLE } from '../../assets/content/Subtitles';

interface EditTargetsModalProps {
  className?: string;
  onClose: () => void;
  onSubmit: () => void;
};

const EditTargetsModal: React.FC<EditTargetsModalProps> = ({
  className = "",
  onClose = () => {},
  onSubmit = () => {}
}) => {
  const companyId = useCompanyId();
  const netZeroPlanId = useAppSelector(getNetZeroPlanId);
  const [plan, setPlanId] = useNetZeroPlan(netZeroPlanId);
  const [newPlan, setNewPlan] = useState<NetZeroPlan>();

  useEffect(() => {
    if(plan) setNewPlan({...plan});
  }, [plan]);

  useEffect(() => {
    netZeroPlanId && setPlanId(netZeroPlanId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [netZeroPlanId]);

  const handleOnChange = (values: {[key: string]: any;}) => {
    newPlan && setNewPlan({
      ...newPlan,
      scope1ReductionTargetInterim: values.scope1 / 100,
      scope2ReductionTargetInterim: values.scope2 / 100,
      scope3ReductionTargetInterim: values.scope3 / 100,
    });
  }

  const handleOnSubmit = () => {
    newPlan && createReductionTargets({
        netZeroPlanId: newPlan.id,
        scope1ReductionTargetInterim: newPlan?.scope1ReductionTargetInterim * 100 ?? 50,
        scope2ReductionTargetInterim: newPlan?.scope2ReductionTargetInterim * 100 ?? 50,
        scope3ReductionTargetInterim: newPlan?.scope3ReductionTargetInterim * 100 ?? 40
      }, companyId)
      .then(() => {
        console.log("success");
        onSubmit();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <Modal
      id="edit-targets-modal"
      className={className}
      onClose={onClose}
    >
      <h2>Edit Reduction Targets</h2>
      <p>{TARGETS_GUIDANCE_SUBTITLE}</p>
      {newPlan ? (
        <ReductionTargetSection 
          onChange={handleOnChange}
          values={{
            interimTargetYear: newPlan?.interimTargetYear,
            completionDate: new Date(newPlan.targetDate).getFullYear(),
            scope1: newPlan.scope1ReductionTargetInterim * 100,
            scope2: newPlan.scope2ReductionTargetInterim * 100,
            scope3: newPlan.scope3ReductionTargetInterim * 100,
          }}
        />
      ) : <Skeleton />}
      <footer>
        <Button variant="ghost" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={handleOnSubmit}>
          Save
        </Button>
      </footer>
    </Modal>
  );
};

export default EditTargetsModal;
