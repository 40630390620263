import './styles.scss';

import React, { useEffect, useState } from 'react';
import { Card } from '@flotilla/component-library';

import { getImpactAreas } from '../../../../../../api/Lookup';
import CheckboxButton, { CheckboxButtonProps } from '../../../../../../components/CheckboxButton';
import CheckboxContainer from '../../../../../../components/CheckboxContainer';
import { Lookups } from '../../../../../../types/Lookup';

interface ImpactAreaSelectorProps {
  className?: string;
  impactAreaId?: number,
  focusGroupId?: number,
  activityIds?: number[],
  onChange?: (impactAreaId?: number, focusGroupId?: number, activityIds?: number[]) => void,
};

const ImpactAreaSelector: React.FC<ImpactAreaSelectorProps> = ({
  className = "",
  impactAreaId: initialImpactAreaId,
  focusGroupId: initialFocusGroupId,
  activityIds: initialActivityIds,
  onChange = () => ({}),
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [impactAreaId, setImpactAreaId] = useState<number | undefined>(initialImpactAreaId);
  const [focusGroupId, setFocusGroupId] = useState<number | undefined>(initialFocusGroupId);
  const [activityIds, setActivityIds] = useState<number[]>(initialActivityIds || []);
  const [impactAreasLookup, setImpactAreasLookup] = useState<Lookups>();
  const [focusGroupsLookup, setFocusGroupsLookup] = useState<Lookups>();
  const [activitiesLookup, setActivitiesLookup] = useState<Lookups>();

  useEffect(() => {
    handleGetLookup();
  }, []);

  useEffect(() => {
    initialImpactAreaId && setImpactAreaId(initialImpactAreaId);
    initialFocusGroupId && setFocusGroupId(initialFocusGroupId);
    initialActivityIds && setActivityIds(initialActivityIds);
  }, [initialImpactAreaId, initialFocusGroupId, initialActivityIds]);

  useEffect(() => {
    setFocusGroupsLookup(impactAreasLookup?.find((value) => value.id === impactAreaId)?.sublabels);
  }, [impactAreaId, impactAreasLookup]);

  useEffect(() => {
    setActivitiesLookup(focusGroupsLookup?.find((value) => value.id === focusGroupId)?.sublabels);
  }, [focusGroupId, focusGroupsLookup]);

  const handleGetLookup = () => {
    setIsLoading(true);
    getImpactAreas()
      .then((res) => setImpactAreasLookup(res))
      .finally(() => setIsLoading(false));
  };

  const handleCheckboxChange = (type: "ImpactAreaId" | "FocusGroupId" | "ActivityId"): ((item: CheckboxButtonProps) => void) => {
    return (item: CheckboxButtonProps) => {
      switch (type) {
        case "ImpactAreaId":
          setImpactAreaId(Number(item.id));
          setFocusGroupId(undefined);
          setActivityIds([]);
          onChange(Number(item.id));
          break;
        case "FocusGroupId":
          setFocusGroupId(Number(item.id));
          setActivityIds([]);
          onChange(impactAreaId, Number(item.id));
          break;
      }
    }
  }

  const handleActivitiesChange = (id: number) => {
    let newIds = activityIds;

    if(activityIds.includes(id))
    {
      newIds = newIds.filter(n => n !== id);
    } else {
      newIds = [...newIds, id];
    }
    
    setActivityIds(newIds);
    onChange(impactAreaId, focusGroupId, newIds);
  }

  return (
    <section id="impact-area-selector" className={className}>
      <h4>Impact Area</h4>
      { !isLoading ? (
        <>
          <CheckboxContainer
            label="Area*"
            name="area"
            initialValue={impactAreaId}
            onChange={handleCheckboxChange("ImpactAreaId")}
            checked={(item) => item.id === impactAreaId}
            children={impactAreasLookup as CheckboxButtonProps[]}
          />
          { Number(impactAreaId) > 0 && focusGroupsLookup && focusGroupsLookup.length > 0 && (
            <CheckboxContainer
              label="Category*"
              name="category"
              initialValue={focusGroupId}
              onChange={handleCheckboxChange("FocusGroupId")}
              checked={(item) => item.id === focusGroupId}
              children={focusGroupsLookup as CheckboxButtonProps[]}
            />
          )}
          { Number(focusGroupId) > 0 && activitiesLookup && (
            <section className="checkbox-container">
              <p>Activity</p>
              <article>            
                {activitiesLookup.map((lookup) => (
                  <CheckboxButton
                    key={lookup.id}
                    label={lookup.label}
                    onToggle={() => handleActivitiesChange(Number(lookup.id))}
                    checked={(lookup.id && activityIds.includes(Number(lookup.id))) || false}
                    className="department-checkbox"
                  />
                ))}
              </article>
            </section>
          )}
        </>
      ) : (
        <article id="skeleton-container">
          <Card className="skeleton-checkbox"/>
          <Card className="skeleton-checkbox"/>
          <Card className="skeleton-checkbox"/>
        </article>
      )}
    </section>
  );
}

export default ImpactAreaSelector;
