import './styles.scss';

import React, { useEffect, useState } from 'react';
import { Skeleton, useAlert } from '@flotilla/component-library';

import { SectionProps } from '../../../../../../types/CreateWizard';
import { getCompliances } from '../../../../../../api/CompanySettings';
import MultiCheckboxContainer, { CheckboxOption } from '../../../../../../components/MultiCheckboxContainer';
import { POLICIES } from '../../../../Policies';

const RegulationAndFrameworkSection: React.FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const { addAlert } = useAlert();
  const [complianceIds, setComplianceIds] = useState<number[]>(values?.["complianceIds"]);
  const [compliances, setCompliances] = useState<CheckboxOption[]>();
  const [loading, setLoading] = useState(false);

  const handleMultiButtonChange = (item: CheckboxOption, selected: boolean) => {
    let newIds = [...(complianceIds || [])].filter((item) => item !== -1);

    if(selected) {
      newIds.push(item.id);
      if (item.id === -1 ) {
        newIds = [-1];
      }
    } else {
      newIds = newIds.filter(r => r !== item.id)
    }

    setComplianceIds(newIds);
    onChange({
      'complianceIds': newIds
    });
  }

  useEffect(() => {
    setLoading(true);
    getCompliances()
        .then(res => {
          setCompliances(res.map(({ id, name }) => {
              const policyInfo = POLICIES.find((item) => item.name === name);
              return {
                  id,
                  label: name,
                  tooltipProps: {
                      children: (
                          <>
                              <h4>{policyInfo?.fullName}</h4>
                              <p>{policyInfo?.description}</p>
                          </>
                      )
                  }
              }
          }));
        })
        .catch(() => {
          addAlert({ id: `Sectors Get Failed`, type: "error", title: "Failed to retrieve sector options", content: "Failed to retrieve the available sector options, please refresh to try again." });
        })
        .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section id="regulation-and-framework-section" className={className}>
      { loading ? (
        <>
          <Skeleton className="checkbox--loading" />
          <Skeleton className="checkbox--loading" />
          <Skeleton className="checkbox--loading" />
        </>
      ) : (
        <MultiCheckboxContainer
          id="compliances"
          label="Which of the following regulations or frameworks would you like to comply with?*"
          aria-required={true}
          selected={complianceIds}
          options={[
              ...(compliances || []),
              {
                  id: -1,
                  label: 'None'
              }
          ]}
          onChange={(item, selected) => handleMultiButtonChange(item, selected)}
        />
      )}
    </section>
  );
}

export default RegulationAndFrameworkSection;
