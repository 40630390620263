export const OUR_EMISSIONS_SUBTITLE = "Below is a breakdown of your carbon emissions for your latest reporting period. You can see how much carbon you're currently producing in each emission factor and what that is likely to reduce to based on the actions in your plan.";
export const PROJECT_PLAN_SUBTITLE = "Your live Net Zero project plan where you can manage your Net Zero actions.";
export const ACTION_LIBRARY_SUBTITLE = "Here you'll find all available actions to add to your plan. Items already included in your plan are marked with a tick. Items available to add to your plan are marked with a plus. If there are any other actions not in the list that you would like to add, please contact us.";
export const EMPLOYEE_SURVEY_SUBTITLE = "Enhance your employee survey response rate: Read our guide to get started.";
export const TARGETS_GUIDANCE_SUBTITLE = "As well as setting a net zero target year, aligning to science-based targets also requires a business to set near-term or interim targets. These targets are typically absolute Scope 1 and 2 emission reduction targets that should be achieved by 2030 from a pre-defined base year. They provide companies with a clearly defined path to reduce greenhouse gas emissions in line with limiting global warming to 1.5°C.";
export const POLICY_CHECKER_SUBTITLE = "The tool below provides a starting point for understanding which UK sustainability regulations and frameworks your business may have to comply with currently or in the future. If you are part of a corporate group, you should consider both the group and the individual company position. Get in touch with Flotilla for more information at %%EMAIL%%[getintouch@flotillaworld.com]%%EMAIL%%.";
export const COMPANY_SETTINGS_SUBTITLE = "Update key information about your business. The choices made here impact the data fields available within Data Collection.";
export const DATA_COLLECTION_DATA_SUBTITLE = "An overview of all periods for which you can enter data. Review previous submissions or enter data for your current period. Read our getting started guide: ";
export const DATA_COLLECTION_DATA_DETAIL_SUBTITLE = "Enter data on each of the tabs shown below. The fields available are driven by your selection in Locations and Company Settings. Your changes will be auto saved every minute to save you the hassle, but feel free to still use the save if you want to be extra safe.";
export const DATA_COLLECTION_LOCATION_SUBTITLE = "An overview of all the locations you operate in or have operated in previously.";
export const DATA_COLLECTION_LOCATION_DETAIL_SUBTITLE = "Update key information about this location. The choices made here impact the data fields available within Data Collection.";
export const DATA_COLLECTION_SUPPLIER_SUBTITLE = "A summary of all your suppliers and the information submitted to Flotilla.";
export const DATA_INTEGRATION_MAPPING_SUBTITLE = "An overview of the mapping between your %%ACCOUNTS_NAME%% groups and the Flotilla activities. This automatically populates your finance data within each data period of the data collection process."