import './styles.scss';

import React from 'react';

import { SectionProps } from '../../../../../../types/CreateWizard';

const FinalSection: React.FC<SectionProps> = ({
  className = "",
  values,
}) => {
  return (
    <section id="net-zero-plan-create-final-section" className={className}>
      <section id="year-summary" className="stat-group">
        <section className="stat-container">
          <article id="net-zero" className="stat">
            <p>Net Zero year</p>
            <h3>{values?.['completionDate']}</h3>
          </article>
        </section>
      </section>
      <section id="reduction-summary" className="stat-group">
        <h4>Reduction Targets</h4>
        <section className="stat-container">
          <article id="scope-1" className="stat">
            <p>Scope 1</p>
            <h3>{values?.['scope1']}<span>%</span></h3>
          </article>
          <article id="scope-2" className="stat">
            <p>Scope 2</p>
            <h3>{values?.['scope2']}<span>%</span></h3>
          </article>
          <article id="scope-3" className="stat">
            <p>Scope 3</p>
            <h3>{values?.['scope3']}<span>%</span></h3>
          </article>
        </section>
      </section>
    </section>
  );
}

export default FinalSection;
