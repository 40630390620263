import './styles.scss';

import { Pie } from '@nivo/pie';
import { StyleSheet } from '@react-pdf/renderer';

import {
  ReportPage,
  ReportDiv,
  ReportP,
  ReportB,
  ReportLi,
  ReportChart,
  ReportUl,
  ReportH3
} from '../../../../../../components/PDFReport';
import { ReportPageProps } from '../../../../../../types/Report';
import { FONT_FAMILY } from '../../../../../../styles/font';

const styles = StyleSheet.create({
  carbonFootprintingKeyTermsPage: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  content: {
    width: '100%',
    paddingTop: 12.5,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    gap: 25,
  },
  side: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 6.25,
  },
  chart: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  legend: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 6,
  },
  legendItem: {
    borderLeft: '4px solid #0076a1',
    backgroundColor: '#EEEEED',
    borderRadius: 4,
    padding: '8px 12.5px',
  },
  legendItemInformation: {
    fontSize: 9,
  },
  legendItemScope1: {
    borderLeftColor: '#0076a1',
  },
  legendItemScope2: {
    borderLeftColor: '#83d1b9',
  },
  legendItemScope3: {
    borderLeftColor: '#008575',
  },
  pieChart: {
    width: '40%',
  },
});

export const CarbonFootprintingKeyTermsExplained: React.FC<ReportPageProps> = ({ page, className, downloadVersion = false, onContentUpdate = () => {}, editable }) => {
  const chartData = [
    {
      id: 'Scope 1',
      label: 'Scope 1',
      value: 10,
      color: '#0076a1',
    },
    {
      id: 'Scope 2',
      label: 'Scope 2',
      value: 15,
      color: '#83d1b9',
    },
    {
      id: 'Scope 3',
      label: 'Scope 3',
      value: 75,
      color: '#008575',
    },
  ];

  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`carbon-footprinting-key-terms-explained ${className}`}
      style={styles.carbonFootprintingKeyTermsPage}
      downloadVersion={downloadVersion}
      footer={page.footer}
      onChange={() => onContentUpdate()}
      pageId={page.pageId}
      showFooter
      editable={editable}
      page={page}
      showHeader
    >
      <ReportDiv
        className="content"
        style={styles.content}
        downloadVersion={downloadVersion}
      >
        <ReportDiv
          className="side left"
          style={styles.side}
          downloadVersion={downloadVersion}
        >
          <ReportDiv className="section" downloadVersion={downloadVersion}>
            <ReportH3 downloadVersion={downloadVersion}>Greenhouse gases (GHG)</ReportH3>
            <br/>
            <ReportP downloadVersion={downloadVersion}>
              Greenhouse gas emissions are the release of gases into the atmosphere that trap heat from
              the sun, causing the planet to warm. These gases act like a blanket, allowing sunlight to
              enter but preventing some of the Earth's heat from escaping back into space. There are 7
              GHGs stated in the GHG protocol:
            </ReportP>
            <ReportUl downloadVersion={downloadVersion}>
              <ReportLi downloadVersion={downloadVersion} style={{ marginTop: 5 }}>
                Carbon dioxide (CO2)
              </ReportLi>
              <ReportLi downloadVersion={downloadVersion}>
                Methane (CH4)
              </ReportLi>
              <ReportLi downloadVersion={downloadVersion}>
                Nitrous oxide (N2O)
              </ReportLi>
              <ReportLi downloadVersion={downloadVersion}>
                Hydrofluorocarbons (HFCs)
              </ReportLi>
              <ReportLi downloadVersion={downloadVersion}>
                Perfluorocarbons (PFCs)
              </ReportLi>
              <ReportLi downloadVersion={downloadVersion}>
                Sulphur hexafluoride (SF6)
              </ReportLi>
              <ReportLi downloadVersion={downloadVersion}>
                Nitrogen trifluoride (NF3)
              </ReportLi>
            </ReportUl>
          </ReportDiv>
          <ReportDiv className="section" downloadVersion={downloadVersion}>
            <ReportH3 downloadVersion={downloadVersion}>tCO2e</ReportH3>
            <br/>
            <ReportP downloadVersion={downloadVersion}>
              Different activities produce different gases. For carbon
              accounting purposes, greenhouse gases are converted into CO2 equivalents for easier reporting and
              comparison. We refer to a business carbon footprint as '
              <ReportB downloadVersion={downloadVersion}>tCO2e</ReportB>', which
              is short for '
              <ReportB downloadVersion={downloadVersion}>
                tonnes of carbon dioxide equivalent
              </ReportB>
              '.
            </ReportP>
          </ReportDiv>
          <br/>
          <ReportDiv className="section" downloadVersion={downloadVersion}>
            <ReportH3 downloadVersion={downloadVersion}>Baseline year</ReportH3>
            <br/>
            <ReportP downloadVersion={downloadVersion}>
              A reference point against which emissions reductions are measured. This is
              used by Flotilla in creating a Net Zero plan and determining reduction targets.
            </ReportP>
          </ReportDiv>
        </ReportDiv>
        <ReportDiv
          className="side right"
          style={styles.side}
          downloadVersion={downloadVersion}
        >
          <ReportDiv className="section" downloadVersion={downloadVersion}>
            <ReportH3 downloadVersion={downloadVersion}>
              Scopes 1, 2, and 3
            </ReportH3>
            <br/>
            <ReportP downloadVersion={downloadVersion}>
              Carbon footprint measures are categorised into three scopes: 1, 2
              and 3.
            </ReportP>
            <ReportDiv
              className="chart"
              style={styles.chart}
              downloadVersion={downloadVersion}
            >
              <ReportDiv
                className="legend"
                style={styles.legend}
                downloadVersion={downloadVersion}
              >
                <ReportDiv
                  id="scope-1"
                  className="item"
                  style={{ ...styles.legendItem, ...styles.legendItemScope1 }}
                  downloadVersion={downloadVersion}
                >
                  <ReportP className="title" downloadVersion={downloadVersion}>
                    Scope 1
                  </ReportP>
                  <ReportP
                    className="information"
                    style={styles.legendItemInformation}
                    downloadVersion={downloadVersion}
                  >
                    Direct emissions from sources that the company owns or controls.
                  </ReportP>
                </ReportDiv>
                <ReportDiv
                  id="scope-2"
                  className="item"
                  style={{ ...styles.legendItem, ...styles.legendItemScope2 }}
                  downloadVersion={downloadVersion}
                >
                  <ReportP className="title" downloadVersion={downloadVersion}>
                    Scope 2
                  </ReportP>
                  <ReportP
                    className="information"
                    style={styles.legendItemInformation}
                    downloadVersion={downloadVersion}
                  >
                    Indirect emissions from electricity, steam, heat or cooling.
                  </ReportP>
                </ReportDiv>
                <ReportDiv
                  id="scope-3"
                  className="item"
                  style={{ ...styles.legendItem, ...styles.legendItemScope3 }}
                  downloadVersion={downloadVersion}
                >
                  <ReportP className="title" downloadVersion={downloadVersion}>
                    Scope 3
                  </ReportP>
                  <ReportP
                    className="information"
                    style={styles.legendItemInformation}
                    downloadVersion={downloadVersion}
                  >
                    All other indirect emissions from activities of the
                    organisation.
                  </ReportP>
                </ReportDiv>
              </ReportDiv>
              <ReportChart downloadVersion={downloadVersion}>
                <Pie
                  margin={{ left: 40, right: 60 }}
                  data={chartData}
                  colors={{ datum: 'data.color' }}
                  innerRadius={0.6}
                  borderColor={{ theme: 'background' }}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextOffset={0}
                  arcLinkLabelsTextColor="#011e26"
                  arcLinkLabelsDiagonalLength={10}
                  arcLinkLabelsStraightLength={0}
                  arcLinkLabelsThickness={9}
                  arcLinkLabelsColor="#fbfaf8"
                  arcLabelsSkipAngle={15}
                  enableArcLabels={false}
                  isInteractive={false}
                  animate={false}
                  theme={{
                    background: '#fbfaf8',
                    text: {
                      fontSize: downloadVersion ? 9 : 14,
                      fontFamily: FONT_FAMILY,
                    }
                  }}
                  height={downloadVersion ? 180 : 310}
                  width={downloadVersion ? 200 : 310}
                />
              </ReportChart>
            </ReportDiv>
            <ReportP downloadVersion={downloadVersion}>
              Scope 3 can account for up to 90% of a business' carbon footprint. We measure all three scopes, so you can understand the full impact of your business activities.
            </ReportP>
          </ReportDiv>
          <ReportDiv className="section" downloadVersion={downloadVersion}>
            <ReportH3 downloadVersion={downloadVersion}>Net Zero</ReportH3>
            <br/>
            <ReportP downloadVersion={downloadVersion}>
              Net Zero is a target set by businesses and countries with the aim
              of limiting global temperature rises to 1.5c. Put simply, Net Zero
              means reducing GHG emissions to as close to zero as
              possible with any remaining emissions re-absorbed from the
              atmosphere. To achieve Net Zero, following science-based targets,
              a business must:
            </ReportP>
            <ReportUl downloadVersion={downloadVersion}>
              <ReportLi downloadVersion={downloadVersion} style={{ marginTop: 5 }}>
                Measure emissions across their value chain
              </ReportLi>
              <ReportLi downloadVersion={downloadVersion}>
                Focus on deep, rapid emission cuts, ideally halving emissions by
                2030, and reducing by at least 90% by 2050
              </ReportLi>
              <ReportLi downloadVersion={downloadVersion}>
                Remove remaining hard-to-abate emissions from the atmosphere via 
                carbon removal solutions.
              </ReportLi>
            </ReportUl>
          </ReportDiv>
        </ReportDiv>
      </ReportDiv>
    </ReportPage>
  );
};
