import './styles.scss';

import { Text, StyleSheet, Font } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

interface ReportH1Props {
  id?: string;
  className?: string;
  style?: Style;
  downloadVersion?: boolean;
  children: string | string[];
}

Font.registerHyphenationCallback((word) => [word]);

const styles = StyleSheet.create({
  text: {
    fontFamily: 'Times-Roman',
    fontSize: 50,
    color: '#011e26',
  },
});

const ReportH1: React.FC<ReportH1Props> = ({
  className = '',
  id,
  children,
  style = {},
  downloadVersion = false,
}) => {
  if (downloadVersion) {
    return (
      <Text id={id} style={[styles.text, style]}>
        {children}
      </Text>
    );
  }

  return (
    <h1 id={id} className={`report-h1 ${className}`}>
      {children}
    </h1>
  );
};

export default ReportH1;
