import './styles.scss';

import React, { useEffect, useState } from 'react';
import { CloseIcon, IconButton, NumberInput, PencilIcon, SaveIcon, Select } from '@flotilla/component-library';

import { ProgressStat } from '../../../../types/ProgressStat';
import { NetZeroPlan } from '../../../../types/NetZeroPlan';

interface NetZeroYearEditableInputProps extends ProgressStat {
  handleAPICall: (year: number) => Promise<NetZeroPlan>;
  permittedValues?: number[];
  onAPISuccess?: () => void;
}

const NetZeroYearEditableInput: React.FC<NetZeroYearEditableInputProps> = ({
  value,
  valueUnit = "",
  handleAPICall,
  permittedValues,
  onAPISuccess = () => ({})
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [viewStat, setViewStat] = useState(false);
  const [netZeroYear, setNetZeroYear] = useState(value);

  useEffect(() => {
    setNetZeroYear(value);
  }, [value]);

  const toggleView = () => setViewStat(!viewStat);

  const handleSaveClick = () => {
    setIsLoading(true);

    handleAPICall(netZeroYear)
      .then(() => {
        setViewStat(false);
        onAPISuccess();
      })
      .catch((error) => {
        console.log('Error when updating a Net Zero plan year value', error);
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <section
      id="net-zero-year-editable-input"
      className={viewStat ? 'stat' : 'view'}
    >
      { viewStat ? (
        <>
          {permittedValues ?
            <Select
              id="net-zero-year"
              className="year-input"
              value={netZeroYear}
              onChange={(event) => setNetZeroYear(parseInt(event.currentTarget.value))}
            >
              {permittedValues.map((value, index) => <option key={index} value={value}>{value}</option>)}
            </Select> :
            <NumberInput
              id="net-zero-year"
              className="year-input"
              value={netZeroYear}
              onChange={(value) => value && setNetZeroYear(value)}
              variant='stat'
              min={new Date().getFullYear()}
              max={2050}
              showSmallStepperButtons
              autoFix
            />}
          <IconButton
            icon={<SaveIcon />}
            onClick={handleSaveClick}
            isLoading={isLoading}
            disabled={value === netZeroYear}
            small
          />
          <IconButton
            icon={<CloseIcon variant='dark'/>}
            onClick={toggleView}
            variant='ghost'
            small
          />
        </>
      ) : (
        <>
          <h1 id="value">{value > 10 ? Math.round(value) : Math.round(value * 10) / 10}{valueUnit}</h1>
          <IconButton
            icon={<PencilIcon variant='dark' />}
            onClick={toggleView}
            variant='ghost'
            small
          />
        </>
      )}
    </section>
  )
}

export default NetZeroYearEditableInput;
