import './styles.scss';

import React, { HTMLAttributes, useEffect, useState } from 'react';

import CheckboxButton, { CheckboxButtonProps } from '../CheckboxButton';

interface CheckboxContainerProps extends Omit<HTMLAttributes<HTMLElement>, "children" | "onChange"> {
  name: string;
  label?: string;
  initialValue?: string | number;
  children?: CheckboxButtonProps[];
  onChange?: (item: CheckboxButtonProps) => void;
  fillContainer?: boolean;
  allowEmpty?: boolean;
  checked?: (item: CheckboxButtonProps) => boolean;
};

const CheckboxContainer: React.FC<CheckboxContainerProps> = ({
  name,
  className = "",
  label,
  onChange = () => {},
  initialValue,
  children,
  fillContainer = false,
  allowEmpty = false,
  checked,
  ...props
}) => {
  const [checkedValue, setCheckedValue] = useState(initialValue);

  useEffect(() => {
    setCheckedValue(initialValue);
  }, [initialValue]);

  const handleChange = (item?: CheckboxButtonProps) => {
    return () => {
      if (item) {
        setCheckedValue(item.label);
        onChange(item);
      }
    }
  }

  const handleChecked = (item: CheckboxButtonProps): boolean => {
    if (checked) {
      return checked(item);
    } else {
      return item.label?.toLowerCase() === String(checkedValue)?.toLowerCase();
    }
  }

  return (
    <section
      className={`checkbox-container ${fillContainer ? "checkbox-container--filled" : ""} ${className}`}
      {...props}
    >
      <p>{label}</p>
      <article>
        {children?.map((child, index) => (
          <CheckboxButton
            {...child}
            key={index}
            name={name}
            checked={handleChecked(child)}
            onToggle={handleChange(child)}
            className="radio"
            type="radio"
          />
        ))}
      </article>
    </section>
  );
}

export default CheckboxContainer;
