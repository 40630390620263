import './styles.scss';

import React, { useState } from 'react';
import { CheckboxButtonV2 } from '@flotilla/component-library';

import { locationType } from '../../../../../../types/Location';
import { SectionProps } from '../../../../../../types/CreateWizard';

const LocationTypeSection: React.FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const [type, setType] = useState<string>(values?.["type"]);

  const handleTypeChange = (updatedType: string) => {
    return (checked: boolean) => {
      const value = checked ? updatedType : '';
      setType(value);
      onChange({ type: value });
    }
  }

  return (
    <section id="location-type-section" className={className}>
      <p>Location type</p>
      <article id="options">
        { locationType.filter((item) => item !== "").map((typeOption, index) => 
          <CheckboxButtonV2
            className='checkbox'
            key={index}
            label={typeOption}
            checked={typeOption === type}
            onToggle={handleTypeChange(typeOption)}
          />
        )}
      </article>
    </section>
  );
}

export default LocationTypeSection;
