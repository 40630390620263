export type QuestionGroup = {
  id: number;
  title: string;
  subtitle?: string;
  questions: Questions;
  orderNumber: number;
}

export type Question = {
  id: number;
  locationQuestionAnswers: Answers;
  orderNumber: number;
  questionText: string;
  questionType: string;
  submissions?: Submissions;
  tooltip?: string;
  helpText?: string;
  mandatory: boolean;
}

export type Answer = {
  id: number;
  linkedLocationQuestionId?: number;
  linkedLocationQuestion?: Question;
  locationQuestionAnswerSubmissions?: Submissions;
  locationQuestionId: number;
  orderNumber: number;
  questionAnswerText: string;
}

export type Submission = {
  id: number;
  locationId?: number;
  locationQuestionAnswerId: number;
  freeTextAnswer?: string;
  createdAt?: string;
  updatedAt?: string;
  questionId?: number;
}

export type QuestionGroups = QuestionGroup[];
export type Questions = Question[];
export type Answers = Answer[];
export type Submissions = Submission[];

export const questionType = [
  "",
  "TextInput",
  "TextArea",
  "Select",
  "Toggle",
  "Checkbox",
  "MultiCheckbox",
  "IntegerInput",
  "DecimalInput"
];
export type QuestionType = typeof questionType[number];

export const getQuestionTypeId = (value: string = "Office"): number => {
  return questionType.findIndex((type) => type === value);
}

export const mapQuestionGroups = (data: Partial<QuestionGroup>[]): QuestionGroups => {
  return data.map((g) => ({
    ...g,
    questions: mapQuestions(g.questions ?? [])
  })) as QuestionGroups;
}

export const mapQuestions = (data: Partial<Question>[]): Questions => {
  return (data.map((item: Partial<Question>) => ({
    ...item,
    questionType: questionType[item.questionType as unknown as number],
    ...(item.locationQuestionAnswers ? { locationQuestionAnswers: mapAnswers(item.locationQuestionAnswers) } : {})
  })) as Questions).sort((a, b) => a.orderNumber - b.orderNumber);
}

export const mapAnswers = (data: Partial<Answer>[]): Answers => {
  return data.map((item: Partial<Answer>) => ({
    ...item,
    ...(item.linkedLocationQuestion ? { linkedLocationQuestion: mapQuestions([item.linkedLocationQuestion])[0] } : {})
  })) as Answers;
}
