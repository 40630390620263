import './styles.scss';

import React from 'react';
import { Tag, TagProps } from '@flotilla/component-library';

interface ScopeTagProps extends TagProps {
  extraText?: string
};

const ScopeTag: React.FC<ScopeTagProps> = ({className, children = "", extraText, ...props}) => (
  <Tag {...props} className={`scope-tag scope-tag--${String(children).toLowerCase().replaceAll(' ', '-')} ${className}`} title={`${children}${extraText ? ` | ${extraText}` : ''}`}>
    {extraText || children}
  </Tag>
);

export default ScopeTag;
