import React from "react";
import { ActionGanttTaskListRowProps } from "./ActionGantt.types";
import { ReactComponent as ChevronLeftIcon } from '../../assets/icons/ChevronLeft.svg';

const ActionGanttTaskListRow: React.FC<ActionGanttTaskListRowProps> = ({ task, height = 29, onExpanderClick }) => {
    return <section className={`${task.id.endsWith('Activity') ? 'activity' : task.type} task-list-row task-list-row--status-${task.action?.status?.replaceAll(' ', '').toLowerCase() || 'todo'}`} style={{ height: height }} onClick={() => { task.type === 'project' && onExpanderClick(task) }}>
        { task.type !== 'task' && <span className='task-list-row-chevron'>
            <ChevronLeftIcon style={{ transform: (task.hideChildren ? 'rotate(-90deg)' : 'rotate(90deg)') }} />
        </span> }
        <div className='task-list-row-name'>
            <span>{task.name}</span>
        </div>
    </section>;
}

export default ActionGanttTaskListRow;