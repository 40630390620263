export type Survey = {
  id: number;
  companyId: number;
  pUrl: string;
  surveyType: string;
  fromDate: string;
  toDate: string;
  active: boolean;
  sentTo: number;
  responses: number;
  responseRate: number;
  engagementCompanySurveyId: number;
  companySurveyQuestions?: CompanySurveyQuestion[];
  includedQuestions?: number[],
};

export type CompanySurveyQuestion = {
  companySurveyId: number,
  companyQuestionId: number,
}

export type EngagementCompanySurvey = {
  id: number;
  companyId: number;
  surveyId: number;
  fromDate: string;
  toDate: string;
  companySurveyQuestions: CompanySurveyQuestion[];
}

export type SurveyType = {
  id: number;
  type: string;
  name: string;
}

export type Surveys = Survey[];
export type EngagementCompanySurveys = EngagementCompanySurvey[];
export type SurveyTypes = SurveyType[];

type SurveysWithReportStatusReport = {
  id: number;
  title: string;
  status: number;
  dateCreated: Date;
  lastUpdatedDate: Date;
  lastUpdatedBy: number;
  companyID: number;
};

export type SurveysWithReportStatus = {
  id: number;
  companyID: number;
  pUrl: string;
  surveyType: string;
  expiryDate: Date;
  sentTo: number;
  reports: SurveysWithReportStatusReport[];
};

export const mapSurveys = (data: {[key: string]: unknown}[]): Surveys => {
  return data.map((item:  {[key: string]: unknown | unknown[] | any}) => ({
    ...item,
    fromDate: item.fromDate.split('T')[0],
    toDate: item.toDate.split('T')[0]
  })) as unknown[] as Surveys;
}
