import { API_DATA_INTEGRATION } from "../../constants/APIEndpoints";
import { checkAuthorised, checkErrors } from "../../helpers/checkAuthorisedAPI";
import { AccountsNominals, DataIntegrations } from "../../types/DataIntegrations";
import { dedupeFetch } from "../dedupeFetch";

export const getDataIntegrations = (companyId: number): Promise<DataIntegrations> => {
    return dedupeFetch(`${API_DATA_INTEGRATION}/GetAll?companyId=${companyId}`, {
      headers: { "X-CSRF": "1" },
      method: 'GET',
    })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => res.payload);
}

export const createDataIntegration = (companyId: number, type: number = 2): Promise<string> => {
  return fetch(`${API_DATA_INTEGRATION}/Create?companyId=${companyId}&type=${type}`, {
    headers: { "X-CSRF": "1" },
    method: 'POST',
  })
  .then((res) => checkAuthorised(res))
  .then(checkErrors)
  .then((res) => res.payload);
}

export const deleteDataIntegration = (dataIntegrationId: number, companyId: number): Promise<DataIntegrations> => {
  return fetch(`${API_DATA_INTEGRATION}/Delete?dataIntegrationId=${dataIntegrationId}&companyId=${companyId}`, {
    headers: { "X-CSRF": "1" },
    method: 'DELETE',
  })
  .then((res) => checkAuthorised(res))
  .then(checkErrors)
  .then((res) => res.payload);
}

export const getAccountsNominals = (dataIntegrationId: number, companyId: number): Promise<AccountsNominals> => {
  return fetch(`${API_DATA_INTEGRATION}/GetDataIntegrationActivities?dataIntegrationId=${dataIntegrationId}&companyId=${companyId}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
  .then((res) => checkAuthorised(res))
  .then(checkErrors)
  .then((res) => res.payload);
}

export const updateAccountsNominals = (accountsNominals: AccountsNominals, companyId: number): Promise<AccountsNominals> => {
  return fetch(`${API_DATA_INTEGRATION}/UpdateDataIntegrationActivities`, {
    headers: { 
      "X-CSRF": "1",
      "Content-Type": "application/json"
     },
    method: 'POST',
    body: JSON.stringify({
      companyId,
      dataIntegrationActivities: accountsNominals.filter(an => an.id || an.activityId).map(aN => {
        return {
          id: aN.id,
          dataIntegrationId: aN.dataIntegrationId,
          thirdPartyId: aN.thirdPartyId,
          activityId: aN.activityId
        }
      })
    })
  })
  .then((res) => checkAuthorised(res))
  .then(checkErrors)
  .then((res) => res.payload);
}