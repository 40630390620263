import './styles.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddIcon, DownloadIcon, GenericData, IconButton, SearchFilter, useAlert } from '@flotilla/component-library';
import { saveAs } from 'file-saver';

import { Action, Actions, impactAreaValues } from '../../../../types/Action';
import { getActions, getActionsCSV } from '../../../../api/Action';
import { ReactComponent as ListIcon } from '../../../../assets/icons/List.svg';
import { ReactComponent as BoardIcon } from '../../../../assets/icons/Board.svg';
import CardView from './Card';
import TableView from './Table';
import Header from '../../../../components/HeaderV2';
import useCachedFilters from '../../../../hooks/PageCaching/useCachedFilters';

interface ActionManagerListProps {
  className?: string;
};

const ActionManagerList: React.FC<ActionManagerListProps> = ({
  className = ""
}) => {
  const { addAlert } = useAlert();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Actions>();
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const { filterData, onFiltersChange } = useCachedFilters("ActionManagerFilters");

  const handleGetData = useCallback(() => {
    setLoading(true);
    getActions()
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        addAlert({
          id: `Getting Actions`,
          type: 'error',
          title: 'Error getting Actions',
          content:
            'We are sorry there seems to be an issue getting the Actions. Please try again.',
        });
        console.log("Error while getting Actions: ", error);
      })
      .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickItem = (action: Action) => {
    navigate(`./${action.id}`);
  }

  const handleDownloadActionsCSV = async () => {
      setIsDownloadLoading(true);
      await getActionsCSV()
        .then((blob) => {
          saveAs(blob, 'Actions.csv');
        })
        .catch((error) => {
          addAlert({
            id: `Downloading Actions CSV`,
            type: 'error',
            title: 'Error Downloading Actions CSV',
            content:
              'Something went wrong when downloading Actions, please try again',
          });
          console.log('Download Actions CSV Error: ', error);
        })
        .finally(() => {
          setIsDownloadLoading(false);
        });
  }

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  return (
    <section id="action-manager-list-page" className={className}>
      <Header
        rightChildren={() =>
          <>
            <IconButton
              id="add-new-action"
              onClick={() => navigate('new')}
              label="Add New"
              icon={<AddIcon />}
              smartLayout />
            <IconButton
              id="download"
              isLoading={isDownloadLoading}
              onClick={(event) => {
                event.stopPropagation();
                handleDownloadActionsCSV();
              } }
              label="Export To CSV"
              icon={<DownloadIcon stroke="#FBFAF8" />}
              smartLayout />
          </>
        }
      >
        Action Manager
      </Header>
    <main>
        <SearchFilter
          id="data-view"
          key={!loading && data ? 'action-manager' : ''}
          data={data as unknown as GenericData}
          onFiltersChange={onFiltersChange}
          quickFilters={filterData?.quickFilters || [
            {
              key: 'impactAreaName',
              label: 'Impact Area',
              options: impactAreaValues,
              sort: (a, b) => impactAreaValues.indexOf(a) - impactAreaValues.indexOf(b),
            },
            {
              key: 'scopeName',
              label: 'Scope',
              options: ['Scope 1', 'Scope 2', 'Scope 3'],
              addLabel: true,
            },
            {
              key: 'productNames',
              label: 'Product',
              isArray: true
            }
          ]}
          filters={filterData?.filters || [
            {
              key: "quickWin",
              label: "Quick Wins",
              value: "Quick Wins",
              type: "boolean"
            },
            {
              key: "employeeSurveyEnabled",
              label: "Employee Suggestible",
              value: "Employee Suggestible",
              type: "boolean"
            },
            {
              key: "activeStatus",
              label: "Active Status",
              addLabel: true,
            },
            {
              key: "defaultPriority",
              label: "Priority",
              addLabel: true,
            },
            {
              key: "costSavingsPotential",
              label: "Cost Savings",
              options: (data?.some(d => d.costSavingsPotential === "None") ? ["None"] : []).concat(["Low", "Medium", "High"]),
              sort: () => 1,
            },
            {
              key: "carbonSavingsPotential",
              label: "Carbon Savings",
              options: (data?.some(d => d.carbonSavingsPotential === "None") ? ["None"] : []).concat(["Low", "Medium", "High"]),
              sort: () => 1,
            },
            {
              key: "coBenefitsPotential",
              label: "Co-Benefits",
              options: (data?.some(d => d.coBenefitsPotential === "None") ? ["None"] : []).concat(["Low", "Medium", "High"]),
              sort: () => 1,
            },
            {
              key: "actionTags",
              label: "Action Tag",
              isArray: true
            },
            {
              key: "departmentNames",
              label: "Departments",
              isArray: true
            },
            {
              key: "scopeCategoryNames",
              label: "Scope Category",
              isArray: true
            },
            {
              key: "focusGroupName",
              label: "Focus Group"
            },
            {
              key: "activityNames",
              label: "Activity",
              isArray: true
            },
          ]}
          views={[
            {
              key: "board",
              label: "Board",
              icon: <BoardIcon />,
              children: (data) =>
                <CardView
                  data={data as unknown as Actions}
                  onClick={handleClickItem}
                  loading={loading}
                />
            },
            {
              key: "list",
              label: "List",
              icon: <ListIcon />,
              children: (data) => (
                <TableView
                  data={data as unknown as Actions}
                  onClick={handleClickItem}
                  loading={loading}
                />
              )
            }
          ]}
        />
      </main>
    </section>
  );
}

export default ActionManagerList;
