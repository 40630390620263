import { API_STATISTICS } from "../../constants";
import { checkAuthorised, checkErrors } from "../../helpers/checkAuthorisedAPI";
import { ActionGroups, mapActionGroupStats } from "../../types/ActionGroupStat";
import { CarbonFootprintData, ImpactAreas, mapImpactAreas } from "../../types/ImpactArea";
import { Activities, mapActivities } from "../../types/Activity";
import { ProgressAPIPayload, ProgressStats, mapProgressStats } from "../../types/ProgressStat";
import { ReductionChart, mapReductionChart } from "../../types/ReductionChart";
import { ReductionStats } from "../../types/ReductionStats";
import { Scopes } from "../../types/Scope";
import { StatusCount } from "../../types/StatusCount";
import { dedupeFetch } from "../dedupeFetch";

export const getImpactAreaStats = (netZeroPlanId: number, companyId: string): Promise<ImpactAreas> => {
  return fetch(`${API_STATISTICS}/GetImpactAreas?netZeroPlanId=${netZeroPlanId}&companyId=${companyId}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then(({ payload }) => mapImpactAreas(payload));
}

export const getCarbonFootprint = (companyId: string): Promise<CarbonFootprintData> => {
  return fetch(`${API_STATISTICS}/GetLatestCarbonFootprintData?companyId=${companyId}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return {
        year: res.payload.year,
        carbonFootprints: res.payload.impactAreas.map((item: any) => ({
          ...item,
          tco2e: item.tcO2e
        }))
      } as CarbonFootprintData
    });
}

export const getProgressStats = (netZeroPlanId: number, companyId: string): Promise<ProgressStats> => {
  return dedupeFetch(`${API_STATISTICS}/GetProgressSoFar?netZeroPlanId=${netZeroPlanId}&companyId=${companyId}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapProgressStats(res.payload);
    });
}

export const getProgressValues = (netZeroPlanId: number, companyId: string): Promise<ProgressAPIPayload> => {
  return dedupeFetch(`${API_STATISTICS}/GetProgressSoFar?netZeroPlanId=${netZeroPlanId}&companyId=${companyId}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return res.payload as ProgressAPIPayload;
    });
}

export const getActionsByDepartment = (netZeroPlanId: number, companyId: string): Promise<ActionGroups> => {
  return fetch(`${API_STATISTICS}/GetDepartments?netZeroPlanId=${netZeroPlanId}&companyId=${companyId}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapActionGroupStats(res.payload);
    });
}

export const getActionsSummary = (netZeroPlanId: number, companyId: string): Promise<StatusCount> => {
  return fetch(`${API_STATISTICS}/GetActionsSummary?netZeroPlanId=${netZeroPlanId}&companyId=${companyId}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return res.payload as StatusCount;
    });
}

export const getReductionStats = (netZeroPlanId: number, companyId: string): Promise<ReductionStats> => {
  return dedupeFetch(`${API_STATISTICS}/GetReductionStats?netZeroPlanId=${netZeroPlanId}&companyId=${companyId}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return res.payload as ReductionStats;
    });
}

export const getReductionChartData = (netZeroPlanId: number, companyId: string): Promise<ReductionChart> => {
  return fetch(`${API_STATISTICS}/GetNetZeroChart?netZeroPlanId=${netZeroPlanId}&companyId=${companyId}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapReductionChart(res.payload);
    });
}

export const getScopeData = (companyId: string): Promise<Scopes> => {
  return fetch(`${API_STATISTICS}/GetLatestScopeData?companyId=${companyId}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return (res.payload as Scopes).sort((a, b) => (a.scope > b.scope ? 1 : -1));
    });
}

export const getReductionImpact = (netZeroPlanId: number, companyId: string, actionId: number, estimatedUptake: number, targetCompletionDate: string): Promise<ReductionStats> => {
  return fetch(`${API_STATISTICS}/CheckActionReductionImpact?netZeroPlanId=${netZeroPlanId}&companyId=${companyId}&actionId=${actionId}&estimatedUptakePercent=${estimatedUptake}&targetCompletionDate=${targetCompletionDate}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return res.payload as ReductionStats;
    });
}

export const getEmissionHotspots = (companyId: string, numResults: number = 9): Promise<Activities> => {
  return dedupeFetch(`${API_STATISTICS}/GetTopNEmissionsFactors?companyId=${companyId}&results=${numResults}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapActivities(res.payload) as Activities;
    });
}