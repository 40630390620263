import './styles.scss';

import { Polygon, StyleSheet, Svg, Text, Tspan } from '@react-pdf/renderer';

import GreenLandscape from '../../images/GreenLandscape.jpg';
import {
  ReportPage,
  ReportImage,
} from '../../../../../../components/PDFReport';
import { ReportPageProps } from '../../../../../../types/Report';
import Colors from '../../../../../../styles/colors';

const styles = StyleSheet.create({
  sectionCoverPage: {
    padding: 0,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  coverImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  infoContainer: {
    width: '50%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: 10,
  },
  svgContainer: {
    height: '100%',
    minWidth: '100%',
    position: 'relative',
    top: 0,
    left: 0
  },
  headerText: {
    fontSize: 14,
    fontFamily: 'Times-Roman',
    fill: Colors.text.brand
  },
  bodyText: {
    fontSize: 5,
    fontFamily: 'Verdana',
    fill: '#ffffff',
  }
});

const LEFT_TRIANGLE_POINTS = "0,110 0,210 297,210 297,190";
const RIGHT_POLYGON_POINTS = "297,0 297,190 110,140 240,0";

const getSvgTextArray = (text: string, maxLength: number = 40): string[] => {
  const stringArray: string[] = [];
  const wordArray = text.split(' ');
  let textLine = '';

  wordArray.forEach((item, index) => {
    let testText = `${textLine} ${item}`;

    if (testText.length > maxLength) {
      stringArray.push(textLine.trim());
      testText = item;
    }
    
    if (index === wordArray.length - 1) {
      stringArray.push(testText);
    }
  
    textLine = testText;
  })

  return stringArray;
}

export const SectionCoverPage: React.FC<ReportPageProps> = ({
  page,
  className,
  downloadVersion = false,
  editable
}) => {
  const imageSrc = page.details[0].imageUrl || GreenLandscape;
  const imageAlt = page.details[0].imageUrl ? 'Section Cover Image' : 'Hills';
  const subtitle = page.pageSubTitle;

  const subtitleArray = getSvgTextArray(subtitle);

  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`section-cover-page ${className}`}
      style={styles.sectionCoverPage}
      downloadVersion={downloadVersion}
      editable={editable}
    >
      <ReportImage
        className="cover-image"
        style={styles.coverImage}
        src={imageSrc}
        alt={imageAlt}
        downloadVersion={downloadVersion}
      />
      { downloadVersion ? (
        <Svg viewBox="0 0 297 210" style={styles.svgContainer}>
          <Polygon style={{ fill: 'white', opacity: 0.7 }} points={LEFT_TRIANGLE_POINTS} />
          <Polygon style={{ fill: 'black', opacity: 0.7 }} points={RIGHT_POLYGON_POINTS} />
          <Text x="95%" y="48%" textAnchor="end" style={styles.headerText}>
            <Tspan>{page.pageTitle}</Tspan>
          </Text>
          <Text x="95%" textAnchor="end" style={styles.bodyText}>
            {subtitleArray.map((item, index) => (
              <Tspan x="95%" y={`${index * 3.5 + 56}%`}>
                {item}
              </Tspan>
            ))}
          </Text>
        </Svg>
      ) : (
        <svg viewBox="0 0 297 210" style={styles.svgContainer}>
          <polygon style={{ fill: 'white', opacity: 0.7 }} points={LEFT_TRIANGLE_POINTS} />
          <polygon style={{ fill: 'black', opacity: 0.7 }} points={RIGHT_POLYGON_POINTS} />
          <text y="45%" textAnchor="end" style={styles.headerText}>
            <tspan x="95%">{page.pageTitle}</tspan>
            <tspan style={styles.bodyText}>
              {subtitleArray.map((item, index) => (
                <tspan x="95%" dy={index === 0 ? "3em" : "1.25em"}>
                  {item}
                </tspan>
              ))}
            </tspan>
          </text>
        </svg>
      )}
    </ReportPage>
  );
};
