import './styles.scss';

import { ReactNode } from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FONT_FAMILY } from '../../../styles/font';

interface ReportUlProps {
  id?: string;
  className?: string;
  style?: Style;
  downloadVersion?: boolean;
  children: string | string[] | number | ReactNode | ReactNode[];
}

const styles = StyleSheet.create({
  ul: {
    fontFamily: FONT_FAMILY,
    fontSize: 11,
    display: 'flex',
    flexDirection: 'column',
    gap: 12.5,
  },
});

const ReportUl: React.FC<ReportUlProps> = ({
  className = '',
  id,
  children,
  style = {},
  downloadVersion = false,
}) => {
  if (downloadVersion) {
    return (
      <View id={id} style={[styles.ul, style]}>
        {children}
      </View>
    );
  }

  return (
    <ul id={id} className={`report-ul ${className}`}>
      {children}
    </ul>
  );
};

export default ReportUl;
