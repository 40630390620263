import './styles.scss';

import React from 'react';
import { CustomIcon, Tag, TagProps } from '@flotilla/component-library';

interface CustomActionTagProps extends Omit<TagProps, "children"> {
  showLabel?: boolean;
};

const CustomActionTag: React.FC<CustomActionTagProps> = ({className, showLabel, ...props}) => (
  <Tag
    {...props}
    className={`tag custom-action-tag ${showLabel ? "custom-action-tag--show-label" : ""} ${className}`}
    title="Custom Action"
    icon={<CustomIcon id="custom-action" fill="#FBFAF8"/>}
    >
    {showLabel ? "Custom Action" : ""}
  </Tag>
);

export default CustomActionTag;
