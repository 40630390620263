import './styles.scss';

import React, { MouseEventHandler, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CloseIcon, DeleteIcon, IconButton, Menu, MenuItem, MoreIcon, PencilIcon, Skeleton, Tab, Tabs, Tag, useAlert } from '@flotilla/component-library';

import { ReactComponent as FlagIcon } from '../../../../assets/icons/Flag.svg';
import { getAction } from '../../../../api/netZeroPlan';
import { updateAction } from '../../../../api/netZeroPlan';
import { Action } from '../../../../types/Action';
import { statusType } from '../../../../types/NetZeroPlan';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import { getNetZeroPlanId } from '../../../../reducers/user';
import { clearAlerts } from '../../../../reducers/alerts';
import AddTag from '../../../../components/AddTag';
import ImpactAreaTag from '../../../../components/ImpactAreaTag';
import NetZeroPlanActionUpdater from '../../../../components/NetZeroPlanActionUpdater';
import DetailsTab from './TabContent/Details';  
import { useCompanyId, useCompanyType, useUserAccess } from '../../../../context';
import { User } from '../../../../types/User';
import AssignedToInput from '../../../../components/AssignedToInput';
import ChecklistTab from './TabContent/Checklist';
import Header from '../../../../components/HeaderV2';
import ScopeTag from '../../../../components/ScopeTag';
import EmployeeSuggestedTag from '../../../../components/EmployeeSuggestedTag';
import QuickWinTag from '../../../../components/QuickWinTag';
import ConfirmActionDeleteModal from './ConfirmActionDeleteModal';
import CustomActionTag from '../../../../components/CustomActionTag';
import RelatedActionsTab from './TabContent/RelatedActions';
import { addModal, removeModal } from '../../../../reducers/modal';
import RecommendActionModal from '../../../../components/Modal/RecommendActionModal';
import RecommendedTag from '../../../../components/RecommendedTag';
import isParentCompany from '../../../../helpers/isParentCompany';
import { recommendSingleAction } from '../../../../api/Action';
import { useCompany } from '../../../../hooks';

interface ActionDetailProps {
  className?: string;
};

const ActionDetail: React.FC<ActionDetailProps> = ({
  className = "",
}) => {
  const { addAlert } = useAlert();
  const { userAccess } = useUserAccess();
  const { NET_ZERO_PLAN_EDIT, CAN_RECOMMEND } = userAccess;
  const dispatch = useAppDispatch();
  const { id: paramId } = useParams();
  const id = Number(paramId);
  const netZeroPlanId = useAppSelector(getNetZeroPlanId);
  const companyId = useCompanyId();
  const [company] = useCompany(companyId);
  const companyType = useCompanyType();
  const navigate = useNavigate();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [detail, setDetail] = useState<Action>();
  const [status, setStatus] = useState(statusType[0]);
  const [targetDate, setTargetDate] = useState<string>(new Date().toString());
  const [currentUptake, setCurrentUptake] = useState<number | undefined>(0);
  const [targetUptake, setTargetUptake] = useState<number | undefined>(0);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [tagList, setTagList] = useState<string[]>();
  const [deleting, setDeleting] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [loading, setLoading] = useState(false);


  const handleGetAction = () => {
    setIsPageLoading(true);
    setDetail(undefined);
    id && netZeroPlanId && companyId && getAction(id, netZeroPlanId, companyId)
      .then((res) => setDetail(res))
      .finally (() => setIsPageLoading(false));
  }

  useEffect(() => {
    handleGetAction();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, companyId, netZeroPlanId]);

  useEffect(() => {
    if (detail?.netZeroPlanAction) {
      const {
        targetCompletionDate,
        actualUptakePercent = 0,
        estimatedUptakePercent = 0,
        status,
        tags,
        user,
        userId
      } = detail?.netZeroPlanAction;
      setStatus(status);
      setTargetDate(targetCompletionDate.split("T")[0]);
      setCurrentUptake(actualUptakePercent);
      setTargetUptake(estimatedUptakePercent);
      setTagList(tags);
      setSelectedUser(user || (selectedUser && userId === selectedUser.id ? selectedUser : undefined));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail]);

  const inPlan = (): boolean => {
    return detail?.netZeroPlanAction !== undefined;
  }

  const handleSaveActionClick = async (updatedTagList?: string[]) => {
    if (detail?.netZeroPlanAction?.id && netZeroPlanId) {
      await updateAction({
        ...detail.netZeroPlanAction,
        netZeroPlanId,
        status,
        targetCompletionDate: targetDate,
        actualUptakePercent: currentUptake || 0,
        estimatedUptakePercent: targetUptake || 0,
        tags: updatedTagList || tagList,
        userId: selectedUser?.id || detail.netZeroPlanAction?.userId
      }, companyId)
      .then((res) => {
        setDetail({...detail, netZeroPlanAction: res });
        dispatch(clearAlerts());
      })
      .catch((error) => {
        addAlert({
          id: `Updating Action ${detail.id}`,
          type: 'error',
          title: 'Error Updating Action',
          content: error.toString(),
        });
        console.log("Error, when updating Action", error);
      });
    }
  }

  const handleRemoveTag = (name: string) => {
    return () => {
      const updatedTagList = tagList?.filter((item) => item !== name);
      setTagList(updatedTagList);
      handleSaveActionClick(updatedTagList);
    }
  }

  const handleAddTag = (value: string) => {
    const updatedTagList = [...(tagList || []), value];
    setTagList(updatedTagList);
    handleSaveActionClick(updatedTagList);
  }

  const handleChangeAssigned = async (user: User) => {
    if (detail?.netZeroPlanAction?.id && netZeroPlanId) {
      await updateAction({
        ...detail.netZeroPlanAction,
        netZeroPlanId,
        status,
        targetCompletionDate: targetDate,
        actualUptakePercent: currentUptake || 0,
        estimatedUptakePercent: targetUptake || 0,
        tags: tagList,
        userId: user.id
      }, companyId)
      .then((res) => {
        setDetail({...detail, netZeroPlanAction: res });
        dispatch(clearAlerts());
        setSelectedUser(user);
      })
      .catch((error) => {
        addAlert({
          id: `Updating Action Assignee ${detail.id}`,
          type: 'error',
          title: 'Error Updating Action Assignee',
          content: error.toString(),
        });
        console.log("Error, when updating Action Assignee", error);
      });
    }
  }
  
  const handleActionUpdate = (action?: Action) => {
    if (action) {
      setDetail(action);
    } else {
      window.location.reload();
    }
  }

  const handleMoreClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    !showMenu && setShowMenu(true);
  }

  const handleOnClickRecommend = async () => {
    if (detail) {
      setShowMenu(false);
      if (isParentCompany(companyType)) {
        dispatch(addModal({
          id: 'recommend-action',
          children: (
            <RecommendActionModal
              companyId={companyId}
              data={detail}
              onClose={(change) => {
                change && handleGetAction();
                dispatch(removeModal());
              }}
            />
          )
        }));
      } else {
        setLoading(true);
        await recommendSingleAction({
          actionId: detail.id,
          recommendingCompanyId: company.parentId,
          companyId: company.id,
          recommend: !detail.recommended
        })
          .then(() => {
            handleGetAction();
            addAlert({ id: `Recommend Action Success`, type: "success", title: "Successfully recommended action" });
          })
          .catch(() => {
            addAlert({ id: `Recommend Action Failed`, type: "error", title: "Failed to recommend action" });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }

  return (
    <section id="action-detail-page" className={className}>
      {deleting && detail?.id && <ConfirmActionDeleteModal action={detail} onClose={() => setDeleting(false)}/>}
      <Header
        showBackButton
        rightChildren={(scrolled) =>
          <>
            { scrolled && inPlan() && (
              <AssignedToInput
                className="assigned"
                onChange={handleChangeAssigned}
                selected={selectedUser}
              />
            )}
              <section className='action-buttons'>
                { NET_ZERO_PLAN_EDIT && detail?.companyId &&
                  <>
                    <IconButton 
                      icon={<PencilIcon stroke='#E35924' strokeWidth={'15px'} />}
                      label='Edit Action'
                      onClick={() => navigate(`${parseInt(companyId) ? `/${companyId}` : ""}/netzeroplan/actionlibrary/${detail?.id}/edit`)}
                      variant='ghost'
                    />
                    <IconButton 
                      icon={<DeleteIcon stroke='#E35924'/>}
                      label='Delete Action'
                      onClick={() => { setDeleting(true) }}
                      variant='ghost'
                    />
                  </>
                }
                { CAN_RECOMMEND && (
                  <>
                    <IconButton 
                      icon={<MoreIcon variant='dark' />}
                      label='More Action Options'
                      aria-label="More"
                      variant='ghost'
                      onClick={handleMoreClick}
                      isLoading={loading}
                    />
                    { showMenu && (
                      <Menu
                        className="list-item-menu"
                        onClose={() => setShowMenu(false)}
                      >
                        <MenuItem onClick={handleOnClickRecommend}>
                          { detail?.recommended ? 'Unrecommend' : 'Recommend' }
                        </MenuItem>
                      </Menu>
                    )}
                  </>
                )}
              </section>
          </>
        }
        isLoading={isPageLoading}
      >
        { detail?.quickWin && <QuickWinTag /> }<h4>{detail?.title}</h4>
      </Header>
      <section id="tags-container">
        <section id="tags">
          { inPlan() && (
            <AssignedToInput
              className="assigned"
              onChange={handleChangeAssigned}
              selected={selectedUser}
            />
          )}
          <section id="tags-scroll">
            {detail?.companyId && <CustomActionTag />}
            {detail?.recommended && <RecommendedTag />}
            {detail?.employeeSuggested && <EmployeeSuggestedTag />}
            {detail?.scope?.name && (
              <ScopeTag>
                {detail.scope?.name}
              </ScopeTag>
            )}
            { detail?.defaultPriority && (
              <Tag
                id="priority-tag"
                icon={<FlagIcon />}
              >
                {`Priority ${detail?.defaultPriority}`} 
              </Tag>
            )}
            {detail?.impactArea && (
              <ImpactAreaTag
                id="impact-area"
                extraText={detail?.activityName}
              >
                {detail?.impactArea.name}
              </ImpactAreaTag>
            )}
            {
              detail?.actionTags && detail.actionTags.map((item, index) => (
                <Tag
                  key={index}
                  className="tag action-tag"
                >
                  {item}
                </Tag>
              ))
            }
            {
              inPlan() && tagList && tagList.map((item, index) => (
                <Tag
                  key={index} 
                  className="tag user-tag"
                  icon={NET_ZERO_PLAN_EDIT ? <CloseIcon variant='dark' onClick={handleRemoveTag(item)} width="12px" height="12px" strokeWidth={1.6}/> : undefined}
                >
                  {item}
                </Tag>
              ))
            }
          </section>
        </section>
        {
          NET_ZERO_PLAN_EDIT && inPlan() && (
            <AddTag
              addTag={handleAddTag}
            />
          )
        }
      </section>
      <main>
        { detail && (
          <>
            <NetZeroPlanActionUpdater action={detail} className="action-updater" onSubmit={handleActionUpdate}/>
            <Tabs key={detail.id} id="tabs">
              <Tab title="Detail" id="detail-tab" className="tab-container">
                <DetailsTab data={detail} onChange={setDetail} />
              </Tab>
              { inPlan() ?
                <Tab title="Checklist" id="checklist-tab" className="tab-container">
                  <ChecklistTab data={detail} onChange={setDetail} />
                </Tab>
                : undefined
              }
              <Tab title="Support" id="support-tab" className="tab-container">
                {detail?.flotillaWorldUrl ? (
                  <iframe id="document" src={detail?.flotillaWorldUrl} title="document" />
                ) : (
                  <p>At the moment we have no documentation on this item. Please feel free to use our Assistant in the bottom right corner for more information.</p>
                )}
              </Tab>
              {detail && ((detail?.parentActions?.length || 0) > 0 || (detail?.childActions?.length || 0) > 0) ?
                <Tab title="Related actions" id="related-actions-tab" className="tab-container">
                  <RelatedActionsTab data={detail} />
                </Tab>
                : undefined
              }
            </Tabs>
          </>
        )}
        { isPageLoading && !detail && (
          <>
            <Skeleton id="tabs" />
            <Skeleton className="action-updater" />
          </>
        )}
      </main>
    </section>
  );
}

export default ActionDetail;
