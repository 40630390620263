import { Actions, mapActions, ScopeCategory } from "./Action";
import { StatusCount } from "./StatusCount";
import { FocusGroups, mapFocusGroups } from "./FocusGroup";

export const impactAreas = ["Travel & Logistics", "Energy", "Facilities", "Supply chain", "Governance"];
export type ImpactAreaType = typeof impactAreas[number];

export const mapImpactAreas = (data: {[key: string]: unknown}[]): ImpactAreas => {
  return data.map((item:  {[key: string]: unknown | unknown[] | any}) => ({
    ...item,
    statusCount: item.statusCounts,
    actionsTotal: item.actions?.length,
    tco2e: item.tcO2e,
    impactArea: item.name,
    ...(item?.actions ? {actions: mapActions(item.actions as {[key: string]: unknown}[])} : {}),
    ...(item?.focusGroups ? {children: mapFocusGroups(item.focusGroups as {[key: string]: unknown}[], item.name)} : {}),
  })) as unknown[] as ImpactAreas;
}

export const getFakeImpactAreas = (): ImpactAreas => {
  return impactAreas.map((impactArea, index) => ({
    id: index,
    name: impactArea
  })) as unknown[] as ImpactAreas;
}

export type ImpactAreaGroupType = {
  id: number,
  name: string,
  statusCount?: StatusCount,
  children?: ImpactAreaGroupsType,
  actions?: Actions,
  actionsTotal?: number,
  tco2e?: number,
  totalPercent?: number,
  tcO2eForComparisonYear?: number,
  totalPercentForComparisonYear?: number,
  value?: number,
  reducedPercent?: number,
  measure?: string,
  impactArea?: string,
  focusGroup?: string,
  activity?: string,
  plannedEstimatedTCo2EReduction?: number,
  estimatedReductionPercent?: number,
  scopeCategory?: ScopeCategory,
  type?: string,
  locationId?: number
}

export interface ImpactArea extends Omit<ImpactAreaGroupType, 'children'> {
  scopeId?: any,
  scope?: any,
  children?: FocusGroups
}

export interface CarbonFootprint {
  impactArea: string,
  tco2e: number
}

export interface CarbonFootprintData {
  year: number,
  carbonFootprints: CarbonFootprints
}

export type CarbonFootprints = CarbonFootprint[];
export type ImpactAreas = ImpactArea[];
export type ImpactAreaGroupsType = ImpactAreaGroupType[];