import { ComputedBarDatum } from "@nivo/bar";
import { Datum } from "@nivo/line";
import { Axes } from "@nivo/axes";
import { computeXYScalesForSeries } from "@nivo/scales";
import { line } from "d3-shape";
import numberFormatter from "../../../../../../../../helpers/numberFormatter";
import { FONT_FAMILY } from "../../../../../../../../styles/font";

type LineProps = {
    bars: ComputedBarDatum<any>[];
    xScale: any;
    yScale: any;
};

export const getTurnoverLine = (revenueData: Datum[], chartWidth: number, chartHeight: number, downloadVersion: boolean): React.FC<LineProps> => {
    return ({ bars, xScale }) => {
      const scale = computeXYScalesForSeries(
        [
          {
            id: "only",
            data: revenueData.map((it) => ({ x: it.xLabel, y: it.value }))
          }
        ],
        { type: "linear" },
        { type: "linear" },
        chartWidth,
        chartHeight
      );
  
      const lineGenerator = line()
        .x((bar) => bar[0])
        .y((bar) => bar[1]);
  
      const lineGeneratorValues: [number, number][] = revenueData
        .map((item) => {
          var yValue = item.value;
          return [
            xScale(item.xLabel) + ((bars[0]?.width ?? 0) / 2),
            yValue > -1 ? scale.yScale(yValue) : -1
          ] as [number, number]
        }).filter(p => p[1] !== -1);
  
      return (
        <>
          <Axes
            yScale={scale.yScale}
            xScale={xScale}
            width={chartWidth}
            height={chartHeight}
            right={{
              ticksPosition: "after",
              tickSize: 0,
              legend: '£m Revenue',
              legendPosition: 'middle',
              legendOffset: downloadVersion ? 40 : 50,
              format: e => e === 0 ? 0 : numberFormatter(e as number)
            }}
          />
          <path
            d={lineGenerator(lineGeneratorValues) || undefined}
            fill="none"
            stroke={'#254A5C'}
            strokeDasharray="4"
            style={{ pointerEvents: 'none', fontFamily: FONT_FAMILY }}
          />
          {revenueData.map((bar) => {
            const value = bar.value;
            return (
              value >= 0 && <circle
                key={bar.xLabel}
                cx={xScale(bar.xLabel) + ((bars[0]?.width ?? 0) / 2)}
                cy={scale.yScale(value)}
                r={4}
                fill="#254A5C"
                stroke="#254A5C"
                style={{ fontFamily: FONT_FAMILY }}
              />
            )
          })}
        </>
      );
    };
  };