import './styles.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table } from '@flotilla/component-library';

import { Action, Actions } from '../../../../../types/Action';
import TableRow from './TableRow';

interface TableViewProps {
  className?: string;
  data?: Actions;
  loading?: boolean;
  onClick?: (action: Action) => void;
  headers: any[];
};

const TableView: React.FC<TableViewProps> = ({
  className = "",
  data,
  onClick = () => ({}),
  loading = false,
  headers,
}) => {
  const navigate = useNavigate();

  const handleClickAction = (action: Action): React.MouseEventHandler<HTMLTableRowElement> => {
    return (event) => {
      event.stopPropagation();
      onClick(action);
    }
  }

  return (
    <section className={className}>
      <Table
        id="project-plan-table"
        className="image-download-content"
        isLoading={loading}
        stickyHeader
        headers={headers}
        data={data as unknown as { [key: string]: string }[]}
        rowComponent={(item, headers) => (
          <TableRow
            key={(item as unknown as Action).id}
            data={item as unknown as Action}
            headers={headers || []}
            onClick={handleClickAction(item as unknown as Action)}
          />
        )}
      />
      {
        !loading && !((data?.length || 0) > 0) && (
          <section id="no-actions-comment">
            {
              (data?.length || 0) > 0 ? (
                <>
                  <h2>No Actions meet this criteria</h2>
                  <p>Currently there are no Actions in your plan that meet the filters set.</p>
                </>
              ) : (
                <>
                  <h2>No Actions in your Plan</h2>
                  <p>Currently there are no Actions in your plan, please try adding them from the Action Library</p>
                </>
              )
            }
            <Button onClick={() => navigate('../actionlibrary')}>
              Action Library
            </Button>
          </section>
        )
      }
    </section>
  );
}

export default TableView;
