import './styles.scss';

import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Card, CircularProgress, IconButton, PadlockIcon, Skeleton, Tooltip, useAlert } from '@flotilla/component-library';

import { useCompanyId, useUserAccess } from '../../../context';
import { ReactComponent as ChevronLeftIcon } from '../../../assets/icons/ChevronLeft.svg';
import { getProgressValues } from '../../../api/Statistics';
import { ProgressAPIPayload } from '../../../types/ProgressStat';
import { useAppSelector } from '../../../helpers/hooks';
import { getNetZeroPlanId } from '../../../reducers/user';
import numberFormatter, { numberLocaleFormatter } from '../../../helpers/numberFormatter';
import { LOCKED_NET_ZERO_WIDGET_TOOLTIP } from '../../../assets/content/Tooltips';

interface NetZeroSummaryProps {
  className?: string;
};

const NetZeroSummary: React.FC<NetZeroSummaryProps> = ({
  className = ""
}) => {
  const { addAlert } = useAlert();
  const { userAccess } = useUserAccess();
  const { ADMIN, STANDARD, NET_ZERO_PLAN_EDIT } = userAccess;
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const netZeroPlanId = useAppSelector(getNetZeroPlanId);
  const [isLoading, setIsLoading] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [data, setData] = useState<ProgressAPIPayload>();

  useEffect(() => {
    if (netZeroPlanId && companyId) {
      setIsLocked(false);
      setIsLoading(true);
      getProgressValues(netZeroPlanId, companyId)
        .then((res) => {
          setData(res);
        })
        .catch((error) => {
          if (error.cause.noReportData) {
            setIsLocked(true);
          } else {
            addAlert({
              id: `Getting progress data ${netZeroPlanId}`,
              type: 'error',
              title: 'Error Getting Progress Data',
              content: 'Something went wrong, please try again by refreshing the page.',
            });
            console.log('Error while getting Progress Data: ', error);
          }
        })
        .finally(() => setIsLoading(false));
    } else if (!netZeroPlanId) {
      setIsLocked(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, netZeroPlanId]);

  const handleTitleClick = () => {
    if (!isLocked && NET_ZERO_PLAN_EDIT) {
      if (!STANDARD || ADMIN) {
        navigate(`/${companyId || 0}/netzeroplan`);
      } else {
        navigate(`/netzeroplan`);
      }
    }
  }

  const getPlannedOfTargetValue = () => {
    return numberFormatter(Math.abs(data?.reductionStats.plannedPercent || 0) / Math.abs(data?.reductionStats.targetPercent || 1) * 100);
  }

  return (
    <Card
      id="net-zero-summary-widget"
      className={`${isLocked ? 'net-zero-summary-widget--locked' : ''} ${className}`}
    >
      <main>
        <header onClick={handleTitleClick}>
          <h3>Net Zero</h3>
          { isLocked ? (
            <Tooltip icon={PadlockIcon} tooltipText={LOCKED_NET_ZERO_WIDGET_TOOLTIP} />
          ) : (
            NET_ZERO_PLAN_EDIT == true ? <IconButton
              icon={<ChevronLeftIcon style={{transform: 'rotate(180deg)'}} />}
              variant='ghost'
              small
            /> : <></>
          )}
        </header>
        <section className="stat-container">
          <article id="target-year-stat" className="stat">
            <h4>{data?.netZeroYear || 0}</h4>
            <p>Target Year</p>
          </article>
          <article id="target-reduction-stat" className="stat">
            <h4>{numberLocaleFormatter(Math.abs(numberFormatter(data?.reductionStats.targetPercent)))}%</h4>
            <p>Annual Target Reduction</p>
          </article>
          <article id="planned-reduction-stat" className="stat">
            <h4>{numberLocaleFormatter(numberFormatter(data?.reductionStats.plannedPercent))}%</h4>
            <p>Annual Planned Reduction</p>
          </article>
        </section>
      </main>
      { isLoading ? (
        <Skeleton circle className="progress--loading" />
      ) : (
        <article className="progress-container">
          <CircularProgress
            score={getPlannedOfTargetValue()}
            centerValue={`${numberLocaleFormatter(getPlannedOfTargetValue() > 100 ? 100 : getPlannedOfTargetValue())}%`}
          />
          <p>% Planned Of Target</p>
        </article>
      )}
    </Card>
  );
}

export default NetZeroSummary;
