import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { CompanyTasks } from "../../types/CompanyTask";
import { getCompanyTasks } from "../../api/CompanyTask";
import { useCompanyId } from "../../context";

const useCompanyTasks = () => {
  const { companyId: paramCompanyId = "0" } = useParams();
  const companyId = useCompanyId();
  const [loading, setLoading] = useState(true);
  const [companyTasks, setCompanyTasks] = useState<CompanyTasks>();

  const fetchApi = useCallback(async () => {
    setLoading(true);
    getCompanyTasks(companyId === "0" ? paramCompanyId : companyId)
      .then((res) => {
        res && setCompanyTasks(res.sort((a, b) => a.id - b.id));
      })
      .finally(() => setLoading(false));
  }, [companyId, paramCompanyId]);

  const refreshCompanyTasks = () => {
    fetchApi();
  }

  useEffect(() => {
    fetchApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    companyTasks,
    refreshCompanyTasks,
    loading
  };
}

export default useCompanyTasks;
