import './styles.scss';

import React, { Fragment, useEffect, useState } from 'react';
import { Divider } from '@flotilla/component-library';

import NotificationListItem from './NotificationListItem';
import { Notifications } from '../../../types/Notification';
import { getAllNotifications } from '../../../api/notification';

interface NotificationListProps {
  id?: string;
  className?: string;
};

const NotificationList: React.FC<NotificationListProps> = ({
  id = "",
  className = ""
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState<Notifications>();

  const getNotifications = () => {
    setIsLoading(true);
    getAllNotifications()
      .then((res) => {
        setNotifications(res.slice(0, 5));
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <section id={id} className={`notification-list-container ${className}`}>
      <header>
        <h3>Notifications</h3>
      </header>
      <article className="customer-list">
        {
          isLoading ? (
            Array.from(Array(3))?.map((skeleton, index) => (
              <Fragment key={index}>
                <NotificationListItem
                  isLoading
                />
                {
                  index < 2 &&
                    <Divider />
                }
              </Fragment>
            ))
          ) : (
            notifications?.map((notification, index) => {
              const {
                title,
                details,
                dateCreated
              } = notification;
              const titleArray = title.split(" - ");
              return (
                <Fragment key={index}>
                  <NotificationListItem
                    title={`${titleArray[1]}, ${titleArray[2]}`}
                    subtitle={titleArray[0].replaceAll("[", "").replaceAll("]", "")}
                    content={details}
                    timestamp={dateCreated}
                  />
                  {
                    index < (notifications.length - 1) &&
                      <Divider />
                  }
                </Fragment>
              )
            })
          )
        }
      </article>
    </section>
  );
}

export default NotificationList;
