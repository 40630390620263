import './styles.scss';

import { ChangeEventHandler, FC, MutableRefObject, useRef, useState } from 'react';
import { TextInput } from '@flotilla/component-library';

import { ReactComponent as CameraIcon } from '../../../../../../assets/icons/Camera.svg';
import { ReactComponent as PersonIcon } from '../../../../../../assets/icons/Person.svg';
import { SectionProps } from '../../../../../../types/CreateWizard';
import { useCompanyLogo } from '../../../../../../hooks';
import { useCompanyId } from '../../../../../../context';

const ExtrasSection: FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const companyLogoInputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const companyId = useCompanyId();
  const [companyUrl, setCompanyUrl] = useState<string>(values?.["url"]);
  const [companyLogo, setCompanyLogo] = useCompanyLogo(companyId);

  const handleLogoFileChange: React.ChangeEventHandler<HTMLInputElement> = (
      event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileUploaded = event.target.files && event.target.files[0];
    if (fileUploaded) {
      setCompanyLogo(fileUploaded);
    }
  };

  const handleEditAvatarClick = () => {
    companyLogoInputRef && companyLogoInputRef.current.click();
  };

  const handleOnChangeUrl: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    setCompanyUrl(value);
    onChange({ url: value });
  }


  return (
    <section id="extras-section" className={className}>
      <section id="edit-avatar" onClick={handleEditAvatarClick}>
        { companyLogo ? (
          <img
            id="company-logo"
            src={companyLogo}
            alt={`Company logo`}
          />
        ) : (
          <PersonIcon
            aria-label="Person Icon"
            id="person-icon"
          />
        )}
        <CameraIcon
          aria-label="Camera Icon"
          id="camera-icon"
        />
        <input
          ref={companyLogoInputRef}
          id="logo-file"
          type="file"
          onChange={handleLogoFileChange}
          accept="image/*"
        />
      </section>
      <section id="company-url">
        <TextInput
          id="company-url-input"
          label="Company URL"
          aria-label='Company url name'
          value={companyUrl || ""}
          onChange={handleOnChangeUrl}
          maxLength={50}
        />
        {companyUrl && (
          <a href={companyUrl.startsWith('http') ? companyUrl : `//${companyUrl}`} target='_blank' rel="noreferrer">Test link</a>
        )}
      </section>
    </section>
  );
}

export default ExtrasSection;
