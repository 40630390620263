import { createContext, useContext } from "react";
import { AccessType, USER_ROLE_ACCESS, USER_ROLE_CUSTOMER_READ } from "../../constants";

export type UserAccessContextType = {
  userAccess: AccessType,
  initialUserAccess: AccessType,
  updateUserAccess: (value: AccessType) => void 
}

export const UserAccessContext = createContext<UserAccessContextType>({
  userAccess: USER_ROLE_ACCESS[USER_ROLE_CUSTOMER_READ],
  initialUserAccess: USER_ROLE_ACCESS[USER_ROLE_CUSTOMER_READ],
  updateUserAccess: () => ({})
});

export const useUserAccess = () => useContext(UserAccessContext);