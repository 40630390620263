import { useState } from 'react';
import { ReactComponent as CopyIcon } from '../../assets/icons/Copy.svg';
import './styles.scss';

type CopyButtonProps = {
    text?: string;
};

const CopyButton: React.FC<CopyButtonProps> = ({ text = undefined }) => {
    const [textTimeout, setTextTimeout] = useState<NodeJS.Timeout>();

    const handleClick = (event: React.MouseEvent) => {
        event?.stopPropagation();
        text && navigator.clipboard.writeText(text);

        if(textTimeout) clearTimeout(textTimeout);
        setTextTimeout(setTimeout(() => {
            clearTimeout(textTimeout);
            setTextTimeout(undefined);
        }, 3000));
    }

    return (
        <section className='copy-btn'>
            <CopyIcon
                className="copy-icon"
                aria-label="Copy"
                onClick={handleClick}
            />
            <p className='flotilla-card'>{textTimeout ? "Copied!" : "Copy to clipboard"}</p>
        </section>
    )
}

export default CopyButton;