import { API_TASK } from "../../constants";
import { checkAuthorised, checkErrors } from "../../helpers/checkAuthorisedAPI";
import { Task, mapTasks } from "../../types/Task";

export const createTask = (task: Partial<Task>, companyId: number): Promise<Task> => {
  const {
    netZeroPlanActionId,
    userId,
    title
  } = task;
  return fetch(`${API_TASK}/create`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: JSON.stringify({
      netZeroPlanActionId,
      userId,
      title,
      companyId
    }),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapTasks([res.payload])[0] as Task;
    });
}

export const updateTask = (task: Task, companyId: number): Promise<Task> => {
  const {
    id: taskId,
    userId,
    title,
    completed,
  } = task;
  return fetch(`${API_TASK}/update`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'PUT',
    body: JSON.stringify({
      taskId,
      userId,
      title,
      completed,
      companyId
    }),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapTasks([res.payload])[0] as Task;
    });
}

export const deleteTask = (id: number, companyId: number): Promise<boolean> => {
  return fetch(`${API_TASK}/delete?taskId=${id}&companyId=${companyId}`, {
    headers: { "X-CSRF": "1" },
    method: 'DELETE',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return res.success;
    });
}
