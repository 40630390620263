import { useCallback, useEffect, useState } from "react";
import { useAlert } from "@flotilla/component-library";

import { useAppDispatch } from "../../helpers/hooks";
import { getDataPeriods as getDataPeriodsAPI } from "../../api/DataPeriod";
import { useCompanyId } from "../../context";
import { DataPeriods } from "../../types/DataPeriod";
import useCompany from "../Company/useCompany";

const useDataPeriods = () => {
  const { addAlert } = useAlert();
  const dispatch = useAppDispatch();
  const companyId = useCompanyId();
  const [company] = useCompany(companyId);
  const [loading, setLoading] = useState(true);
  const [dataPeriods, setDataPeriods] = useState<DataPeriods>();

  const fetchApi = useCallback(async () => {
    if (company?.id) {
      setLoading(true);
      await getDataPeriodsAPI(company?.id)
        .then((res) => {
          setDataPeriods(res);
        })
        .catch(() => {
          addAlert({ id: `Data Periods Get Failed ${company?.id}`, type: "error", title: "Get Data Periods Unsuccessful", content: "Failed to get Data Periods please try again." });
        })
        .finally(() => setLoading(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const refreshDataPeriods = () => {
    fetchApi();
  }

  useEffect(() => {
    if (company?.id) {
      fetchApi();
    }
  }, [company, dispatch, fetchApi]);

  return {
    dataPeriods,
    refreshDataPeriods,
    loading
  };
}

export default useDataPeriods;
