import './styles.scss';

import React from 'react';
import { Tag, TagProps, VerifiedIcon } from '@flotilla/component-library';

interface RecommendedTagProps extends Omit<TagProps, "children"> {
  showLabel?: boolean;
};

const RecommendedTag: React.FC<RecommendedTagProps> = ({className, showLabel, ...props}) => (
  <Tag
    {...props}
    className={`tag recommended-tag ${showLabel ? "recommended-tag--show-label" : ""} ${className}`}
    title="Recommended"
    icon={<VerifiedIcon id="recommended" variant='dark' />}
    >
    {showLabel ? "Recommended" : ""}
  </Tag>
);

export default RecommendedTag;
