import { API_MEASURE } from "../../constants";
import { checkAuthorised, checkErrors } from "../../helpers/checkAuthorisedAPI";
import { Measures } from "../../types/Measure";

export const getMeasures = (): Promise<Measures> => {
  return fetch(`${API_MEASURE}/GetAll`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return res.payload;
    });
}
