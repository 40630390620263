import './styles.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { Card, Stat, StatLabel, StatValue, useAlert } from '@flotilla/component-library';

import { ReductionStats as ReductionStatsType } from '../../types/ReductionStats';
import { useAppSelector } from '../../helpers/hooks';
import { getNetZeroPlanId } from '../../reducers/user';
import { getReductionImpact, getReductionStats } from '../../api/Statistics';
import { useCompanyId } from '../../context';
import { ReactComponent as ArrowUpIcon } from '../../assets/icons/ChartArrowUp.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/ChartArrowDown.svg';
import { ReactComponent as MinusCircleIcon } from '../../assets/icons/Minus.svg';

interface ReductionStatsProps {
  className?: string;
  variant?: 'standard' | 'inline';
  showDifferenceIcon?: boolean;
  actionId?: number;
  estimatedUptake?: number;
  targetCompletionDate?: string;
  refresh?: boolean;
  companyId?: string;
};

const ReductionStats: React.FC<ReductionStatsProps> = ({
  className = "",
  variant = 'standard',
  showDifferenceIcon = false,
  actionId,
  estimatedUptake,
  targetCompletionDate,
  refresh = false,
  companyId: initialCompanyId
}) => {
  const { addAlert } = useAlert();
  const netZeroPlanId = useAppSelector(getNetZeroPlanId);
  const companyId = useCompanyId();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<ReductionStatsType>();

  const handleGetActions = useCallback(async () => {
    setIsLoading(true);
    netZeroPlanId && await getReductionStats(netZeroPlanId, initialCompanyId ?? companyId)
      .then((res) => setData(res))
      .catch((error) => {
        if (!error.cause.noReportData) {
          addAlert({
            id: `Getting Reduction Stats ${netZeroPlanId}`,
            type: 'error',
            title: 'Error Getting Reduction Stats',
            content: 'Something went wrong, please try again by refreshing the page.',
          });
          console.log('Error while getting Reduction Stats: ', error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [netZeroPlanId, companyId]);

  const handleGetReductionImpact = () => {
    netZeroPlanId && actionId && estimatedUptake && targetCompletionDate && getReductionImpact(netZeroPlanId, companyId, actionId, estimatedUptake, targetCompletionDate)
      .then((res) => setData(res));
  };

  useEffect(() => {
    handleGetActions();
  }, [handleGetActions]);

  useEffect(() => {
    refresh && handleGetActions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    handleGetReductionImpact();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionId, estimatedUptake, targetCompletionDate]);

  const formatValue = (value?: number): number => {
    if (value) {
      const absValue = Math.abs(value);

      if (absValue < 1) {
        return Math.round(absValue * 100) / 100;
      } else if (absValue < 10) {
        return Math.round(absValue * 10) / 10;
      } else {
        return Math.round(absValue);
      }
    }
    return 0;
  }

  const getDifferenceIcon = () => {
    const planned = data?.plannedPercent || 0;
    const target = data?.targetPercent || 0;
    if (planned >= target) {
      return <ArrowDownIcon id="down-arrow" className="difference-icon" />
    } else if (planned >= (target - 1)) {
      return <MinusCircleIcon id="neutral-arrow" className="difference-icon" />;
    }
    return <ArrowUpIcon id="up-arrow" className="difference-icon" />;
  }

  if (isLoading) {
    return (
      <section id="reduction-stats-container" className={`loading ${variant} ${className}`}>
        <label className='stat-label'>Annual Estimated Reductions</label>
        <section className='stat-row'>
          <Card />
          <Card />
        </section>
      </section>
    )
  }

  return (
    <section id="reduction-stats-container" className={`${variant} ${className}`}>
      <label className='stat-label'>Annual Estimated Reductions</label>
      <section className='stat-row'>
        <Stat id="planned" className="stat">
          <StatValue id="value">
            { showDifferenceIcon && getDifferenceIcon() }
            <span>
              {formatValue(data?.plannedPercent)}
            </span>
          </StatValue>
          <StatLabel>% Planned</StatLabel>
        </Stat>
        <Stat id="target" className="stat">
          <StatValue id="value">{formatValue(data?.targetPercent)}</StatValue>
          <StatLabel>% Target</StatLabel>
        </Stat>
      </section>
    </section>
  );
};

export default ReductionStats;
