import './styles.scss';

import React, { Fragment, useEffect } from 'react';

import { Action } from '../../../../../../types/Action';
import { Divider } from '@flotilla/component-library';
import useTasks from '../../../../../../hooks/NetZeroPlan/useTasks';
import ChecklistItem from './ChecklistItem';

interface ChecklistTabProps {
  data?: Action;
  onChange?: (data?: Action) => void
};

const ChecklistTab: React.FC<ChecklistTabProps> = ({ data, onChange = () => ({}) }) => {
  const {
    tasks,
    addTask,
    updateTask,
    removeTask
  } = useTasks(data?.netZeroPlanAction?.tasks || []);

  useEffect(() => {
    const updatedData = data;
    if(updatedData?.netZeroPlanAction?.tasks) {
      updatedData.netZeroPlanAction.tasks = tasks;
    }
    onChange(updatedData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  return (
    <article id="checklist-tab-content">
      { tasks?.map((item, index) => (
        <Fragment 
          key={index}
        >
          <ChecklistItem
            task={item}
            onCheck={updateTask}
            onSave={updateTask}
            onDelete={removeTask}
          />
          <Divider />
        </Fragment>
      ))}
      { data?.netZeroPlanAction?.id && 
        <ChecklistItem 
          task={{
            id: 0,
            netZeroPlanActionId: data.netZeroPlanAction.id,
            title: '',
            completed: false
          }}
          newItem
          onSave={addTask}
        />
      }
    </article>
  )
}

export default ChecklistTab;
