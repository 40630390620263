import './styles.scss';

import React, { ChangeEventHandler, useState } from 'react';
import { Button, CheckboxButtonV2, Datalist, Label, Skeleton, TextInput, useAlert } from '@flotilla/component-library';

import { editCompanyDetails } from '../../../api/companies';
import useCompanies from '../../../hooks/Company/useCompanies';
import { Company } from '../../../types/Company';
import { Lookup } from '../../../types/Lookup';
import useProductTypes from '../../../hooks/Lookup/useProductTypes';
import useCompanyTypes from '../../../hooks/Lookup/useCompanyTypes';
import isParentCompany from '../../../helpers/isParentCompany';
import { COMPANY_TYPE_STANDARD_ID } from '../../../constants';

interface CreateCustomerModalProps {
  className?: string;
  data: Company;
  onClose: (change?: boolean) => void;
};

interface Option {
  value: string;
  [key: string]: unknown;
}

const EditCustomerModal: React.FC<CreateCustomerModalProps> = ({
  className = "",
  data,
  onClose = () => {},
}) => {
  const { addAlert } = useAlert();
  const {
    companies = []
  } = useCompanies();
  const {
    productTypes = [],
    loading: productTypesLoading
  } = useProductTypes();
  const {
    companyTypes = [],
    loading: companyTypesLoading
  } = useCompanyTypes();
  const [isLoading, setIsLoading] = useState(false);
  const [companyName, setCompanyName] = useState<string | undefined>(data.name);
  const [companyType, setCompanyType] = useState<Lookup | undefined>(data.companyType);
  const [productType, setProductType] = useState<Lookup | undefined>(data.product);
  const [parentCompany, setParentCompany] = useState<Option | undefined>({
    value: data.parentName || '',
    id: data.parentID,
  });

  const handleSubmit = async () => {
    if (companyName) {
      setIsLoading(true);
      await editCompanyDetails({
        ...data,
        name: companyName,
        parentID: Number(parentCompany?.id) || 0,
        companyType,
        product: productType
      })
        .then(() => {
          addAlert({ id: `Edit Company Success`, type: "success", title: "Successfully edited a company" });
          onClose(true);
        })
        .catch((err) => {
          setIsLoading(false);
          if(err?.message.includes('already exists')) {
            addAlert({ id: `Edit Company Failed`, type: "error", title: "Failed to edit company", content: err.message });
          }
          else {
            addAlert({ id: `Edit Company Failed`, type: "error", title: "Failed to edit company", content: "Something went wrong, please try again." });
          }
        });
    }
  };

  const handleOnChangeParent = (option: Option | unknown | undefined) => {
    setParentCompany(option as Option | undefined);
    if (option) {
      setCompanyType({ id: COMPANY_TYPE_STANDARD_ID, label: '' });
    }
  }

  const handleOnChangeName: ChangeEventHandler<HTMLInputElement> = (event) => {
    setCompanyName(event.currentTarget.value);
  }

  const handleOnChangeProductType = (option: Lookup) => {
    return (checked: boolean) => {
      setProductType(checked ? option : undefined);
    }
  }

  const handleOnChangeCompanyType = (option: Lookup) => {
    return (checked: boolean) => {
      setCompanyType(checked ? option : undefined);
      if (companyType?.id === COMPANY_TYPE_STANDARD_ID) {
        setParentCompany(undefined);
      }
    }
  }

  return (
    <section
      id="edit-customer-modal"
      className={`edit-customer-modal ${className}`}
      onClick={((event) => event.stopPropagation())}
    >
      <h4>Edit Customer</h4>
      <main>
        <TextInput
          id="company-name"
          label="Company Name"
          aria-label="Company name"
          value={companyName}
          onChange={handleOnChangeName}
        />
        <section id="product-type" className="option-container">
          <Label>Product type</Label>
          <article id="options">
            { productTypes.map((item, index) => (
              <CheckboxButtonV2
                key={index}
                checked={item.id === productType?.id}
                onToggle={handleOnChangeProductType(item)}
              >
                {item.label}
              </CheckboxButtonV2>
            ))}
            { productTypes.length <= 0 && productTypesLoading && (
              <>
                <Skeleton className="checkbox-loader" />
                <Skeleton className="checkbox-loader" />
                <Skeleton className="checkbox-loader" />
              </>
            )}
          </article>
        </section>
        <section id="company-type" className="option-container">
          <Label>Company type</Label>
          <article id="options">
            { companyTypes.filter(item => item.label.toLowerCase() !== "licensor" && (!parentCompany?.id || item.label.toLowerCase() === 'standard')).map((item, index) => (
              <CheckboxButtonV2
                key={index}
                className="company-type-options"
                checked={item.id === companyType?.id}
                onToggle={handleOnChangeCompanyType(item)}
                disabled={item.id === COMPANY_TYPE_STANDARD_ID && (data.children?.length || 0) > 0}
                tooltipProps={item.id === COMPANY_TYPE_STANDARD_ID && (data.children?.length || 0) > 0 ? {
                  tooltipText: "This type can't be selected as this company has child companies"
                } : undefined}
              >
                {item.label}
              </CheckboxButtonV2>
            ))}
            { companyTypes.length <= 0 && companyTypesLoading && (
              <>
                <Skeleton className="checkbox-loader" />
                <Skeleton className="checkbox-loader" />
                <Skeleton className="checkbox-loader" />
              </>
            )}
          </article>
        </section>
        { companyType?.id === COMPANY_TYPE_STANDARD_ID && (
          <Datalist
            key="parent-company"
            id="parent-company"
            className="parent-company"
            options={companies?.filter((item) => isParentCompany(item.companyType) && item.id !== data.id).map((item => ({ value: item.name, ...item })))}
            onChange={handleOnChangeParent}
            value={parentCompany}
            searchKeys={['value']}
            label='Parent Company'
          />
        )}
      </main>

      <section className="button-container">
        <Button variant="ghost" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} isLoading={isLoading} disabled={!companyName || companyName.trim() === ''} title={(!companyName || companyName.trim() === '') ? 'Company Name must not be empty' : ''}>Save</Button>
      </section>
    </section>
  );
}

export default EditCustomerModal;
