import './styles.scss';

import { FC, FocusEventHandler } from 'react';
import { Button, Datalist, Label, NumberInput, Switch } from '@flotilla/component-library';

import { useConversionFactor, useMeasures } from '../../../hooks';
import { ConversionFactor } from '../../../types/ConversionFactor';
import useImpactAreas from '../../../hooks/Lookup/useImpactAreas';
import { Lookup } from '../../../types/Lookup';

interface ConversionFactorModalProps {
  className?: string;
  onClose: () => void;
  onSubmit?: () => void;
  conversionFactor?: ConversionFactor;
};

const ConversionFactorModal: FC<ConversionFactorModalProps> = ({
  className = "",
  onClose = () => {},
  onSubmit = () => {},
  conversionFactor: _conversionFactor = {
    id: -1,
    activityId: 0,
    measureId: 0,
    isDaily: false,
    dateFrom: '',
    dateTo: '',
    value: 0
  }
}) => {
  const { measures, loading: measuresLoading } = useMeasures();
  const { impactAreas, loading: impactAreasLoading } = useImpactAreas(true);
  const {
    conversionFactor,
    updateConversionFactor,
    saveConversionFactor,
    loading
  } = useConversionFactor(_conversionFactor);

  const handleOnClose = () => {
    onClose();
  };

  const handleOnClickCreate = () => {
    saveConversionFactor()
      .then(onSubmit);
  }

  const handleOnChangeNumber = (key: keyof ConversionFactor) => {
    return (value: number | undefined) => {
      updateConversionFactor({[key]: value});
    }
  }

  const handleOnBlurNumber = (key: keyof ConversionFactor): FocusEventHandler<HTMLInputElement> => {
    return (event) => {
      const initialValue = event.currentTarget.value === '' ? undefined : Number(event.currentTarget.value.split(',').join(''));
      const value = typeof initialValue === "number" && !isNaN(initialValue) ? initialValue : undefined;
      updateConversionFactor({[key]: value});
    }
  }

  const handleOnChangeOption = (key: keyof ConversionFactor, objectKey: keyof ConversionFactor) => {
    return (option: any | undefined) => {
      updateConversionFactor({
        [key]: option?.value || 0,
        [objectKey]: option as Lookup
      });
    }
  }

  const handleOnToggle = (key: keyof ConversionFactor) => {
    return (value: boolean | undefined) => {
      updateConversionFactor({[key]: value});
    }
  }

  return (
    <section
      id="conversion-factor-modal"
      className={className}
    >
      <h4>{_conversionFactor.id === -1 ? 'Create' : 'Update'} Conversion Factor</h4>
      <main>
        <Datalist
          id="activity"
          label="Activity"
          options={impactAreas?.map((item) => ({  ...item, value: String(item.id) })) || []}
          onChange={handleOnChangeOption('activityId', 'activity')}
          value={conversionFactor?.activity ? { label: conversionFactor.activity.name, ...conversionFactor.activity, value: String(conversionFactor.activity.id) } : undefined}
          disabled={impactAreasLoading}
        />
        <Datalist
          id="measure"
          label="Measure"
          options={measures?.map((item) => ({ value: String(item.id), label: item.name, ...item })) || []}
          onChange={handleOnChangeOption('measureId', 'measure')}
          value={conversionFactor?.measure ? {  ...conversionFactor.measure, value: String(conversionFactor.measure.id || 0), label: conversionFactor.measure.name } : undefined}
          disabled={measuresLoading}
        />
        <NumberInput
          id="year"
          label="Year"
          onChange={handleOnChangeNumber('year')}
          value={conversionFactor.year}
          max={new Date().getFullYear()}
          min={2000}
          autoFix
        />
        <NumberInput
          id="value"
          label="Value"
          value={conversionFactor.value}
          onBlur={handleOnBlurNumber('value')}
          financial
          decimal
        />
        <NumberInput
          id="min-value"
          label="Min value"
          value={conversionFactor.minValue}
          onBlur={handleOnBlurNumber('minValue')}
          financial
          decimal
        />
        <NumberInput
          id="max-value"
          label="Max value"
          value={conversionFactor.maxValue}
          onBlur={handleOnBlurNumber('maxValue')}
          financial
          decimal
        />
        <section className="switch-container">
          <Label>Is Daily</Label>
          <Switch
            checked={conversionFactor.isDaily}
            onToggle={handleOnToggle('isDaily')}
            small
          />
        </section>
      </main>
      <section className="button-container">
        <Button variant="ghost" onClick={handleOnClose}>
          Cancel
        </Button>
        <Button
          onClick={handleOnClickCreate}
          disabled={measuresLoading || impactAreasLoading || !(conversionFactor.activityId > 0 && conversionFactor.measureId > 0 && (conversionFactor.year || 0) > 0 && (conversionFactor.value || 0) > 0)}
          isLoading={loading}
        >
          {_conversionFactor.id === -1 ? 'Create' : 'Update'}
        </Button>
      </section>
    </section>
  );
}

export default ConversionFactorModal;
