import { BarCustomLayerProps, BarDatum } from "@nivo/bar";
import { useTooltip } from '@nivo/tooltip';
import { NetZeroPlan } from "../../../../types/NetZeroPlan";
import { line } from 'd3-shape';
import InterimToolTip from "./InterimToolTip";
import './styles.scss';

const getInterimYearLine = (netZeroPlan?: NetZeroPlan) => ({ bars, xScale, yScale }: BarCustomLayerProps<BarDatum>) => {
    if (bars.length === 0) return null;

    const { showTooltipFromEvent, hideTooltip } = useTooltip();
    const x = xScale(netZeroPlan?.interimTargetYear || 0) + bars[0].width / 2;
    const y1 = yScale(0);

    const lineGenerator = line()
        .x((bar) => bar[0])
        .y((bar) => bar[1]);

    const handleMouseEvent = (event: React.MouseEvent<SVGGElement, MouseEvent>) => {
        showTooltipFromEvent(<InterimToolTip plan={netZeroPlan}/>, event);
    };

    const handleMouseLeave = () => {
        hideTooltip();
    };
  
    return (
    <>
        <g key={'interim-year'}
          className="interim-element"
          transform={`translate(${x},-15)`}
          onMouseEnter={handleMouseEvent}
          onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseEvent}>
            <rect
              x={-128 / 2} y={-12}
              width={128} height={20}
              rx={10} ry={10}
              fill="#E2E4E3"
            />
            <text
              textAnchor="middle"
              alignmentBaseline="middle"
              style={{ fill: '#011E26', fontSize: '12px' }}
            >
              Near-term Target
            </text>
        </g>
        <path
          d={lineGenerator([[ x, y1 ], [x, 0]]) || undefined}
          stroke={'#e35924'}
          strokeDasharray="4"
          className="interim-element"
        />
        <path
          d={lineGenerator([[ x + 3, y1 ], [x, 0]]) || undefined}
          stroke={'transparent'}
          strokeWidth={6}
          className="interim-element"
          onMouseEnter={handleMouseEvent}
          onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseEvent}
        />
    </>
  );
};

export default getInterimYearLine;