import './styles.scss';

import { useState } from "react";
import { Button, Modal, useAlert } from "@flotilla/component-library";

import { updateReportHidden } from '../../../../../../api/Report';
import { useCompanyId } from '../../../../../../context';

interface ConfirmVisibilityToggleModalProps {
    onClose: () => void;
    onSuccess: () => void;
    reportId: number;
    hiding: boolean;
  };

const ConfirmVisibilityToggleModal: React.FC<ConfirmVisibilityToggleModalProps> = ({
    onClose = () => {},
    onSuccess = () => {},
    reportId,
    hiding
  }) => {
    const { addAlert } = useAlert();
    const companyId = useCompanyId();
    const [isLoading, setIsLoading] = useState(false);

    const handleOnClickConfirm = () => {
        setIsLoading(true);

        updateReportHidden(reportId, hiding, companyId)
            .then(() => {
                addAlert({
                    id: `Hiding report: ${reportId}`,
                    type: 'success',
                    title: `Success, report has been ${hiding ? "hidden" : "made visible"}!`,
                    content: `You have ${hiding ? "hidden" : "unhidden"} the report.`,
                });
                onSuccess();
            })
            .catch((error) => {
                var actionText = hiding ? "hiding" : "unhiding";
                addAlert({
                    id: `Hiding report: ${reportId}`,
                    type: 'error',
                    title: `Error ${actionText} report`,
                    content: `There seems to be an error when ${actionText} the report, please try again.`,
                });
                console.log(`Error ${actionText} report: `, error);
                setIsLoading(false);
            });
    }

    return (
        <Modal
          id="hide-report-modal"
          onClose={onClose}
        >
            <h2>{hiding ? "Hide" : "Unhide"} Report</h2>
            <main>
                <p>Are you sure you wish to {hiding ? "hide" : "unhide"} this report?</p>
            </main>
            <section className="button-container">
                <Button onClick={handleOnClickConfirm} isLoading={isLoading}>Confirm</Button>
                <Button variant="ghost" onClick={onClose}>
                    Cancel
                </Button>
            </section>
        </Modal>);
}

export default ConfirmVisibilityToggleModal;