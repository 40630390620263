import './styles.scss';

import React, { Fragment, useEffect, useState } from 'react';
import { Card, TextInput, CheckboxButtonV2, CloseIcon } from '@flotilla/component-library';

import { getSDGs } from '../../../../../../api/Lookup';
import { Lookup, Lookups, mapSDGLookups } from '../../../../../../types/Lookup';
import { SDGs, SDTs } from '../../../../../../types/Action';

interface SDGSelectorProps {
  className?: string;
  sdgs?: SDGs;
  onChange?: (sdts?: SDTs) => void,
};

const SDGSelector: React.FC<SDGSelectorProps> = ({
  className = "",
  sdgs,
  onChange = () => ({}),
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [sDGsLookup, setSDGsLookup] = useState<Lookups>();
  const [selectedSDGLookup, setSelectedSDGLookup] = useState<Lookup>();
  const [searchValue, setSearchValue] = useState("");
  const [searchSDGs, setSearchSDGs] = useState<Lookups>([]);
  const [selectedSDGs, setSelectedSDGs] = useState<Lookups>([]);

  useEffect(() => {
    handleGetLookup();
  }, []);

  useEffect(() => {
    setSelectedSDGs(mapSDGLookups(sdgs as unknown as { [key: string]: unknown; }[] || []));
  }, [sdgs]);

  useEffect(() => {
    const selectedSdts: SDTs = [];
    selectedSDGs.forEach((selectedSDG) => {
      selectedSDG?.sublabels?.forEach((selectedSDT) => {
        selectedSdts.push({
          id: selectedSDT.id as number,
          label: selectedSDT.label
        });
      })
    });
    onChange(selectedSdts);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSDGs]);

  const handleGetLookup = () => {
    setIsLoading(true);
    getSDGs()
      .then((res) => {
        setSDGsLookup(res);
      })
      .finally(() => setIsLoading(false));
  };

  const handleCheckboxChange = (item: Lookup): ((checked: boolean) => void) => {
    return (checked: boolean) => {
      if (checked) {
        setSelectedSDGLookup(item);
      } else {
        setSelectedSDGLookup(undefined);
      }
    }
  }

  const toggleSDG = (item: Lookup, parentItem: Lookup, checked: boolean = false) => {
    if (checked) {
      if (selectedSDGs.length < 1) {
        setSelectedSDGs([
          {
            ...parentItem,
            sublabels: [item]
          }
        ]);
      } else if (selectedSDGs.findIndex((value) => value.id === parentItem.id) < 0) {
        setSelectedSDGs([
          ...selectedSDGs,
          {
            ...parentItem,
            sublabels: [item]
          }
        ]);
      } else {
        setSelectedSDGs([
          ...selectedSDGs.map((value) => {
            if (value.id === parentItem.id) {
              return {
                ...value,
                sublabels: [
                  ...(value.sublabels as Lookups),
                  item
                ]
              }
            }
            return value;
          })
        ]);
      }
    } else {
      setSelectedSDGs(selectedSDGs.map((value) => {
        if (value.id === parentItem.id) {
          return {
            ...value,
            sublabels: (value.sublabels as Lookups).filter((childValue) => childValue.id !== item.id)
          }
        }
        return value;
      }));
    }

  }

  const handleSelectSDG = (item: Lookup, parentItem: Lookup): ((checked: boolean) => void) => {
    return (checked: boolean) => {
      toggleSDG(item, parentItem, checked);
    }
  }

  const isSDGSelected = (item: Lookup, parentItem: Lookup): boolean => {
    const parent = selectedSDGs.find((value) => value.id === parentItem.id);

    if (parent) {
      return (parent.sublabels as Lookups).findIndex((value) => value.id === item.id) > -1;
    }

    return false;
  }

  const handleSearchOnChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.currentTarget.value;
    setSearchValue(value);
    let searchedValues: Lookups = [];
    if (value.length > 0) {
      searchedValues = sDGsLookup?.filter((item) => JSON.stringify(item).includes(value)) || [];
      if (searchedValues.length > 0) {
        searchedValues = searchedValues.map((item) => {
          return {
            ...item,
            sublabels: (item.sublabels as Lookups).filter((childItem) => JSON.stringify(childItem).includes(value)) || []
          }
        })
      }
    }
    setSearchSDGs(searchedValues);
  }

  return (
    <section id="sdg-selector" className={className}>
      <h4>SDGs</h4>
      <article className="selected-container">
        {selectedSDGs?.map((selectedSDGGroup, index) => (
          <Fragment key={index}>
            {(selectedSDGGroup.sublabels as Lookups).map((selectedSDG) => (
              <Card
                key={selectedSDG.id}
                className="selected-sdg"
              >
                <img
                  className="sdg-icon"
                  alt={selectedSDGGroup.label}
                  src={(selectedSDGGroup?.imgURL || '') as string}
                />
                <section className="labels">
                  <p>{selectedSDG["targetNumber"] as string || ''}</p>
                  <p id="sub-label" title={selectedSDG.label}>{selectedSDG.label}</p>
                </section>
                <CloseIcon
                  className='close'
                  variant='dark'
                  small
                  onClick={() => toggleSDG(selectedSDG, selectedSDGGroup)}
                />
              </Card>
            ))}
          </Fragment>
        ))}
      </article>
      <TextInput
        id="search-sdg-input"
        placeholder='Search SDGs...'
        value={searchValue}
        onChange={handleSearchOnChange}
      />
      <article className="checkbox-container">
        { !isLoading ? (
          <>
            { selectedSDGLookup ? (
              <>
                <p onClick={() => setSelectedSDGLookup(undefined)}>Back</p>
                {(selectedSDGLookup?.sublabels as Lookups).map((value) => (
                  <CheckboxButtonV2
                    className="sdg-checkbox"
                    key={selectedSDGLookup.id}
                    label={selectedSDGLookup.label}
                    onToggle={handleSelectSDG(value, selectedSDGLookup)}
                    checked={isSDGSelected(value, selectedSDGLookup)}
                  >
                    <img
                      className="sdg-icon"
                      alt={selectedSDGLookup.label}
                      src={(selectedSDGLookup?.imgURL || '') as string}
                    />
                    <section className="labels">
                      <p>{value["targetNumber"] as string || ''}</p>
                      <p id="sub-label" title={value.label}>{value.label}</p>
                    </section>
                  </CheckboxButtonV2>
                ))}
              </>
            ) : (
              searchValue.length > 0 ? (
                <>
                  {searchSDGs.map((searchSDG) => (
                    <Fragment key={searchSDG.id}>
                      {(searchSDG.sublabels as Lookups).map((value) => (
                        <CheckboxButtonV2
                          className="sdg-checkbox"
                          key={value.id}
                          label={searchSDG.label}
                          onToggle={handleSelectSDG(value, searchSDG)}
                          checked={isSDGSelected(value, searchSDG)}
                        >
                          <img
                            className="sdg-icon"
                            alt={searchSDG.label}
                            src={(searchSDG?.imgURL || '') as string}
                          />
                          <section className="labels">
                            <p>{value.label}</p>
                            <p id="sub-label">{searchSDG.label}</p>
                          </section>
                        </CheckboxButtonV2>
                      ))}
                    </Fragment>
                  ))}
                </>

              ) : (
                <>
                  {sDGsLookup?.map((sDGLookup) => (
                    <CheckboxButtonV2
                      className="sdg-checkbox"
                      key={sDGLookup.id}
                      label={sDGLookup.label}
                      onToggle={handleCheckboxChange(sDGLookup)}
                    >
                      <img
                        className="sdg-icon"
                        alt={sDGLookup.label}
                        src={(sDGLookup?.imgURL || '') as string}
                      />
                    </CheckboxButtonV2>
                  ))}
                </> 
              )
            )}
          </>
        ) : (
          <article id="skeleton-container">
            <Card className="skeleton-checkbox"/>
            <Card className="skeleton-checkbox"/>
            <Card className="skeleton-checkbox"/>
          </article>
        )}
      </article>
    </section>
  );
}

export default SDGSelector;