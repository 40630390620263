import './styles.scss';

import React from 'react';
import { Avatar } from '@flotilla/component-library';

interface NotificationListItemProps {
  className?: string;
  title?: string;
  subtitle?: string;
  logo?: string | null;
  content?: string;
  timestamp?: Date;
  isLoading?: boolean;
};

const NotificationListItem: React.FC<NotificationListItemProps> = ({
  className = "",
  title = "",
  subtitle = "",
  logo,
  content = "",
  timestamp,
  isLoading = false,
}) => {

  if (isLoading) {
    return <article className="notification-list-item notification-list-item--skeleton" />;
  }

  return (
    <article
      className={`notification-list-item ${className}`}
    >
      <section
        className="title-avatar-container"
        // onClick={() => onAction(company, 'edit')}
      >
        <Avatar
          imgSrc={logo}
          imgSrcAlt={`${title} logo`}
        />
        <section className="title-subtitle-container">
          <p className="title">{title}</p>
          <p className="subtitle">{subtitle}</p>
        </section>
      </section>
      <section className="content">
        <p>{content}</p>
      </section>
      {
        timestamp &&
          <p className="timestamp">{new Date(timestamp).toLocaleString()}</p>
      }
    </article>
  );
}

export default NotificationListItem;
