import React from 'react';

import { Route, Routes } from 'react-router-dom';

import AskFlotilla from './AskFlotilla';
import PolicyChecker from './PolicyChecker';

const SupportGuidance: React.FC = () => {  
  return (
    <Routes>
      <Route path="askflotilla" element={<AskFlotilla />} />
      <Route path="policychecker" element={<PolicyChecker />} />
    </Routes>
  );
}

export default SupportGuidance;
