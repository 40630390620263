import './styles.scss';

import { Button, CloseIcon, IconButton } from '@flotilla/component-library';

import { useState } from 'react';
import { useCompanyId } from '../../../../context';
import { deleteReportPageDetail } from '../../../../api/Report';

interface ConfirmDeletePopUpProps {
  contentId: number;
  onDelete: () => void;
  onClose: () => void;
}

export const ConfirmDeletePopUp: React.FC<ConfirmDeletePopUpProps> = ({
  contentId,
  onDelete,
  onClose,
}) => {
  const companyId = useCompanyId();
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteClick = async () => {
    setIsLoading(true);
    await deleteReportPageDetail(contentId, companyId)
      .then(() => onDelete())
      .catch((error) => {
        console.log('Delete Report Comment Error: ', error);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div id="confirm-delete-popup-container">
      <div id="confirm-delete-popup">
        <h2>Confirm Comment Delete</h2>
        <IconButton
          className="close-icon"
          variant="ghost"
          aria-label="Close Icon"
          icon={<CloseIcon variant='dark' />}
          onClick={() => onClose()}
        />
        <main>
          <p>Are you sure you want to delete this comment.</p>
        </main>
        <article id="action-buttons">
          <Button id="cancel-button" variant="ghost" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            id="delete-button"
            isLoading={isLoading}
            onClick={handleDeleteClick}
          >
            Yes, Delete
          </Button>
        </article>
      </div>
    </div>
  );
};
