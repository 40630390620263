import './styles.scss';

import { StyleSheet } from '@react-pdf/renderer';

import {
  ReportPage,
  ReportDiv,
} from '../../../../../../components/PDFReport';
import { ReportPageProps } from '../../../../../../types/Report';
import { HowOftenDoYouWFHByOffice } from './Sections/HowOftenDoYouWFHByOffice';
import { TravelMethodsByOffice } from './Sections/TravelMethodsByOffice';
import { AverageCommuteByOffice } from './Sections/AverageCommuteByOffice';
import { CarTypesByOffice } from './Sections/CarTypesByOffice';
import { Data } from '../EmployeeCommuting/Sections/helpers';
import { Theme } from '@nivo/core';

const styles = StyleSheet.create({
  officeCommutingPage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 25,
    paddingLeft: 5,
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 0,
  },
});

export interface ChartByOfficeProps {
  className?: string;
  data: Data;
  downloadVersion?: boolean;
  chartWidth: number;
  chartHeight: number;
  theme?: Theme;
  keys?: string[];
}

export const MultiOfficeCommutingPage: React.FC<ReportPageProps> = ({ 
  page, className, downloadVersion = false, onContentUpdate = () => {}, editable
}) => {
  var wfhData = page.charts.find(
    (chart) => chart.name === 'Employee commuting by office - WFH'
  );

  const travelMethodData = page.charts.find(
    (chart) => chart.name === 'Employee commuting by office - Travel Method'
  );

  const commuteDistanceData = page.charts.find(
    (chart) => chart.name === 'Employee commuting by office - Commute Distance'
  );

  const carTypeData = page.charts.find(
    (chart) => chart.name === 'Employee commuting by office - Car Type'
  );

  const keys = (page?.details?.[0]?.comments?.content?.[0] || "").split(',');

  const chartWidth = downloadVersion ? 167 : 364;
  const chartHeight = downloadVersion ? 375 : 600;
  const theme = {
    background: 'transparent',
    axis: {
      ticks: {
        text: {
          fontSize: downloadVersion ? 6 : 8,
          fill: '#011E26'
        }
      }
    }
  };

  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`office-commuting-page ${className}`}
      style={styles.officeCommutingPage}
      downloadVersion={downloadVersion}
      footer={page.footer}
      onChange={() => onContentUpdate()}
      pageId={page.pageId}
      showFooter
      editable={editable}
      page={page}
      showHeader
    >
      <ReportDiv
        className="content"
        style={styles.content}
        downloadVersion={downloadVersion}
      >
        {wfhData && (
          <HowOftenDoYouWFHByOffice
            data={wfhData}
            downloadVersion={downloadVersion}
            chartWidth={chartWidth}
            chartHeight={chartHeight}
            theme={theme}
            keys={keys}
          />
        )}
        {travelMethodData && (
          <TravelMethodsByOffice
            data={travelMethodData}
            downloadVersion={downloadVersion}
            chartWidth={chartWidth}
            chartHeight={chartHeight}
            theme={theme}
            keys={keys}
          />
        )}
        {commuteDistanceData && (
          <AverageCommuteByOffice
            data={commuteDistanceData}
            downloadVersion={downloadVersion}
            chartWidth={chartWidth}
            chartHeight={chartHeight}
            theme={theme}
            keys={keys}
          />
        )}
        {carTypeData && (
          <CarTypesByOffice
            data={carTypeData}
            downloadVersion={downloadVersion}
            chartWidth={chartWidth}
            chartHeight={chartHeight}
            theme={theme}
            keys={keys}
          />
        )}
      </ReportDiv>
    </ReportPage>
  );
};