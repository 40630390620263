import SurveyEmployeesSection from "./Sections/SurveyEmployees";
import SurveyDatesSection from "./Sections/SurveyDates";
import { CreateWizardPage, CreateWizardPages } from "../../../../types/CreateWizard";

const getPages = (): CreateWizardPages => ([
  {
    name: 'Employees',
    title: 'Number of Employees',
    subtitle: "Please enter the number of employees this will be sent to, this allows us to provide metrics for completion.",
    children: SurveyEmployeesSection,
    isNextDisabled: (values) => values?.["sentTo"] === undefined || isNaN(values?.["sentTo"]) || Number(values?.["sentTo"]) === 0
  },
  {
    name: 'Dates',
    title: 'Survey Dates',
    subtitle: "Enter the dates for which you would like the survey to be accessible by employees.",
    children: SurveyDatesSection,
    isNextDisabled: (values) => (values?.["fromDate"] === undefined || values?.["fromDate"] === '') || (values?.["toDate"] === undefined || values?.["toDate"] === '')
  }
] as (CreateWizardPage | boolean)[]).filter(Boolean) as CreateWizardPages;


export default getPages;