import './styles.scss';

import { Text, StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

interface ReportH2Props {
  id?: string;
  className?: string;
  style?: Style;
  downloadVersion?: boolean;
  children: string | string[];
}

const styles = StyleSheet.create({
  text: {
    fontFamily: 'Times-Roman',
    fontSize: 22,
    color: '#011e26',
  },
});

const ReportH2: React.FC<ReportH2Props> = ({
  className = '',
  id,
  children,
  style = {},
  downloadVersion = false,
}) => {
  if (downloadVersion) {
    return (
      <Text id={id} style={[styles.text, style]}>
        {children}
      </Text>
    );
  }

  return (
    <h2 id={id} className={`report-h2 ${className}`}>
      {children}
    </h2>
  );
};

export default ReportH2;
