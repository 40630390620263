import { BarCustomLayer } from "@nivo/bar";
import { Datum } from "@nivo/line";

export const getBarTotals = (downloadVersion: boolean) : BarCustomLayer<Datum> => {
    return ({ bars, xScale, yScale }) => {
        const labelOffset = 12;
        
        if (bars.length === 0) return null;
    
        const totals: {[key: string] : number} = {};
        const bandwidth = bars[0]?.width ?? 0;
    
        bars.forEach((bar: Datum) => {
            const indexValue = bar.data.indexValue;
            if (!(indexValue in totals)) {
                totals[indexValue] = 0;
            }

            totals[indexValue] += bar.data.value;
        });
        
        const labels = Object.keys(totals).map((indexValue) => {
            const x = xScale(parseInt(indexValue)) + bandwidth / 2;
            const y = yScale(totals[indexValue]) - labelOffset;
            
            const text = totals[indexValue].toString();
            const rectWidth = downloadVersion ? 31 + (text.length * 7) : 36 + (text.length * 8);
        
            return <g key={"total." + indexValue} transform={`translate(${x},${y})`}>
                <rect
                    x={-rectWidth / 2} y={downloadVersion ? -10 : -12}
                    width={rectWidth} height={downloadVersion ? 16 : 20}
                    rx={downloadVersion ? 8 : 10} ry={downloadVersion ? 8 : 10}
                    fill="#E2E4E3"
                />
                <text
                textAnchor="middle"
                alignmentBaseline="middle"
                style={{ fill: '#011E26', fontSize: downloadVersion ? '10px' : '12px' }}
                transform={`translate(0,${downloadVersion ? 2 : 0})`}
                >
                    {text} FTE
                </text>
            </g>;
        });

        return <>{labels}</>;
    };
};