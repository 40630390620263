import { useCallback, useEffect, useState } from "react";
import { useAlert } from "@flotilla/component-library";

import { useAppDispatch } from "../../helpers/hooks";
import { ConversionFactors } from "../../types/ConversionFactor";
import { getConversionFactors, deleteConversionFactor as deleteConversionFactorAPI } from "../../api/ConversionFactors";

const useConversionFactors = () => {
  const { addAlert } = useAlert();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [conversionFactors, setConversionFactors] = useState<ConversionFactors>();

  const fetchApi = useCallback(() => {
    setLoading(true);
    getConversionFactors()
      .then(setConversionFactors)
      .catch((error) => {
        addAlert({
          id: "Get Conversion Factors Error",
          type: 'error',
          title: 'Error getting Conversion Factors Roles',
          content: 'We are sorry there seems to be an issue getting the Conversion Factors. Please try again.',
        });
        console.log("Error while getting Conversion Factors: ", error);
      })
      .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const updateConversionFactors = () => {
    fetchApi();
  }

  const deleteConversionFactor = async (id: number): Promise<void> => {
    return await deleteConversionFactorAPI(id)
      .then(() => {
        setConversionFactors([...(conversionFactors || []).filter((item) => item.id !== id)]);
      })
      .catch((error) => {
        addAlert({
          id: "Delete Conversion Factor Error",
          type: 'error',
          title: 'Error deleting Conversion Factor',
          content: 'We are sorry there seems to be an issue deleting the Conversion Factor. Please try again.',
        });
        console.log("Error while getting Conversion Factors: ", error);
      });
  }

  useEffect(() => {
    fetchApi();
  }, [dispatch, fetchApi]);

  return {
    conversionFactors,
    updateConversionFactors,
    deleteConversionFactor,
    loading
  };
}

export default useConversionFactors;
