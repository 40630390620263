import React from 'react';

import { Route, Routes } from 'react-router-dom';

import ActionManagerList from './List';
import ActionManagerDetail from './Detail';

const ActionManager: React.FC = () => {
  return (
    <Routes>
      <Route index element={<ActionManagerList />} />
      <Route path="new" element={<ActionManagerDetail mode="Create" />} />
      <Route path=":id" element={<ActionManagerDetail />} />
    </Routes>
  );
}

export default ActionManager;
