import './styles.scss';

import { useState } from "react";
import { Button, Modal, useAlert } from "@flotilla/component-library";

import { deleteRanking } from "../../../../api/Rankings";

interface ConfirmRankingDeleteModalProps {
    onClose: () => void;
    onDelete: () => void;
    rankingId: number
  };

const ConfirmRankingDeleteModal: React.FC<ConfirmRankingDeleteModalProps> = ({
    onClose = () => {},
    onDelete = () => {},
    rankingId
  }) => {
    const { addAlert } = useAlert();
    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = () => {
      setIsLoading(true);

      deleteRanking(rankingId)
      .then(() => {
        addAlert({
          id: `Ranking deleted ${rankingId}`,
          type: 'success',
          title: `Success, Ranking deleted!`,
          content: `You have deleted the Action Ranking.`,
        });
        onDelete();
      })
      .catch((error) => {
        addAlert({
          id: `Ranking deleted ${rankingId}`,
          type: 'error',
          title: `Error Deleting Action`,
          content: `There seems to be an error when deleting the Action Ranking, please try again.`,
        });
        console.log("Error deleting action: ", error);
        setIsLoading(false);
      });
    }

    return (
        <Modal
          id="delete-ranking-modal"
          onClose={onClose}
        >
            <h2>Delete Action Ranking</h2>
            <main>
                <p>Are you sure you wish to delete this ranking entry?</p>
            </main>
            <section className="button-container">
            <Button onClick={handleDelete} isLoading={isLoading}>DELETE</Button>
            <Button variant="ghost" onClick={onClose}>
                Cancel
            </Button>
            </section>
        </Modal>);
}

export default ConfirmRankingDeleteModal;