import './styles.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from '@flotilla/component-library';

import { ImpactArea } from '../../../../types/ImpactArea';
import { numberLocaleFormatter } from '../../../../helpers/numberFormatter';

interface ImpactAreaCardProps extends Omit<ImpactArea, "id"> {
  id: string,
  className?: string;
  actionsOnly?: boolean;
};

const ImpactAreaCard: React.FC<ImpactAreaCardProps> = ({
  id,
  className = "",
  name,
  scope,
  tco2e = 0,
  totalPercent = 0,
  reducedPercent = 0,
  statusCount,
  actionsOnly = false
}) => {
  const navigate = useNavigate();
  const actionLabels = [
    {
      key: "todo",
      label: "To do",
    },
    {
      key: "active",
      label: "Active",
    },
    {
      key: "overdue",
      label: "Overdue",
    },
    {
      key: "completed",
      label: "Completed"
    }
  ];

  return (
    <Card id={String(id)} className={`impact-area-card ${className}`}>
      <header>
        <section
          id="label-container"
          onClick={() => navigate(`./projectplan?impactArea=${encodeURIComponent(name)}`)}
        >
          <h4>{name}</h4>
          { scope && <p className="small-text">{scope}</p> }
        </section>
        { !actionsOnly && totalPercent > 0 && tco2e > 0 && (
          <section id="value-container">
            <p id="value">{numberLocaleFormatter(Math.round(totalPercent * 10) / 10)}%</p>
            <p className="small-text">{numberLocaleFormatter(Math.round(tco2e * 10) / 10)} tCO2e</p>
          </section>
        )}
      </header>
      { !actionsOnly && (
        <section id="progress-container">
          <label htmlFor="progress" id="progress-text" className="small-text" >{numberLocaleFormatter(Math.round(reducedPercent * 10) / 10)}% reduced from baseline</label>
          <progress id="progress" value={reducedPercent} max="100">{reducedPercent}</progress>
        </section>
      ) }
      <section id="actions-container">
        {actionLabels.map((actionLabel, index) => (
          <section key={index} className={`action action--${actionLabel.key}`} onClick={() => navigate(`./projectplan?impactArea=${name}&status=${actionLabel.label}`)}>
            <label>{actionLabel.label}</label>
            <p>{numberLocaleFormatter(statusCount?.[`${actionLabel.key}Actions`] || 0)}</p>
          </section>
        ))}
      </section>
    </Card>
  );
}

export default ImpactAreaCard;
