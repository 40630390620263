import { mapActions } from "./Action";
import { ImpactAreaGroupType } from "./ImpactArea";
import { ScopeCategories, ScopeCategory, mapScopeCategories } from "./ScopeCategory";

export interface Scope extends ImpactAreaGroupType {
  scope: number;
  tcO2e: number;
  percent: number;
  children?: ScopeCategories;
}

export type Scopes = Scope[];

export const mapScopes = (data: {[key: string]: unknown}[]): Scopes => {
  return data.map((item:  {[key: string]: unknown | unknown[] | any}) => ({
    ...item,
    id: item.scope,
    statusCount: item.statusCounts,
    actionsTotal: item.actions?.length,
    tco2e: item.tcO2e,
    scope: item.scope,
    ...(item?.actions ? {actions: mapActions(item.actions as {[key: string]: unknown}[])} : {}),
    ...(item?.scopeCategories ? {children: mapScopeCategories(item.scopeCategories as {[key: string]: unknown}[])} : {}),
  })) as unknown[] as Scopes;
}


export const getFakeScopes = (): Scopes => {
  return [
    {
      id: 1,
      name: 'Scope 1',
      scope: 1,
      tcO2e: 1,
      percent: 1
    },
    {
      id: 2,
      name: 'Scope 2',
      scope: 2,
      tcO2e: 2,
      percent: 2
    },
    {
      id: 3,
      name: 'Scope 3',
      scope: 3,
      tcO2e: 3,
      percent: 3
    }
  ]
}