import { ChartByOfficeProps } from '..';
import {
  ReportChart,
  ReportDiv,
  ReportH4
} from '../../../../../../../components/PDFReport';
import { BarChart, BartCO2eDifference } from '../../BarChart';
import { getChartLabels } from '../Labels';

export const AverageCommuteByOffice: React.FC<
    ChartByOfficeProps
> = ({ className = '', data, downloadVersion = false, chartHeight, chartWidth, theme, keys }) => {
  const chartData =
    (data.data.map((obj) => ({
      key: obj.xLabel,
      distance: obj.value,
      colourHex: obj.colourHex
    })) ?? [])
    .filter(d => keys?.includes(d.key));

  chartData.sort((a, b) => b.key.localeCompare(a.key));

  return (
    <ReportDiv
      id="average-commute-by-office"
      className={className}
      downloadVersion={downloadVersion}
    >
      <ReportH4 className="section-header" downloadVersion={downloadVersion} style={{ display: "flex", alignSelf: "center", fontSize: 10 }}>
        Avg. one-way commute (miles)
      </ReportH4>
      <ReportChart downloadVersion={downloadVersion}>
        <BarChart
          width={chartWidth - (downloadVersion ? 0 : 60)}
          height={chartHeight}
          fontSize={downloadVersion ? 6 : 8}
          data={chartData}
          layout='horizontal'
          getColor={() => '#317F70'}
          keys={['distance']}
          theme={theme}
          // Needed for font to be right size
          axisLeft={{
            tickSize: 0,
            tickPadding: 8,
            format: () => ' '
          }}
          barComponent={(props) => (
            <BartCO2eDifference
              {...props}
              hideValues
              downloadVersion={downloadVersion}
              color={props.bar.data.data.colourHex}
            />
          )}
          enableLabel={false}
          enableGridX
          margin={{ top: 10, right: (downloadVersion ? 15 : 30), bottom: 60, left: (downloadVersion ? 15 : 30)}}
          layers={['grid', 'axes', 'bars', 'markers', 'legends', 'annotations', getChartLabels(downloadVersion)]}
        />
      </ReportChart>
    </ReportDiv>
  );
};