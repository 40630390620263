import { Card } from "@flotilla/component-library"
import { NetZeroPlan } from "../../../../types/NetZeroPlan"

const InterimToolTip: React.FC<{plan?: NetZeroPlan}> = ({ plan }) => {
    return (
      <Card id="interim-tooltip">
          <p>{`Near-term targets have been set to ${(plan?.scope1ReductionTargetInterim || 0) * 100}% for scope 1, ${(plan?.scope1ReductionTargetInterim || 0) * 100}% for scope 2 and ${(plan?.scope3ReductionTargetInterim || 0) * 100}% for scope 3.`}</p>
      </Card>
    )
}

export default InterimToolTip;