import './styles.scss';

import React from 'react';
import { CheckboxButtonV2 } from '@flotilla/component-library';

import { outputTypes } from '../../../../../../types/Report';
import { SectionProps } from '../../../../../../types/CreateWizard';
import PowerPointIcon from '../../../../../images/PowerPoint-Icon.png';
import PDFIcon from '../../../../../images/PDF-Icon.png';

const ReportOutputSection: React.FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const handleTypeChange = (updatedType: number) => {
    return (checked: boolean) => {
      const value = checked ? updatedType : -1;
      onChange({ outputType: value });
    }
  }

  return (
    <section id="report-output-section" className={className}>
      <article id="options">
        {outputTypes.map((typeOption, index) => (
          <CheckboxButtonV2
            className='checkbox'
            key={typeOption}
            label={typeOption}
            checked={index === values?.["outputType"]}
            onToggle={handleTypeChange(index)}
            icon={<img src={typeOption === 'PDF' ? PDFIcon : PowerPointIcon} alt={typeOption} />}
          />
        ))}
      </article>
    </section>
  );
}

export default ReportOutputSection;
