import './styles.scss';

import React, { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { Button, Modal, TextInput, useAlert } from '@flotilla/component-library';

import { Action, Actions } from '../../../types/Action';
import { getActions } from '../../../api/Action';
import CardItem from '../../../pages/Administration/ActionManager/List/CardItem';

interface SetParentActionModalProps {
  className?: string;
  onClose: () => void;
  onSubmit?: (action: Action) => void;
  parentAction?: Action,
};

const SetParentActionModal: React.FC<SetParentActionModalProps> = ({
  className = "",
  onClose = () => {},
  onSubmit = () => {},
  parentAction,
}) => {
  const { addAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [selectedAction, setSelectedAction] = useState<Action>();
  const [initialActions, setInitialActions] = useState<Actions>();
  const [actions, setActions] = useState<Actions>();

  const handleGetData = useCallback(() => {
    setIsLoading(true);
    getActions()
      .then((res) => {
        setInitialActions(res);
        setActions(res);
      })
      .catch((error) => {
        addAlert({
          id: `Getting Actions`,
          type: 'error',
          title: 'Error getting Actions',
          content:
            'We are sorry there seems to be an issue getting the Actions. Please try again.',
        });
        console.log("Error while getting Actions: ", error);
      })
      .finally(() => setIsLoading(false));
  }, [addAlert]);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  useEffect(() => {
    setSelectedAction(parentAction);
  }, [parentAction]);

  useEffect(() => {
    setActions(initialActions?.slice(0, 6));
  }, [initialActions]);

  const handleItemClick = (action: Action) => {
    return () => {
      setSelectedAction(action);
    }
  }

  const handleSubmit = () => {
    selectedAction && onSubmit(selectedAction);
  };


  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchTerm(event?.currentTarget.value);
    let filteredActions = initialActions ? [...initialActions] : [];
    filteredActions = filteredActions.filter((item) => {
      const itemCompare  = JSON.stringify(item).toLowerCase();
      return itemCompare.includes(event.currentTarget.value.toLowerCase());
    }).splice(0, 6);
    if (filteredActions.length > 0) {
      setActions(filteredActions);
    }
  };

  return (
    <Modal
      id="set-parent-action-modal"
      className={className}
      onClose={onClose}
    >
      <h2>Add Action to your Net Zero Plan</h2>

      <TextInput
        id="search-parent-action"
        label="Search"
        placeholder="Search for Parent Action..."
        aria-label="Search parent action"
        value={searchTerm}
        onChange={handleSearchChange}
      />

      { actions && (
        <main id="actions-container">
          { actions.map((item, index) => (
            <CardItem
              key={`${item.id}-${index}`}
              className={`action-card ${(selectedAction && item.id === selectedAction.id) ? "action-card--selected" : ""}`}
              onClick={handleItemClick(item)}
              {...item}
            />
          ))}
        </main>
      )}

      <section className="button-container">
        <Button
          variant="ghost"
          onClick={() => onClose()}
          isLoading={isLoading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={!selectedAction}
        >
          Add
        </Button>
      </section>
    </Modal>
  );
}

export default SetParentActionModal;
