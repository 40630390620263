import './styles.scss';
import { FullCompanyQuestion } from '../../../../../types/Company';
import { ReactComponent as MinusIcon } from '../../../../../assets/icons/Minus.svg';
import { ReactComponent as ChevronLeftIcon } from '../../../../../assets/icons/ChevronLeft.svg';
import { AddIcon, IconButton } from '@flotilla/component-library';
import { useState } from 'react';

type QuestionRowProps = {
    question: FullCompanyQuestion,
    selected: boolean,
    onClick: () => void;
};

const QuestionRow: React.FC<QuestionRowProps> = ({ question, selected, onClick }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <section className={`company-question-row ${expanded ? "open" : "closed"}`}>
            <section className='row-content'>
                {selected && <IconButton
                    className='arrow-icon arrow-icon--left'
                    onClick={() => onClick()}
                    icon={<MinusIcon />}   
                    small             
                />}
                {question.companyQuestionAnswers.length && <IconButton
                className="expand-button"
                variant='ghost'
                small
                icon={<ChevronLeftIcon />}
                onClick={() => { setExpanded(!expanded) }}
                label="View Answers"
                />}
                <p key={question.id}>{question.questionText}</p>
                {!selected && <IconButton
                    className='arrow-icon arrow-icon--right'
                    onClick={() => onClick()}
                    icon={<AddIcon />}         
                    small       
                />}
            </section>
            {expanded && <section className='answer-list'>
                {question.companyQuestionAnswers.map((a, idx) => {
                    return <p>Answer {idx + 1}: {a.answerText}</p>
                })}
            </section>}
        </section>
    );
};

export default QuestionRow;