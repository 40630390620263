import { useCallback, useEffect, useState } from "react";

import { getCompanyTypes } from "../../api/Lookup";
import { Lookups } from "../../types/Lookup";

const useCompanyTypes = () => {
  const [loading, setLoading] = useState(true);
  const [companyTypes, setCompanyTypes] = useState<Lookups>();

  const fetchApi = useCallback(async () => {
    setLoading(true);
    getCompanyTypes()
      .then(setCompanyTypes)
      .finally(() => setLoading(false));;
  }, []);

  const refresh = () => {
    fetchApi();
  }

  useEffect(() => {
    fetchApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    companyTypes,
    refresh,
    loading
  };
}

export default useCompanyTypes;
