import { createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";
import { ModalProps } from "@flotilla/component-library";

export interface ModalState {
  modal: ModalProps | undefined
  status: 'idle' | 'loading' | 'failed';
}

const initialState: ModalState = {
  modal: undefined,
  status: 'idle'
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    addModal: (state, action) => {
      state.modal =  action.payload;
    },
    removeModal: (state) => {
      state.modal = undefined;
    },
  },
});

export const { addModal, removeModal } = modalSlice.actions;

export const getModal = (state: RootState): ModalProps | undefined => state.modal.modal;

export default modalSlice.reducer;
