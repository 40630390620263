import { createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";
import { AlertProps } from "@flotilla/component-library";

export interface AlertsState {
  alerts: AlertProps[]
  status: 'idle' | 'loading' | 'failed';
}

const initialState: AlertsState = {
  alerts: [],
  status: 'idle'
};

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    addAlert: (state, action) => {
      state.alerts.push(action.payload);
    },
    deleteAlert: (state, action) => {
      state.alerts = state.alerts.filter((_, index) => index !== Number(action.payload));
    },
    clearAlerts: (state) => {
      state.alerts = [];
    },
  },
});

export const { addAlert, deleteAlert, clearAlerts } = alertsSlice.actions;

export const getAlerts = (state: RootState): AlertProps[] => state.alerts.alerts;

export default alertsSlice.reducer;
