import './styles.scss';

import { useEffect, useState } from "react";
import { Blocker, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Alert, AlertType, CircularProgress, Datalist, IconButton, SaveIcon, Spinner, WrenchIcon, useAlert } from "@flotilla/component-library";
import { DynamicSearcher, Query, SearcherFactory } from '@m31coding/fuzzy-search';

import { getAccountsNominals, getDataIntegrations, updateAccountsNominals } from '../../../api/DataIntegration';
import { getAllMappableActivities } from '../../../api/Activity';
import { DATA_INTEGRATION_MAPPING_SUBTITLE } from '../../../assets/content/Subtitles';
import { ACCOUNTS_MAPPING_INFO_ALERT_MESSAGE, ACCOUNTS_MAPPING_INFO_ALERT_TITLE } from '../../../assets/content/AlertMessages';
import Header from "../../../components/HeaderV2";
import SaveChangesModal from '../../../components/Modal/SaveChangesModal';
import { useCompanyId } from "../../../context";
import { useCompany } from "../../../hooks";
import { Activities, Activity } from '../../../types/DataPeriod';
import { AccountsNominals, DataIntegration } from '../../../types/DataIntegrations';

interface AccountsMappingProps {};

const AccountsMapping: React.FC<AccountsMappingProps> = () => {
  const { addAlert } = useAlert();
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const [company] = useCompany(companyId);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [updated, setUpdated] = useState(false);
  const { integrationId } = useParams();
  const { key, state } = useLocation();
  const [accountNominals, setAccountNominals] = useState<AccountsNominals>();
  const [activities, setActivities] = useState<Activities>();
  const [hasMappedDefaults, setHasMappedDefaults] = useState(false);
  const [integration, setIntegration] = useState<DataIntegration | undefined>(state?.dataIntegration);
  const [searcher, setSearcher] = useState<DynamicSearcher<Activity, number>>();
  const [pendingSync, setPendingSync] = useState(false);

  useEffect(() => {
    if(company?.id && integrationId) {
      !state?.dataIntegration && getDataIntegrations(company?.id)
        .then((res) => {
          setIntegration(res.find(r => r.id === parseInt(integrationId)));
        })
        .catch(() => {
          addAlert({ id: `Failed To Retreive Integration Details`, type: "error", title: "Failed to load integration details", content: "Failed to load the data integration details, please refresh to try again." });
        });

      getAccountsNominals(parseInt(integrationId), company?.id)
        .then((res) => {
          res.forEach(res => res.predicted = -1);
          res.sort((a, b) => a.accountName.localeCompare(b.accountName));
          setAccountNominals(res);
        })
        .catch((err) => {
          if(err.toString().toLowerCase().includes("failed to retrieve financial data")) {
            setPendingSync(true);
          } else {
            addAlert({ id: `Failed To Retreive Accounts Nominals`, type: "error", title: "Failed to load accounts data", content: "Failed to load the accounts nominals data, please refresh to try again." });
          }
          setIsLoading(false);
        })

      getAllMappableActivities()
        .then((res) => {
          res.sort((a, b) => a.name.localeCompare(b.name));
          
          var searcher = SearcherFactory.createDefaultSearcher<Activity, number>();
          searcher.indexEntities(
            res ?? [],
            a => a.id,
            a => [a.name, ...(a.searchTerms ?? [])]);
          setSearcher(searcher);

          setActivities(res);
        })
        .catch(() => {
          addAlert({ id: `Failed To Retreive Activities`, type: "error", title: "Failed to load activities list", content: "Failed to load the activities list, please refresh to try again." });
          setIsLoading(false);
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    if(activities?.length && accountNominals && !hasMappedDefaults && searcher) {
      var newNominals = [...accountNominals];
      for(let i = 0; i < newNominals.length; i++) {
        var newNominal = { ...newNominals[i] };

        if(!newNominal.activityId) {
          var result = searcher.getMatches(new Query(newNominal.accountName, 10, 0.1));

          for (const match of result.matches) {
            if (match.matchedString.toLowerCase().normalize('NFKD').startsWith(newNominal.accountName.toLowerCase().normalize('NFKD')) ||
              newNominal.accountName.toLowerCase().normalize('NFKD').startsWith(match.matchedString.toLowerCase().normalize('NFKD'))) {
              var idx = result.matches.findIndex(m => m === match);
              if(idx > -1) {
                result.matches[idx] = { ...result.matches[idx], quality: match.quality + 0.2 };
              }
            }
          }
          result.matches.sort((m1, m2) => m2.quality - m1.quality);

          if(result.matches.length && result.matches[0].quality > 0.3) {
            newNominal.activityId = (result.matches[0].entity as any).id;
            newNominal.predicted = result.matches[0].quality;
          }

          newNominal.matches = result.matches.filter(m => m.quality > 0.2);
        }

        newNominals[i] = newNominal;
      }
      setHasMappedDefaults(true);
      setAccountNominals(newNominals);
      setIsLoading(false);
      setCanSave(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activities, accountNominals]);

  const handleSave = (navigationBlocker?: Blocker) => {
    if(!isLoading && !isSaving && accountNominals) {
      setIsSaving(true);
      updateAccountsNominals(accountNominals, company.id)
        .then(() => {
          setAccountNominals(accountNominals.map(aN => { return { ...aN, predicated: false }}));
          addAlert({ id: `Account Nominal Mappings Saved`, type: "success", title: "Mappings saved successfully" });
          navigationBlocker?.proceed?.();
        })
        .catch(() => {
          addAlert({ id: `Account Nominal Mappings Failed To Save`, type: "error", title: "Saving mappings unsuccessful", content: "Failed to save mappings, please try again." });
        })
        .finally(() => {
          setIsSaving(false);
          setUpdated(false);
        });
    }
  };

  const handleOnChangeMapping = (accountName: string) => {
    return (option: any) => {
      const activityId = parseInt(option?.activity?.id || 0);
      var newAccountsNominals = [...(accountNominals ?? [])];
      var idx = newAccountsNominals.findIndex(an => an.accountName === accountName);
      if(idx >= 0 && newAccountsNominals[idx].activityId !== activityId) {
        newAccountsNominals[idx] = { ...newAccountsNominals[idx], activityId: activityId, predicted: -1 };
      }
      setAccountNominals(newAccountsNominals);
      setUpdated(true);
    }
  }

  return (
    <section id="accounts-mapping-page">
      <Header
        showBackButton
        onBackClick={!key ? () => navigate(Number(companyId) > 0 ? `/${companyId}` : '') : undefined}
        rightChildren={() =>
          <IconButton
            icon={<SaveIcon />}
            onClick={() => handleSave()}
            isLoading={isLoading || isSaving}
            disabled={!canSave}
          />
        }
        subtitle={DATA_INTEGRATION_MAPPING_SUBTITLE.replaceAll("%%ACCOUNTS_NAME%%", integration?.name ?? "Accounts")}
      >
        {integration?.name ?? "Accounts"} Connection
      </Header>
      {isLoading ? (
        <Spinner size='large' lightBackground/>
      ) : (
        (pendingSync || !accountNominals?.length) ? (
          <main className='pending-data'>
            <WrenchIcon variant='brand' width={60} height={60}/>
            { pendingSync ? (
              <>
                <h4>Your finance data is still being synced.</h4>
                <p>Please check back soon.</p>
              </>
            ) : (
              <>  
                <h4>No account nominals were found.</h4>
                <p>Please ensure your accounts package is correctly configured.</p>
              </>
            )}
          </main>
        ) : (
          <main>
            <Alert
              id="info-message"
              title={ACCOUNTS_MAPPING_INFO_ALERT_TITLE.replaceAll("%%ACCOUNTS_NAME%%", integration?.name ?? "Accounts")}
              content={
                <>
                  {ACCOUNTS_MAPPING_INFO_ALERT_MESSAGE.replaceAll("%%ACCOUNTS_NAME%%", integration?.name ?? "Accounts").split("<br/>").map((item) => <><br/><p>{item}</p></>)}
                </>
              }
            />
              <section id="content">
                <label className='header'>{integration?.name ?? "Accounts"} Group</label>
                <label className='header'>Flotilla Activity</label>
                {accountNominals?.map(an => {
                  var alertType = an.predicted === -1 ? "" : !an.predicted || an.predicted < 0.34 ? 'error' : an.predicted < 0.67 ? 'warning' : 'success';
                  const selectedActivity = activities?.find((item) => item.id === an.activityId);
                  const options = [
                    ...an.matches?.map(a => ({
                      value: (a.entity as Activity).name,
                      label: (a.entity as Activity).name,
                      searchTerms: a.entity.searchTerms,
                      activity: a.entity as Activity
                    })) || [],
                    ...activities?.map((item) => ({
                      value: item.name,
                      label: item.name,
                      searchTerms: item.searchTerms,
                      activity: item
                    })) || []
                  ];

                  return (
                    <>
                      <label>{an.accountName}</label>
                      <Datalist
                        key={`${an.accountName}-${an.activityId}`}
                        id={`activity-${an.accountName}`}
                        className={`activity-datalist ${alertType}`}
                        options={options}
                        onChange={handleOnChangeMapping(an.accountName)}
                        alertType={alertType as unknown as AlertType}
                        value={selectedActivity ? {
                          value: selectedActivity.name,
                          label: selectedActivity.name,
                          activity: selectedActivity
                        } : undefined}
                        searchKeys={['value', 'label', 'searchTerms']}
                      />
                      {an.predicted !== -1 && (
                        <CircularProgress 
                          className={alertType}
                          score={(an.predicted || 0)}
                          maxScore={1.1}
                          centerValue={" "}
                          width={20}
                        />
                      )}
                    </>
                  );
                })}
            </section>
          </main>
        )
      )}
      <SaveChangesModal
        condition={updated}
        onSave={handleSave}            
      />
    </section>
  );
}

export default AccountsMapping;