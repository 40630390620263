import './styles.scss';
import { Button, Modal, useAlert } from "@flotilla/component-library";
import { useState } from "react";
import { deleteDataPeriod } from '../../../api/DataPeriod';

interface ConfirmDataPeriodDeleteModalProps {
    onClose: () => void;
    onDelete: () => void;
    dataPeriodId: string
  };

const ConfirmDataPeriodDeleteModal: React.FC<ConfirmDataPeriodDeleteModalProps> = ({
    onClose = () => {},
    onDelete = () => {},
    dataPeriodId
  }) => {
    const { addAlert } = useAlert();
    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = () => {
      setIsLoading(true);
      deleteDataPeriod(dataPeriodId)
      .then(() => {
          addAlert({ id: `Delete Data Period Success`, type: "success", title: "Successfully delete data period" });
          onDelete();
      })
      .catch(() => {
          addAlert({ id: `Delete Data Period Failed`, type: "error", title: "Failed to delete data period", content: "Failed to delete your data period, please try again later." });
          onClose();
      })
      .finally(() => setIsLoading(false));
    }

    return (
        <Modal
          id="delete-data-period-modal"
          onClose={onClose}
        >
            <h2>Delete Data Period</h2>
            <main>
                <p>Are you sure you wish to delete this data period? This is permanent and cannot be undone.</p>
            </main>
            <section className="button-container">
            <Button onClick={handleDelete} isLoading={isLoading}>DELETE</Button>
            <Button variant="ghost" onClick={onClose}>
                Cancel
            </Button>
            </section>
        </Modal>);
}

export default ConfirmDataPeriodDeleteModal;