import { createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";

export const pageCacheSlice = createSlice({
  name: 'pageCache',
  initialState: {} as any,
  reducers: {
    addPageCache: (state: {[key: string]: any}, action) => {
      var { storeKey, data } = action.payload;
      state[storeKey] ??= {};
      state[storeKey][window.history.state.key || 0] = data;
    }
  }
});

export const { addPageCache } = pageCacheSlice.actions;

export const getPageCache = (key: string): (state: RootState) => any =>
  (state: RootState) => state.pageCache?.[key]?.[window.history.state.key || 0];

export default pageCacheSlice.reducer;
