import { useCallback, useEffect, useState } from "react";

import { Companies } from "../../types/Company";
import { getAllCompanies } from "../../api/companies";

const useCompanies = () => {
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState<Companies>();

  const fetchApi = useCallback(async () => {
    setLoading(true);
    getAllCompanies()
      .then((res) => {
        res && setCompanies(res.sort((a, b) => a.name > b.name ? 1 : -1));
      })
      .finally(() => setLoading(false));
  }, []);

  const refreshCompanies = () => {
    fetchApi();
  }

  useEffect(() => {
    fetchApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    companies,
    refreshCompanies,
    loading
  };
}

export default useCompanies;
