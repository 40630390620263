import './styles.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { AddIcon, DeleteIcon, IconButton, Spinner, Table, useAlert } from '@flotilla/component-library';

import { ActionRanking, RankingProperty, ActionRankings, RankingPropertyName } from '../../../types/ActionRanking';
import { getRankings } from '../../../api/Rankings';
import EditRankingModal from './EditRankingModal';
import ConfirmRankingDeleteModal from './ConfirmRankingDeleteModal/ConfirmRankingDeleteModal';
import Header from '../../../components/HeaderV2';

const QUICK_FILTER = {
  key: 'propertyName',
};

const RankingSort = (a: ActionRanking, b: ActionRanking) => {
  return a.propertyName?.localeCompare(b.propertyName || "") || a.min - b.min || a.max - b.max;
};

const GetValueDisplayText = (property: RankingProperty, value: number) => {
  switch(property) {
    case RankingProperty.CarbonFootprintScope1:
    case RankingProperty.CarbonFootprintScope2:
    case RankingProperty.CarbonFootprintScope3:
    case RankingProperty.AvoidancePercent:
    case RankingProperty.EmployeeSuggested:
      return `${value}%`;
    case RankingProperty.CostSavingsPotential:
    case RankingProperty.CarbonSavingsPotential:
    case RankingProperty.CoBenefitPotential:
      switch(value) {
        case 1:
          return 'Low';
        case 2:
          return 'Medium';
        case 3:
          return 'High';
        default:
          return 'None';
      }
    case RankingProperty.QuickWin:
      return value ? 'Yes' : 'No';
    case RankingProperty.Priority:
      return value;
  }
}

const ActionRankingManager: React.FC = () => {
  const { addAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ActionRankings>();
  const [editingItem, setEditingItem] = useState<ActionRanking | null>();
  const [deletingItem, setDeletingItem] = useState<number | null>();

  const handleGetData = useCallback(() => {
    setIsLoading(true);
    getRankings()
      .then((res) => {
        res.forEach(r => r.propertyName = RankingPropertyName[r.property])
        setData(res.sort(RankingSort));
      })
      .catch((error) => {
        addAlert({
          id: `Getting Rankings`,
          type: 'error',
          title: 'Error getting Rankings',
          content:
            'We are sorry there seems to be an issue getting the Rankings. Please try again.',
        });
        console.log("Error while getting Rankings: ", error);
      })
      .finally(() => setIsLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  const updateItem = (item: ActionRanking) => {
    var newRankings = [...(data || [])];
    var existingIdx = newRankings.findIndex(r => r.id === item.id);
    item.propertyName = RankingPropertyName[item.property];

    if(existingIdx > -1) {
      newRankings[existingIdx] = item;
    } else {
      newRankings.push(item);
    }

    setEditingItem(null);
    setData(newRankings.sort(RankingSort));
  }

  const deleteItem = () => {
    if(!deletingItem) return;
    
    var newRankings = [...(data || [])];
    var existingIdx = newRankings.findIndex(r => r.id === deletingItem);

    if(existingIdx > -1) {
      newRankings.splice(existingIdx, 1);
    }

    setDeletingItem(null);
    setData(newRankings.sort(RankingSort));
  }

  return (
    <section id="action-ranking-page">
      <Header
        rightChildren={() =>
          <IconButton
            id="add-new-ranking"
            onClick={() => { setEditingItem({} as ActionRanking) }}
            label="Add New"
            icon={<AddIcon />}
            smartLayout
          />
        }
      >
        Action Rankings
      </Header>
      <main>
        { !data && isLoading && (
          <Spinner
            className="loading-spinner"
            lightBackground
          />  
        )}
        { data &&
            <Table
              stickyHeader
              quickFilters={[QUICK_FILTER]}
              headers={[
                {
                  key: "property",
                  label: "Property Type"
                },
                {
                  key: "min",
                  label: "Min"
                },
                {
                  key: "max",
                  label: "Max"
                },
                {
                  key: "baseScore",
                  label: "Base Score"
                }
              ]}
              data={data as unknown as {[key: string]: string;}[]}
              rowComponent={(item) => {
                const {
                  id,
                  property,
                  propertyName,
                  min,
                  max,
                  baseScore
                } = item as unknown as ActionRanking;
                return (
                  <tr key={id} className={`ranking-row ranking-row--${RankingProperty[property]}`} onClick={() => { setEditingItem({ id, property, min, max, baseScore} as ActionRanking) }}>
                    <td className="ranking-name">
                      <article>{propertyName}</article>
                    </td>
                    <td>{GetValueDisplayText(property, min)}</td>
                    <td>{GetValueDisplayText(property, max)}</td>
                    <td className="ranking-score">
                      {baseScore}
                      <IconButton
                          icon={<DeleteIcon />}
                          onClick={(e) => { e.stopPropagation(); setDeletingItem(id) }}
                          variant='ghost'
                          small
                        />
                    </td>
                  </tr>
                )
              }}
            />
        }
      </main>
      {editingItem && <EditRankingModal
        actionRanking={editingItem}
        onClose={() => setEditingItem(null)}
        onSubmit={(item) => { 
          setEditingItem(null);
          updateItem(item);
        }}
        existingRankings={data}
      />}
      {deletingItem && (
        <ConfirmRankingDeleteModal
          onClose={() => setDeletingItem(null)}
          onDelete={deleteItem}
          rankingId={deletingItem}
        />
      )}
    </section>
  );
}

export default ActionRankingManager;
