import React from "react";
import { ActionGanttTaskListHeaderProps } from "./ActionGantt.types";

const ActionGanttTaskListHeader: React.FC<ActionGanttTaskListHeaderProps> = ({ headerHeight, rowWidth, fontFamily, fontSize, children }) => {
    return <section className='task-list-header' style={{ fontFamily: fontFamily, fontSize: fontSize, height: headerHeight, width: rowWidth }}>
        <span>Action</span>
        { children }
    </section>
};

export default ActionGanttTaskListHeader;