import { API_DATA } from "../constants";
import { checkAuthorised } from "../helpers/checkAuthorisedAPI";

export const uploadDataCollection = (id: string, file: File) => {
  const data = new FormData();
  data.append('file', file);
  return fetch(`${API_DATA}/UploadExcelTemplate?companyId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'POST',
    body: data
  })
    .then((res) => checkAuthorised(res))
    .then((res) => {
      if (res.success) {
        return res.payload?.fileID as number;
      } else {
        throw new Error(res.message);
      }
    });
}

export const submitDataCollection = (fileId: number) => {
  return fetch(`${API_DATA}/SubmitExcelFile?fileId=${fileId}`, {
    headers: { "X-CSRF": "1" },
    method: 'POST',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => {
      if (res.success) {
        return res;
      } else {
        throw new Error(res.message);
      }
    });
}