import './styles.scss';

import { ChangeEventHandler, FC, HTMLAttributes, useState } from 'react';

import { Label, LabelProps, Select } from '@flotilla/component-library';
import { getDateYYYYMMDDString } from '../../helpers/dateFormatter';

interface DayMonthInputProps extends Omit<HTMLAttributes<HTMLElement>, 'onChange'> {
  label?: LabelProps;
  value?: Date;
  onChange?: (value: string | undefined) => void;
  disabled?: boolean;
};

const DayMonthsOptions = {
  January: 31,
  February: 28,
  March: 31,
  April: 30,
  May: 31,
  June: 30,
  July: 31,
  August: 31,
  September: 30,
  October: 31,
  November: 30,
  December: 31,
}

const DayMonthInput: FC<DayMonthInputProps> = ({
  className,
  value: _value = new Date(),
  onChange = () => ({}),
  label,
  disabled = false,
  ...props
}) => {
  const getDays = (month: number = 0) => {
    return Array.from(Array(Object.values(DayMonthsOptions)[month]).keys());
  }

  const [days, setDays] = useState<number[]>(getDays(Number(_value.getMonth())));
  const [value, setValue] = useState<Date | undefined>(_value);

  const updateDate = (_date?: Date) => {
    setValue(_date);
    onChange(_date ? getDateYYYYMMDDString(_date) : _date);
  }
  
  const handleOnChange = (key: string): ChangeEventHandler<HTMLSelectElement> => {
    return (event) => {
      const updatedDate = value;
      const selectedValue = event.currentTarget.value;
      if (key === 'day') {
        updatedDate?.setDate(Number(selectedValue));
      } else if (key === 'month') {
        updatedDate?.setMonth(Number(selectedValue));
        updateDays(Number(selectedValue));
      }
      updateDate(updatedDate);
    }
  }

  const updateDays = (month: number = 0) => {
    setDays(getDays(month));
  }

  return (
    <article className={`day-month-input ${className}`} {...props}>
      <Label {...label} />
      <section id="day-month-container">
        <Select
          id="day-select"
          className="day-select"
          onChange={handleOnChange('day')}
          value={value?.getUTCDate()}
          disabled={disabled}
        >
          { days.map((value, index) => (
            <option key={index} value={index + 1}>{value + 1}</option>
          ))}
        </Select>
        <Select
          id="month-select"
          className="month-select"
          onChange={handleOnChange('month')}
          value={value?.getUTCMonth()}
          disabled={disabled}
        >
          { Object.keys(DayMonthsOptions).map((value, index) => (
            <option key={index} value={index}>{value}</option>
          ))}
        </Select>
      </section>
    </article>
  )
}

export default DayMonthInput;
