import { BarCustomLayer } from "@nivo/bar";
import { numberLocaleFormatter } from "../../../../../../../../helpers/numberFormatter";
import { Datum } from "@nivo/line";

export const getEmissionsLabels = (downloadVersion: boolean, emissionsData: Datum[]) : BarCustomLayer<Datum> => {
    return ({ bars, xScale, yScale }) => {
      const totals: {[key: string] : number} = {};
      const labelOffset = 30;
      const bandwidth = bars[0]?.width ?? 0;
  
      emissionsData.forEach((bar: Datum) => {
        const indexValue = bar.xLabel;
        if (!(indexValue in totals)) {
          totals[indexValue] = 0;
        }

        totals[indexValue] += bar.value
      });
  
      return <>
        {Object.keys(totals).map((indexValue) => {
          const x = xScale(parseInt(indexValue)) + bandwidth / 2;
          const y = yScale(0) + labelOffset;
          const text = totals[indexValue];
    
          return <g key={"tco2e." + indexValue} transform={`translate(${x},${y})`}>
            <text
              textAnchor="middle"
              alignmentBaseline="middle"
              style={{ fill: '#011E26', fontSize: downloadVersion ? '10px' : '12px' }}
            >
              {numberLocaleFormatter(text, 1)} tCO2e
            </text>
          </g>;
        })}
      </>
    }
  }