import { PackageContentType } from '../../../types/OffsetPackage';

import GoldProjectBrazil from './images/gold-project-brazil.jpg';
import GoldProjectBrazilIcons from './images/gold-project-brazil-icons.jpg';
import GoldProjectPeru from './images/gold-project-peru.jpg';
import GoldProjectPeruIcons from './images/gold-project-peru-icons.jpg';
import SilverProjectBrazil from './images/silver-project-brazil.jpg';
import SilverProjectBrazilIcons from './images/silver-project-brazil-icons.jpg';
import SilverProjectChina from './images/silver-project-china.jpg';
import SilverProjectChinaIcons from './images/silver-project-china-icons.jpg';
import BronzeProjectBrazil from './images/bronze-project-brazil.jpg';
import BronzeProjectBrazilIcons from './images/bronze-project-brazil-icons.jpg';
import BronzeProjectIndia from './images/bronze-project-india.jpg';
import BronzeProjectIndiaIcons from './images/bronze-project-india-icons.jpg';

export const getExamplePackageContent = (): PackageContentType => {
  const topText = [
    'This is an example of one of the bespoke bundles of offsetting projects recommended by Flotilla.  It features environmental projects that put people and communities at the heart of their positive action. These are high quality nature projects which deliver lasting change by understanding the economic drivers of environmental damage.  They work with communities to develop skills and training with the aim of protecting and restoring our natural resources.',
  ];

  const projects = [
    {
      title: 'Pacajai REDD+ Project',
      subtitle: 'Brazil',
      percentage: '50%',
      image: GoldProjectBrazil,
      content: [
        'Prevention and avoidance of unplanned deforestation in native forests',
        'Rigorous monitoring and inspection',
        'Local community involvement, training, provision of resources',
      ],
      icons: [GoldProjectBrazilIcons],
    },
    {
      title: 'Keo Seima REDD+ Wildlife Sanctuary',
      subtitle: 'Cambodia',
      percentage: '50%',
      image: GoldProjectPeru,
      content: [
        'Wildlife protection',
        'Protects the forest which is home to critically endangered species',
      ],
      icons: [GoldProjectPeruIcons],
    },
  ];

  return {
    topText: topText,
    projects: projects,
  };
};

// Left in for now as we may change the packages, can be removed if not updated after a while
export const getGoldPackageContent = (): PackageContentType => {
  const topText = [
    'Our Gold bundle features environmental projects that put people and communities at the heart of their positive action.',
    'Our Gold high-quality nature projects deliver lasting change by understanding the economic drivers of environmental damage. They work with communities to develop skills and training with the aim of protecting and restoring our natural resources.',
  ];

  const projects = [
    {
      title: 'Pacajai REDD+ Project',
      subtitle: 'Brazil',
      percentage: '50%',
      image: GoldProjectBrazil,
      content: [
        'Prevention and avoidance of unplanned deforestation in native forests',
        'Rigorous monitoring and inspection',
        'Local community involvement, training, provision of resources',
      ],
      icons: [GoldProjectBrazilIcons],
    },
    {
      title: 'Keo Seima REDD+ Wildlife Sanctuary',
      subtitle: 'Cambodia',
      percentage: '50%',
      image: GoldProjectPeru,
      content: [
        'Wildlife protection',
        'Protects the forest which is home to critically endangered species',
      ],
      icons: [GoldProjectPeruIcons],
    },
  ];

  return {
    topText: topText,
    projects: projects,
  };
};

export const getSilverPackageContent = (): PackageContentType => {
  const topText = [
    'Our Silver bundle balances high-quality nature projects with affordable energy projects.',
    'Our Silver projects still ensure a contribution to positive action with a community focus. Our energy projects reduce the impact of emissions either through energy efficiency or lower carbon replacement fuels.',
  ];

  const projects = [
    {
      title: 'Pacajai REDD+ Project',
      subtitle: 'Brazil',
      percentage: '75%',
      image: SilverProjectBrazil,
      content: [
        'Prevention and avoidance of unplanned deforestation in native forests',
        'Rigorous monitoring and inspection',
        'Local community involvement, training, provision of resources',
      ],
      icons: [SilverProjectBrazilIcons],
    },
    {
      title: 'Tranvic',
      subtitle: 'China',
      percentage: '25%',
      image: SilverProjectChina,
      content: [
        'Uses wasted heat emitted during production to make steam, then electricity',
        'Less reliance on the local grid',
      ],
      icons: [SilverProjectChinaIcons],
    },
  ];

  return {
    topText: topText,
    projects: projects,
  };
};

export const getBronzePackageContent = (): PackageContentType => {
  const topText = [
    'Our Bronze bundle offers a blend of environmental and energy projects that are price sensitive.',
    'Bronze projects are a blend of nature-based solutions aimed at preventing deforestation with an energy project that reduces fossil fuel emissions.',
  ];

  const projects = [
    {
      title: 'Cikel Brazilian Amazon',
      subtitle: 'Brazil',
      percentage: '50%',
      image: BronzeProjectBrazil,
      content: [
        'Prevention and avoidance of unplanned deforestation in Para State, Brazil',
        'Continuation of limited forest management activities under FSC with log-impact logging',
      ],
      icons: [BronzeProjectBrazilIcons],
    },
    {
      title: 'Kothapeta Renewable Energy',
      subtitle: 'India',
      percentage: '50%',
      image: BronzeProjectIndia,
      content: ['Emission reduction through the use of cleaner fuels'],
      icons: [BronzeProjectIndiaIcons],
    },
  ];

  return {
    topText: topText,
    projects: projects,
  };
};
