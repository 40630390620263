import './styles.scss';

import React, { InputHTMLAttributes, ReactElement, useEffect, useState } from 'react';
import { Card } from '@flotilla/component-library';

export interface CheckboxButtonProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "children"> {
  onToggle?: (checked: boolean) => void;
  label?: string;
  icon?: ReactElement;
  [key: string]: string | number | unknown | undefined;
};

const CheckboxButton: React.FC<CheckboxButtonProps> = ({
  className = "",
  checked: initialChecked = false,
  label,
  onToggle = () => ({}),
  type = "checkbox",
  icon,
  ...props
}) => {
  const [checked, setChecked] = useState(initialChecked);

  useEffect(() => {
    setChecked(initialChecked);
  }, [initialChecked]);

  const handleToggle = () => {
    const toggledCheck = !checked;
    if (type === "checkbox") {
      setChecked(toggledCheck);
    }
    onToggle(toggledCheck);
  }

  return (
    <section className='checkbox-button-container'>
      <input
        {...props}
        type={type}
        checked={checked}
        onChange={handleToggle}
      />
      <Card
        className={`checkbox-button ${className}`}
        onClick={handleToggle}
      >
        { icon && icon }
        { label && <p>{label}</p> }
      </Card>
    </section>
  );
}

export default CheckboxButton;
