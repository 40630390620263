import './styles.scss';

import { StyleSheet } from '@react-pdf/renderer';

import { HowOftenDoYouWorkFromHome } from './Sections/HowOftenDoYouWorkFromHome';
import { HowDidYouTravelToWork } from './Sections/HowDidYouTravelToWork';
import { HowFarDoYouDriveToWork } from './Sections/HowFarDoYouDriveToWork';
import { HowBigIsYourCar } from './Sections/HowBigIsYourCar';
import { WhatCarDoYouDrive } from './Sections/WhatCarDoYouDrive';
import {
  ReportPage,
  ReportDiv,
  ReportP,
} from '../../../../../../components/PDFReport';
import { ReportPageProps } from '../../../../../../types/Report';

const styles = StyleSheet.create({
  employeeCommutingPage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 25,
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 25,
  },
  side: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 10,
  },
});

export const EmployeeCommutingPage: React.FC<ReportPageProps> = ({ report, page, className, downloadVersion = false, onContentUpdate = () => {}, editable }) => {
  // Data for left side of page
  const howOftenDoYouWorkFromHomeData = page.charts.find(
    (chart) =>
      chart.name === 'Employee commuting work from home vs office and emissions'
  );
  const howDidYouTravelToWorkData = page.charts.find(
    (chart) => chart.name === 'Employee commuting by transport method'
  );
  const commuteEmissions = Math.round(
    howOftenDoYouWorkFromHomeData?.data.find(
      (dataItem) => dataItem.legend === 'CommuteEmissions'
    )?.value || 0
  );
  const carCommutingEmissions = Math.round(report.extras.carTravelEmissions);

  // Data for right side of page
  const howFarIsYourDriveToWorkData = page.charts.find(
    (chart) => chart.name === 'Employee commuting drive to work distance'
  );
  const whatCarDoYouDriveData = page.charts.find(
    (chart) => chart.name === 'Employee commuting by car type'
  );
  const howBigIsYourCarData = page.charts.find(
    (chart) => chart.name === 'Employee commuting by car size'
  );
  const averageDrivingCommute = Math.round(report.extras.averageCommuteMiles);

  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`employee-commuting-page ${className}`}
      style={styles.employeeCommutingPage}
      downloadVersion={downloadVersion}
      footer={page.footer}
      onChange={() => onContentUpdate()}
      pageId={page.pageId}
      showFooter
      editable={editable}
      page={page}
      showHeader
    >
      <ReportDiv
        className="content"
        style={styles.content}
        downloadVersion={downloadVersion}
      >
        <ReportDiv
          className="content-container left-side"
          style={styles.side}
          downloadVersion={downloadVersion}
        >
          <ReportP downloadVersion={downloadVersion}>
            Employee commuting accounted for around {commuteEmissions}% of your
            total emissions in your latest reporting year.
          </ReportP>
          {howOftenDoYouWorkFromHomeData && (
            <HowOftenDoYouWorkFromHome
              data={howOftenDoYouWorkFromHomeData}
              downloadVersion={downloadVersion}
            />
          )}
          {howDidYouTravelToWorkData && (
            <HowDidYouTravelToWork
              data={howDidYouTravelToWorkData}
              carCommutingEmissions={carCommutingEmissions}
              downloadVersion={downloadVersion}
            />
          )}
        </ReportDiv>

        <ReportDiv
          className="content-container right-side"
          style={styles.side}
          downloadVersion={downloadVersion}
        >
          {howFarIsYourDriveToWorkData && (
            <HowFarDoYouDriveToWork
              data={howFarIsYourDriveToWorkData}
              averageDrivingCommute={averageDrivingCommute}
              downloadVersion={downloadVersion}
            />
          )}
          {whatCarDoYouDriveData && (
            <WhatCarDoYouDrive
              data={whatCarDoYouDriveData}
              downloadVersion={downloadVersion}
            />
          )}
          {howBigIsYourCarData && (
            <HowBigIsYourCar
              data={howBigIsYourCarData}
              downloadVersion={downloadVersion}
            />
          )}
        </ReportDiv>
      </ReportDiv>
    </ReportPage>
  );
};
