import './styles.scss';

import React, { useEffect, useState } from 'react';
import { Alert, CheckboxButtonV2, Skeleton, useAlert } from '@flotilla/component-library';

import { Link } from 'react-router-dom';
import { NO_DATA_FOR_BASELINE_YEAR_MESSAGE } from '../../../../AlertMessages';
import { getAvailableBaselineYears } from '../../../../../../api/DataPeriod';
import { useCompanyId } from '../../../../../../context';
import { SectionProps } from '../../../../../../types/CreateWizard';

const ReportBaselineYearSection: React.FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const { addAlert } = useAlert();
  const companyId = useCompanyId();
  const [years, setYears] = useState<string[]>();

  useEffect(() => {
    getAvailableBaselineYears(companyId)
      .then(res => setYears(res))
      .catch(() => {
        addAlert({ id: `Get Available Baseline Years Failed`, type: "error", title: "Failed to retrieve available baseline years", content: "Something went wrong, please try again." });
        setYears([]);
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const handleTypeChange = (year: string) => {
    return (checked: boolean) => {
      onChange({ baselineYear: checked ? year : 0 });
    }
  }

  const getAlertContent = () => {
    const content = NO_DATA_FOR_BASELINE_YEAR_MESSAGE.split('%%LINK%%')
    return (
      <p>{content[0]} <Link to='../../datacollection'>Click here</Link> {content[1]}</p>
    );
  };

  return (
    <section id="report-baseline-year-section" className={className}>
      { years && years.length === 0 && (
        <Alert
          type='info'
          title='No Data Submitted'
          content={getAlertContent()}
        />
      )}
      <article id="options">
        { years && years.map((year, index) => 
          <CheckboxButtonV2
            className='checkbox'
            key={index}
            label={String(year)}
            checked={year === values?.["baselineYear"]}
            onToggle={handleTypeChange(year)}
          />
        )}
        { !years && (
          <>
            <Skeleton className="checkbox--loading" />
            <Skeleton className="checkbox--loading" />
            <Skeleton className="checkbox--loading" />
          </>
        )}
      </article>
    </section>
  );
}

export default ReportBaselineYearSection;
