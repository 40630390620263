import './styles.scss';

import React, { HTMLAttributes, ReactNode, useEffect, useState } from 'react';
import { IconButton } from '@flotilla/component-library';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ChevronLeftIcon } from '../../assets/icons/ChevronLeft.svg';

interface HeaderProps extends HTMLAttributes<HTMLElement> {
  subtitle?: string | React.ReactNode;
  showBackButton?: boolean;
  onBackClick?: () => void;
  isLoading?: boolean;
  rightChildren?: (scrolled: boolean) => React.ReactNode;
  headerSubtitle?: boolean;
  tooltip?: ReactNode;
};

const Header: React.FC<HeaderProps> = ({
  children,
  subtitle,
  showBackButton = false,
  onBackClick,
  isLoading = false,
  className,
  rightChildren,
  headerSubtitle = false,
  tooltip,
  ...props
}) => {
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const subtitle = document.getElementById('subtitle');
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;
      setScrolled(scrollPosition > (subtitle?.clientHeight || 0));
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      // Cleanup: Remove the scroll event listener when the component unmounts
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick();
    } else {
      navigate(-1);
    }
  }

  return (
    <section
      {...props}
      id="flotilla-page-header"
      className={`flotilla-header ${showBackButton ? 'flotilla-header--back-button' : ''} ${scrolled ? 'flotilla-header--scrolled' : ''} ${className || ''}`}
    >
      <header>
        { showBackButton ? (
          <section id="back-text-container">
            <IconButton
              id="back-button"
              variant='ghost'
              isLoading={isLoading}
              icon={<ChevronLeftIcon />}
              onClick={handleBackClick}
              label="Back"
            />
            <section className={headerSubtitle ? "back-button-header header-subtitle" : "back-button-header"}>
              { typeof children === 'string' ? (
                <h3>{children}</h3>
              ) : children}
              { headerSubtitle && subtitle && <p id="subtitle" className={headerSubtitle ? "header-subtitle" : ""}>{subtitle}</p> }
            </section>
          </section>
        ) : (
          <>
            <h2>{children}</h2>
            {tooltip}
          </>
        )}
        { rightChildren && (
          <section id="right-children">
            {rightChildren(scrolled)}
          </section>
        )}
      </header>
      { !headerSubtitle && subtitle && <p id="subtitle">{subtitle}</p> }
    </section>
  )
}

export default Header;
