import React from "react";

import { ActionGanttTooltipProps } from "./ActionGantt.types";
import StatusTag from "../StatusTag";
import { formatDate } from "../../helpers/dateFormatter";

const ActionGanttTooltip: React.FC<ActionGanttTooltipProps> = ({ task: { type, action }, fontSize, fontFamily }) => {
    return action ? <article className='gantt-bar-tooltip' style={{ fontSize: fontSize, fontFamily: fontFamily }}>
        {type !== 'project' && <StatusTag className="gantt-bar-tooltip-status" size="small">{action.status}</StatusTag>}
        <p className="gantt-bar-tooltip-title">{action.impactAreaName} | {action.activityName}{action.activityName !== action.focusGroupName && (' | ' + action.focusGroupName)}</p>
        {action.departmentsString && <p className="gantt-bar-tooltip-department">Department - {action.departmentsString}</p>}
        {type !== 'project' && action.assignedTo && <p className="gantt-bar-tooltip-department">{action.assignedTo}</p>}
        <p>{action.startDate && formatDate(action.startDate)}{action.startDate && action.targetCompletionDate && ' - '}{action.targetCompletionDate && formatDate(action.targetCompletionDate)}</p>
    </article> : null;
}
export default ActionGanttTooltip;