import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAlert } from "@flotilla/component-library";

import { Location } from "../../types/Location";
import { useAppDispatch } from "../../helpers/hooks";
import { createLocation, getLocation, submitLocationQuestionAnswers, updateLocation as updateLocationAPI } from "../../api/Location";
import { useCompanyId } from "../../context";

const useLocation = (id?: number) => {
  const { addAlert } = useAlert();
  const dispatch = useAppDispatch();
  const { id: paramId } = useParams();
  const companyId = useCompanyId();
  const [loading, setLoading] = useState(false);
  const [locationId, setLocationId] = useState<number | undefined>(id || Number(paramId));
  const [location, setLocation] = useState<Location>();
  const [updatedLocation, setUpdatedLocation] = useState(false);

  const fetchApi = useCallback(async () => {
    if (locationId) {
      setLoading(true);
      await getLocation(locationId, companyId)
        .then((res) => {
          setLocation(res);
        })
        .finally(() => setLoading(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  const refreshLocation = () => {
    fetchApi();
  }

  const saveLocation = async (): Promise<Location | void> => {
    if (locationId && location) {
      setLoading(true);
      return await updateLocationAPI(location)
        .then((res) => {
          setLocation(res);
          return res;
        })
        .finally(() => {
          setLoading(false);
          setUpdatedLocation(false);
        });
    } else if (location) {
      setLoading(true);
      return await createLocation(companyId, location)
        .then(async (res) => {
          setLocationId(res.id);
          setLocation(res);
          await submitLocationQuestionAnswers(res.id, location.extras, companyId);
          addAlert({ id: `Location Created ${id}`, type: "success", title: "Location Created Successfully" });
          return res;
        })
        .catch((error) => {
          addAlert({ id: `Location Create Failed ${id}`, type: "error", title: "Location Create Unsuccessful", content: error?.toString() || "Location failed to create please try again." });
        })
        .finally(() => {
          setLoading(false);
          setUpdatedLocation(false);
        });
    }
  }

  const updateLocation = (updatedLocation: Partial<Location>) => {
    setLocation({
      ...location,
      ...updatedLocation
    } as Location);
    setUpdatedLocation(true);
  }

  useEffect(() => {
    if (locationId) {
      fetchApi();
    }
  }, [locationId, dispatch, fetchApi]);

  useEffect(() => {
    setLocationId(id || Number(paramId));
  }, [id, paramId]);

  return {
    location,
    refreshLocation,
    saveLocation,
    updateLocation,
    loading,
    updatedLocation
  };
}

export default useLocation;
