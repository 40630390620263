export interface User {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  fullName?: string;
  companyId: number;
  companyGuid: string;
  lastLoggedIn: string;
  sub?: number;
  email?: string;
  department?: Department;
  departmentValue?: string;
  roles?: UserRoles;
  userRoleValues?: string[];
  userRolesString?: string;
  status?: string;
  lastActive?: string;
  lastActiveDays?: number;
  inviteId?: number;
  disabled?: boolean;
}

export interface UserRole {
  id: number;
  name: string;
  friendlyName: string;
}

export interface Department {
  id: number;
  name: string;
}

export type Users = User[];
export type UserRoles = UserRole[];

const userStatus = ['', 'Invited', 'Active', 'Disabled'];
export type UserStatusType = typeof userStatus[number];
export const getUserStatusId = (value: string = ""): number => {
  return userStatus.findIndex((status) => status === value);
}

export const mapUsers = (data: {[key: string]: unknown}[]): Users => {
  return data.map((item:  {[key: string]: unknown | unknown[] | any}) => ({
    ...item,
    firstName: item.firstname,
    lastName: item.lastname,
    fullName: (item.firstname || item.lastname) ? `${item.firstname} ${item.lastname}` : undefined,
    userRolesString: (item.roles as UserRoles || []).map((item) => item.name).join(' | '),
    userRoleValues: (item.roles as UserRoles || []).map((item) => item.name),
    departmentValue: item.department?.name || '',
    status: userStatus[item.status],
    lastActiveDays: Math.round((new Date().getTime() - new Date(item.lastActive).getTime()) / (1000 * 60 * 60 * 24))
  })) as unknown[] as Users;
}
