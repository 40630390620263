import './styles.scss';

import { FC, useState } from 'react';
import { GenericData, IconButton, Menu, MenuItem, MoreIcon, Table } from '@flotilla/component-library';

import { ConversionFactor, ConversionFactors } from '../../../../../types/ConversionFactor';
import { addModal, removeModal } from '../../../../../reducers/modal';
import { useAppDispatch } from '../../../../../helpers/hooks';
import ConfirmDeleteModal from '../../../../../components/Modal/ConfirmDeleteModal';
import ConversionFactorModal from '../../../../../components/Modal/ConversionFactorModal';

interface TableViewProps {
  className?: string;
  data?: ConversionFactors;
  loading?: boolean;
  deleteConversionFactor?: (id: number) => Promise<void>;
  updateConversionFactor?: () => void;
};

const TableView: FC<TableViewProps> = ({
  className = "",
  data,
  loading = false,
  deleteConversionFactor,
  updateConversionFactor = () => ({})
}) => {
  const dispatch = useAppDispatch();
  const [menuConversionFactor, setMenuConversionFactor] = useState<ConversionFactor>();
  const handleOnClickMore = (conversionFactor: ConversionFactor) => {
    return () => {
      setMenuConversionFactor(conversionFactor);
    }
  }

  const handleOnCloseMenu = () => {
    setMenuConversionFactor(undefined);
  }

  const handleOnClickEdit = () => {
    dispatch(addModal({
      id: 'update-conversion-factor-modal',
      children: (
        <ConversionFactorModal
          onClose={() => dispatch(removeModal())}
          onSubmit={() => {
            dispatch(removeModal());
            updateConversionFactor();
          }}
          conversionFactor={menuConversionFactor}
        />
      )
    }));
    
  }

  const handleOnClickDelete = () => {
    if (menuConversionFactor && deleteConversionFactor) {
      dispatch(addModal({
        id: 'delete-conversion-factor-modal',
        children: (
          <ConfirmDeleteModal
            header="Delete Conversion Factor"
            deleteItem={() => deleteConversionFactor(menuConversionFactor.id)}
          />
        )
      }));
    }
  }
  
  return (
    <section id="conversion-factor-table-view" className={className}>
      <Table
        id="conversion-factor-table"
        isLoading={loading}
        stickyHeader
        dataTable
        headers={[
          {
            key: "activityName",
            label: "Name"
          },
          {
            key: "measureName",
            label: "Measure"
          },
          {
            key: "year",
            label: "Year",
            type: 'number'
          },
          {
            key: "value",
            label: "Value",
            type: 'number'
          },
          {
            key: "minValue",
            label: "Min",
            type: 'number'
          },
          {
            key: "maxValue",
            label: "Max",
            type: 'number'
          },
          {
            key: "isDaily",
            label: "Daily"
          },
          {
            key: "",
            label: ""
          },
        ]}
        data={data as unknown as GenericData}
        rowComponent={(_item) => {
          const item = _item as unknown as ConversionFactor;
          const {
            id,
            activityName,
            measureName,
            year,
            value,
            minValue,
            maxValue,
            isDaily
          } = item;
          return (
            <tr key={id}>
              <td>{activityName}</td>
              <td>{measureName}</td>
              <td>{year}</td>
              <td>{value}</td>
              <td>{minValue}</td>
              <td>{maxValue}</td>
              <td>{isDaily ? 'Daily' : ''}</td>
              <td id="more-container">
                <section>
                  <IconButton
                    icon={<MoreIcon variant='dark' />}
                    small
                    variant='ghost'
                    onClick={handleOnClickMore(item)}
                  />
                  { item.id === menuConversionFactor?.id && (
                    <Menu id="conversion-factor-menu" onClose={handleOnCloseMenu}>
                      <MenuItem onClick={handleOnClickEdit}>Edit</MenuItem>
                      <MenuItem onClick={handleOnClickDelete}>Delete</MenuItem>
                    </Menu>
                  )}
                </section>
              </td>
            </tr>
          )
        }}
      />
    </section>
  );
}

export default TableView;
