import './styles.scss';
import { Button, Modal } from "@flotilla/component-library";

interface ConfirmRemoveModalProps {
    onClose: () => void;
    onSuccess: () => void;
  };

const ConfirmRemoveModal: React.FC<ConfirmRemoveModalProps> = ({
    onClose = () => {},
    onSuccess = () => {}
  }) => {
    return (
        <Modal
          id="hide-report-modal"
          onClose={onClose}
        >
            <h2>Remove Action</h2>
            <main>
                <p>Are you sure you wish to remove this action from your plan?</p>
            </main>
            <section className="button-container">
                <Button onClick={onSuccess}>Confirm</Button>
                <Button variant="ghost" onClick={onClose}>
                    Cancel
                </Button>
            </section>
        </Modal>);
}

export default ConfirmRemoveModal;