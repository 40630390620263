import { API_COMPANY } from "../constants";
import { checkAuthorised, checkErrors } from "../helpers/checkAuthorisedAPI";
import { Companies, Company, mapCompanies } from "../types/Company";
import { Lookup } from "../types/Lookup";
import { dedupeFetch } from "./dedupeFetch";

export const getAllCompanies = (): Promise<Companies | void> => {
  return fetch(`${API_COMPANY}/GetAll`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => {
      return mapCompanies(res.payload);
    })
}

export const getCompany = (id: string): Promise<Company> => {
  return dedupeFetch(`${API_COMPANY}/Get?companyId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => checkErrors(res))
    .then((res) => mapCompanies([res.payload])[0]);
}

export const getChildCompanies = (id: string | number): Promise<Companies | void> => {
  return dedupeFetch(`${API_COMPANY}/GetChildCompanies?parentCompanyId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => mapCompanies(res.payload));
}

export const createCompany = (parentId: number = 0, companyName: string, companyType?: Lookup, productType?: Lookup): Promise<Company> => {
  return fetch(`${API_COMPANY}/Create`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: JSON.stringify({
      parentId,
      name: companyName,
      companyType: companyType?.id,
      product: productType?.id
    }),
  })
    .then((res) => checkAuthorised(res))
    .then((res) => checkErrors(res))
    .then((res) => mapCompanies([res.payload])[0]);
}

export const setCompanyLogo = (id: string, logo: File): Promise<string> => {
  const data = new FormData();
  data.append('file', logo);
  data.append('companyId', id);
  return fetch(`${API_COMPANY}/UploadLogo`, {
    headers: {
      "X-CSRF": "1",
    },
    method: 'POST',
    body: data,
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.payload as string);
}

export const editCompanyDetails = (companyDetails: Company): Promise<boolean> => {
  return fetch(`${API_COMPANY}/Update`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'PUT',
    body: JSON.stringify({
      ...companyDetails,
      companyType: companyDetails.companyType?.id,
      product: companyDetails.product?.id
    }),
  })
    .then((res) => checkAuthorised(res))
    .then((res) => checkErrors(res))
    .then((res) => res.success as boolean);
}
