import './styles.scss';

import { useCallback, useEffect, useState } from 'react';
import { Reorder } from 'framer-motion';

import { Page } from '../../../../../../types/Report';
import { ContentsOption } from './ContentsOption';
import { ReactComponent as ContentsIcon } from '../../../../../../assets/icons/Contents.svg';

type ContentsSideBarProps = {
  pages: Page[];
  id?: string;
  className?: string;
  onChangePage: (id: number, pageNumber: number, section: string) => void;
  onDeletePage: (id: number) => void;
};

export const ContentsSideBar: React.FC<ContentsSideBarProps> = ({
  pages,
  id = "",
  className = "",
  onChangePage,
  onDeletePage,
}) => {
  const [hidden, setHidden] = useState<boolean>(true);
  const [pagesList, setPagesList] = useState<Page[]>(pages);
  const [activePageId, setActivePageId] = useState<number>();

  const handleOnReorder = (reorderedPages: Page[]) => {
    setPagesList(reorderedPages);
  };

  const getNewPageSection = (
    page: Page,
    index: number,
    pages: Page[]
  ): string => {
    if (page.type === 'SectionCover') {
      return page.pageSection;
    } else if (index > 0) {
      return pages[index - 1].pageSection;
    }
    return '';
  };

  const handleOnMouseUp = (page: Page) => {
    const id = page.pageId;
    const elementId = `${page.pageTitle} - ${page.pageNumber}`;

    const pagesListIndex = pagesList.findIndex((page) => page.pageId === id);
    const pagesIndex = pages.findIndex((page) => page.pageId === id);
    if (pagesListIndex !== pagesIndex) {
      const newPageSection = getNewPageSection(page, pagesListIndex, pagesList);
      onChangePage(id, pagesListIndex + 1, newPageSection);
    } else {
      const element = document.getElementById(elementId);
      if (element) {
        const topOffset = -100;
        const scrollPosition = element.getBoundingClientRect().top + window.scrollY + topOffset;
        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      }
    }
  };

  const handleScroll = useCallback(async () => {
    const inViewPage = pages.find((page) => {
      const element = document.getElementById(
        `${page.pageTitle} - ${page.pageNumber}`
      );
      const elementYValue = element?.getBoundingClientRect().y || 0;
      const elementHeightOffset = (element?.offsetHeight || 0);
      return elementYValue < elementHeightOffset && elementYValue > 0;
    });
    if (inViewPage) {
      setActivePageId(inViewPage.pageId);
    } else {
      setActivePageId(-1);
    }
  }, [pages]);

  const getPageNumber = () => {
    const activePage = pagesList.find((page) => page.pageId === activePageId);
    return activePage?.pageNumber;
  };

  const handleDeletePage = (id: number) => {
    setPagesList(pagesList.filter((page) => page.pageId !== id));
    onDeletePage(id);
  };

  useEffect(() => {
    setPagesList(pages);
    document.getElementById("report-detail-page")?.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      document.getElementById("report-detail-page")?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, pages]);

  return (
    <Reorder.Group
      id={id}
      className={`contents-sidebar ${hidden ? "contents-sidebar--hide" : ""} ${className}`}
      axis="y"
      values={pagesList}
      onReorder={handleOnReorder}
    >
      <button onClick={() => setHidden(!hidden)} id="toggle">
        <ContentsIcon />
        <p id="active-page-number">
          {activePageId && activePageId > -1 ? `Page ${getPageNumber()}` : ' '}
        </p>
      </button>
      <section className="contents-container">
        <h2>Contents</h2>
        {pagesList.map((page) => {
          return (
            <Reorder.Item
              key={`${page.pageId} ${page.pageNumber}`}
              value={page}
              onMouseUp={() => handleOnMouseUp(page)}
            >
              <ContentsOption
                page={page}
                active={page.pageId === activePageId}
                onDelete={() => handleDeletePage(page.pageId)}
              />
            </Reorder.Item>
          );
        })}
      </section>
    </Reorder.Group>
  );
};
