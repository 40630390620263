import './styles.scss';

import React, { useEffect, useState } from 'react';
import { CheckboxButtonV2, Skeleton, useAlert } from '@flotilla/component-library';

import { SectionProps } from '../../../../../../types/CreateWizard';
import { Sector, Sectors } from '../../../../../../types/CompanySettings';
import { getSectors } from '../../../../../../api/CompanySettings';

const SectorSection: React.FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const { addAlert } = useAlert();
  const [sectorId, setSectorId] = useState<number>(values?.["sectorId"]);
  const [sectors, setSectors] = useState<Sectors>();
  const [loading, setLoading] = useState(false);

  const handleOnToggle = (selectedOption: Sector) => {
    return (checked: boolean) => {
      const value = checked ? selectedOption.id : 0;
      setSectorId(value);
      onChange({
        sectorId: value,
        sector: checked ? selectedOption : undefined
      });
    }
  }

  useEffect(() => {
    setLoading(true);
    getSectors()
        .then(res => {
            setSectors(res);
        })
        .catch(() => {
            addAlert({ id: `Sectors Get Failed`, type: "error", title: "Failed to retrieve sector options", content: "Failed to retrieve the available sector options, please refresh to try again." });
        })
        .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section id="sector-section" className={className}>
      <article id="options">
        { sectors?.map((option, index) => 
          <CheckboxButtonV2
            className='checkbox'
            key={index}
            label={option.name}
            checked={option.id === sectorId}
            onToggle={handleOnToggle(option)}
          />
        )}
        { loading && (
          <>
            <Skeleton className="checkbox--loading" />
            <Skeleton className="checkbox--loading" />
            <Skeleton className="checkbox--loading" />
          </>
        )}
      </article>
    </section>
  );
}

export default SectorSection;
