import { useCallback, useEffect, useState } from "react";
import { useAlert } from "@flotilla/component-library";

import { useAppDispatch } from "../../helpers/hooks";
import { getMeasures } from "../../api/Measure";
import { Measures } from "../../types/Measure";

const useMeasures = () => {
  const { addAlert } = useAlert();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [measures, setMeasures] = useState<Measures>();

  const fetchApi = useCallback(() => {
    setLoading(true);
    getMeasures()
      .then(setMeasures)
      .catch((error) => {
        addAlert({
          id: "Get Measures Error",
          type: 'error',
          title: 'Error getting Measures',
          content: 'We are sorry there seems to be an issue getting the Measures. Please try again.',
        });
        console.log("Error while getting Measures: ", error);
      })
      .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const refreshMeasures = () => {
    fetchApi();
  }

  useEffect(() => {
    fetchApi();
  }, [dispatch, fetchApi]);

  return {
    measures,
    refreshMeasures,
    loading
  };
}

export default useMeasures;
