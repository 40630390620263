import './styles.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert, Wizard } from '@flotilla/component-library';

import { getLocationWizardPages } from '../../../../assets/content/CreateWizard';
import useLocation from '../../../../hooks/Location/useLocation';
import FinalSection from '../../../../assets/content/CreateWizard/Location/Sections/Final';

interface LocationCreateProps {
  className?: string;
};

const LocationCreate: React.FC<LocationCreateProps> = ({
  className = "",
}) => {
  const { addAlert } = useAlert();
  const navigate = useNavigate();
  const {
    location,
    saveLocation,
    updateLocation,
    loading
  } = useLocation();

  const handleOnComplete = () => {
    saveLocation()
      .then((res) => {
        addAlert({
          id: `Location Saved ${location?.id}`,
          type: 'success',
          title: 'Location Saved Successfully'
        });
        navigate(res ? `../${res.id}` : '..', { replace: true })
      })
      .catch(() => addAlert({
        id: `Location Saved ${location?.id}`,
        type: "error",
        title: "Location Save Unsuccessful",
        content: "Location failed to save please try again." })
      );
  }
  
  const handleOnChange = (values: { [key: string]: any }) => {
    updateLocation(values);
  }

  return (
    <Wizard
      className={className}
      pages={getLocationWizardPages(location)}
      values={location}
      onChange={handleOnChange}
      startPage={{
        name: 'Initial',
        title: 'Create Location',
        subtitle: 'Welcome to your Location Creator Wizard, this Wizard will help create a new Location to use in our Data Collection!'
      }}
      endPage={{
        name: 'Final',
        title: 'Create Location',
        subtitle: 'Please confirm the details you have entered below. Once the location has been created, the address can not be changed.',
        children: <FinalSection values={location}/>,
      }}
      submitLoading={loading}
      onComplete={handleOnComplete}
      onBack={() => navigate(-1)}
    />
  );
}

export default LocationCreate;
