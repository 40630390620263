import './styles.scss';

import React, { MouseEventHandler } from 'react';
import { Skeleton } from '@flotilla/component-library';

import { User, Users } from '../../../../types/User';
import UserCard from './User';

interface CardViewProps {
  className?: string;
  data: Users;
  loading?: boolean;
  onClick?: (user: User) => void;
  onChange?: () => void;
};

const CardView: React.FC<CardViewProps> = ({
  className = "",
  data,
  loading = false,
  onClick = () => ({}),
  onChange = () => ({})
}) => {
  const handleActionClick = (user: User): MouseEventHandler<HTMLElement> => {
    return (event) => {
      event.stopPropagation();
      onClick(user);
    }
  }

  return (
    <section id="card-view" className={className}>
      { data?.length > 0 ? (
        data?.map((user) => (
          <UserCard
            key={user.id}
            data={user}
            onClick={handleActionClick(user)}
            onChange={onChange}
          />
        ))
      ) : (
        loading && (
          <>
            <Skeleton className="user-card--loading"/>
            <Skeleton className="user-card--loading"/>
            <Skeleton className="user-card--loading"/>
          </>
        )
      )}
    </section>
  );
}

export default CardView;
