import './styles.scss';

import React, { ChangeEventHandler, useState } from 'react';
import { TextInput } from '@flotilla/component-library';

import { getDateYYYYMMDDString } from '../../../../../../helpers/dateFormatter';
import { SectionProps } from '../../../../../../types/CreateWizard';

const LocationStartDateSection: React.FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const [startDate, setStartDate] = useState<string>(values?.["startDate"] || new Date().toISOString().substring(0, 10));

  const handleStartDateChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = getDateYYYYMMDDString(event.currentTarget.value);

    setStartDate(value);
    onChange({ startDate: value });
  }

  return (
    <section id="location-start-date-section" className={className}>
      <TextInput
        id="location-start-date"
        label="Location Start Date"
        aria-label='Location start date'
        value={startDate}
        type='date'
        onChange={handleStartDateChange}
        alertType={startDate === '' ? 'error' : undefined}
        alertMessage={startDate === '' ? 'Date needs to be a suitable value.': undefined}
      />
    </section>
  );
}

export default LocationStartDateSection;
