type BrandColorsType = {
  dark: string;
  light: string;
  brand: string;
};

type ColorsType = {
  primary: string;
  secondary: string;
  invertPrimary: string;
  invertSecondary: string;
  brand: string;
};

type ExtraColorsType = {
  commute: string;
  businessTravel: string;
  businessExpenses: string;
  you: string;
};

type ColorsGroupType = {
  text: ColorsType;
  surface: ColorsType;
  border: ColorsType;
  progressBar: string;
  extraColors: ExtraColorsType;
  error: ColorsType;
  success: ColorsType;
};

type ImpactAreaColorsType = {
  travellogistics: ColorsType;
  supplychain: ColorsType;
  facilities: ColorsType;
  buildings: ColorsType;
  people: ColorsType;
  governance: ColorsType;
};

export const BrandColors: BrandColorsType = {
  dark: '#011E26',
  light: '#FBFAF8',
  brand: '#E35924'
};

export const GreyColors: { [key: number]: string } = {
  20: '#F6F6F4',
  40: '#F1F1F0',
  60: '#ECEDEB',
  80: '#E7E8E7',
  100: '#E2E4E3',
  200: '#C9CECE',
  700: '#4C6065',
  800: '#334A50',
  900: '#1A343B'
};

const Colors: ColorsGroupType = {
  text: {
    primary: GreyColors[100],
    secondary: GreyColors[200],
    invertPrimary: GreyColors[900],
    invertSecondary: GreyColors[700],
    brand: BrandColors.brand
  },
  surface: {
    primary: BrandColors.dark,
    secondary: GreyColors[800],
    invertPrimary: BrandColors.light,
    invertSecondary: GreyColors[100],
    brand: BrandColors.brand
  },
  border: {
    primary: GreyColors[100],
    secondary: GreyColors[200],
    invertPrimary: GreyColors[900],
    invertSecondary: GreyColors[700],
    brand: BrandColors.brand
  },
  progressBar: GreyColors[800],
  extraColors: {
    commute: '#B2DC50',
    businessTravel: '#0069B0',
    businessExpenses: '#FFA400',
    you: '#F95738'
  },
  error: {
    primary: '#FF4040',
    secondary: '#FF4040',
    invertPrimary: '',
    invertSecondary: '',
    brand: '#FF4040'
  },
  success: {
    primary: '#38A169',
    secondary: '#38A169',
    invertPrimary: '',
    invertSecondary: '',
    brand: '#38A169'
  }
};

const ImpactAreaColors: ImpactAreaColorsType = {
  travellogistics: {
    primary: '#C7D043',
    secondary: '#C7D043',
    invertPrimary: '',
    invertSecondary: '',
    brand: '#C7D043'
  },
  supplychain: {
    primary: '#648491',
    secondary: '#648491',
    invertPrimary: '',
    invertSecondary: '',
    brand: '#648491'
  },
  facilities: {
    primary: '#213F37',
    secondary: '#213F37',
    invertPrimary: '',
    invertSecondary: '',
    brand: '#213F37'
  },
  buildings: {
    primary: '#1BAF94',
    secondary: '#1BAF94',
    invertPrimary: '',
    invertSecondary: '',
    brand: '#1BAF94'
  },
  people: {
    primary: '#d87093',
    secondary: '#d87093',
    invertPrimary: '',
    invertSecondary: '',
    brand: '#d87093'
  },
  governance: {
    primary: '#0D91E2',
    secondary: '#0D91E2',
    invertPrimary: '',
    invertSecondary: '',
    brand: '#0D91E2'
  },
}

export default Colors;
export { ImpactAreaColors, type ImpactAreaColorsType };
