import './styles.scss';

import ReportOl from '../../ReportOl';
import ReportDiv from '../../ReportDiv';
import ReportH3 from '../../ReportH3';
import { ReportEditorProps } from '..';
import ReportOlLi from '../../ReportOlLi';

export const OrderedList: React.FC<ReportEditorProps> = ({
  style = {},
  editorContent,
  downloadVersion = false,
}) => {
  const {
    header = '',
    content = [],
    boxAccentColor = '#011E26',
  } = editorContent;

  return (
    <ReportDiv
      style={style}
      className={`report-editor-ordered-list-container`}
      downloadVersion={downloadVersion}
    >
      {header && (
        <ReportH3 downloadVersion={downloadVersion}>{header}</ReportH3>
      )}
      <ReportOl
        className="report-editor-ordered-list"
        style={{ borderColor: boxAccentColor }}
        downloadVersion={downloadVersion}
      >
        {content?.map((contentItem, index) => (
          <ReportOlLi
            key={index}
            number={index + 1}
            style={{ borderColor: boxAccentColor }}
            downloadVersion={downloadVersion}
          >
            {contentItem}
          </ReportOlLi>
        ))}
      </ReportOl>
    </ReportDiv>
  );
};
