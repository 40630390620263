import { FC } from 'react';

import { Route, Routes } from 'react-router-dom';

import ConversionFactorList from './List';

const ConversionFactor: FC = () => {
  return (
    <Routes>
      <Route index element={<ConversionFactorList />} />
    </Routes>
  );
}

export default ConversionFactor;
