import './styles.scss';

import React, { useEffect, useState } from 'react';
import { Skeleton } from '@flotilla/component-library';

import { Lookup, Lookups } from '../../../../../../types/Lookup';
import CheckboxButton from '../../../../../../components/CheckboxButton';
import useProductTypes from '../../../../../../hooks/Lookup/useProductTypes';

interface ProductSelectorProps {
  className?: string;
  products?: Lookups;
  onChange?: (products?: Lookups) => void,
};

const ProductSelector: React.FC<ProductSelectorProps> = ({
  className = "",
  products: _products = [],
  onChange = () => ({}),
}) => {
  const [products, setProducts] = useState<Lookups>(_products);
  const { productTypes: productsLookup, loading } = useProductTypes();

  useEffect(() => {
    _products && setProducts(_products);
  }, [_products]);

  const handleCheckboxChange = (item: Lookup): ((checked: boolean) => void) => {
    return (checked: boolean) => {
      var newProducts = products;
      if (checked) {
        newProducts = [
          ...(products || []),
          item
        ];
      } else {
        newProducts = products?.filter((product) => product.id !== item.id);
      }
      setProducts(newProducts);
      onChange(newProducts);
    }
  }

  return (
    <section id="product-selector" className={className}>
      <h4>Products</h4>
      <article className="checkbox-container">
        { !loading ? ( productsLookup?.map((item) =>  (
          <CheckboxButton
            key={item.id}
            label={item.label}
            onToggle={handleCheckboxChange(item)}
            checked={products.findIndex((product) => product.id === item.id) > -1}
            className="checkbox"
          />
        ))) : (
          <>
            <Skeleton className="checkbox--skeleton"/>
            <Skeleton className="checkbox--skeleton"/>
            <Skeleton className="checkbox--skeleton"/>
          </>
        )}
      </article>
    </section>
  );
}

export default ProductSelector;
