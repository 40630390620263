import './styles.scss';

import React, { useState } from 'react';
import { CheckboxButtonV2, NumberInput, Switch } from '@flotilla/component-library';

import Header from '../../../../components/HeaderV2';
import PolicyCheckerSummary, { PolicyCheckerValues, publicSectorValueOptions } from '../../../../components/PolicyCheckerSummary';
import { POLICY_CHECKER_SUBTITLE } from '../../../../assets/content/Subtitles';

interface PolicyCheckerProps {
  className?: string;
};

interface PolicyCheckerQuestionProps {
  question: PolicyCheckerQuestionType;
  values?: PolicyCheckerValues;
  onChange?: (data: Partial<PolicyCheckerValues>) => void;
}

type ConditionalPolicyCheckerQuestion = {
  condition?: string;
  question: PolicyCheckerQuestionType;
}

type PolicyCheckerQuestionType = {
  type: string;
  label: string;
  id?: string;
  key: keyof PolicyCheckerValues;
  options?: string[];
  conditionalQuestion?: ConditionalPolicyCheckerQuestion;
  [key: string]: any;
}

const POLICY_CHECKER_QUESTIONS: PolicyCheckerQuestionType[] = [
  {
    type: 'number',
    label: 'Turnover',
    key: 'turnover',
    prependChild: '£',
    financial: true,
    decimal: true,
  },
  {
    type: 'number',
    label: 'Balance sheet',
    key: 'balanceSheet',
    id: 'balance-sheet',
    prependChild: '£',
    financial: true,
    decimal: true,
  },
  {
    type: 'number',
    label: 'Headcount',
    key: 'headcount',
    financial: true,
  },
  {
    type: 'switch',
    label: 'Are you a listed entity?',
    key: 'listedEntity'
  },
  {
    type: 'switch',
    label: 'Are you a UK PIE (Public Interest Entity)?',
    key: 'ukPie'
  },
  {
    type: 'switch',
    label: 'Are you a financial institution?',
    key: 'financeInstitution'
  },
  {
    type: 'switch',
    label: 'Do you tender within the public sector or to the NHS?',
    key: 'tenderPublicSector',
    conditionalQuestion: {
      question: {
        type: 'checkbox',
        label: 'What is the value of your contract?',
        key: 'publicSectorValue',
        options: publicSectorValueOptions
      }
    }
  },
  {
    type: 'switch',
    label: 'Do you produce packaging?',
    key: 'producePackaging'
  },
  {
    type: 'switch',
    label: 'Are you in the construction industry?',
    key: 'constructionIndustry'
  },
  {
    type: 'switch',
    label: 'Do you lease property to a third-party?',
    key: 'propertyLandlord'
  },
  {
    type: 'switch',
    label: 'Do you have significant operations within the EU?',
    key: 'operationsEU'
  }
];



const PolicyCheckerQuestion: React.FC<PolicyCheckerQuestionProps> = ({
  question,
  values = {},
  onChange = () => ({})
}) => {
  const {
    id,
    label,
    key,
    type,
    conditionalQuestion,
    options,
    prependChild,
    financial,
    decimal,
  } = question;

  const handleOnChangeNumberInput = (key: keyof PolicyCheckerValues) => {
    return (value: number | undefined) => {
      onChange({
        ...values,
        [key]: value
      })
    }
  }

  const handleOnToggleSwitch = (key: keyof PolicyCheckerValues) => {
    return (checked: boolean | undefined) => {
      onChange({
        ...values,
        [key]: checked
      })
    }
  }

  const handleOnToggleOption = (key: keyof PolicyCheckerValues, option: string) => {
    return (checked: boolean | undefined) => {
      onChange({
        ...values,
        [key]: checked ? option : undefined
      })
    }
  }

  if (type === 'switch') {
    return (
      <>
        <article className="switch-input">
          <label>{label}</label>
          <Switch
            checked={values[key] as boolean}
            onToggle={handleOnToggleSwitch(key)}
            small
          />
        </article>
        {conditionalQuestion && values[key] && <PolicyCheckerQuestion question={conditionalQuestion.question} onChange={onChange} values={values} />}
      </>
    )
  } else if (type === "checkbox") {
    return (
      <article className="checkbox-input">
        <label>{label}</label>
        <section className="options">
          {options?.map((option) => (
            <CheckboxButtonV2
            onToggle={handleOnToggleOption(key, option)}
            checked={option === values[key]}
            >
              {option}
            </CheckboxButtonV2>
          ))}
        </section>
      </article>
    )
  }

  return (
    <NumberInput
      id={id || key}
      label={label}
      value={values[key] as number || undefined}
      onChange={handleOnChangeNumberInput(key)}
      prependChild={prependChild}
      financial={financial}
      decimal={decimal}
    />
  )
}

const PolicyChecker: React.FC<PolicyCheckerProps> = ({
  className = "",
}) => {
  const [policyCheckerValues, setPolicyCheckerValues] = useState<PolicyCheckerValues>({});

  const handleUpdatePolicyCheckerValues = (data: Partial<PolicyCheckerValues>) => {
    setPolicyCheckerValues({
      ...policyCheckerValues,
      ...data
    })
  }

  return (
    <section id="policy-checker-page" className={className}>
      <Header
        subtitle={
          <p>
            {POLICY_CHECKER_SUBTITLE
              .split("%%EMAIL%%")
              .map(item => {
                if (item.startsWith("[") && item.endsWith("]")) {
                  const email = item.replaceAll("[", "").replaceAll("]", "");
                  return <a href={`mailto:${email}`}>{email}</a>;
                }
                return item;
              })
            }
          </p>
        }
      >
        Policy Checker
      </Header>
      <main>
        <section id="inputs">
          { POLICY_CHECKER_QUESTIONS.map((question) => <PolicyCheckerQuestion question={question} onChange={handleUpdatePolicyCheckerValues} values={policyCheckerValues}/>)}
        </section>
        <PolicyCheckerSummary
          className='policy-checker-summary'
          policyCheckerValues={policyCheckerValues}
        />
      </main>
    </section>
  );
}

export default PolicyChecker;
