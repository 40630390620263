import './styles.scss';

import React, { useEffect } from 'react';

import { SectionProps } from '../../../../../../types/CreateWizard';
import Question from '../../../../../../components/Question';
import { Submissions } from '../../../../../../types/Question';
import useLocationMandatoryQuestions from '../../../../../../hooks/Location/useLocationMandatoryQuestions';
import { Skeleton } from '@flotilla/component-library';

const LocationExtrasSection: React.FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const {
    questions,
    updateSubmissions,
    loading,
    submissions,
    completed
  } = useLocationMandatoryQuestions(values?.["extras"]);

  const handleOnChange = (questionId: number, _updatedSubmissions: Submissions = []) => {
    updateSubmissions(questionId, _updatedSubmissions);
  }

  useEffect(() => {
    onChange({
      extras: completed ? submissions : undefined
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed, submissions]);

  return (
    <section id="location-extras-section" className={className}>
      { questions?.map((question) => (
        <Question
          key={question.id}
          question={question}
          onChange={handleOnChange}
        />
      )) }
      { loading && (
        <>
          <Skeleton className="question--loading" />
          <Skeleton className="question--loading" />
        </>
      )}
    </section>
  );
}

export default LocationExtrasSection;
