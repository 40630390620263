import { useCallback, useEffect, useState } from "react";
import { useAlert } from "@flotilla/component-library";

import { useAppDispatch, useAppSelector } from "../../helpers/hooks";
import { Actions } from "../../types/Action";
import { getAllActions } from "../../api/netZeroPlan";
import { useCompanyId } from "../../context";
import { getNetZeroPlanId } from "../../reducers/user";
import { getActions } from "../../api/Action";

const useActions = (isAdmin: boolean = false) => {
  const { addAlert } = useAlert();
  const dispatch = useAppDispatch();
  const companyId = useCompanyId();
  const netZeroPlanId = useAppSelector(getNetZeroPlanId);
  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState<Actions>();

  const fetchApi = useCallback(() => {
    if (!isAdmin && netZeroPlanId) {
      setLoading(true);
      getAllActions(netZeroPlanId, companyId)
        .then(setActions)
        .catch((error) => {
          addAlert({
            id: "Get Actions Error",
            type: 'error',
            title: 'Error getting Actions',
            content: 'We are sorry there seems to be an issue getting the Actions. Please try again.',
          });
          console.log("Error while getting Actions: ", error);
        })
        .finally(() => setLoading(false));
    } else if (isAdmin) {
      setLoading(true);
      getActions()
        .then(setActions)
        .catch((error) => {
          addAlert({
            id: "Get Actions Error",
            type: 'error',
            title: 'Error getting Actions',
            content: 'We are sorry there seems to be an issue getting the Actions. Please try again.',
          });
          console.log("Error while getting Actions: ", error);
        })
        .finally(() => setLoading(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const updateActions = () => {
    fetchApi();
  }

  useEffect(() => {
    fetchApi();
  }, [dispatch, fetchApi]);

  return {
    actions,
    updateActions,
    loading
  };
}

export default useActions;
