import "gantt-task-react/dist/index.css";
import './styles.scss';

import React from "react";
import { useEffect, useState } from "react";
import { Gantt, ViewMode } from "gantt-task-react";
import { Selector, SelectorOption, Switch, TextInput } from "@flotilla/component-library";

import ActionGanttTaskList from "./ActionGanttTaskList";
import ActionGanttTaskListHeader from "./ActionGanttTaskListHeader";
import ActionGanttTooltip from "./ActionGanttTooltip";
import { ActionGanttProps, Task } from "./ActionGantt.types";
import { Action } from "../../types/Action";
import { getImpactAreaColor } from "../../helpers/colors";
import { FONT_FAMILY } from "../../styles/font";

// Sort prior to project/group generation
const sortActions = (a: Action, b: Action) => {
    if (a.impactAreaName !== b.impactAreaName) {
        return a.impactAreaName.localeCompare(b.impactAreaName);
    }

    return a.activityName && b.activityName ? a.activityName.localeCompare(b.activityName) : 0;
}

const sortGrouping = (a: Task, b: Task) => {
    if (a.start !== b.start) {
        return a.start.getTime() - b.start.getTime();
    }

    if (a.end !== b.end) {
        return a.end.getTime() - b.end.getTime();
    }

    return a.name.localeCompare(b.name);
};

// Sorting by start date requires a tiered sorting system
// ImpactAreas->Activities->NetZeroPlanActions
// in order to maintain groupings
const sortTasks = (tasks: Task[]) => {
    var result: Task[] = [];
    var impactAreas = tasks.filter(t => t.id.endsWith('ImpactArea')).sort(sortGrouping);

    impactAreas.forEach(k => {
        result.push(k);
        var activities = tasks.filter(t => t.id.endsWith('Activity') && t.action?.impactAreaName === k.action?.impactAreaName).sort(sortGrouping);

        activities.forEach(g => {
            result.push(g);
            var actions = tasks.filter(t => t.type === 'task' && t.action?.activityName === g.action?.activityName).sort(sortGrouping);

            result = result.concat(actions);
        });
    });

    return result;
}

const MIN_COLUMN_WIDTH = 84;
const MAX_COLUMN_WIDTH = 500;
const ONE_DAY_TICKS = 86400000;

type ViewOptions = {
    startDate: string;
    endDate: string;
    viewMode: SelectorOption;
    columnWidth: number;
    hideProjects: boolean;
}

const ActionGantt: React.FC<ActionGanttProps> = ({ actions = [], ganttHeight, columnWidth: defaultColumnWidth = 250, titleWidth = 350, locale = 'en-GB', onClick = () => { } }) => {
    const [tasks, setTasks] = useState<Task[]>([]);
    const [visibleTasks, setVisibleTasks] = useState<Task[]>([]);
    const [viewOptions, setViewOptions] = useState<ViewOptions>({
        viewMode: { key: ViewMode.Month },
        startDate: '',
        endDate: '',
        columnWidth: defaultColumnWidth,
        hideProjects: false,
    });

    useEffect(() => {
        const {
            startDate,
            endDate,
            viewMode,
            columnWidth,
            hideProjects
        } = viewOptions;
        let newTasks: Task[] = [];
        let lastProject = '';
        let lastActivity = '';

        // Pre-sort actions so that all ImpactAreas and Activities are together for grouping
        let sortedActions = [...actions].sort(sortActions);

        const createTask = (a: Action, id: string, name: string, children: Action[] = []) => {
            let start, end;

            if (children.length) {
                start = new Date(Math.min(...children.flatMap(c => c.startDate ? [new Date(c.startDate).getTime()] : [])));
                end = new Date(Math.max(...children.flatMap(c => c.targetCompletionDate ? [new Date(c.targetCompletionDate).getTime()] : [])));
            } else {
                start = a.startDate ? new Date(a.startDate) : new Date();
                end = a.targetCompletionDate ? new Date(a.targetCompletionDate) : new Date();
            }

            // Set minimum task duration (proportional to column width) within chart to avoid visual bugs on projects
            var widthMultiplier = ONE_DAY_TICKS * (MIN_COLUMN_WIDTH/columnWidth);
            switch (viewMode.key) {
                case ViewMode.Year:
                    let yearMinimumEnd = new Date(new Date(start).setTime(start.getTime() + (72 * widthMultiplier)));
                    end = end > yearMinimumEnd ? end : yearMinimumEnd;
                    break;
                case ViewMode.QuarterYear:
                    let quarterMinimumEnd = new Date(new Date(start).setTime(start.getTime() + (18 * widthMultiplier)));
                    end = end > quarterMinimumEnd ? end : quarterMinimumEnd;
                    break;
                case ViewMode.Month:
                    let monthMinimumEnd = new Date(new Date(start).setTime(start.getTime() + (6 * widthMultiplier)));
                    end = end > monthMinimumEnd ? end : monthMinimumEnd;
                    break;
                default:
                    end = start > end ? start : end;
            }


            newTasks.push({
                start: start,
                end: end,
                name: name,
                id,
                type: children.length ? 'project' : 'task',
                progress: 0,
                isDisabled: true,
                project: name === a.impactAreaName ? name : a.impactAreaName + a.activityName,
                styles: { backgroundColor: getImpactAreaColor(a.impactAreaName) },
                hideChildren: false,
                action: a
            });
        }

        sortedActions.forEach(a => {
            let allMatches: Action[];

            if (lastProject !== a.impactAreaName) {
                allMatches = sortedActions.filter(b => b.impactAreaName === a.impactAreaName);
                createTask(a, a.id + 'ImpactArea', a.impactAreaName, allMatches);
                lastProject = a.impactAreaName;
            }

            if (a.activityName && lastActivity !== a.activityName) {
                allMatches = sortedActions.filter(b => b.activityName === a.activityName);
                createTask(a, a.id + 'Activity', a.activityName, allMatches);
                lastActivity = a.activityName;
            }

            createTask(a, a.id.toString(), a.title);
        });

        newTasks = sortTasks(newTasks);

        if (hideProjects) {
            newTasks = newTasks.filter(t => t.type !== 'project');
        }

        let start = startDate ? new Date(startDate) : null;
        let end = endDate ? new Date(endDate) : null;

        end?.setMonth(end.getMonth() + 1);

        if(start) {
            newTasks = newTasks.filter(t => t.end > (start as Date));
        }

        if(end) {
            newTasks = newTasks.filter(t => t.start < (end as Date));
        }

        newTasks.forEach(t => {
            if(start && t.start < start) t.start = start;
            if(end && t.end > end) t.end = end;
            t.start.setHours(0, 0, 0, 1);
        })

        setTasks(newTasks);
        setVisibleTasks(newTasks);
    }, [actions, viewOptions]);

    const handleExpanderClick = (task: Task) => {
        var visibleTasksClone = [...visibleTasks];

        // Update hideChildren property
        var taskIdx = visibleTasksClone.findIndex(t => t.id === task.id);
        visibleTasksClone[taskIdx] = task;


        if (task.id.endsWith('Activity')) {
            // Activity - Sub Project
            if (task.hideChildren) {
                setVisibleTasks(visibleTasksClone.filter(t => !t.project?.endsWith(task?.name || '') || t.type === 'project'));
            } else {
                setVisibleTasks(sortTasks([...visibleTasksClone, ...tasks.filter(t => t.project === task.project && t.type !== 'project')]));
            }
        } else {
            // Impact Area - Project
            if (task.hideChildren) {
                setVisibleTasks(visibleTasksClone.filter(t => !t.project?.startsWith(task?.project || '') || (t.type === 'project' && !t.id.endsWith('Activity'))));
            } else {
                setVisibleTasks(sortTasks([...visibleTasksClone, ...tasks.filter(t => t.project?.startsWith(task?.project || '') && !(t.type === 'project' && !t.id.endsWith('Activity')))]));
            }
        }
    };

    const handleOnChangeViewOption = (updatedOption: Partial<ViewOptions>) => {
        const updatedViewOptions = {
            ...viewOptions,
            ...updatedOption
        } as ViewOptions;
        setViewOptions(updatedViewOptions);
        handleSaveViewOptions(updatedViewOptions);
    }

    const handleSaveViewOptions = (updatedViewOptions: ViewOptions) => {
        window.localStorage.setItem(`flotilla-gantt-options`, JSON.stringify(updatedViewOptions));
    }

    useEffect(() => {
        const localViewOptions = JSON.parse(window.localStorage.getItem(`flotilla-gantt-options`) || '{}');
        setViewOptions((prev) => ({
            ...prev,
            ...localViewOptions
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className="action-gantt">
            <section className="action-gantt-controls" data-html2canvas-ignore="true">
                <p>From </p>
                <TextInput
                    id="gantt-start-date"
                    type="month"
                    value={viewOptions.startDate}
                    onChange={(e) => handleOnChangeViewOption({ startDate: e.target.value})}
                />
                <p> to </p>
                <TextInput
                    id="gantt-end-date"
                    type="month"
                    value={viewOptions.endDate}
                    onChange={(e) => handleOnChangeViewOption({ endDate: e.target.value})}
                />
                <label id="action-toggle-label">
                    <p>Actions Only</p>
                </label>
                <Switch
                    checked={viewOptions.hideProjects}
                    onToggle={(val) => handleOnChangeViewOption({ hideProjects: val || false})}
                    small
                />
                <div className="slide-container">
                    <label>Column Width</label>
                    <input type="range" 
                        min={MIN_COLUMN_WIDTH}
                        max={MAX_COLUMN_WIDTH}
                        value={viewOptions.columnWidth}
                        onChange={(e) => handleOnChangeViewOption({ columnWidth: parseInt(e.target.value)}) } />
                </div>
                <Selector 
                    options={[
                        { key: ViewMode.Month, label: "By Month" },
                        { key: ViewMode.QuarterYear, label: "By Quarter" },
                        { key: ViewMode.Year, label: "By Year" },
                    ]}
                    onSelect={(o) => handleOnChangeViewOption({ viewMode: o })}
                    selected={viewOptions.viewMode.key}
                />
            </section>
            <section className="image-download-content">
                {tasks && tasks.length && <Gantt
                    tasks={visibleTasks}
                    viewMode={viewOptions.viewMode.key as ViewMode}
                    ganttHeight={ganttHeight}
                    columnWidth={viewOptions.columnWidth}
                    rowHeight={46}
                    listCellWidth={titleWidth + 'px'}
                    TaskListTable={ActionGanttTaskList}
                    TaskListHeader={ActionGanttTaskListHeader}
                    TooltipContent={ActionGanttTooltip}
                    onExpanderClick={handleExpanderClick}
                    onClick={onClick}
                    locale={locale}
                    fontFamily={FONT_FAMILY}
                    todayColor="#011E261A"
                    preStepsCount={0}
                />}
            </section>
        </section>
    )
}

export default ActionGantt;