import './styles.scss';

import React, { useEffect, useState } from 'react';
import { Alert, Card, NumberInput } from '@flotilla/component-library';
import { ResponsiveLine, Serie } from '@nivo/line';

import { emulateReductionTargets } from '../../../../../../api/Reduction';
import { useCompanyId } from '../../../../../../context';
import { scopeColorScale } from '../../../../../../helpers/colors';
import { SectionProps } from '../../../../../../types/CreateWizard';

const ReductionTargetSection: React.FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const companyId = useCompanyId();
  const scopes = ["1", "2", "3"];
  const [chartData, setChartData] = useState<Serie[]>([]);

  useEffect(() => {
    companyId && emulateReductionTargets({
      baselineYear: 2020,
      currentYear: 2021,
      interimTargetYear: values?.["interimTargetYear"] || 2030,
      targetYear: values?.["completionDate"],
      scope1ReductionTargetInterim: values?.scope1 ?? 50,
      scope2ReductionTargetInterim: values?.scope2 ?? 50,
      scope3ReductionTargetInterim: values?.scope3 ?? 40,
      scope1ReductionTargetNetZero: 90,
      scope2ReductionTargetNetZero: 90,
      scope3ReductionTargetNetZero: 90
    }, companyId)
      .then((res) => {
        const data: Serie[] = [];
        res.forEach((item) => {
          const dataIndex = data.findIndex((dataItem: Serie) => dataItem.id === `Scope ${item.scope}`);
          if (dataIndex > -1) {
            data[dataIndex].data.push({
              x: item.year,
              y: item.tCO2e
            });
          } else {
            data.push({
              id: `Scope ${item.scope}`,
              data: [
                {
                  x: item.year,
                  y: item.tCO2e
                }
              ]
            });
          }
        })
        setChartData(data.map((item) => ({...item, data: item.data.sort((a, b) => Number(a.x) - Number(b.x))})).sort((a, b) => b.id.toString().localeCompare(a.id.toString())));
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, values]);

  const handleReductionTargetChange = (scope: string) => {
    return (value: number | undefined) => {
      onChange({
        ...values,
        [`scope${scope}`]: value ? Math.round(value) : undefined
      });
    }
  }

  return (
    <section id="reduction-target-section" className={className}>
      <header>
        <section>
          <h4>2030</h4>
          <p>Interim Target</p>
        </section>
        <section>
          <h4>{values?.["completionDate"]}</h4>
          <p>Net Zero Target</p>
        </section>
      </header>
      {scopes.map((scope, index) => (
        <Card key={index} id={`scope-${scope}`} className="reduction-target-input">
          <h4>Scope {scope}</h4>
          <NumberInput
            id={`scope-${scope}-interim-target`}
            className="target input-target"
            name={`Scope ${scope} Interim Target`}
            onChange={handleReductionTargetChange(scope)}
            value={values?.[`scope${scope}`] ? Math.round(values?.[`scope${scope}`]) : 50}
            showStepperButtons
            step={5}
            max={90}
            min={scope === '3' ? 0 : 50}
            variant='stat'
            appendChild="%"
            autoFix
            decimal={false}
          />
          <h3 className="target">90%</h3>
        </Card>
      ))}
      {((values?.scope3 ?? 0) < 40 || (values?.scope3 ?? 0) > 50) && 
        <Alert 
          className="sbt-warning" 
          type='warning' 
          title='Most businesses set a scope 3 near-term target of between 40-50%. To align with science based targets your business should target a scope 3 reduction figure of no less than 40%.'
        />
      }
      <section id="reduction-chart">
        <ResponsiveLine
          animate
          curve="monotoneX"
          data={chartData}
          colors={(data) =>
            scopeColorScale[data.id.substring(data.id.length - 1)]
          }
          defs={[
            {
              colors: [
                {
                  color: 'inherit',
                  offset: 0
                },
                {
                  color: 'inherit',
                  offset: 100,
                  opacity: 0
                }
              ],
              id: 'gradientA',
              type: 'linearGradient'
            }
          ]}
          enableArea
          fill={[
            {
              id: 'gradientA',
              match: '*'
            }
          ]}
          margin={{
            bottom: 40,
            left: 40,
            right:20,
            top: 10
          }}
          axisBottom={{
            format: '%Y',
          }}
          xScale={{
            format: '%Y',
            precision: 'year',
            type: 'time',
            useUTC: false
          }}
          enablePointLabel
          enablePoints={false}
          enableGridX={false}
          enableGridY={false}
          isInteractive
          enableSlices="x"
          yFormat=">-.0f"
        />
      </section>
    </section>
  );
}

export default ReductionTargetSection;
